export default function getTotalInvoice(invoice: any, percentage: any = null) {
    const discountPercentage = invoice.percentage || percentage;
    let { totalInvoice } = invoice;

    if (discountPercentage) {
        totalInvoice -= +invoice.totalInvoice * (+discountPercentage / 100);
    }

    if (invoice.grossCommission) {
        totalInvoice += +invoice.grossCommission;
    }

    if (invoice.interestPercentage) {
        totalInvoice += +(
            totalInvoice *
            (Number(invoice.interestPercentage) / 100)
        );
    }

    return totalInvoice;
}
