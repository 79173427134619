/* eslint-disable import/prefer-default-export */
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledDiscountButton = styled(Button)`
    height: 20px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
