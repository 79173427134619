import EmailConfirmation from '../../pages/EmailConfirmation';
import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';

export const emailConfirmationRoute = new Route('/email-confirmation');

export function emailConfirmationRouteComponent() {
    return (
        <RouteWrapper
            path={emailConfirmationRoute.path}
            exact
            component={EmailConfirmation}
        />
    );
}
