import { Badge } from 'react-bootstrap';
import DigitalDocumentStatus from '../../../constants/digitalDocumentStatus';
import { documentTypes } from '../../../constants/documents';
import { SellersClicksignDocuments } from '../../../shared/interfaces/sellerClicksignDocuments.interface';

interface SellerDigitalDocumentStatusBadgeProps {
    digitalDocuments: SellersClicksignDocuments[];
}

export default function SellerDigitalDocumentStatusBadge({
    digitalDocuments,
}: SellerDigitalDocumentStatusBadgeProps) {
    const digitalDocument = digitalDocuments?.find(
        (document) =>
            document.documentType === documentTypes.TermsAndConditions,
    );

    if (!digitalDocument) {
        return <Badge variant="secondary">Não gerado</Badge>;
    }

    const { variant, label } = {
        [DigitalDocumentStatus.Sent]: {
            label: 'Em Andamento',
            variant: 'info',
        },
        [DigitalDocumentStatus.Signed]: {
            label: 'Assinado',
            variant: 'success',
        },
    }[digitalDocument.status];

    return <Badge variant={variant}>{label}</Badge>;
}
