import { Form, Modal, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ProductDeliveryDateHttpService from '../../../../../services/http/product-delivery-date-http';
import isFriendlyHttpError from '../../../../../utils/isFriendlyHttpError';
import { StyledButtonDiv, StyledCancelButton, StyledFooterDiv } from './styles';
import ConfirmCheckBox from './ConfirmCheckBox';
import IdentifierInput from './IdentifierInput';
import ConfirmButton from './ConfirmButton';

interface ProductDeliveryDateDeleteManyProps {
    show: boolean;
    onHide(): void;
}

export interface ProductDeliveryDateDeleteManyFields {
    recordOrigin: string;
    removeConfirm: boolean;
}

export default function ProductDeliveryDateDeleteManyModal({
    show,
    onHide,
}: ProductDeliveryDateDeleteManyProps) {
    const { control, watch, handleSubmit, errors, setValue } =
        useForm<ProductDeliveryDateDeleteManyFields>({
            shouldUnregister: false,
            defaultValues: {
                recordOrigin: '',
                removeConfirm: false,
            },
        });

    const formData = watch();

    const deleteManyMutation = useMutation({
        mutationFn: async (recordOrigin: string) => {
            await ProductDeliveryDateHttpService.removeByRecordOrigin(
                recordOrigin,
            );
        },
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);

                return;
            }

            toast.error('Ocorreu um erro ao remover produtos em lote');
        },
        onSuccess: async () => {
            toast.success('Produtos removidos com sucesso');
            onHide();
        },
    });

    const onSubmit = handleSubmit((data) =>
        deleteManyMutation.mutateAsync(data.recordOrigin),
    );

    return (
        <Modal
            aria-labelledby="invoice-validation-modal"
            size="lg"
            show={show}
            onHide={onHide}
            centered
        >
            <Form onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Exclusão em lote
                        <p>
                            Remove todas as datas de disponibilidade referente a
                            um identificador de Inclusão.
                        </p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-3">
                        <IdentifierInput
                            control={control}
                            errorMessage={errors.recordOrigin?.message}
                        />
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <StyledFooterDiv>
                        <ConfirmCheckBox
                            disabled={deleteManyMutation.isLoading}
                            onChange={() => {
                                setValue(
                                    'removeConfirm',
                                    !formData.removeConfirm,
                                );
                            }}
                            control={control}
                        />

                        <StyledButtonDiv>
                            <StyledCancelButton
                                onClick={onHide}
                                disabled={deleteManyMutation.isLoading}
                            >
                                Cancelar
                            </StyledCancelButton>

                            <ConfirmButton
                                disabled={
                                    !formData.removeConfirm ||
                                    deleteManyMutation.isLoading
                                }
                                isLoading={deleteManyMutation.isLoading}
                                canConfirm={formData.removeConfirm}
                            />
                        </StyledButtonDiv>
                    </StyledFooterDiv>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
