import Request from './request';

export default class CustomerHttpService {
    private static uri = 'erp/customers';

    public static readMany(params: {}) {
        return Request.get(this.uri, params);
    }

    public static readOne(id: string) {
        return Request.get(`${this.uri}/${id}`);
    }

    public static create(data: {}) {
        return Request.post(this.uri, data);
    }

    public static update(id: string, data: {}) {
        return Request.put(`${this.uri}/${id}`, data);
    }
}
