import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';
import List from '../../pages/Returns/List';

export const returnListRoute = new Route('/returns');

export const returnRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={returnListRoute.path}
            component={List}
            isPrivate
            exact
        />
    </Switch>
);
