import styled from 'styled-components';

export const StyledPageTitle = styled.h3`
    font-size: 22px !important;
    font-weight: bolder !important;
`;

export const StyledPageSubTitle = styled.p`
    font-size: 14px !important;
    margin-bottom: 10px !important;
`;
