/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { Form, Image, InputGroup } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { theme, control } from '../../../styles/react-select-config';
import Select from '../../Select';
import { signUpRoute } from '../../../routes/config';
import clockIcon from '../../../assets/icons/clock.svg';
import wrenchIcon from '../../../assets/icons/wrench.svg';
import panelIcon from '../../../assets/icons/panel.svg';
import thunderIcon from '../../../assets/icons/thunder.svg';
import employeesAmountIcon from '../../../assets/icons/employees_amount.svg';
import ticketIcon from '../../../assets/icons/ticket.svg';

interface filters {
    question1Data: any;
    question2Data: any;
    question3Data: any;
    question4Data: any;
    question5Data: any;
    question6Data: any;
}

const IntegratorCompanyInfo = (props: any) => {
    const pathname = window.location.pathname;

    const [profile, setProfile] = useState('');

    const {
        control: formControl,
        watch,
        reset,
        // formState,
    } = useForm<filters>({
        shouldUnregister: false,
        defaultValues: {
            question1Data: 0,
            question2Data: 0,
            question3Data: 0,
            question4Data: 0,
            question5Data: 0,
            question6Data: 0,
        },
    });

    const questionsData = watch();

    const question1: any[] = [
        { label: 'Acima de 3 anos', value: 'a', points: 15 },
        { label: 'Até 1 ano', value: 'b', points: 5 },
        { label: 'De 1 a 3 anos', value: 'c', points: 15 },
    ];

    const question2: any[] = [
        { label: 'Acima de 100', value: 'a', points: 20 },
        { label: 'Até 50', value: 'b', points: 10 },
        { label: 'De 50 a 100', value: 'c', points: 15 },
    ];

    const question3: any[] = [
        { label: 'Comercial ', value: 'a', points: 15 },
        { label: 'Grandes Usinas', value: 'b', points: 25 },
        { label: 'Residencial', value: 'c', points: 10 },
    ];

    const question4: any[] = [
        { label: 'Acima de 100 kWp', value: 'a', points: 20 },
        { label: 'Até 20 kWp', value: 'b', points: 10 },
        { label: 'De 20 kWp a 100 kWp', value: 'c', points: 15 },
    ];

    const question5: any[] = [
        { label: 'Acima de 15', value: 'a', points: 20 },
        { label: 'Até 5', value: 'b', points: 5 },
        { label: 'De 5 a 15', value: 'c', points: 10 },
    ];

    useEffect(() => {
        if (props.integrator.profilePoints) {
            if (props.integrator.profilePoints <= 40) {
                props.integrator.profile = 'Integrador Volume';
                setProfile('Integrador Volume');
            } else if (
                props.integrator.profilePoints > 40 &&
                props.integrator.profilePoints <= 70
            ) {
                props.integrator.profile = 'Integrador Crescimento';
                setProfile('Integrador Crescimento');
            } else if (props.integrator.profilePoints > 70) {
                props.integrator.profile = 'Integrador Valor';
                setProfile('Integrador Valor');
            }
        }

        let total = 0;

        if (questionsData.question1Data?.label) {
            props.integrator.question1 = questionsData.question1Data?.label;
        }

        if (questionsData.question2Data?.label) {
            props.integrator.question2 = questionsData.question2Data?.label;
        }

        if (questionsData.question3Data?.label) {
            props.integrator.question3 = questionsData.question3Data?.label;
        }

        if (questionsData.question4Data?.label) {
            props.integrator.question4 = questionsData.question4Data?.label;
        }

        if (questionsData.question5Data?.label) {
            props.integrator.question5 = questionsData.question5Data?.label;
        }

        // eslint-disable-next-line
        Object.values(questionsData).map((item) => {
            if (item !== 0) {
                total += item?.points;
            }
        });

        if (
            questionsData.question1Data !== 0 &&
            questionsData.question2Data !== 0 &&
            questionsData.question3Data !== 0 &&
            questionsData.question4Data !== 0 &&
            questionsData.question5Data !== 0
        ) {
            props.integrator.profilePoints = total;

            if (props.integrator.profilePoints <= 40) {
                props.integrator.profile = 'Integrador Volume';
                setProfile('Integrador Volume');
            } else if (
                props.integrator.profilePoints > 40 &&
                props.integrator.profilePoints <= 70
            ) {
                props.integrator.profile = 'Integrador Crescimento';
                setProfile('Integrador Crescimento');
            } else if (props.integrator.profilePoints > 70) {
                props.integrator.profile = 'Integrador Valor';
                setProfile('Integrador Valor');
            }
        }
    }, [questionsData, props.integrator]);

    const fetchIntegratorData = () => {
        if (props.integrator.profilePoints) {
            const selectedQuestion1 = question1.findIndex(
                (question: any) =>
                    question.label === props.integrator.question1,
            );

            const selectedQuestion2 = question2.findIndex(
                (question: any) =>
                    question.label === props.integrator.question2,
            );

            const selectedQuestion3 = question3.findIndex(
                (question: any) =>
                    question.label === props.integrator.question3,
            );

            const selectedQuestion4 = question4.findIndex(
                (question: any) =>
                    question.label === props.integrator.question4,
            );

            const selectedQuestion5 = question5.findIndex(
                (question: any) =>
                    question.label === props.integrator.question5,
            );

            questionsData.question1Data = question1[selectedQuestion1];
            questionsData.question2Data = question2[selectedQuestion2];
            questionsData.question3Data = question3[selectedQuestion3];
            questionsData.question4Data = question4[selectedQuestion4];
            questionsData.question5Data = question5[selectedQuestion5];
        }
    };

    useEffect(() => {
        if (questionsData) {
            fetchIntegratorData();
            reset(questionsData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset]);

    if (pathname === signUpRoute.path) {
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                    }}
                >
                    <div style={{ display: 'flex', width: '48%' }}>
                        <div
                            style={{
                                width: 48,
                                height: 48,
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid #707070',
                                justifyContent: 'center',
                            }}
                        >
                            <Image src={clockIcon} />
                        </div>
                        <Form.Group
                            style={{ width: '100%' }}
                            controlId="formBasicQuestion1"
                        >
                            <Form.Control
                                style={{ display: 'none' }}
                                isInvalid={!!props.errors.question1}
                            />
                            <Controller
                                isClearable={false}
                                className="form-control-nexen"
                                as={Select}
                                control={formControl}
                                name="question1Data"
                                options={question1}
                                isMulti={false}
                                styles={{
                                    control,
                                }}
                                theme={theme}
                                placeholder="Tempo de atuação no mercado"
                                defaultValue={null}
                                path={pathname}
                            />

                            {props.errors.question1 && (
                                <Form.Control.Feedback type="invalid">
                                    {props.errors.question1}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </div>

                    <div style={{ display: 'flex', width: '48%' }}>
                        <div
                            style={{
                                width: 48,
                                height: 48,
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid #707070',
                                justifyContent: 'center',
                            }}
                        >
                            <Image src={wrenchIcon} />
                        </div>
                        <Form.Group
                            style={{ width: '100%' }}
                            controlId="formBasicQuestion2"
                        >
                            <Form.Control
                                style={{ display: 'none' }}
                                isInvalid={!!props.errors.question2}
                            />
                            <Controller
                                isClearable={false}
                                className="form-control-nexen"
                                as={Select}
                                control={formControl}
                                name="question2Data"
                                options={question2}
                                isMulti={false}
                                styles={{
                                    control,
                                }}
                                theme={theme}
                                placeholder="N° de instalações já realizadas"
                                defaultValue={null}
                                path={pathname}
                            />

                            {props.errors.question2 && (
                                <Form.Control.Feedback type="invalid">
                                    {props.errors.question2}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                    }}
                >
                    <div style={{ display: 'flex', width: '48%' }}>
                        <div
                            style={{
                                width: 48,
                                height: 48,
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid #707070',
                                justifyContent: 'center',
                            }}
                        >
                            <Image src={panelIcon} />
                        </div>
                        <Form.Group
                            style={{ width: '100%' }}
                            controlId="formBasicQuestion3"
                        >
                            <Form.Control
                                style={{ display: 'none' }}
                                isInvalid={!!props.errors.question1}
                            />
                            <Controller
                                isClearable={false}
                                className="form-control-nexen"
                                as={Select}
                                control={formControl}
                                name="question3Data"
                                options={question3}
                                isMulti={false}
                                styles={{
                                    control,
                                }}
                                theme={theme}
                                placeholder="Foco das instalações"
                                defaultValue={null}
                                path={pathname}
                            />
                            {props.errors.question3 && (
                                <Form.Control.Feedback type="invalid">
                                    {props.errors.question3}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </div>

                    <div style={{ display: 'flex', width: '48%' }}>
                        <div
                            style={{
                                width: 48,
                                height: 48,
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid #707070',
                                justifyContent: 'center',
                            }}
                        >
                            <Image src={thunderIcon} />
                        </div>
                        <Form.Group
                            style={{ width: '100%' }}
                            controlId="formBasicQuestion4"
                        >
                            <Form.Control
                                style={{ display: 'none' }}
                                isInvalid={!!props.errors.question4}
                            />
                            <Controller
                                isClearable={false}
                                className="form-control-nexen"
                                as={Select}
                                control={formControl}
                                name="question4Data"
                                options={question4}
                                isMulti={false}
                                styles={{
                                    control,
                                }}
                                theme={theme}
                                placeholder="Potência dos Kits vendidos"
                                defaultValue={null}
                                path={pathname}
                            />

                            {props.errors.question4 && (
                                <Form.Control.Feedback type="invalid">
                                    {props.errors.question4}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                    }}
                >
                    <div style={{ display: 'flex', width: '48%' }}>
                        <div
                            style={{
                                width: 48,
                                height: 48,
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid #707070',
                                justifyContent: 'center',
                            }}
                        >
                            <Image src={employeesAmountIcon} />
                        </div>
                        <Form.Group
                            style={{ width: '100%' }}
                            controlId="formBasicQuestion5"
                        >
                            <Form.Control
                                style={{ display: 'none' }}
                                isInvalid={!!props.errors.question5}
                            />
                            <Controller
                                isClearable={false}
                                className="form-control-nexen"
                                as={Select}
                                control={formControl}
                                name="question5Data"
                                options={question5}
                                isMulti={false}
                                styles={{
                                    control,
                                }}
                                theme={theme}
                                placeholder="N° de funcionários"
                                defaultValue={null}
                                path={pathname}
                            />
                            {props.errors.question5 && (
                                <Form.Control.Feedback type="invalid">
                                    {props.errors.question5}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </div>

                    <div style={{ display: 'flex', width: '48%' }}>
                        <Form.Group
                            style={{ width: '100%' }}
                            controlId="formBasicQuestion6"
                        >
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            border: '1px solid #707070',
                                            width: 45,
                                        }}
                                    >
                                        <Image src={ticketIcon} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    className={
                                        pathname === signUpRoute.path
                                            ? 'nexen-form-control'
                                            : ''
                                    }
                                    value={props.integrator.question6}
                                    isInvalid={!!props.errors.question6}
                                    onChange={props.handleChanges}
                                    isValid={
                                        props.integrator.question6 &&
                                        !props.errors.question6 &&
                                        props.signUp
                                    }
                                    name="question6"
                                    type="text"
                                    placeholder="Trabalha com quais marcas de módulos e inversor?"
                                />
                                {props.errors.question6 && (
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.question6}
                                    </Form.Control.Feedback>
                                )}
                            </InputGroup>
                        </Form.Group>
                    </div>
                </div>
                {props.signUp === false && (
                    <div
                        style={{
                            display: 'flex',
                            borderRadius: 5,
                            border: '1px solid #CCCCCC',
                            color: '#808080',
                            padding: 10,
                            marginTop: 15,
                            fontSize: 12,
                        }}
                    >
                        Perfil: {profile || 'Perfil não definido'}
                    </div>
                )}
            </>
        );
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                }}
            >
                <div style={{ display: 'flex', width: '48%' }}>
                    <Form.Group
                        style={{ width: '100%' }}
                        controlId="formBasicQuestion1"
                    >
                        <Form.Label>
                            Há quanto tempo atua no mercado de GD
                        </Form.Label>
                        <Controller
                            isClearable={false}
                            className="form-control-nexen"
                            as={Select}
                            control={formControl}
                            name="question1Data"
                            options={question1}
                            isMulti={false}
                            styles={{
                                control,
                            }}
                            theme={theme}
                            placeholder="Tempo de atuação no mercado"
                            defaultValue={null}
                        />

                        {props.errors.question1 && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.question1}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </div>

                <div style={{ display: 'flex', width: '48%' }}>
                    <Form.Group
                        style={{ width: '100%' }}
                        controlId="formBasicQuestion2"
                    >
                        <Form.Label>
                            Quantas instalações sua empresa já realizou
                        </Form.Label>
                        <Form.Control
                            style={{ display: 'none' }}
                            isInvalid={!!props.errors.question2}
                        />
                        <Controller
                            isClearable={false}
                            className="form-control-nexen"
                            as={Select}
                            control={formControl}
                            name="question2Data"
                            options={question2}
                            isMulti={false}
                            styles={{
                                control,
                            }}
                            theme={theme}
                            placeholder="N° de instalações já realizadas"
                            defaultValue={null}
                        />

                        {props.errors.question2 && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.question2}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                }}
            >
                <div style={{ display: 'flex', width: '48%' }}>
                    <Form.Group
                        style={{ width: '100%' }}
                        controlId="formBasicQuestion3"
                    >
                        <Form.Label>
                            Qual o foco das instalações que sua empresa executa
                        </Form.Label>
                        <Form.Control
                            style={{ display: 'none' }}
                            isInvalid={!!props.errors.question1}
                        />
                        <Controller
                            isClearable={false}
                            className="form-control-nexen"
                            as={Select}
                            control={formControl}
                            name="question3Data"
                            options={question3}
                            isMulti={false}
                            styles={{
                                control,
                            }}
                            theme={theme}
                            placeholder="Foco das instalações"
                            defaultValue={null}
                        />
                        {props.errors.question3 && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.question3}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </div>

                <div style={{ display: 'flex', width: '48%' }}>
                    <Form.Group
                        style={{ width: '100%' }}
                        controlId="formBasicQuestion4"
                    >
                        <Form.Label>
                            Qual a potência dos kits instalados pela sua
                            empresa?
                        </Form.Label>
                        <Form.Control
                            style={{ display: 'none' }}
                            isInvalid={!!props.errors.question4}
                        />
                        <Controller
                            isClearable={false}
                            className="form-control-nexen"
                            as={Select}
                            control={formControl}
                            name="question4Data"
                            options={question4}
                            isMulti={false}
                            styles={{
                                control,
                            }}
                            theme={theme}
                            placeholder="Potência dos Kits vendidos"
                            defaultValue={null}
                        />

                        {props.errors.question4 && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.question4}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                }}
            >
                <div style={{ display: 'flex', width: '48%' }}>
                    <Form.Group
                        style={{ width: '100%' }}
                        controlId="formBasicQuestion5"
                    >
                        <Form.Label>
                            Quantos funcionários sua empresa possui?
                        </Form.Label>
                        <Form.Control
                            style={{ display: 'none' }}
                            isInvalid={!!props.errors.question5}
                        />
                        <Controller
                            isClearable={false}
                            className="form-control-nexen"
                            as={Select}
                            control={formControl}
                            name="question5Data"
                            options={question5}
                            isMulti={false}
                            styles={{
                                control,
                            }}
                            theme={theme}
                            placeholder="N° de funcionários"
                            defaultValue={null}
                        />
                        {props.errors.question5 && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.question5}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </div>

                <div style={{ display: 'flex', width: '48%' }}>
                    <Form.Group
                        style={{ width: '100%' }}
                        controlId="formBasicQuestion6"
                    >
                        <Form.Label>
                            Quais marcas de módulo e inversor sua empresa
                            trabalha?
                        </Form.Label>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            value={props.integrator.question6}
                            isInvalid={!!props.errors.question6}
                            onChange={props.handleChanges}
                            isValid={
                                props.integrator.question6 &&
                                !props.errors.question6 &&
                                props.signUp
                            }
                            name="question6"
                            type="text"
                            placeholder="Trabalha com quais marcas de módulos e inversor?"
                        />
                        {props.errors.question6 && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.question6}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </div>
            </div>
            {props.signUp === false && (
                <div
                    style={{
                        display: 'flex',
                        borderRadius: 5,
                        border: '1px solid #CCCCCC',
                        color: '#808080',
                        padding: 10,
                        marginTop: 15,
                        fontSize: 12,
                    }}
                >
                    Perfil: {profile || 'Perfil não definido'}
                </div>
            )}
        </>
    );
};

export default IntegratorCompanyInfo;
