export default function formatDate(value: string): string {
    if (!value) {
        return '';
    }

    const nArr = value.split('T');

    let newDate = '';
    let nDate = '';

    if (nArr.length > 1) {
        [nDate] = nArr;
    } else {
        nDate = value;
    }

    newDate = `${nDate.split('-')[2]}/${nDate.split('-')[1]}/${
        nDate.split('-')[0]
    }`;

    return newDate;
}
