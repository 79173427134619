import { signInRoute } from '../routes/config';
import history from './history';
import AuthHttpService from './http/auth-http';

export default class LoginService {
    public static async login(login: string, password: string) {
        const response = await AuthHttpService.login(login, password);

        if (!response.data) {
            throw new Error('Ocorreu um problema ao fazer o login');
        }

        return response.data;
    }

    public static async logout() {
        localStorage.removeItem('seller');

        await AuthHttpService.logout();

        history.push(signInRoute.path);
    }
}
