import ReturnStatus from './returnStatus';

export const statusFilterOptions = [
    {
        label: 'Aguardando Aprovação',
        value: ReturnStatus.WaitingApproval,
    },
    {
        label: 'Liberado para Devolução',
        value: ReturnStatus.FreeToReturn,
    },
    {
        label: 'Aguardando Desmontagem',
        value: ReturnStatus.AwaitDisassembly,
    },
    {
        label: 'Devolvido',
        value: ReturnStatus.Returned,
    },
    {
        label: 'Processando',
        value: ReturnStatus.Processing,
    },
];

export const clearanceOptions = [
    {
        label: 'Não liberado',
        value: false,
    },
    {
        label: 'Liberado',
        value: true,
    },
];
