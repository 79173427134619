export default function formatLevel(level: string): string {
    switch (level) {
        case '002':
            return 'TABELA BRONZE';
        case '004':
            return 'TABELA PRATA';
        case '005':
            return 'TABELA OURO';
        case '006':
            return 'TABELA DIAMANTE';

        default:
            return 'TABELA NÃO ENCONTRADA';
    }
}
