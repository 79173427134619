import { Form } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledFormControl = styled(Form.Control)`
    &:disabled {
        color: gray;
        ::placeholder {
            color: gray;
        }
    }
`;

export const StyledSelect = styled.div`
    div {
        z-index: 2;
    }
`;
