/* eslint-disable @typescript-eslint/no-shadow */
import { useState, useEffect, ChangeEvent } from 'react';
import { Col, Modal, Row, Form, Button, Spinner } from 'react-bootstrap';
import { useForm, Controller, FieldError } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { StyledRow, StyledTitle } from './styles';
import {
    optionsTheme,
    theme,
    multiControl as tradeControl,
} from '../../../../../styles/react-select-config';
import { useConfiguration } from '../../../../../contexts/configurationContext';
import kitsHttpService from '../../../../../services/http/kit-http';
import Branch from '../../../../../shared/interfaces/branch.interface';
import removeNonNumericChars from '../../../../../utils/removeNonNumericChars';
import BranchHttpService from '../../../../../services/http/branch-http';

interface FormData {
    name: string;
    originalId: string;
    modules: any[];
    cable1: string;
    cable2: string;
    cableQuantity: string;
    branches: BranchOptions[];
}

interface BranchOptions {
    label: string;
    value: number;
    id: number;
}

const RoofTypeEditor = (props: any) => {
    const [loading, setLoading] = useState(false);
    const {
        register,
        watch,
        setValue,
        reset,
        control,
        errors,
        setError,
        clearErrors,
    } = useForm<FormData>({
        defaultValues: {
            name: '',
            originalId: '',
            modules: [],
            cable1: '',
            cable2: '',
            cableQuantity: '',
            branches: [],
        },
    });
    const {
        setShowRoofTypeEditorModal,
        setSelectedRoofType,
        selectedRoofType,
        modules,
        setRoofTypes,
    } = useConfiguration();

    const moduleOptions = modules?.map((item: any) => ({
        label: item.name,
        value: item.id,
        module: item,
    }));

    const formData = watch();

    const roofTypeRules = {
        branches: Yup.string().required('Campo obrigatório'),
    };

    async function saveRoofType() {
        setLoading(true);
        clearErrors();

        try {
            const schemaRoofType = Yup.object().shape(roofTypeRules);

            await schemaRoofType.validate(formData, {
                abortEarly: false,
            });
        } catch (error) {
            setError('branches', {
                type: 'campo',
                message: 'Campo Obrigatório',
            });
            setLoading(false);

            return;
        }

        try {
            const formData = watch();

            const roofType: any = {
                id: selectedRoofType?.id,
                name: formData.name,
                originalId: formData.originalId,
                modules: formData?.modules?.map((item) => item.module) ?? [],
                cable1: formData?.cable1,
                cable2: formData?.cable2,
                cableQuantity: formData?.cableQuantity,
                branches: formData?.branches.map((item: any) => item),
            };

            await kitsHttpService.storeRoofType(roofType);

            toast.success('Tipo de Telhado salvo.');

            const response = await kitsHttpService.getRoofTypes({
                take: 20,
            });

            setRoofTypes(response.data.roofTypes);
        } catch (error) {
            toast.error('Erro ao salvar Tipo de Telhado.');
        }

        setLoading(false);
        setShowRoofTypeEditorModal(false);
        setSelectedRoofType({});
    }

    useEffect(() => {
        if (selectedRoofType.name) {
            const FormattedRoofType = selectedRoofType;

            const selectedModules = selectedRoofType?.modules?.map(
                (item: any) => ({
                    label: item.name,
                    value: item.id,
                    module: item,
                }),
            );

            const selectedBranches = selectedRoofType?.branches?.map(
                (item: Branch) => ({
                    label: item.name,
                    value: item.id,
                    id: item.id,
                }),
            );

            FormattedRoofType.modules = selectedModules;
            FormattedRoofType.branches = selectedBranches;

            setSelectedRoofType(FormattedRoofType);

            setValue('name', selectedRoofType.name);
            setValue('originalId', selectedRoofType.originalId);
            setValue('modules', selectedModules);
            setValue('cable1', selectedRoofType.cable1);
            setValue('cable2', selectedRoofType.cable2);
            setValue('cableQuantity', selectedRoofType.cableQuantity);
            setValue('branches', selectedBranches);
        } else {
            reset({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRoofType]);

    async function loadCompanyBranches() {
        const response = await BranchHttpService.readMany({});

        const companyBranches: BranchOptions[] = [];

        if (response?.data?.results) {
            response.data.results.map((branch: Branch) =>
                companyBranches.push({
                    label: branch.name,
                    id: branch.id,
                    value: branch.id,
                }),
            );
        }

        return companyBranches;
    }

    const checkForBranch = (id: number) => {
        const currentBranches = formData?.branches?.map(
            (branch: BranchOptions) => branch.id,
        );

        return !currentBranches?.includes(id);
    };

    const { data: companyBranches, isLoading } = useQuery(
        'companyBranches',
        loadCompanyBranches,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
        },
    );

    const handleChangeErpCode = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.value = removeNonNumericChars(e.target.value);
    };

    return (
        <>
            <Modal
                className="modal-dialog-scrollable"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                size="lg"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className="ml-3 mr-3"
                        id="contained-modal-title-vcenter"
                    >
                        Tipo de Telhado
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="w-100">
                    <StyledRow className="ml-0 mt-2 mr-1">
                        <Col>
                            <StyledTitle>Filiais</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Group controlId="formBasicBranches">
                                <Controller
                                    className="form-control-nexen"
                                    as={Select}
                                    control={control}
                                    name="branches"
                                    options={
                                        !isLoading &&
                                        companyBranches?.filter(
                                            (branch: BranchOptions) =>
                                                checkForBranch(branch.id),
                                        )
                                    }
                                    isMulti
                                    styles={{
                                        control: tradeControl,
                                        option: optionsTheme,
                                    }}
                                    theme={theme}
                                    placeholder="Selecione..."
                                    defaultValue={[]}
                                    noOptionsMessage={() => 'Sem opções'}
                                />
                                {errors.branches && (
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className="d-block"
                                    >
                                        {
                                            (
                                                errors?.branches as unknown as FieldError
                                            )?.message
                                        }
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-2 mr-1">
                        <Col>
                            <StyledTitle>Nome</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="name"
                                type="string"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>
                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>ID Original</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="originalId"
                                type="number"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Cabo 1 - Código Erp</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="cable1"
                                type="text"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                                onChange={handleChangeErpCode}
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Cabo 2 - Código Erp</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="cable2"
                                type="text"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                                onChange={handleChangeErpCode}
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Quantidade de cabos</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="cableQuantity"
                                type="number"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Módulos</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Controller
                                className="form-control-nexen"
                                as={Select}
                                control={control}
                                name="modules"
                                options={moduleOptions.filter(
                                    (item: any) =>
                                        !selectedRoofType?.modules?.includes(
                                            item,
                                        ),
                                )}
                                isMulti
                                styles={{
                                    control: tradeControl,
                                    option: optionsTheme,
                                }}
                                theme={theme}
                                placeholder="Selecione..."
                                noOptionsMessage={() => 'Sem opções'}
                                defaultValue={[]}
                                maxMenuHeight={100}
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-5 mr-1">
                        <Col sm={6} />
                        <Col>
                            <Button
                                className="w-100 float-right"
                                variant="outline-primary"
                                onClick={() => {
                                    setShowRoofTypeEditorModal(false);
                                    setSelectedRoofType({});
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100 float-right"
                                onClick={() => {
                                    saveRoofType();
                                }}
                            >
                                Salvar{'   '}
                                {loading && (
                                    <Spinner animation="border" size="sm" />
                                )}
                            </Button>
                        </Col>
                    </StyledRow>
                </Modal.Body>
                <Modal.Footer className="ml-3 mr-3 mb-2" />
            </Modal>
        </>
    );
};

export default RoofTypeEditor;
