import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { StyledPageSubTitle, StyledPageTitle } from '../../../styles/pageTitle';

interface DiscountsHeaderProps {
    onClick: () => void;
    isSaveEnabled: boolean;
}

export default function DiscountsHeader({
    onClick,
    isSaveEnabled,
}: DiscountsHeaderProps) {
    return (
        <Row className="header align-items-center pr-2 pl-2">
            <Col>
                <div className="d-flex flex-column">
                    <StyledPageTitle className="mt-2">
                        Descontos
                    </StyledPageTitle>
                    <StyledPageSubTitle>
                        Gestão da política comercial de descontos
                    </StyledPageSubTitle>
                </div>
            </Col>
            <Col className="d-flex justify-content-end">
                {isSaveEnabled ? (
                    <Button
                        variant="primary"
                        className="w-auto"
                        onClick={onClick}
                    >
                        Aplicar Política
                    </Button>
                ) : (
                    <OverlayTrigger
                        placement="left"
                        delay={{
                            show: 250,
                            hide: 400,
                        }}
                        overlay={
                            <Tooltip id="disabled-apply-policy-tooltip">
                                Preencha todos os descontos e selecione pelo
                                menos um usuário
                            </Tooltip>
                        }
                    >
                        <Button disabled>Aplicar Política</Button>
                    </OverlayTrigger>
                )}
            </Col>
        </Row>
    );
}
