import DigitalDocumentStatus from './digitalDocumentStatus';
import sellerStatus from './sellerStatus';

export const statusFilterOptions = [
    {
        label: 'ATIVO',
        value: sellerStatus.Approved,
    },
    {
        label: 'PENDENTE',
        value: sellerStatus.Pending,
    },
    {
        label: 'REPROVADO',
        value: sellerStatus.Reproved,
    },
    {
        label: 'PROCESSANDO',
        value: sellerStatus.Processing,
    },
    {
        label: 'BLOQUEADO',
        value: sellerStatus.Disabled,
    },
];

export const contractFilterOptions = [
    {
        label: 'ASSINADO',
        value: DigitalDocumentStatus.Signed,
    },
    {
        label: 'EM ANDAMENTO',
        value: DigitalDocumentStatus.Sent,
    },
    {
        label: 'NÃO GERADO',
        value: 'UNSENT',
    },
];

export const attachmentFilterOptions = [
    {
        label: 'Sim',
        value: true,
    },
    {
        label: 'Não',
        value: false,
    },
];
