import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledH4 = styled.h4`
    font-size: 20px !important;
`;

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;

export const StyledLink = styled(Link)`
    color: inherit !important;
    font-weight: bolder;
`;
