import ReturnStatus from '../constants/returnStatus';

function getReturnStatusText(status: string) {
    if (status === ReturnStatus.WaitingApproval) {
        return 'Aguardando Aprovação';
    }

    if (status === ReturnStatus.FreeToReturn) {
        return 'Liberado para Devolução';
    }

    if (status === ReturnStatus.AwaitDisassembly) {
        return 'Aguardando Desmontagem';
    }

    if (status === ReturnStatus.Returned) {
        return 'Devolvido';
    }

    if (status === ReturnStatus.Processing) {
        return 'Processando';
    }

    return '';
}

export default getReturnStatusText;
