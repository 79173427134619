import { Image } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledDropzoneTitle = styled.h5`
    font-size: 14px;
    color: #707070;
    font-weight: bold;
    text-align: center;
    transition: all 0.2s ease;
`;

export const StyledDropzoneDiv = styled.div`
    border: 2px dashed #ced4da;
    margin: 10px 0 30px 0;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #ced4da;
    border-radius: 10px;
    transition: all 0.2s ease;

    :hover {
        border: 2px dashed #f2994a;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #f2994a;
    }

    :hover ${StyledDropzoneTitle} {
        color: #f2994a;
    }
`;

export const StyledFileDiv = styled.div<{ background: string; border: string }>`
    width: 100%;
    background-color: ${(props) => props.background};
    border-left: 2px solid ${(props) => props.border};

    display: flex;
    align-items: center;
    margin: 0 0 15px 0;
    padding: 15px;

    @media (max-width: 480px) {
        flex-wrap: wrap;
        padding: 20px;
    }
`;

export const StyledSelectDiv = styled.div`
    padding: 0 15px 0 0;

    @media (max-width: 480px) {
        width: 100%;
        padding: 0;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        width: 35%;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        width: 30%;
    }

    @media (min-width: 1025px) and (max-width: 1200px) {
        width: 25%;
    }

    @media (min-width: 1201px) {
        width: 20%;
    }
`;

export const StyledLinkDiv = styled.div`
    padding: 0 15px 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 480px) {
        width: 90%;
        padding: 20px 15px 0 10px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        width: 55%;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        width: 60%;
    }

    @media (min-width: 1025px) and (max-width: 1200px) {
        width: 65%;
    }

    @media (min-width: 1201px) {
        width: 70%;
    }
`;

export const StyledImageDiv = styled.div`
    padding: 0 15px 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 10%;

    @media (max-width: 480px) {
        padding: 20px 10px 0 0;
    }
`;

export const StyledImage = styled(Image)`
    cursor: pointer;

    :hover {
        filter: brightness(0) saturate(100%);
    }

    @media (max-width: 480px) {
        width: 14px;
    }
`;

export const StyledLink = styled.a<{ newFile?: boolean }>`
    color: ${(props) => (props.newFile ? '#f2994a' : '#707070')};
    font-size: 0.75rem;
    cursor: pointer;

    :hover {
        color: ${(props) => (props.newFile ? '#707070' : '#f2994a')};
        text-decoration: none;
    }
`;

export const StyledH5 = styled.h4`
    color: #707070;
    font-size: 14px;
    font-style: italic;
`;

export const StyledUl = styled.ul`
    color: #707070;
    font-size: 14px;
    font-style: italic;
`;

export const SelectStyles = {
    control: (base: any, state: any) => ({
        ...base,
        height: 48,
        fontSize: 12,
        // eslint-disable-next-line no-nested-ternary
        border: state.isFocused
            ? '1px solid #9aa7b3'
            : !state.hasValue
            ? '1px solid #ff4554'
            : '1px solid #ced4da',
        borderRadius: '0px',
        boxShadow: state.isFocused
            ? '0px 0px 6px rgba(154, 167, 179, 0.90)'
            : null,
        '&:hover': {
            borderColor: '#9aa7b3',
        },
    }),
    menu: (base: any) => ({
        ...base,
        marginTop: 0,
    }),
};

export const SelectTheme = (theme: any) => ({
    ...theme,

    colors: {
        ...theme.colors,
        primary: 'rgba(242, 153, 74, 0.8)',
        primary25: 'rgba(242, 153, 74, 0.4)',
        boxShadow: 'none',
    },
});
