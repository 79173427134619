import React, { useState, useEffect } from 'react';
import { ProgressBar } from './styles';

export interface StepProgressBarProps {
    step: number;
}

export function StepProgressBar({ step }: StepProgressBarProps) {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        switch (step) {
            case 1:
                setProgress(0);
                break;
            case 2:
                setProgress(25);
                break;
            case 3:
                setProgress(50);
                break;
            case 4:
                setProgress(75);
                break;
            case 5:
                setProgress(100);
                break;
            default:
        }
    }, [step]);

    return (
        <>
            <ProgressBar value={progress} max="100" />
        </>
    );
}
