import React, { useState } from 'react';
import { Row, Col, Form, Nav } from 'react-bootstrap';
import { StyledNav } from '../../../styles/nav';
import ProductDeliveryDateTable from './ProductDeliveryDates/ProductDeliveryDateTable';

const ProductData = () => {
    const [tab, setTab] = useState('productDeliveryDate');

    return (
        <>
            <Form.Group controlId="formConfiguration">
                <Row className="mb-3">
                    <Col md={8}>
                        <StyledNav
                            variant="tabs"
                            activeKey={tab}
                            onSelect={(selectedKey: string) =>
                                setTab(selectedKey)
                            }
                        >
                            <Nav.Item>
                                <Nav.Link eventKey="productDeliveryDate">
                                    Disponibilidade
                                </Nav.Link>
                            </Nav.Item>
                        </StyledNav>
                    </Col>
                </Row>

                {tab === 'productDeliveryDate' && <ProductDeliveryDateTable />}
            </Form.Group>
        </>
    );
};

export default ProductData;
