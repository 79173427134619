import * as Yup from 'yup';

export const librariesRules = {
    title: Yup.string().required('Campo obrigatório'),
    description: Yup.string().required('Campo obrigatório'),
};

export const brandsRules = {
    name: Yup.string().required('Campo obrigatório'),
};

export const modulesRules = {
    name: Yup.string().required('Campo obrigatório'),
};

export const categoriesRules = {
    name: Yup.string().required('Campo obrigatório'),
};
