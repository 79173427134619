import * as Yup from 'yup';

const accessoriesRules = {
    productErpCode: Yup.string().required('Campo obrigatório'),
    accessoryErpCode: Yup.string().required('Campo obrigatório'),
    formula: Yup.string()
        .required('Campo obrigatório')
        .matches(
            /^(?:[PMI]|\d+)(?:[+\-*/](?:[PMI]|\d+))*$/,
            'Fórmula inválida! Por favor, siga as regras estabelecidas: (1) Dois operadores não podem estar juntos; (2) P, M ou I devem ter operadores entre si; (3) Operadores não podem estar no inicio ou no fim da fórmula.',
        ),
};

export default accessoriesRules;
