import React from 'react';
import { Container, Row, Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import imgSignin from '../../assets/signin.png';
import {
    StyledCol,
    StyledCol2,
    LoginPageText,
    RegisterPageText,
} from './styles';

interface Props {
    children: any;
}

const AccessLayout: React.FC<Props> = ({ children }) => {
    const location = useLocation();

    return (
        <Container fluid>
            <Row>
                <StyledCol className="text-center d-none d-lg-flex">
                    <Image style={{ width: '100%' }} src={imgSignin} />

                    {(location.pathname === '/login' ||
                        location.pathname === '/password/forgot') && (
                        <LoginPageText>
                            <span style={{ display: 'inline' }}>
                                Aqui você encontra <br />
                                tudo o que precisa <br />
                                para dar ainda mais <br />
                                <b>qualidade</b> e <b>lucratividade</b>
                                <br />
                                aos seus projetos.
                            </span>{' '}
                        </LoginPageText>
                    )}

                    {(location.pathname === '/signup' ||
                        location.pathname ===
                            '/select-module-registration') && (
                        <RegisterPageText>
                            <h2
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <span style={{ display: 'inline' }}>
                                    <b>Seja um parceiro</b> nexen
                                </span>
                                e dê mais energia <br />
                                ao seu negócio: <br />
                            </h2>
                            <span>{'>'} Promoções exclusivas</span>
                            <span>{'>'} Produtos de altíssima qualidade</span>
                            <span>{'>'} Condições imperdíveis</span>
                            <span>
                                {'>'} Benefícios para você garantir mais
                                lucratividade <br /> nos seus projetos
                            </span>
                            <div
                                style={{
                                    background:
                                        'linear-gradient(180deg, rgba(217, 217, 217, 0.4) -61.25%, rgba(217, 217, 217, 0.15) 37.01%, rgba(217, 217, 217, 0.25) 100%)',
                                    boxShadow:
                                        '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                    backdropFilter: 'blur(30px)',
                                    borderRadius: 10,
                                    textAlign: 'center',
                                    padding: 10,
                                    marginTop: 40,
                                    fontWeight: 'bold',
                                }}
                            >
                                Cadastre-se agora mesmo e aproveite!
                            </div>
                        </RegisterPageText>
                    )}
                </StyledCol>
                <StyledCol2>
                    <div style={{ width: '100%' }}>{children}</div>
                </StyledCol2>
            </Row>
        </Container>
    );
};

export default AccessLayout;
