import { Col, Form } from 'react-bootstrap';
import { Controller, Control } from 'react-hook-form';
import removeNonNumericChars from '../../../../../../utils/removeNonNumericChars';

interface IdentifierInputProps {
    control: Control;
    errorMessage?: string;
}

export default function IdentifierInput({
    control,
    errorMessage,
}: IdentifierInputProps) {
    return (
        <Col>
            <Form.Label>Identificador de Inclusão</Form.Label>
            <Controller
                control={control}
                name="recordOrigin"
                render={({ onChange, value }) => (
                    <Form.Control
                        type="text"
                        value={value}
                        onChange={(e) => {
                            const numericValue = removeNonNumericChars(
                                e.target.value,
                            );

                            onChange(numericValue);
                        }}
                        className="form-control"
                        placeholder="Digite aqui..."
                    />
                )}
                rules={{ required: 'Campo obrigatório' }}
            />
            {errorMessage && (
                <Form.Control.Feedback type="invalid" className="d-block">
                    {errorMessage}
                </Form.Control.Feedback>
            )}
        </Col>
    );
}
