const getStateName = (uf: string) => {
    let data;

    switch (uf.toUpperCase()) {
        case 'AC':
            data = 'Acre';
            break;
        case 'AL':
            data = 'Alagoas';
            break;
        case 'AM':
            data = 'Amazonas';
            break;
        case 'AP':
            data = 'Amapa';
            break;
        case 'BA':
            data = 'Bahia';
            break;
        case 'CE':
            data = 'Ceara';
            break;
        case 'DF':
            data = 'Distrito Federal';
            break;
        case 'ES':
            data = 'Espirito Santo';
            break;
        case 'GO':
            data = 'Goias';
            break;
        case 'MA':
            data = 'Maranhao';
            break;
        case 'MG':
            data = 'Minas Gerais';
            break;
        case 'MS':
            data = 'Mato Grosso do Sul';
            break;
        case 'MT':
            data = 'Mato Grosso';
            break;
        case 'PA':
            data = 'Para';
            break;
        case 'PB':
            data = 'Paraiba';
            break;
        case 'PE':
            data = 'Pernambuco';
            break;
        case 'PI':
            data = 'Piaui';
            break;
        case 'PR':
            data = 'Parana';
            break;
        case 'RJ':
            data = 'Rio de Janeiro';
            break;
        case 'RN':
            data = 'Rio Grande do Norte';
            break;
        case 'RO':
            data = 'Rondonia';
            break;
        case 'RR':
            data = 'Roraima';
            break;
        case 'RS':
            data = 'Rio Grande do Sul';
            break;
        case 'SC':
            data = 'Santa Catarina';
            break;
        case 'SE':
            data = 'Sergipe';
            break;
        case 'SP':
            data = 'São Paulo';
            break;
        case 'TO':
            data = 'Tocantins';
            break;
        default:
            data = '';
    }

    return data;
};

export default getStateName;
