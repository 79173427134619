import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledDeleteButton = styled(Button)`
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

export const StyledButtonDiv = styled.div`
    display: flex;
    justify-content: flex-end;

    * {
        margin-left: 15px;
    }
`;
