/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { Col, Modal, Row, Spinner, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import StateService from '../../../../services/state';
import {
    StyledConfirmButton,
    StyledOutlineButton,
} from '../../../../styles/button';
import {
    theme,
    control as controlSelect,
} from '../../../../styles/react-select-config';
import { phoneMask } from '../../../../utils/masks';
import formatMask from '../../../../utils/fotmatMask';
import removeNonNumericChars from '../../../../utils/removeNonNumericChars';
import ContactHttpService from '../../../../services/http/contact-http';

interface Props {
    data?: any;
    show?: boolean | undefined;
    onHide(): void;
}

interface ErpStates {
    value: string;
    label: string;
}

function ContactModal(props: Props) {
    const [loading, setLoading] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [isStateOptionsEmpty, setStateOptionsEmpty] = useState(false);
    const [states, setStates] = useState<ErpStates[]>([]);

    const loadStates = async (value?: any) => {
        setLoading(true);

        try {
            const results = await StateService.get();

            const statesList: any = results
                .filter((r) => !props.data?.states.split(',').includes(r.code))
                .map((item: any) => ({
                    value: item.code,
                    label: item.name,
                }));

            // Filtragem de estados duplicados
            const { data } = await ContactHttpService.readMany({});

            let statesString: string[] = [];
            const newStatesList: any[] = [];

            statesList.forEach((state: any) => {
                statesString.push(state.value);
            });

            data?.results?.forEach((contact: any) => {
                // eslint-disable-next-line array-callback-return
                contact?.states?.split(',').map((contactState: string) => {
                    statesString = statesString.filter(
                        (state: string) => !state.includes(contactState.trim()),
                    );
                });
            });

            statesString.forEach((state: string) => {
                const singleStateObject = statesList.find(
                    (singleState: any) => singleState.value === state,
                );

                newStatesList.push(singleStateObject);
            });

            if (newStatesList.length === 0) {
                setStateOptionsEmpty(true);
            }

            setStates(newStatesList);

            if ((value || value === null) && props?.data?.id) {
                const contactStates: any = [];
                const newStates: any = [];

                // eslint-disable-next-line array-callback-return
                props?.data?.states?.split(',').map((contactState: string) => {
                    contactStates.push(contactState);
                });

                if (value === null) {
                    contactStates.forEach((contactState: string) => {
                        const stateObject = results.find(
                            (singleState: any) =>
                                singleState.code === contactState,
                        );

                        const newObject = {
                            value: stateObject?.code,
                            label: stateObject?.name,
                        };

                        newStatesList.push(newObject);
                    });
                } else {
                    value?.forEach((state: any) => {
                        newStates.push(state.value);
                    });

                    contactStates.forEach((contactState: string) => {
                        const singleStateObject = newStates.find(
                            (newState: any) => newState === contactState,
                        );

                        if (!singleStateObject) {
                            const stateObject = results.find(
                                (singleState: any) =>
                                    singleState.code === contactState,
                            );

                            const newObject = {
                                value: stateObject?.code,
                                label: stateObject?.name,
                            };

                            newStatesList.push(newObject);
                        }
                    });
                }
            }
        } catch (error) {
            setDisabled(true);

            toast.error(
                'Houve um erro ao carregar os estados associados, por favor tente novamente mais tarde',
            );
        } finally {
            setLoading(false);
        }
    };

    const { control, handleSubmit, setValue, errors } = useForm({
        defaultValues: {
            consultantName: '',
            phoneNumber: '',
            states: '',
            ...props.data,
        },
    });

    async function handleSubmitRequest(params: any): Promise<void> {
        try {
            if (props.data?.id) {
                await ContactHttpService.update(props.data?.id, params);
            } else {
                await ContactHttpService.create(params);
            }

            toast.success(
                props.data?.id
                    ? 'Alterações feita com sucesso!'
                    : 'Consultor cadastrado com sucesso!',
            );

            props.onHide();
        } catch (err: any) {
            toast.error(err.message);
        } finally {
            setLoading(false);
        }
    }

    async function onSubmit(params: any) {
        if (loading) {
            return;
        }

        setLoading(true);

        // eslint-disable-next-line no-param-reassign
        params = {
            ...params,
            id: props.data?.id || null,
            states: params.states.map((item: any) => item.value).join(','),
            rdStationId: null,
        };

        handleSubmitRequest(params);
    }

    useEffect(() => {
        if (props.show) {
            setStates([]);
            setDisabled(false);
            setStateOptionsEmpty(false);
            loadStates();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    async function loadDefaultData() {
        const results = await StateService.get();

        setValue('consultantName', props.data?.consultantName || '');
        setValue('phoneNumber', props.data?.phoneNumber || '');
        setValue(
            'states',
            props.data?.states.split(',').map((state: string) => {
                const stateFound = results.find((r) => r.code === state);

                return {
                    label: stateFound?.name,
                    value: state,
                };
            }),
        );
    }

    useEffect(() => {
        loadDefaultData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);

    return (
        <Modal
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton onHide={props.onHide}>
                    <Modal.Title>
                        {props.data?.id ? 'Editar' : 'Novo'} contato
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="mb-2">
                    <Row>
                        <Col md="6">
                            <Controller
                                rules={{ required: 'Campo obrigatório' }}
                                control={control}
                                name="consultantName"
                                render={({ onChange, value }) => (
                                    <>
                                        <Form.Label>
                                            Nome do consultor
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={value}
                                            onChange={onChange}
                                        />
                                    </>
                                )}
                            />
                            {errors.consultantName?.message && (
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                >
                                    {errors.consultantName?.message}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                        <Col>
                            <Controller
                                rules={{
                                    required: 'Campo obrigatório',
                                    maxLength: 11,
                                    minLength: 11,
                                }}
                                control={control}
                                name="phoneNumber"
                                render={({ value }) => (
                                    <>
                                        <Form.Label>
                                            Whatsapp do consultor
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            as={MaskedInput}
                                            mask={phoneMask}
                                            placeholder="Digite aqui..."
                                            value={
                                                formatMask(
                                                    value,
                                                    '(##) #####-####',
                                                ) as string
                                            }
                                            onChange={(event) => {
                                                setValue(
                                                    'phoneNumber',
                                                    removeNonNumericChars(
                                                        event.target.value,
                                                    ),
                                                );
                                            }}
                                        />
                                    </>
                                )}
                            />
                            {errors.phoneNumber?.message && (
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                >
                                    {errors.phoneNumber?.message}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" style={{ marginTop: 15 }}>
                            <Controller
                                control={control}
                                name="states"
                                rules={{ required: 'Campo obrigatório' }}
                                render={({ onChange, value }) => (
                                    <>
                                        <Form.Label>
                                            Estados associados
                                        </Form.Label>
                                        <Select
                                            // eslint-disable-next-line @typescript-eslint/no-shadow
                                            onChange={(value: any) => {
                                                onChange(value);
                                                loadStates(value);
                                            }}
                                            styles={{ control: controlSelect }}
                                            value={value}
                                            options={states}
                                            isMulti
                                            theme={theme}
                                            placeholder="Selecione os estados..."
                                            noOptionsMessage={() =>
                                                isStateOptionsEmpty
                                                    ? 'Demais estados já estão atribuídos à algum consultor'
                                                    : 'Opção não encontrada...'
                                            }
                                        />
                                    </>
                                )}
                            />
                            {errors.states?.message && (
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                >
                                    {errors.states?.message}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <StyledOutlineButton onClick={props.onHide}>
                        Cancelar
                    </StyledOutlineButton>
                    <StyledConfirmButton
                        variant="primary"
                        type="submit"
                        disabled={loading || isDisabled}
                    >
                        {loading ? (
                            <Col md={12} className="text-center">
                                <Spinner animation="border" />
                            </Col>
                        ) : (
                            'Salvar'
                        )}
                    </StyledConfirmButton>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default ContactModal;
