import React, { useState } from 'react';
import { Modal, Nav, Row } from 'react-bootstrap';
import { StyledNav } from '../../../pages/Integrators/styles';
import BuiyngPolicies from '../BuyingPolicies';
import ExchangesRefundsAndReturnsPolicy from '../ExchangesRefundsAndReturnsPolicy';
import OffersAndPromotions from '../OffersAndPromotions';
import PrivacyAndSecurity from '../PrivacyAndSecurity';
import ShippingPolicies from '../ShippingPolicies';
import { StyledNavLink, StyledRow } from '../styles';

const UserTerms = (props: any) => {
    const [tab, setTab] = useState('tab-1');

    const { show, onHide } = props;

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        className="ml-3 mr-3"
                        id="contained-modal-title-vcenter"
                    >
                        Termos de uso
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ml-3 mr-3">
                    <Row>
                        <StyledNav
                            fill
                            variant="tabs"
                            activeKey={tab}
                            onSelect={(selectedKey: string) =>
                                setTab(selectedKey)
                            }
                            className="mb-3"
                        >
                            <Nav.Item>
                                <StyledNavLink eventKey="tab-1">
                                    POLÍTICAS DE COMPRA
                                </StyledNavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <StyledNavLink eventKey="tab-2">
                                    PRIVACIDADE E SEGURANÇA
                                </StyledNavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <StyledNavLink eventKey="tab-3">
                                    OFERTAS E PROMOÇŌES
                                </StyledNavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <StyledNavLink eventKey="tab-4">
                                    POLÍTICAS DE FRETE E ENTREGA
                                </StyledNavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <StyledNavLink eventKey="tab-5">
                                    TROCAS, DEVOLUÇŌES E REEMBOLSO
                                </StyledNavLink>
                            </Nav.Item>
                        </StyledNav>
                    </Row>
                    <StyledRow>
                        {tab === 'tab-1' && <BuiyngPolicies />}

                        {tab === 'tab-2' && <PrivacyAndSecurity />}

                        {tab === 'tab-3' && <OffersAndPromotions />}
                        {tab === 'tab-4' && <ShippingPolicies />}
                        {tab === 'tab-5' && (
                            <ExchangesRefundsAndReturnsPolicy />
                        )}
                    </StyledRow>
                </Modal.Body>

                <Modal.Footer className="ml-3 mr-3 mb-2" />
            </Modal>
        </>
    );
};

export default UserTerms;
