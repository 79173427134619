import { Image } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledSpan = styled.span`
    color: #000;
    font-weight: 700;
    text-decoration: 'none';
    font-family: Branding;

    :hover {
        color: #7800e1;
    }
`;

export const StyledImage = styled(Image)`
    font-weight: 700;
    font-family: Branding;
`;
