import { Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import BaseLayout from '../../components/BaseLayout';
import DiscountsHeader from '../../components/DiscountsManagement/DiscountsHeader';
import ConfirmationDialog from '../../utils/ConfirmationDialog';
import NotificationIcon from '../../constants/notificationIcon';
import UserHttpService from '../../services/http/user-http';
import DiscountsUsersList from '../../components/DiscountsManagement/DiscountUsersList';
import DiscountsPaymentMethodsList from '../../components/DiscountsManagement/DiscountsList';
import Role from '../../constants/roles';
import { useAuth } from '../../contexts/authContext';
import userHasRoles from '../../utils/userHasRoles';
import { dashboardDefaultRoute } from '../../routes/config';
import { Discount } from '../../shared/interfaces/discount.interface';
import handleResponseError from '../../utils/handleResponseError';

export interface DiscountsManagementFields {
    discounts: Record<string, Discount>;
    ids: Set<number>;
}

export default function DiscountsManagement() {
    const { user } = useAuth();
    const userCanAcess = userHasRoles(user, [Role.Administrator]);

    if (!userCanAcess) {
        const history = useHistory();
        history.push(dashboardDefaultRoute.path);
    }

    const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
    const [installmentsLenght, setInstallmentsLenght] = useState(0);

    const { watch, setValue } = useForm<DiscountsManagementFields>({
        shouldUnregister: false,
        defaultValues: {
            discounts: {},
            ids: new Set<number>(),
        },
    });

    const formData = watch();

    const batchUpdateMutation = useMutation({
        mutationFn: async () => {
            const discounts = Object.values(formData.discounts);
            const ids = Array.from(formData.ids);

            await UserHttpService.batchUpdate({
                ids,
                discounts,
            });
        },
        onError: (error: any) => {
            handleResponseError(error, 'Erro ao atualizar descontos');
        },
        onSuccess: async () => {
            toast.success('Política de descontos atualizada com sucesso');
        },
    });

    const handleSetDiscounts = (discounts: Record<string, Discount>) => {
        setValue('discounts', discounts);
    };

    const handleSetSelectedUsers = (ids: Set<number>) => {
        setValue('ids', ids);
    };

    const discountsLength = Object.keys(formData.discounts).length;
    const canSaveDiscounts =
        formData.ids.size >= 1 &&
        discountsLength === installmentsLenght &&
        installmentsLenght !== 0;

    return (
        <BaseLayout>
            <DiscountsHeader
                isSaveEnabled={canSaveDiscounts}
                onClick={() => setShowSaveConfirmation(true)}
            />

            <Row className="pl-2 pr-2 mt-4">
                <DiscountsPaymentMethodsList
                    setDiscounts={handleSetDiscounts}
                    setTotalInstallments={(lenght: number) =>
                        setInstallmentsLenght(lenght)
                    }
                    discounts={formData.discounts}
                />

                <DiscountsUsersList
                    setSelectedUsers={handleSetSelectedUsers}
                    selectedUsers={formData.ids}
                />
            </Row>

            {showSaveConfirmation && (
                <ConfirmationDialog
                    show={showSaveConfirmation}
                    onHide={() => {
                        setShowSaveConfirmation(false);
                    }}
                    onConfirm={async () => batchUpdateMutation.mutateAsync()}
                    icon={NotificationIcon.Warning}
                    title="Salvar nova política de descontos"
                    text="Deseja salvar os descontos para os usuários selecionados?"
                />
            )}
        </BaseLayout>
    );
}
