import { Link } from 'react-router-dom';
import { Row, Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledH4 = styled.h4`
    font-size: 20px !important;
`;

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;

export const StyledLink = styled(Link)`
    color: inherit !important;
    font-weight: bolder;
`;

export const StyledRow = styled(Row)`
    background-color: #ffffff !important;
`;

export const StyledTitle = styled.h3`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #707070;
`;

export const StyledOutlineButton = styled(Button)`
    width: 200px;
    background-color: #ffffff;
    border: 1px solid solid #6fcf97;
    color: #6fcf97 !important;
    :hover {
        background-color: ${(props) => !props.$disabled && '#6fcf97'};
        color: ${(props) => !props.$disabled && '#ffffff !important'};
    }
    :active {
        color: #ffffff !important;
    }
`;

export const StyledConfirmButton = styled(Button)`
    width: 200px;
`;

export const StyledCancelButton = styled(Button)`
    width: 200px;
    background-color: #ffffff;
    border: 1px solid solid #6fcf97;
    color: #2eb378 !important;

    :hover {
        background-color: #6fcf97;
        color: #ffffff !important;
    }
`;
