/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { StyledPageSubTitle, StyledPageTitle } from '../../../styles/pageTitle';
import getStateName from '../../../utils/getStateName';
import { cepMask } from '../../../utils/masks';

const AddressData = (props: any) => {
    const { register } = useFormContext();

    return (
        <>
            <StyledPageTitle>Dados de endereço</StyledPageTitle>
            <StyledPageSubTitle>
                Para atualizar os dados de endereço, entre em contato com um(a)
                consultor(a) Nexen através de nossos canais de atendimento
            </StyledPageSubTitle>
            <hr />

            <Row>
                <Col sm={12} md={6}>
                    <Form.Group>
                        <Form.Label>CEP</Form.Label>
                        <Form.Control
                            ref={register}
                            name="seller.addressCep"
                            type="text"
                            as={MaskedInput}
                            mask={cepMask}
                            value={props.user?.seller?.addressCep}
                            readOnly
                        />
                    </Form.Group>
                </Col>
                <Col sm={12} md={4}>
                    <Form.Group>
                        <Form.Label>Endereço</Form.Label>
                        <Form.Control
                            ref={register}
                            name="seller.addressDescription"
                            type="text"
                            readOnly
                        />
                    </Form.Group>
                </Col>

                <Col sm={12} md={2}>
                    <Form.Group>
                        <Form.Label>Número</Form.Label>
                        <Form.Control
                            ref={register}
                            name="seller.addressNumber"
                            type="text"
                            readOnly
                        />
                    </Form.Group>
                </Col>

                <Col sm={12} md={3}>
                    <Form.Group>
                        <Form.Label>Estado</Form.Label>
                        <Form.Control
                            ref={register}
                            name="seller.erpState"
                            value={getStateName(props.user?.seller?.erpState)}
                            type="text"
                            readOnly
                        />
                    </Form.Group>
                </Col>

                <Col sm={12} md={3}>
                    <Form.Group>
                        <Form.Label>Cidade</Form.Label>
                        <Form.Control
                            ref={register}
                            name="seller.erpCityName"
                            type="text"
                            readOnly
                        />
                    </Form.Group>
                </Col>

                <Col sm={12} md={6}>
                    <Form.Group>
                        <Form.Label>Bairro</Form.Label>
                        <Form.Control
                            ref={register}
                            name="seller.addressNeighborhood"
                            type="text"
                            readOnly
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

export default AddressData;
