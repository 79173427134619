import { Form, InputGroup, Row } from 'react-bootstrap';
import { Control, Controller } from 'react-hook-form';
import InvertersFilterCard from '../FilterCard';
import { InverterFilterProps } from '..';

interface InvertersFilterSectionProps {
    showFilterSection: boolean;
    control: Control<InverterFilterProps>;
}

export default function InvertersFilterSection({
    showFilterSection,
    control,
}: InvertersFilterSectionProps) {
    return (
        <>
            <Row className="mb-3">
                {showFilterSection && <InvertersFilterCard control={control} />}
            </Row>
            <Row>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <i className="fas fa-search" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Controller
                        as={
                            <Form.Control placeholder="Digite aqui o que procura..." />
                        }
                        type="text"
                        name="filterTerm"
                        control={control}
                        defaultValue=""
                    />
                </InputGroup>
            </Row>
        </>
    );
}
