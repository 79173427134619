import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../../../styles/react-select-config';
import { SelectOption } from '../../../../Select';

export interface AccessoriesFilterProps {
    filterTerm: string;
    filterProduct: Array<SelectOption<string>>;
    filterAccessory: Array<SelectOption<string>>;
}

interface Props {
    filterToggle: boolean;
    control: Control<AccessoriesFilterProps>;
    products: Array<SelectOption<string>>;
    disableProduct: boolean;
}

const AccessoriesFilter = (props: Props) => {
    const {
        filterToggle,
        control,
        products: productFilterOptions,
        disableProduct,
    } = props;

    return (
        <>
            {filterToggle && (
                <Row className="mb-3">
                    <Card className="mt-2" style={{ width: '100%' }}>
                        <Card.Body>
                            <Card.Title>
                                <strong>Filtros</strong>
                            </Card.Title>

                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="formBasicProduct">
                                        <Form.Label>Produto</Form.Label>
                                        <Controller
                                            isClearable
                                            className="form-control-nexen"
                                            as={
                                                <Select
                                                    isDisabled={disableProduct}
                                                />
                                            }
                                            control={control}
                                            name="filterProduct"
                                            options={productFilterOptions}
                                            isMulti
                                            styles={{
                                                control: tradeControl,
                                                option: optionsTheme,
                                            }}
                                            theme={theme}
                                            placeholder={
                                                disableProduct
                                                    ? 'Carregando produtos...'
                                                    : 'Selecione...'
                                            }
                                            defaultValue=""
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formBasicAcessory">
                                        <Form.Label>Acessório</Form.Label>
                                        <Controller
                                            isClearable
                                            className="form-control-nexen"
                                            as={
                                                <Select
                                                    isDisabled={disableProduct}
                                                />
                                            }
                                            control={control}
                                            name="filterAcessory"
                                            options={productFilterOptions}
                                            isMulti
                                            styles={{
                                                control: tradeControl,
                                                option: optionsTheme,
                                            }}
                                            theme={theme}
                                            placeholder={
                                                disableProduct
                                                    ? 'Carregando produtos...'
                                                    : 'Selecione...'
                                            }
                                            defaultValue=""
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Row>
            )}
            <Row>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <i className="fas fa-search" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Controller
                        as={
                            <Form.Control placeholder="Digite aqui o que procura..." />
                        }
                        type="text"
                        name="filterTerm"
                        control={control}
                        defaultValue=""
                    />
                </InputGroup>
            </Row>
        </>
    );
};

export { AccessoriesFilter };
