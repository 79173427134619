/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Col, Modal, Row, Form, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { StyledRow, StyledTitle } from './styles';
import SalesCampaignHttpService from '../../../../services/http/sales-campaign-http';
import { Product } from '../SalesCampaignProductsModal';

export interface Campaign {
    id?: number;
    name: string;
    priceTable: string;
    maxDiscount: number;
    maxInvoices: number;
    products: Product[];
}

interface Props {
    show: boolean;
    campaign: Campaign | null;
    reset(): void;
    onHide(): void;
}

const SalesCampaignModal = (props: Props) => {
    const newCampaign: Campaign = {
        name: '',
        priceTable: '',
        products: [],
        maxDiscount: 0,
        maxInvoices: 1,
    };

    const [loading, setLoading] = useState(false);
    const [campaign, setCampaign] = useState<Campaign>(newCampaign);

    async function saveCampaign() {
        setLoading(true);

        try {
            if (campaign.name === '' || campaign.priceTable === '') {
                toast.error(
                    'Campo nome ou tabela de preços preenchido incorretamente',
                );
                setLoading(false);

                return;
            }

            if (campaign.maxInvoices % 1 !== 0 || campaign.maxInvoices < 0) {
                toast.error(
                    'Número máximo de pedidos deve ser um inteiro positivo',
                );
                setLoading(false);

                return;
            }

            if (campaign.maxDiscount < 0 || campaign.maxDiscount > 100) {
                toast.error('Percentual máximo de desconto inválido');
                setLoading(false);

                return;
            }

            await SalesCampaignHttpService.patchSeason(campaign);

            props.onHide();
            props.reset();

            toast.success('Campanha salva com sucesso');
            return;
        } catch (error) {
            console.log('error -> ', error);

            toast.error('Erro ao salvar campanha');
        }

        setLoading(false);
    }

    const onChangeText = (value: any, field: string) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const newCampaign: Campaign = {
            id: campaign.id,
            name: field === 'name' ? value : campaign.name,
            priceTable: field === 'price_table' ? value : campaign.priceTable,
            maxDiscount:
                field === 'max_discount' ? value : campaign.maxDiscount,
            maxInvoices:
                field === 'max_invoices' ? value : campaign.maxInvoices,
            products: campaign.products,
        };

        setCampaign(newCampaign);
    };

    useEffect(() => {
        if (props.campaign) {
            setCampaign(props.campaign);
        }
    }, [props]);

    return (
        <>
            <Modal
                dialogClassName="modal-50w"
                show={props.show}
                onHide={props.onHide}
                size="lg"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className="ml-3 mr-3"
                        id="contained-modal-title-vcenter"
                    >
                        {props.campaign
                            ? 'Editar Campanha'
                            : 'Cadastrar Campanha'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="w-100 mb-4">
                    {campaign && (
                        <>
                            <StyledRow className="ml-0 mt-4 mr-1">
                                <Col>
                                    <StyledTitle>Nome da campanha</StyledTitle>
                                </Col>
                                <Col>
                                    <StyledTitle>Tabela de preços</StyledTitle>
                                </Col>
                            </StyledRow>
                            <Row className="ml-0 mt-2 mr-1">
                                <Col>
                                    <Form.Control
                                        name="name"
                                        value={campaign.name}
                                        onChange={({ target }) => {
                                            onChangeText(target.value, 'name');
                                        }}
                                        type="string"
                                        className="form-control-nexen"
                                        placeholder="Digite aqui..."
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        name="price_table"
                                        type="string"
                                        value={campaign.priceTable}
                                        onChange={({ target }) => {
                                            onChangeText(
                                                target.value,
                                                'price_table',
                                            );
                                        }}
                                        className="form-control-nexen"
                                        placeholder="Digite aqui..."
                                    />
                                </Col>
                            </Row>

                            <StyledRow className="ml-0 mt-4 mr-1">
                                <Col>
                                    <StyledTitle>
                                        Percentual de desconto máximo
                                    </StyledTitle>
                                </Col>
                                <Col>
                                    <StyledTitle>
                                        Número máximo de pedidos por integrador
                                    </StyledTitle>
                                </Col>
                            </StyledRow>
                            <Row className="ml-0 mt-2 mr-1">
                                <Col>
                                    <Form.Control
                                        name="discount"
                                        value={campaign.maxDiscount}
                                        onChange={({ target }) => {
                                            onChangeText(
                                                target.value,
                                                'max_discount',
                                            );
                                        }}
                                        min={0}
                                        max={100}
                                        type="number"
                                        className="form-control-nexen"
                                        placeholder="Digite aqui..."
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        name="invoices"
                                        value={campaign.maxInvoices}
                                        onChange={({ target }) => {
                                            onChangeText(
                                                target.value,
                                                'max_invoices',
                                            );
                                        }}
                                        min={1}
                                        type="number"
                                        className="form-control-nexen"
                                        placeholder="Digite aqui..."
                                    />
                                </Col>
                            </Row>
                        </>
                    )}

                    <StyledRow className="ml-0 mt-5 mr-1">
                        <Col sm={6} />
                        <Col>
                            <Button
                                className="w-100 float-right"
                                variant="outline-primary"
                                onClick={() => {
                                    props.onHide();
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100 float-right"
                                onClick={() => saveCampaign()}
                            >
                                {loading ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    'Salvar'
                                )}
                            </Button>
                        </Col>
                    </StyledRow>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SalesCampaignModal;
