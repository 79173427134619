/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col, Modal, Row, Form, Button, Spinner } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { StyledRow, StyledTitle } from './styles';
import { useConfiguration } from '../../../../contexts/configurationContext';
import {
    tradeControl,
    theme,
    optionsTheme,
} from '../../../../styles/react-select-config';
import { BannerLocation } from '../../../../constants/configuration';
import BannerHttpService from '../../../../services/http/banner-http';

interface FormData {
    fileName: string;
    location: { label: string; value: BannerLocation };
    awsLink: string;
    referLink: string;
    active: boolean;
}

const BannerEditor = (props: any) => {
    const [loading, setLoading] = useState(false);
    const { register, getValues, setValue, reset, control } =
        useForm<FormData>();
    const {
        setShowBannerEditorModal,
        selectedBanner,
        setSelectedBanner,
        setHasChange,
    } = useConfiguration();

    const locationOptions = [
        { label: 'Dashboard', value: BannerLocation.dashboard },
        {
            label: 'Pontos - Informações',
            value: BannerLocation.pointsInformations,
        },
    ];

    async function saveBanner() {
        setLoading(true);

        try {
            const useFormData = getValues();

            await BannerHttpService.update(selectedBanner.id, {
                fileName: useFormData.fileName,
                referLink: useFormData.referLink,
                location: useFormData.location.value,
                active: selectedBanner.active,
            });

            setHasChange(true);

            toast.success('Banner salvo.');
        } catch (error) {
            console.log(error);

            toast.error('Erro ao salvar dados de banner.');
        }

        setLoading(false);
    }

    useEffect(() => {
        if (selectedBanner?.fileName) {
            setValue('fileName', selectedBanner.fileName);
            setValue(
                'location',
                selectedBanner.location === BannerLocation.dashboard
                    ? locationOptions[0]
                    : locationOptions[1],
            );
            setValue('awsLink', selectedBanner.awsLink);
            setValue('referLink', selectedBanner.referLink);
            setValue('active', selectedBanner.active);
        } else {
            reset({});
        }
    }, [selectedBanner]);

    return (
        <>
            <Modal
                className="modal-dialog-scrollable"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                size="md"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className="ml-3 mr-3"
                        id="contained-modal-title-vcenter"
                    >
                        Banner
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="w-100">
                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Nome</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                disabled
                                name="fileName"
                                type="string"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>
                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Link Destino</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="referLink"
                                type="string"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>
                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Local</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Controller
                                isClearable={false}
                                className="form-control-nexen"
                                as={Select}
                                control={control}
                                name="location"
                                options={locationOptions}
                                isMulti={false}
                                styles={{
                                    control: tradeControl,
                                    option: optionsTheme,
                                }}
                                theme={theme}
                                placeholder="Selecione..."
                                defaultValue=""
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-5 mr-1">
                        <Col sm={6} />
                        <Col>
                            <Button
                                className="w-100 float-right"
                                variant="outline-primary"
                                onClick={() => {
                                    setShowBannerEditorModal(false);
                                    setSelectedBanner({});
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100 float-right"
                                onClick={() => {
                                    saveBanner();
                                    setShowBannerEditorModal(false);
                                    setSelectedBanner({});
                                }}
                            >
                                Salvar{'   '}
                                {loading && (
                                    <Spinner animation="border" size="sm" />
                                )}
                            </Button>
                        </Col>
                    </StyledRow>
                </Modal.Body>
                <Modal.Footer className="ml-3 mr-3 mb-2" />
            </Modal>
        </>
    );
};

export default BannerEditor;
