import React, { useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import LibraryHttpService from '../../../services/http/library-http';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../styles/react-select-config';
import { StyledRow, StyledTitle } from '../styles';

function LibrariesModalInputs({
    register,
    errors,
    control,
    options,
    id,
    setValue,
}: any) {
    async function loadData() {
        const { data } = await LibraryHttpService.getLibrary(id);

        const keys = Object.keys(data);

        // eslint-disable-next-line no-restricted-syntax
        for (const key of keys) {
            if (key === 'reference') {
                setValue(
                    key,
                    options.find(
                        (option: any) => option.value === data.reference,
                    ),
                );
                // eslint-disable-next-line no-continue
                continue;
            }

            setValue(key, data[key]);
        }
    }

    useEffect(() => {
        if (id) loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <StyledRow className="ml-0 mt-2 mr-1">
                <Col>
                    <StyledTitle>Título</StyledTitle>
                </Col>
            </StyledRow>
            <Row className="ml-0 mr-1">
                <Col>
                    <Form.Group>
                        <Form.Control
                            ref={register}
                            name="title"
                            type="string"
                            className="form-control-nexen"
                            placeholder="Digite aqui..."
                            isInvalid={!!errors.title}
                        />
                        {errors.title && (
                            <Form.Control.Feedback type="invalid">
                                {errors.title}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <StyledRow className="ml-0 mt-2 mr-1">
                <Col>
                    <StyledTitle>Descrição</StyledTitle>
                </Col>
            </StyledRow>
            <Row className="ml-0 mb-2 mr-1">
                <Col>
                    <Form.Group>
                        <Form.Control
                            ref={register}
                            name="description"
                            type="string"
                            as="textarea"
                            className="form-control-nexen"
                            placeholder="Digite aqui..."
                            isInvalid={!!errors.description}
                        />
                        {errors.description && (
                            <Form.Control.Feedback type="invalid">
                                {errors.description}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <StyledRow className="ml-0 mr-1">
                <Col>
                    <StyledTitle>Tipo</StyledTitle>
                </Col>
            </StyledRow>
            <Row className="ml-0 mr-1">
                <Col>
                    <Controller
                        isClearable={false}
                        className="form-control-nexen"
                        as={Select}
                        control={control}
                        name="type"
                        options={options}
                        isMulti={false}
                        styles={{
                            control: tradeControl,
                            option: optionsTheme,
                        }}
                        theme={theme}
                        placeholder="Selecione um tipo..."
                        defaultValue={options[0]}
                        handleMouseDownOnMenu={false}
                        menuPosition="fixed"
                        menuShouldBlockScroll={false}
                        menuShouldScrollIntoView
                    />
                </Col>
            </Row>
            <StyledRow className="ml-0 mt-4 mr-1">
                <Col>
                    <StyledTitle>Rota</StyledTitle>
                </Col>
            </StyledRow>
            <Row className="ml-0 mr-1">
                <Col>
                    <Form.Control
                        ref={register}
                        name="route"
                        type="string"
                        className="form-control-nexen"
                        placeholder="Digite aqui..."
                    />
                </Col>
            </Row>
            <StyledRow className="ml-0 mt-4 mr-1">
                <Col>
                    <StyledTitle>Ícone</StyledTitle>
                </Col>
            </StyledRow>
            <Row className="ml-0 mr-1">
                <Col>
                    <Form.Control
                        ref={register}
                        name="icon"
                        type="string"
                        className="form-control-nexen"
                        placeholder="Digite aqui..."
                    />
                </Col>
            </Row>
        </>
    );
}

export default LibrariesModalInputs;
