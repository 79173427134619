import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
    background-color: #ffffff !important;
`;

export const StyledTitle = styled.h3`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #707070;
`;

export const StyledSelect = styled.div`
    .invalid-select > div {
        border-color: #dc3545;
    }
`;
