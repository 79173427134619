import Request from './request';

export default class ReturnHttpService {
    private static uri = 'returns';

    public static index(params: {}) {
        return Request.get(this.uri, params);
    }

    public static saveReturn(params: {}) {
        return Request.post(this.uri, params);
    }

    public static logistics(params: {}) {
        return Request.patch(`${this.uri}/logistics`, params);
    }

    public static financial(params: {}) {
        return Request.patch(`${this.uri}/financial`, params);
    }

    public static logs(params: {}) {
        return Request.post(`${this.uri}/logs`, params);
    }

    public static updateStatus(params: {}) {
        return Request.patch(`${this.uri}/updateStatus`, params);
    }

    public static gerarRetorno(params: {}) {
        return Request.patch(`${this.uri}/generateReturn`, params);
    }

    public static desmontarKits(params: {}) {
        return Request.patch(`${this.uri}/disassembleKits`, params);
    }

    public static removeOrder(params: number) {
        return Request.del(`${this.uri}/removeReturn/${params}`);
    }
}
