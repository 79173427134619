import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledConfirmButton = styled(Button)`
    width: 200px;
`;

export const StyledOutlineButton = styled(Button)`
    width: 200px;
    background-color: #ffffff;
    border: 1px solid solid #6fcf97;
    color: #6fcf97 !important;
    :hover {
        background-color: ${(props) => !props.$disabled && '#6fcf97'};
        color: ${(props) => !props.$disabled && '#ffffff !important'};
    }
    :active {
        color: #ffffff !important;
    }
`;
