import { ListAccessoriesParams } from '../../components/Configuration/OnlineStoreData/Accessories';
import Accessory from '../../shared/interfaces/accessory.interface';
import Request from './request';

export interface ListInvertersParams {
    skip?: number;
    take?: number;
    term?: string;
    brandFilter?: string;
    active?: string;
}

export default class kitsHttpService {
    private static uri = 'kits';

    public static getModules(data: any) {
        return Request.get(`${this.uri}/modules`, data);
    }

    public static storeModule(data: {}) {
        return Request.post(`${this.uri}/modules`, data);
    }

    public static deleteModule(id: number) {
        return Request.del(`${this.uri}/modules/${id}`);
    }

    public static getRoofTypes(data: any) {
        return Request.get(`${this.uri}/rooftypes`, data);
    }

    public static storeRoofType(data: {}) {
        return Request.post(`${this.uri}/rooftypes`, data);
    }

    public static async deleteRoofType(id: number) {
        return Request.del(`${this.uri}/rooftypes/${id}`);
    }

    public static getKits(data: any) {
        return Request.get(`${this.uri}/kits`, data);
    }

    public static async saveKits(item: FormData) {
        return Request.post(`${this.uri}/kits/upload`, item, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }

    public static async deleteKits() {
        return Request.del(`${this.uri}/kits`);
    }

    public static getInverterBrands(params?: any) {
        return Request.get(`${this.uri}/inverterBrands`, params);
    }

    public static async saveInverterBrand(inverterBrand: any) {
        return Request.post(`${this.uri}/inverterBrands`, inverterBrand);
    }

    public static async deleteInverterBrand(id: number) {
        return Request.del(`${this.uri}/inverterBrands/${id}`);
    }

    public static getInverters(params: ListInvertersParams) {
        return Request.get(`${this.uri}/inverters`, params);
    }

    public static async saveInverter(inverter: any) {
        return Request.post(`${this.uri}/inverters`, inverter);
    }

    public static async deleteInverter(id: number) {
        return Request.del(`${this.uri}/inverters/${id}`);
    }

    public static getAccessories(params?: ListAccessoriesParams) {
        return Request.get(`${this.uri}/accessories`, params);
    }

    public static async saveAccessory(params: Accessory) {
        return Request.post(`${this.uri}/accessories`, params);
    }

    public static async deleteAccessory(id: number) {
        return Request.del(`${this.uri}/accessories/${id}`);
    }
}
