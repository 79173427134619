import React from 'react';
import { Switch } from 'react-router-dom';
import { Library } from '../../pages/Library';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';

export const librariesRoute = new Route('/library/libraries');
export const libraryModulesRoute = new Route('/library/modules');
export const libraryBrandsRoute = new Route('/library/brands');
export const libraryBrandsCategoriesRoute = new Route('/library/categories');
export const libraryItemRoute = new Route('/library/items');

export const LibrariesRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={librariesRoute.path}
            component={Library}
            isPrivate
            exact
        />

        <RouteWrapper
            path={libraryModulesRoute.path}
            component={Library}
            isPrivate
            exact
        />

        <RouteWrapper
            path={libraryBrandsRoute.path}
            component={Library}
            isPrivate
            exact
        />

        <RouteWrapper
            path={libraryBrandsCategoriesRoute.path}
            component={Library}
            isPrivate
            exact
        />

        <RouteWrapper
            path={libraryItemRoute.path}
            component={Library}
            isPrivate
            exact
        />
    </Switch>
);
