import { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Dropdown,
    Form,
    InputGroup,
    Row,
    Spinner,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import { ReactComponent as ReactMoreIcon } from '../../../../assets/icons/more.svg';
import NotificationIcon from '../../../../constants/notificationIcon';
import kitsHttpService from '../../../../services/http/kit-http';
import Branch from '../../../../shared/interfaces/branch.interface';
import { StyledPageTitle } from '../../../../styles/pageTitle';
import ConfirmationDialog from '../../../../utils/ConfirmationDialog';
import InverterBrandModal from './InverterBrandModal';
import { StyledTable, StyledTh } from './styles';

const InvertersBrandsTable = () => {
    const [term, setTerm] = useState('');
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingCheck, setLoadingCheck] = useState(false);
    const [invertersBrands, setInvertersBrands] = useState([]);
    const [selectedInverterBrand, setSelectedInverterBrand] = useState({});
    const [selectedInverterBrandId, setSelectedInverterBrandId] = useState<
        number | null
    >(null);
    const [showAddInverterBrandModal, setShowAddInverterBrandModal] =
        useState(false);
    const [showRemoveInverterBrandModal, setShowRemoveInverterBrandModal] =
        useState<boolean>(false);

    async function loadInvertersBrands() {
        setLoading(true);

        try {
            const response = await kitsHttpService.getInverterBrands({
                term,
            });

            setInvertersBrands(response.data.data);
        } catch (error) {
            toast.error('Houve um erro ao carregar as marcas de inversores.');
        } finally {
            setLoading(false);
        }
    }

    async function setActiveInverterBrand(inverterBrand: any) {
        setLoadingCheck(true);

        try {
            // eslint-disable-next-line no-param-reassign
            inverterBrand.active = !inverterBrand.active;

            await kitsHttpService.saveInverterBrand(inverterBrand);

            if (inverterBrand.active) {
                toast.success('Marca de inversor ativada.');
            } else {
                toast.success('Marca de inversor desativada.');
            }
        } catch (error) {
            toast.error('Erro ao salvar marca de inversor.');
        }

        setLoadingCheck(false);
    }

    const deleteInverterBrand = async () => {
        setLoading(true);

        try {
            if (!selectedInverterBrandId) {
                throw new Error('Inversor não encontrado para exclusão');
            }
            const response = await kitsHttpService.deleteInverterBrand(
                selectedInverterBrandId,
            );

            if (response.status === 200) {
                loadInvertersBrands();
                toast.success('Marca de inversor excluída.');
            }
        } catch (error: any) {
            if (
                error.message ===
                'Não é possível deletar essa marca de inversor pois existe um inversor relacionado a ela.'
            ) {
                toast.error(error.message);
                return;
            }

            toast.error(
                'Houve um erro ao remover marca de inversor, por favor tente novamente mais tarde.',
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setInvertersBrands([]);
        setIsFirstRender(false);
    }, []);

    useEffect(() => {
        loadInvertersBrands();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (!isFirstRender) {
            const timer = setTimeout(() => {
                setInvertersBrands([]);
                loadInvertersBrands();
            }, 500);

            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    return (
        <>
            <InverterBrandModal
                show={showAddInverterBrandModal}
                selectedInverterBrand={selectedInverterBrand}
                // eslint-disable-next-line react/jsx-no-bind
                refetch={loadInvertersBrands}
                onHide={() => {
                    setSelectedInverterBrand({});
                    setShowAddInverterBrandModal(false);
                }}
            />

            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">
                        Marca Inversores
                    </StyledPageTitle>
                </Col>
                <Col>
                    <Button
                        className="float-right w-25 m-2"
                        onClick={() => {
                            setSelectedInverterBrand({});
                            setShowAddInverterBrandModal(true);
                        }}
                    >
                        Nova marca de inversor
                    </Button>
                </Col>
            </Row>
            <Row>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <i className="fas fa-search" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        value={term}
                        onChange={(event) => setTerm(event.target.value)}
                        placeholder="Digite aqui o que procura..."
                    />
                </InputGroup>
            </Row>
            <Row>
                <StyledTable bordered hover size="sm" className="text-center">
                    <thead>
                        <tr>
                            <StyledTh>ID</StyledTh>
                            <StyledTh>Nome</StyledTh>
                            <StyledTh>Ativo</StyledTh>
                            <StyledTh>Filiais</StyledTh>
                            <StyledTh />
                        </tr>
                    </thead>
                    <tbody>
                        {invertersBrands.length > 0 &&
                            invertersBrands?.map((item: any, index: any) => (
                                <tr key={index}>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.id.toString()}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.name}
                                        />
                                    </td>
                                    <td>
                                        {!loadingCheck && (
                                            <Form.Check
                                                id={`${index.toString()}m`}
                                                checked={item.active}
                                                onChange={() => {
                                                    setActiveInverterBrand(
                                                        item,
                                                    );
                                                }}
                                            />
                                        )}
                                        {loadingCheck && (
                                            <Form.Check
                                                id={`${index.toString()}m`}
                                                checked={item.active}
                                                disabled
                                            />
                                        )}
                                    </td>
                                    <td>
                                        {item.branches
                                            .map(
                                                (branch: Branch) => branch.name,
                                            )
                                            .join(', ')}
                                    </td>
                                    <td>
                                        <Dropdown key="left">
                                            <Dropdown.Toggle
                                                bsPrefix="nexen"
                                                className="mt-n3 mb-n3"
                                                as={Button}
                                                variant="text"
                                            >
                                                <ReactMoreIcon
                                                    fill="#bdbdbd"
                                                    width="10"
                                                    height="20"
                                                />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setSelectedInverterBrand(
                                                            item,
                                                        );
                                                        setShowAddInverterBrandModal(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    Editar
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setSelectedInverterBrandId(
                                                            item.id,
                                                        );
                                                        setShowRemoveInverterBrandModal(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    Remover
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </StyledTable>
            </Row>

            <Row className="mx-4">
                <br />
                {loading && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}

                {invertersBrands.length === 0 && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Row>

            {showRemoveInverterBrandModal && (
                <ConfirmationDialog
                    show={showRemoveInverterBrandModal}
                    onHide={() => {
                        setShowRemoveInverterBrandModal(false);
                        setSelectedInverterBrandId(null);
                    }}
                    onConfirm={deleteInverterBrand}
                    icon={NotificationIcon.Warning}
                    title="Excluir a marca de inversor"
                    text="Deseja excluir a marca de inversor selecionada?"
                />
            )}
        </>
    );
};

export default InvertersBrandsTable;
