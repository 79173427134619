/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledDiv = styled.div`
    margin-left: -80%;

    @media (max-width: 350px) {
        margin-left: 0%;
    }
`;
