import { BannerLocation } from '../../constants/configuration';
import Request from './request';

export default class BannerHttpService {
    private static uri = 'banners';

    public static create(banner: globalThis.FormData) {
        return Request.post(`${this.uri}`, banner);
    }

    public static readMany(params: {
        skip?: number;
        take?: number;
        term?: string;
        active?: boolean;
        location?: BannerLocation;
    }) {
        return Request.get(`${this.uri}`, params);
    }

    public static update(
        id: number,
        banner: {
            fileName: string;
            location: BannerLocation;
            referLink: string;
            active: boolean;
        },
    ) {
        return Request.patch(`${this.uri}/${id}`, banner);
    }

    public static delete(id: number) {
        return Request.del(`${this.uri}/${id}`);
    }
}
