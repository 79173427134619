import React, { useState, useEffect } from 'react';
import {
    Table,
    Dropdown,
    Row,
    Col,
    Form,
    Button,
    InputGroup,
    Spinner,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { ReactComponent as ReactMoreIcon } from '../../../assets/icons/more.svg';
import { StyledTh } from './styles';
import { StyledPageTitle } from '../../../styles/pageTitle';
import PointsSeasonModal from './PointsSeasonModal';
import PointHttpService from '../../../services/http/point-http';
import formatDate from '../../../utils/formatDate';
import ConfigurationHttpService from '../../../services/http/configuration-http';
import ConfigurationReference from '../../../constants/configurationReference';

const PointData = () => {
    const [selectedSeason, setSelectedSeason] = useState({});
    const [term, setTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [showCreatePointsSeasonModal, setShowCreatePointsSeasonModal] =
        useState(false);
    const [seasons, setSeasons] = useState<any>([]);
    const [isEdit, setIsEdit] = useState(false);
    const [actualSeason, setActualSeason] = useState<any>();

    async function getSeasons() {
        setLoading(true);

        try {
            const response = await PointHttpService.getPointsSeasons();

            setSeasons(response.data.pointsSeasons);
        } catch (error) {
            const err = error as any;

            toast.error(err.message);
        }

        setLoading(false);
    }

    const deleteSeason = async (season: any) => {
        setLoading(true);

        try {
            await PointHttpService.deletePointsSeason(season.id);

            getSeasons();
            toast.success('Temporada excluída.');
        } catch (error) {
            const err = error as any;

            toast.error(err.message);
        }

        setLoading(false);
    };

    const getActualSeason = async () => {
        setLoading(true);

        try {
            const response = await ConfigurationHttpService.readOne(
                ConfigurationReference.ActiveSeason,
            );

            setActualSeason(response.data.value);
        } catch (error) {
            const err = error as any;

            toast.error(err.message);
        }

        setLoading(false);
    };

    const updateActualPointsSeason = async (item: any) => {
        setLoading(true);

        const newSeason = {
            value: item.id.toString(),
        };

        try {
            const response = await ConfigurationHttpService.update(
                ConfigurationReference.ActiveSeason,
                newSeason,
            );

            setActualSeason(`${response.data.value}`);
        } catch (error) {
            const err = error as any;

            toast.error(err.message);
        }

        setLoading(false);
    };

    const disableActualSeason = async () => {
        setLoading(true);

        try {
            const response = await ConfigurationHttpService.update(
                ConfigurationReference.ActiveSeason,
                { value: null },
            );

            setActualSeason(response.data);
        } catch (error) {
            const err = error as any;

            toast.error(err.message);
        }

        setLoading(false);
    };

    useEffect(() => {
        getSeasons();
        getActualSeason();
    }, []);

    return (
        <>
            <PointsSeasonModal
                show={showCreatePointsSeasonModal}
                isOpen={setShowCreatePointsSeasonModal}
                // eslint-disable-next-line react/jsx-no-bind
                update={getSeasons}
                selectedSeason={selectedSeason}
                isEdit={isEdit}
                onHide={() => {
                    setSelectedSeason({});
                    setShowCreatePointsSeasonModal(false);
                }}
            />

            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">Pontos</StyledPageTitle>
                </Col>
                <Col>
                    <Button
                        className="float-right w-25 m-2"
                        onClick={() => {
                            setIsEdit(false);
                            setShowCreatePointsSeasonModal(true);
                        }}
                    >
                        Nova Temporada
                    </Button>
                </Col>
            </Row>
            <Row>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <i className="fas fa-search" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        value={term}
                        onChange={(event) => setTerm(event.target.value)}
                        placeholder="Digite aqui o que procura..."
                    />
                </InputGroup>
            </Row>
            <Row>
                <Table bordered hover size="sm" className="text-center">
                    <thead>
                        <tr>
                            <StyledTh>Nome</StyledTh>
                            <StyledTh>Data de ínicio</StyledTh>
                            <StyledTh>Data de fim</StyledTh>
                            <StyledTh>Ativo</StyledTh>
                            <StyledTh />
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <div>
                                <Spinner animation="border" />
                            </div>
                        ) : (
                            seasons.map((item: any, index: any) => (
                                <tr key={index}>
                                    <td style={{ padding: 10 }}>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.name}
                                        />
                                    </td>
                                    <td>{formatDate(item.seasonStart)}</td>
                                    <td>
                                        {DateTime.fromISO(
                                            item.seasonFinish,
                                        ).toLocaleString()}
                                    </td>
                                    <td>
                                        {item.id.toString() === actualSeason
                                            ? 'Sim'
                                            : '-'}
                                    </td>
                                    <td>
                                        <Dropdown key="left">
                                            <Dropdown.Toggle
                                                bsPrefix="nexen"
                                                className="mt-n3 mb-n3"
                                                as={Button}
                                                variant="text"
                                            >
                                                <ReactMoreIcon
                                                    fill="#bdbdbd"
                                                    width="10"
                                                    height="20"
                                                />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setSelectedSeason(item);
                                                        setShowCreatePointsSeasonModal(
                                                            true,
                                                        );
                                                        setIsEdit(true);
                                                    }}
                                                >
                                                    Editar
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        deleteSeason(item);
                                                    }}
                                                >
                                                    Remover
                                                </Dropdown.Item>
                                                {!(
                                                    item.id.toString() ===
                                                    actualSeason
                                                ) && (
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            updateActualPointsSeason(
                                                                item,
                                                            );
                                                        }}
                                                    >
                                                        Selecionar como ativa
                                                    </Dropdown.Item>
                                                )}
                                                {item.id.toString() ===
                                                    actualSeason && (
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            disableActualSeason();
                                                        }}
                                                    >
                                                        Desativar
                                                    </Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
            </Row>

            {seasons.length === 0 && (
                <Col md={12} className="text-center">
                    <strong style={{ color: '#adadad' }}>
                        Sem itens para carregar
                    </strong>
                </Col>
            )}
        </>
    );
};

export default PointData;
