import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../styles/react-select-config';

export interface OptionProps {
    label: string;
    value: string;
}

export interface UserFilterProps {
    filterTerm: string;
    filterRoles: OptionProps[];
    filterRegions: OptionProps[];
}

interface Props {
    filterToggle: boolean;
    control: Control<UserFilterProps>;
    roles: OptionProps[];
    regions: OptionProps[];
}

const UserFilter = (props: Props) => {
    const { filterToggle, control, roles, regions } = props;

    return (
        <>
            {filterToggle && (
                <Row>
                    <Col>
                        <Card className="mt-4 mx-2">
                            <Card.Body>
                                <Card.Title>
                                    <strong>Filtros</strong>
                                </Card.Title>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group controlId="formBasicProduct">
                                            <Form.Label>Permissões</Form.Label>
                                            <Controller
                                                isClearable
                                                className="form-control-nexen"
                                                as={Select}
                                                control={control}
                                                name="filterRoles"
                                                options={roles}
                                                isMulti
                                                styles={{
                                                    control: tradeControl,
                                                    option: optionsTheme,
                                                }}
                                                theme={theme}
                                                placeholder="Selecione..."
                                                defaultValue=""
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="formBasicProduct">
                                            <Form.Label>Regiões</Form.Label>
                                            <Controller
                                                isClearable
                                                className="form-control-nexen"
                                                as={Select}
                                                control={control}
                                                name="filterRegions"
                                                options={regions}
                                                isMulti
                                                styles={{
                                                    control: tradeControl,
                                                    option: optionsTheme,
                                                }}
                                                theme={theme}
                                                placeholder="Selecione..."
                                                defaultValue=""
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
            <Row className="pl-2 pr-2 mt-4">
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-search" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Controller
                            as={
                                <Form.Control placeholder="Digite aqui o que procura..." />
                            }
                            type="text"
                            name="filterTerm"
                            control={control}
                            defaultValue=""
                        />
                    </InputGroup>
                </Col>
            </Row>
        </>
    );
};

export default UserFilter;
