import { NavDropdown } from 'react-bootstrap';
import { StyledDropdownSubtitle, StyledListGroup } from './style';
import NotificationItem from '../NotificationItem';
import Notification from '../../../../shared/interfaces/notification.interface';

interface NotificationsListProps {
    notifications: Notification[];
    title: string;
}

export default function NotificationsList({
    notifications,
    title,
}: NotificationsListProps) {
    return (
        <NavDropdown.ItemText className="ml-n4 mr-n4">
            <StyledDropdownSubtitle className="ml-4">
                {title} {notifications.length}
            </StyledDropdownSubtitle>

            <StyledListGroup variant="flush" className="light-scrollbar">
                {notifications?.map((notification) => (
                    <NotificationItem
                        key={notification.id}
                        notification={notification}
                    />
                ))}
            </StyledListGroup>
        </NavDropdown.ItemText>
    );
}
