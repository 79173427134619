import { ChangeEvent, DragEvent, ReactNode, useState } from 'react';
import { toast } from 'react-toastify';
import StyledLabel from './style';

interface FileInputProps {
    maxSizeInMb: number;
    onChange: (file: File) => void;
    accept: string;
    label: string | ReactNode;
}

export default function FileInput({
    onChange,
    maxSizeInMb,
    accept,
    label,
}: FileInputProps) {
    const [isDragOver, setDragOver] = useState(false);

    const handleDragEnter = (event: DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        setDragOver(false);
    };

    const handleDragOver = (event: DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        setDragOver(true);
    };

    const isValidFileSize = (file?: File): boolean => {
        if (!file) {
            return false;
        }
        const fileMbSize = file.size / (1024 * 1024);

        if (fileMbSize > maxSizeInMb) {
            return false;
        }

        return true;
    };

    const handleDrop = (event: DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        setDragOver(false);

        const file = event.dataTransfer.files[0];

        if (!isValidFileSize(file)) {
            toast.error(
                `Arquivos com mais de ${maxSizeInMb}Mb não são permitidos`,
            );
            return;
        }

        onChange(file);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        const fileInput = event.target;

        if (!fileInput || !fileInput.files || fileInput.files.length === 0) {
            return;
        }

        const file = fileInput.files[0];

        const fileExtension = file.name.toLowerCase().split('.').pop();
        if (!fileExtension || !accept.includes(fileExtension)) {
            const formattedAccept = accept.replace('.', '');
            toast.error(
                `Somente arquivos com a extensão ${formattedAccept} são permitidos`,
            );
            return;
        }

        if (!isValidFileSize(file)) {
            toast.error(
                `Arquivos com mais de ${maxSizeInMb} MB não são permitidos`,
            );
            return;
        }

        onChange(file);
    };

    const labelClasses = ['upload-input', isDragOver ? 'drag-over' : ''].filter(
        Boolean,
    );

    return (
        <>
            <StyledLabel
                className={labelClasses.join(' ')}
                htmlFor="file"
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                {label}
                <input
                    name="file"
                    id="file"
                    type="file"
                    accept={accept}
                    style={{ display: 'none' }}
                    onChange={handleChange}
                />
            </StyledLabel>
        </>
    );
}
