import { Form } from 'react-bootstrap';
import { Controller, Control } from 'react-hook-form';

interface ConfirmCheckBoxProps {
    control: Control;
    disabled: boolean;
    onChange: () => void;
}

export default function ConfirmCheckBox({
    control,
    disabled,
    onChange,
}: ConfirmCheckBoxProps) {
    return (
        <Controller
            control={control}
            name="removeConfirm"
            render={({ value }) => (
                <Form.Check
                    id="removeConfirm"
                    label="Desejo excluir todos os registros com este identificador."
                    checked={value}
                    style={{
                        textAlign: 'left',
                        marginRight: 'auto',
                    }}
                    onChange={onChange}
                    disabled={disabled}
                />
            )}
        />
    );
}
