import { Modal, Row, Spinner, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import ProductDeliveryDateHttpService from '../../../../../services/http/product-delivery-date-http';
import isFriendlyHttpError from '../../../../../utils/isFriendlyHttpError';
import FileInput from '../../../../FileInput';
import {
    StyledCancelButton,
    StyledImportButton,
    StyledLink,
    StyledRow,
} from './styles';
import FileCard from './FileCard';
import NEXEN_BUCKET_PORTAL_URL from '../../../../../config/webservice';

interface ProductDeliveryDateInsertManyModalProps {
    show: boolean;
    onHide(): void;
}

export interface ProductDeliveryDateInsertManyModalFields {
    selectedFile: File;
    selectedFileName: string;
}

export default function ProductDeliveryDateInsertManyModal({
    show,
    onHide,
}: ProductDeliveryDateInsertManyModalProps) {
    const { watch, handleSubmit, setValue, errors, control } =
        useForm<ProductDeliveryDateInsertManyModalFields>({
            shouldUnregister: false,
            defaultValues: {
                selectedFileName: '',
                selectedFile: undefined,
            },
        });

    const formData = watch();

    const uploudMutation = useMutation({
        mutationFn: async () => {
            const data = new FormData();
            data.append(
                'file',
                formData.selectedFile,
                formData.selectedFile.name,
            );
            await ProductDeliveryDateHttpService.import(data);
        },
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);

                return;
            }

            toast.error('Ocorreu um erro ao importar produtos em lote');
        },
        onSuccess: async () => {
            toast.success('Produtos importados com sucesso');
            onHide();
        },
    });

    const onSubmit = handleSubmit(() => uploudMutation.mutateAsync());

    const maxSizeInMb = 5;

    return (
        <Modal
            aria-labelledby="invoice-validation-modal"
            size="lg"
            show={show}
            onHide={onHide}
            centered
        >
            <Form onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title
                        className="mb-0"
                        id="contained-modal-title-vcenter"
                    >
                        Inclusão em lote
                        <p className="mt-0">
                            Inclui datas de disponibilidade a partir de arquivo
                            CSV com tamanho máximo de {maxSizeInMb} MB. <br />{' '}
                        </p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StyledRow>
                        Arquivo exemplo:
                        <StyledLink
                            href={`${NEXEN_BUCKET_PORTAL_URL}/exc-planilha/csv-exemplo.csv`}
                        >
                            Arquivo.csv
                        </StyledLink>
                    </StyledRow>
                    <Row>
                        <Controller
                            control={control}
                            as={FileInput}
                            name="selectedFile"
                            onChange={(file: File) => {
                                setValue('selectedFile', file);
                                setValue('selectedFileName', file.name);
                            }}
                            accept=".csv"
                            maxSizeInMb={maxSizeInMb}
                            defaultValue={null}
                            label={
                                <>
                                    Arraste e solte um arquivo aqui ou clique
                                    para <br /> fazer a seleção a partir do seu
                                    computador.
                                </>
                            }
                            rules={{ required: 'Campo obrigatório' }}
                        />
                        {errors.selectedFile && (
                            <Form.Control.Feedback
                                type="invalid"
                                className="d-block ml-4"
                            >
                                Campo obrigatório
                            </Form.Control.Feedback>
                        )}
                    </Row>

                    {formData.selectedFile && (
                        <Row>
                            <FileCard file={formData.selectedFile} />
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <StyledCancelButton
                        onClick={onHide}
                        disabled={uploudMutation.isLoading}
                    >
                        Cancelar
                    </StyledCancelButton>
                    <StyledImportButton
                        type="submit"
                        disabled={uploudMutation.isLoading}
                    >
                        {uploudMutation.isLoading ? (
                            <>
                                <Spinner animation="border" size="sm" />
                                <span>Importando...</span>
                            </>
                        ) : (
                            'Importar'
                        )}
                    </StyledImportButton>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
