/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React from 'react';
import { Form, InputGroup, Image } from 'react-bootstrap';
import { signUpRoute } from '../../../routes/config';
import personIcon from '../../../assets/icons/person.svg';
import emailIcon from '../../../assets/icons/email.svg';
import passwordIcon from '../../../assets/icons/password.svg';

const IntegratorUser = (props: any) => {
    const pathname = window.location.pathname;

    console.log(props.integrator);

    if (pathname === signUpRoute.path) {
        return (
            <>
                <Form.Group controlId="formBasicName">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    border: '1px solid #707070',
                                    width: 45,
                                }}
                            >
                                <Image src={personIcon} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            disabled={props.isDisabled}
                            isInvalid={!!props.errors.integratorsNick}
                            onChange={props.handleChanges}
                            value={props.integrator.username}
                            isValid={
                                props.integrator.username &&
                                !props.errors.username &&
                                props.signUp
                            }
                            name="integratorsNick"
                            maxLength={50}
                            type="text"
                            placeholder="Nome"
                        />
                        {props.errors.integratorsNick && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.integratorsNick}
                            </Form.Control.Feedback>
                        )}
                    </InputGroup>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    border: '1px solid #707070',
                                    width: 45,
                                }}
                            >
                                <Image src={emailIcon} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            disabled={props.isDisabled}
                            isInvalid={!!props.errors.email}
                            onChange={props.handleChanges}
                            value={props.integrator.email}
                            isValid={
                                props.integrator.email &&
                                !props.errors.email &&
                                props.signUp
                            }
                            name="email"
                            type="email"
                            maxLength={256}
                            placeholder="E-mail"
                        />
                        {props.errors.email && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.email}
                            </Form.Control.Feedback>
                        )}
                    </InputGroup>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    border: '1px solid #707070',
                                    width: 45,
                                }}
                            >
                                <Image src={passwordIcon} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            disabled={props.isDisabled}
                            isInvalid={!!props.errors.password}
                            onChange={props.handleChanges}
                            value={props.integrator.password}
                            isValid={
                                props.integrator.password &&
                                !props.errors.password &&
                                props.signUp
                            }
                            name="password"
                            type="password"
                            placeholder="Senha"
                        />
                        {props.errors.password && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.password}
                            </Form.Control.Feedback>
                        )}
                    </InputGroup>
                </Form.Group>

                <Form.Group controlId="formBasicConfirmPassword">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    border: '1px solid #707070',
                                    width: 45,
                                }}
                            >
                                <Image src={passwordIcon} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            disabled={props.isDisabled}
                            isInvalid={!!props.errors.password_confirmation}
                            onChange={props.handleChanges}
                            value={props.integrator.password_confirmation}
                            isValid={
                                !props.errors.password &&
                                props.integrator.password &&
                                props.integrator.password_confirmation &&
                                !props.errors.password_confirmation &&
                                props.signUp
                            }
                            name="password_confirmation"
                            type="password"
                            placeholder="Confirme sua senha"
                        />
                        {props.errors.password_confirmation && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.password_confirmation}
                            </Form.Control.Feedback>
                        )}
                    </InputGroup>
                </Form.Group>
            </>
        );
    }

    return (
        <>
            <Form.Group controlId="formBasicName">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                    className={
                        pathname === signUpRoute.path
                            ? 'nexen-form-control'
                            : ''
                    }
                    disabled={props.isDisabled}
                    isInvalid={!!props.errors.integratorsNick}
                    onChange={props.handleChanges}
                    value={props.integrator.integratorsNick}
                    isValid={
                        props.integrator.integratorsNick &&
                        !props.errors.integratorsNick &&
                        props.signUp
                    }
                    name="integratorsNick"
                    maxLength={50}
                    type="text"
                    placeholder="Informe seu nome"
                />

                {props.errors.integratorsNick && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.integratorsNick}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                    className={
                        pathname === signUpRoute.path
                            ? 'nexen-form-control'
                            : ''
                    }
                    disabled={props.isDisabled}
                    isInvalid={!!props.errors.email}
                    onChange={props.handleChanges}
                    value={props.integrator.email}
                    isValid={
                        props.integrator.email &&
                        !props.errors.email &&
                        props.signUp
                    }
                    name="email"
                    type={props.isSignUp ? 'email' : 'text'}
                    maxLength={256}
                    placeholder="Informe seu e-mail"
                />

                {props.errors.email && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.email}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
                <Form.Label>Senha</Form.Label>
                <Form.Control
                    className={
                        pathname === signUpRoute.path
                            ? 'nexen-form-control'
                            : ''
                    }
                    disabled={props.isDisabled}
                    isInvalid={!!props.errors.password}
                    onChange={props.handleChanges}
                    value={props.integrator.password}
                    isValid={
                        props.integrator.password &&
                        !props.errors.password &&
                        props.signUp
                    }
                    name="password"
                    type={props.isSignUp ? 'password' : 'text'}
                    placeholder="Informe a senha"
                />

                {props.errors.password && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.password}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group controlId="formBasicConfirmPassword">
                <Form.Label>Confirme sua senha</Form.Label>
                <Form.Control
                    className={
                        pathname === signUpRoute.path
                            ? 'nexen-form-control'
                            : ''
                    }
                    disabled={props.isDisabled}
                    isInvalid={!!props.errors.password_confirmation}
                    onChange={props.handleChanges}
                    value={props.integrator.password_confirmation}
                    isValid={
                        !props.errors.password &&
                        props.integrator.password &&
                        props.integrator.password_confirmation &&
                        !props.errors.password_confirmation &&
                        props.signUp
                    }
                    name="password_confirmation"
                    type={props.isSignUp ? 'password' : 'text'}
                    placeholder="Confirmar a senha"
                />

                {props.errors.password_confirmation && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.password_confirmation}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        </>
    );
};

export default IntegratorUser;
