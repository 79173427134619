import Role from '../constants/roles';

export default function userHasRoles(
    user: any,
    requiredRoles: Role[],
): boolean {
    if (!user) {
        return false;
    }

    const userRoles = user.roles?.map((item: any) => item.reference);

    return requiredRoles.some((role: Role) => userRoles?.includes(role));
}
