import { useHistory } from 'react-router';
import { signInRoute } from '../../../routes/config';
import { StyledIcon, StyledButton } from './styles';

export default function EmailConfirmationSucessLayout() {
    const history = useHistory();

    const handleReturnToLogin = () => history.push(signInRoute.path);

    return (
        <>
            <p className="mt-4">
                <StyledIcon className="fas fa-check-circle" /> Seu e-mail foi
                verificado com sucesso. Agradecemos por tornar a sua conta mais
                segura 😊
            </p>

            <StyledButton onClick={handleReturnToLogin} className="w-100 mt-3">
                Acessar plataforma
            </StyledButton>
        </>
    );
}
