import Request from './request';

export default class SalesCampaignHttpService {
    private static uri = 'blackfriday';

    public static async index(params: {}) {
        return Request.get(this.uri, params);
    }

    public static async save(body: any) {
        return Request.post(this.uri, body);
    }

    public static async patch(body: any) {
        return Request.patch(this.uri, body);
    }

    public static async deleteItem(id: any) {
        return Request.del(`${this.uri}/blackfriday/${id}`);
    }

    public static async indexSeason(params: {}) {
        return Request.get(`${this.uri}/season`, params);
    }

    public static async saveSeason(body: any) {
        return Request.post(`${this.uri}/season`, body);
    }

    public static async patchSeason(body: any) {
        return Request.patch(`${this.uri}/season`, body);
    }
}
