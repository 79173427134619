import { Form, Spinner } from 'react-bootstrap';
import { Control } from 'react-hook-form';
import { StyledButton, StyledButtonOutlined, StyledIcon } from './styles';
import SendCodeLayoutInput from './SendCodeLayoutInput';

interface EmailConfirmationSendCodeLayoutProps {
    control: Control;
    email: string;
    handleRequestEmail: () => void;
    handleSubmit: () => void;
    errorMessage?: string;
    isLoading: boolean;
}

export default function EmailConfirmationSendCodeLayout({
    control,
    email,
    handleRequestEmail,
    handleSubmit,
    errorMessage,
    isLoading,
}: EmailConfirmationSendCodeLayoutProps) {
    return (
        <Form>
            <p className="mt-4">
                <StyledIcon className="fas fa-check-circle" />
                Um código de confirmação foi enviado para{' '}
                <strong>{email}</strong>.
            </p>

            <SendCodeLayoutInput
                control={control}
                errorMessage={errorMessage}
            />

            <StyledButton
                disabled={isLoading}
                onClick={handleSubmit}
                className="w-100 mt-3"
            >
                {isLoading ? (
                    <>
                        <Spinner animation="border" size="sm" />
                        <span>Confirmando...</span>
                    </>
                ) : (
                    'Confirmar'
                )}
            </StyledButton>
            <StyledButtonOutlined
                className="btn-nexen mt-3"
                block
                onClick={handleRequestEmail}
            >
                Reenviar código
            </StyledButtonOutlined>
        </Form>
    );
}
