export enum documentTypes {
    TermsAndConditions = 'TERMS_AND_CONDITIONS',
    CpfRg = 'CPF_RG',
    SocialContract = 'SOCIAL_CONTRACT',
    AddressReceipt = 'ADDRESS_RECEIPT',
}

export enum documentFolders {
    SellerDocuments = 'seller_documents/',
}

export enum documentSubFolders {
    CpfRg = 'cpf_rg/',
    SocialContrats = 'social_contracts/',
    AddressReceipts = 'address_receipts/',
}
