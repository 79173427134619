import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { StyledDeleteButton } from './styles';

interface ConfirmButtonProps {
    canConfirm: boolean;
    disabled: boolean;
    isLoading: boolean;
}

export default function ConfirmButton({
    canConfirm,
    disabled,
    isLoading,
}: ConfirmButtonProps) {
    return (
        <>
            {canConfirm ? (
                <StyledDeleteButton type="submit" disabled={disabled}>
                    {isLoading ? (
                        <>
                            <Spinner animation="border" size="sm" />
                            <span>Excluindo...</span>
                        </>
                    ) : (
                        'Excluir'
                    )}
                </StyledDeleteButton>
            ) : (
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="overlay-example">
                            É necessário confirmar a exclusão dos registros,
                            assinalando o marcador acima
                        </Tooltip>
                    }
                >
                    <StyledDeleteButton disabled>Excluir</StyledDeleteButton>
                </OverlayTrigger>
            )}
        </>
    );
}
