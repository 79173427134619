/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Dropdown,
    Form,
    InputGroup,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import { ReactComponent as ReactMoreIcon } from '../../../../assets/icons/more.svg';
import NotificationIcon from '../../../../constants/notificationIcon';
import { useConfiguration } from '../../../../contexts/configurationContext';
import kitsHttpService from '../../../../services/http/kit-http';
import Branch from '../../../../shared/interfaces/branch.interface';
import { StyledPageTitle } from '../../../../styles/pageTitle';
import ConfirmationDialog from '../../../../utils/ConfirmationDialog';
import { StyledTh } from '../styles';
import ModuleEditor from './ModuleEditor/ModuleEditor';

const ModuleTable = () => {
    const [term, setTerm] = useState('');
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [loading, setLoading] = useState(false);
    const [hasReturnedModule, setHasReturnedModule] = useState(false);
    const [loadingCheck, setLoadingCheck] = useState(false);

    const {
        showModuleEditorModal,
        setShowModuleEditorModal,
        modules,
        setModules,
        setSelectedModule,
    } = useConfiguration();

    const [selectedModuleId, setSelectedModuleId] = useState<number | null>(
        null,
    );
    const [showRemoveModuleModal, setShowRemoveModuleModal] =
        useState<boolean>(false);

    async function loadModules() {
        setLoading(true);

        try {
            const response = await kitsHttpService.getModules({
                term,
            });

            const totalItems = response.data.meta.total;

            setModules((prevModules: any) => [
                ...prevModules,
                ...response.data.modules,
            ]);

            if (totalItems > 0) {
                setHasReturnedModule(true);
            } else {
                setHasReturnedModule(false);
            }
            // eslint-disable-next-line no-empty
        } catch (error) {}

        setLoading(false);
    }

    async function setModuleActivated(module: any) {
        setLoadingCheck(true);

        try {
            // eslint-disable-next-line no-param-reassign
            module.active = !module.active;

            await kitsHttpService.storeModule(module);

            if (module.active) {
                toast.success('Módulo ativado.');
            } else {
                toast.success('Módulo desativado.');
            }
        } catch (error) {
            toast.error('Erro ao salvar Módulo.');
        }

        setLoadingCheck(false);
    }

    const deleteModule = async () => {
        setLoading(true);

        try {
            if (!selectedModuleId) {
                throw new Error('Módulo não encontrado para exclusão');
            }
            await kitsHttpService.deleteModule(selectedModuleId);

            const newModules = modules.filter(
                (item: any) => item.id !== module.id,
            );

            setModules(newModules);

            toast.success('módulo excluído.');
        } catch (error) {
            const err = error as any;

            toast.error(err.message);
        }

        setLoading(false);
    };

    useEffect(() => {
        setModules([]);
        setIsFirstRender(false);
    }, []);

    useEffect(() => {
        loadModules();
    }, []);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (!isFirstRender) {
            const timer = setTimeout(() => {
                setModules([]);
                loadModules();
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [term]);

    return (
        <>
            <ModuleEditor
                show={showModuleEditorModal}
                onHide={() => {
                    setSelectedModule({});
                    setShowModuleEditorModal(false);
                }}
            />

            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">Módulos</StyledPageTitle>
                </Col>
                <Col>
                    <Button
                        className="float-right w-25 m-2"
                        onClick={() => {
                            setSelectedModule({});
                            setShowModuleEditorModal(true);
                        }}
                    >
                        Novo Módulo
                    </Button>
                </Col>
            </Row>
            <Row>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <i className="fas fa-search" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        value={term}
                        onChange={(event) => setTerm(event.target.value)}
                        placeholder="Digite aqui o que procura..."
                    />
                </InputGroup>
            </Row>
            <Row>
                <Table bordered hover size="sm" className="text-center">
                    <thead>
                        <tr>
                            <StyledTh>ID</StyledTh>
                            <StyledTh>Nome</StyledTh>
                            <StyledTh>Código ERP</StyledTh>
                            <StyledTh>Potência</StyledTh>
                            <StyledTh>Filiais</StyledTh>
                            <StyledTh>Ativo</StyledTh>
                            <StyledTh />
                        </tr>
                    </thead>
                    <tbody>
                        {modules &&
                            modules.map((item: any, index: any) => (
                                <tr key={index}>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.id.toString()}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.name}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.originalId}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.basePower}
                                        />
                                    </td>
                                    <td>
                                        {item.branches
                                            .map(
                                                (branch: Branch) => branch.name,
                                            )
                                            .join(', ')}
                                    </td>
                                    <td>
                                        {!loadingCheck && (
                                            <Form.Check
                                                id={`${index.toString()}m`}
                                                checked={item.active}
                                                onChange={() => {
                                                    setModuleActivated(item);
                                                }}
                                            />
                                        )}
                                        {loadingCheck && (
                                            <Form.Check
                                                id={`${index.toString()}m`}
                                                checked={item.active}
                                                disabled
                                            />
                                        )}
                                    </td>
                                    <td>
                                        <Dropdown key="left">
                                            <Dropdown.Toggle
                                                bsPrefix="nexen"
                                                className="mt-n3 mb-n3"
                                                as={Button}
                                                variant="text"
                                            >
                                                <ReactMoreIcon
                                                    fill="#bdbdbd"
                                                    width="10"
                                                    height="20"
                                                />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setSelectedModule(item);
                                                        setShowModuleEditorModal(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    Editar
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setSelectedModuleId(
                                                            item.id,
                                                        );
                                                        setShowRemoveModuleModal(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    Remover
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Row>

            <Row className="mx-4">
                <br />
                {loading && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}

                {!hasReturnedModule && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Row>

            {showRemoveModuleModal && (
                <ConfirmationDialog
                    show={showRemoveModuleModal}
                    onHide={() => {
                        setShowRemoveModuleModal(false);
                        setSelectedModuleId(null);
                    }}
                    onConfirm={deleteModule}
                    icon={NotificationIcon.Warning}
                    title="Excluir o módulo"
                    text="Deseja excluir o módulo selecionado?"
                />
            )}
        </>
    );
};

export default ModuleTable;
