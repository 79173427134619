import { DropdownButton } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledNotificationTitle = styled.span`
    font-size: 12px !important;
    font-weight: bolder;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
`;

export const StyledNotificationMessage = styled.p`
    font-size: 12px !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
`;

export const StyledDropdownButton = styled(DropdownButton)`
    .btn-primary {
        background: none;
        border: none;

        ::after {
            content: none;
        }

        :active {
            background: none !important;
            border: none !important;
        }

        :focus {
            background: none !important;
            border: none;

            box-shadow: none;
        }
    }

    :active {
        background: none;
        border: none;
    }
`;

export const StyledLink = styled.span`
    font-size: 0.75rem;
    cursor: pointer;
    margin-left: 3px;
    color: #f6b44f;

    :hover {
        color: #e0814e;
        text-decoration: underline;
    }
`;
