import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';
import { List, New, View } from '../../pages/Integrators';

export const integratorListRoute = new Route('/integrators');

export const integratorShowRoute = new Route('/integrators/:id/show');

export const integratorEditRoute = new Route('/integrators/:id/edit');

export const integratorViewRoute = new Route('/integrators/:id/view');

export const IntegratorsRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={integratorEditRoute.path}
            exact
            component={New}
            isPrivate
        />

        <RouteWrapper
            path={integratorListRoute.path}
            exact
            component={List}
            isPrivate
        />

        <RouteWrapper
            path={integratorViewRoute.path}
            exact
            component={View}
            isPrivate
        />
    </Switch>
);
