/* eslint-disable import/prefer-default-export */
import { Nav } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledNav = styled(Nav)`
    border: none;
    .nav-link {
        font-size: 14px;
        padding: 0 20px 0 0;
        border: none;
    }
    .nav-link:hover {
        border: none;
        text-decoration: underline;
    }
`;
