import RoleType from '../../constants/roleType';
import Request from './request';

export default class RoleHttpService {
    private static uri = 'roles';

    public static readMany(params: { roleType: RoleType }) {
        return Request.get(this.uri, params);
    }
}
