import {
    BannerLocation,
    BannerLocationNames,
} from '../constants/configuration';

function getLocationName(location: string) {
    switch (location) {
        case BannerLocation.dashboard:
            return BannerLocationNames.dashboard;

        case BannerLocation.pointsInformations:
            return BannerLocationNames.pointsInformations;

        default:
            return '';
    }
}

export default getLocationName;
