import { useQuery } from 'react-query';
import { SelectOption } from '../components/Select';
import CountryState from '../shared/interfaces/countryState.interface';
import StateService from '../services/state';

interface useCountryStatesResponse {
    countryStates?: CountryState[];
    countryStatesOptions?: Array<SelectOption<string>>;
    isLoading: boolean;
}

export default function useCountryStates(): useCountryStatesResponse {
    const getCountryStates = async (): Promise<CountryState[]> => {
        return StateService.get();
    };

    const { data: countryStates, isLoading } = useQuery({
        queryKey: ['country-states'],
        queryFn: getCountryStates,
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
    });

    const countryStatesOptions: Array<SelectOption<string>> | undefined =
        countryStates?.map(({ code, name }: CountryState) => {
            return {
                value: code,
                label: name,
            };
        });

    return { countryStates, countryStatesOptions, isLoading };
}
