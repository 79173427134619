import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;

export const StyledCard = styled(Card)`
    height: 75vh;
`;
