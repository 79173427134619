import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
    gap: 0.5rem;
    border-radius: 10px;
    border-color: #7800e1;
    background: linear-gradient(
        90deg,
        #7800e1 0%,
        #f6700f 82.78%,
        #ffb855 100%
    );
    :disabled {
        background-color: #7800e1 !important;
        border-color: #7800e1 !important;
    }

    &:hover {
        border-color: #7800e1;
    }
`;

export const StyledIcon = styled.i`
    margin-right: 5px;
    color: #5cb85c;
    vertical-align: middle;
`;

export const StyledButtonOutlined = styled(Button)`
    background-color: #ffffff !important;
    border-radius: 10px;
    border-color: #7800e1;
    color: #7800e1 !important;
`;
