import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Nav, Table } from 'react-bootstrap';

export const StyledH4 = styled.h4`
    font-size: 20px !important;
    cursor: pointer;
`;

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;

export const StyledLink = styled(Link)`
    color: inherit !important;
    font-weight: bolder;
`;

export const StyledNav = styled(Nav)`
    margin: 10px 8px;
    border: none;

    .nav-link {
        font-size: 14px;
        padding: 0 20px 0 0;
        border: none;
    }
    .nav-link:hover {
        border: none;
        text-decoration: underline;
    }
`;

export const notificationMethodsStyles = {
    multiValue: (base: any, state: any) =>
        state.data.isFixed
            ? {
                  ...base,
                  backgroundColor: 'gray',
                  border: state.isFocused
                      ? '1px solid rgba(47, 204, 139, 0.233)'
                      : null,
                  borderRadius: 3,
                  boxShadow: state.isFocused
                      ? '0px 0px 6px rgba(0, 0, 0, 0.30)'
                      : null,
                  fontSize: 10,
              }
            : base,
    multiValueLabel: (base: any, state: any) =>
        state.data.isFixed
            ? {
                  ...base,
                  color: 'white',
                  border: state.isFocused
                      ? '1px solid rgba(47, 204, 139, 0.233)'
                      : null,
                  borderRadius: 3,
                  boxShadow: state.isFocused
                      ? '0px 0px 6px rgba(0, 0, 0, 0.30)'
                      : null,
                  fontSize: 10,
              }
            : base,
    multiValueRemove: (base: any, state: any) =>
        state.data.isFixed
            ? {
                  ...base,
                  display: 'none',
                  border: state.isFocused
                      ? '1px solid rgba(47, 204, 139, 0.233)'
                      : null,
                  borderRadius: 3,
                  boxShadow: state.isFocused
                      ? '0px 0px 6px rgba(0, 0, 0, 0.30)'
                      : null,
                  fontSize: 10,
              }
            : base,
};

export const StyledTable = styled(Table)`
    th:nth-child(1),
    td:nth-child(1) {
        width: 34%;
    }

    th:nth-child(2),
    td:nth-child(2) {
        width: 34%;
    }

    th:nth-child(3),
    td:nth-child(3) {
        width: 20%;
    }

    th:nth-child(4),
    td:nth-child(4) {
        width: 10%;
    }

    th:nth-child(5),
    td:nth-child(5) {
        width: 2%;
    }
`;

export const StyledSpan = styled.span`
    font-weight: bolder;
    color: #adadad;
`;
