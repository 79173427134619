import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import { useEffect } from 'react';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../styles/react-select-config';
import {
    attachmentFilterOptions,
    contractFilterOptions,
    statusFilterOptions,
} from '../../../constants/sellerFilters';
import useCommercialConsultants from '../../../hooks/useCommercialConsultants';
import Role from '../../../constants/roles';
import { AuthValues, useAuth } from '../../../contexts/authContext';
import userHasRoles from '../../../utils/userHasRoles';
import useCountryStates from '../../../hooks/useCountryStates';
import useCities from '../../../hooks/useCities';
import useSellers from '../../../hooks/useSellers';

export default function SellerFilter() {
    const { commercialConsultants } = useCommercialConsultants();
    const { countryStatesOptions } = useCountryStates();

    const { filterForm, showFilterSection } = useSellers();

    const filterData = filterForm.watch();

    const selectedStates = filterData.filterStates?.map((state) => state.value);
    const { citiesOptions } = useCities(selectedStates || []);

    const { user }: AuthValues = useAuth();

    const hasCommercialConsultantsFilterAccess = userHasRoles(user, [
        Role.Administrator,
        Role.CommercialSupervisor,
        Role.Registration,
    ]);

    useEffect(() => {
        if (citiesOptions === undefined) return;

        const newCities = filterData.filterCities?.filter((filterCity) =>
            citiesOptions?.find(
                (cityOption) => cityOption.value === filterCity.value,
            ),
        );

        if (filterData.filterCities?.length === newCities?.length) return;

        filterForm.setValue('filterCities', newCities);
    }, [citiesOptions, filterData.filterCities, filterForm]);

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <Row>
                <Col>
                    {showFilterSection && (
                        <Card className="mt-4 mx-2">
                            <Card.Body>
                                <Card.Title>
                                    <strong>Filtros</strong>
                                </Card.Title>
                                <Row>
                                    <Col md={2}>
                                        <Form.Group controlId="formBasicStatus">
                                            <Form.Label>Status</Form.Label>

                                            <Controller
                                                isClearable
                                                className="form-control-nexen"
                                                as={Select}
                                                control={filterForm.control}
                                                name="filterStatus"
                                                options={statusFilterOptions}
                                                isMulti
                                                styles={{
                                                    control: tradeControl,
                                                    option: optionsTheme,
                                                }}
                                                theme={theme}
                                                placeholder="Selecione..."
                                                defaultValue=""
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group controlId="formBasicContract">
                                            <Form.Label>Contrato</Form.Label>

                                            <Controller
                                                isClearable
                                                className="form-control-nexen"
                                                as={Select}
                                                control={filterForm.control}
                                                name="filterContract"
                                                options={contractFilterOptions}
                                                isMulti
                                                styles={{
                                                    control: tradeControl,
                                                    option: optionsTheme,
                                                }}
                                                theme={theme}
                                                placeholder="Selecione..."
                                                defaultValue=""
                                            />
                                        </Form.Group>
                                    </Col>
                                    {hasCommercialConsultantsFilterAccess && (
                                        <Col md={2}>
                                            <Form.Group controlId="formBasicCommercialConsultants">
                                                <Form.Label>
                                                    Consultores comerciais
                                                </Form.Label>
                                                <Controller
                                                    isClearable
                                                    className="form-control-nexen"
                                                    as={Select}
                                                    control={filterForm.control}
                                                    name="filterComercialConsultants"
                                                    options={
                                                        commercialConsultants ||
                                                        []
                                                    }
                                                    isMulti
                                                    styles={{
                                                        control: tradeControl,
                                                        option: optionsTheme,
                                                    }}
                                                    theme={theme}
                                                    placeholder="Selecione..."
                                                    defaultValue=""
                                                />
                                            </Form.Group>
                                        </Col>
                                    )}
                                    <Col md={2}>
                                        <Form.Group controlId="formBasicAttachments">
                                            <Form.Label>Anexos</Form.Label>

                                            <Controller
                                                isClearable
                                                className="form-control-nexen"
                                                as={Select}
                                                control={filterForm.control}
                                                name="filterAttachment"
                                                options={
                                                    attachmentFilterOptions
                                                }
                                                styles={{
                                                    control: tradeControl,
                                                    option: optionsTheme,
                                                }}
                                                theme={theme}
                                                placeholder="Selecione..."
                                                defaultValue=""
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="formDate">
                                            <Form.Label>
                                                Data de cadastro
                                            </Form.Label>
                                            <Row>
                                                <Col md={5}>
                                                    <Controller
                                                        render={({
                                                            onChange,
                                                            value,
                                                        }) => (
                                                            <Form.Control
                                                                type="date"
                                                                onChange={
                                                                    onChange
                                                                }
                                                                value={value}
                                                            />
                                                        )}
                                                        className="form-control-nexen"
                                                        control={
                                                            filterForm.control
                                                        }
                                                        name="filterStartDate"
                                                        defaultValue=""
                                                    />
                                                </Col>
                                                <span className="d-flex align-self-center">
                                                    à
                                                </span>
                                                <Col md={5}>
                                                    <Controller
                                                        render={({
                                                            onChange,
                                                            value,
                                                        }) => (
                                                            <Form.Control
                                                                type="date"
                                                                onChange={
                                                                    onChange
                                                                }
                                                                value={value}
                                                            />
                                                        )}
                                                        className="form-control-nexen"
                                                        control={
                                                            filterForm.control
                                                        }
                                                        name="filterEndDate"
                                                        defaultValue=""
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <Form.Group controlId="formBasicStates">
                                            <Form.Label>Estados</Form.Label>
                                            <Controller
                                                isClearable
                                                className="form-control-nexen"
                                                as={Select}
                                                control={filterForm.control}
                                                name="filterStates"
                                                options={
                                                    countryStatesOptions || []
                                                }
                                                isMulti
                                                styles={{
                                                    control: tradeControl,
                                                    option: optionsTheme,
                                                }}
                                                theme={theme}
                                                placeholder="Selecione..."
                                                defaultValue=""
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group controlId="formBasicCities">
                                            <Form.Label>Cidades</Form.Label>
                                            <Controller
                                                isClearable
                                                isDisabled={
                                                    !filterData.filterStates
                                                        ?.length
                                                }
                                                className="form-control-nexen"
                                                as={Select}
                                                control={filterForm.control}
                                                name="filterCities"
                                                options={citiesOptions || []}
                                                isMulti
                                                styles={{
                                                    control: tradeControl,
                                                    option: optionsTheme,
                                                }}
                                                theme={theme}
                                                placeholder="Selecione..."
                                                defaultValue=""
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    )}
                    <Row className="pl-2 pr-2 mt-4">
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <i className="fas fa-search" />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Controller
                                    as={
                                        <Form.Control placeholder="Digite aqui o que procura..." />
                                    }
                                    type="text"
                                    name="filterTerm"
                                    control={filterForm.control}
                                    defaultValue=""
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}
