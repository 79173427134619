import React, { useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import LibraryHttpService from '../../../services/http/library-http';
import { StyledRow, StyledTitle } from '../styles';

function BrandsModalInputs({ register, errors, id, setValue }: any) {
    async function loadData() {
        const { data } = await LibraryHttpService.getBrand(id);

        const keys = Object.keys(data);

        // eslint-disable-next-line no-restricted-syntax
        for (const key of keys) {
            setValue(key, data[key]);
        }
    }

    useEffect(() => {
        if (id) loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <StyledRow className="ml-0 mt-2 mr-1">
                <Col>
                    <StyledTitle>Nome</StyledTitle>
                </Col>
            </StyledRow>
            <Row className="ml-0 mr-1">
                <Col>
                    <Form.Group>
                        <Form.Control
                            ref={register}
                            name="name"
                            type="string"
                            className="form-control-nexen"
                            placeholder="Digite aqui..."
                            isInvalid={!!errors.name}
                        />
                        {errors.name && (
                            <Form.Control.Feedback type="invalid">
                                {errors.name}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
}

export default BrandsModalInputs;
