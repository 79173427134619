import { Row, Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledImportButton = styled(Button)`
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

export const StyledCancelButton = styled(Button)`
    width: 200px;
    background-color: #ffffff;
    border: 1px solid solid #6fcf97;
    color: #2eb378 !important;

    :hover {
        background-color: #6fcf97;
        color: #ffffff !important;
    }
`;

export const StyledLink = styled.a<{ newFile?: boolean }>`
    font-size: 0.75rem;
    cursor: pointer;
    margin-left: 3px;
`;

export const StyledRow = styled(Row)`
    font-size: 0.75rem;
    margin: 3px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: #707070;
`;
