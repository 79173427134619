/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import { Col, Form, Row, InputGroup, Image } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import { signUpRoute } from '../../../routes/config';
import { cpfMask, phoneMask } from '../../../utils/masks';
import personIcon from '../../../assets/icons/person.svg';
import emailIcon from '../../../assets/icons/email.svg';
import whatsAppIcon from '../../../assets/icons/whatsapp.svg';
import calendarIcon from '../../../assets/icons/calendar.svg';
import idIcon from '../../../assets/icons/id.svg';

const IntegratorPartner = (props: any) => {
    const [calendarDate, setCalendarDate] = useState(false);

    const pathname = window.location.pathname;

    if (pathname === signUpRoute.path) {
        return (
            <>
                <Form.Group controlId="formBasicPartnerName">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    border: '1px solid #707070',
                                    width: 45,
                                }}
                            >
                                <Image src={personIcon} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            value={props.integrator.partnerName}
                            isInvalid={!!props.errors.partnerName}
                            onChange={props.handleChanges}
                            isValid={
                                props.integrator.partnerName &&
                                !props.errors.partnerName &&
                                props.signUp
                            }
                            name="partnerName"
                            type="text"
                            placeholder="Nome do Administrador da Empresa"
                        />
                        {props.errors.partnerName && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.partnerName}
                            </Form.Control.Feedback>
                        )}
                    </InputGroup>
                </Form.Group>

                <Form.Group controlId="formBasicPartnerEmail">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    border: '1px solid #707070',
                                    width: 45,
                                }}
                            >
                                <Image src={emailIcon} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            value={props.integrator.partnerEmail}
                            isInvalid={!!props.errors.partnerEmail}
                            onChange={props.handleChanges}
                            isValid={
                                props.integrator.partnerEmail &&
                                !props.errors.partnerEmail &&
                                props.signUp
                            }
                            name="partnerEmail"
                            type="text"
                            placeholder="E-mail do Administrador da Empresa"
                        />
                        {props.errors.partnerEmail && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.partnerEmail}
                            </Form.Control.Feedback>
                        )}
                    </InputGroup>
                </Form.Group>

                <Form.Group controlId="formBasicPartnerPhone">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    border: '1px solid #707070',
                                    width: 45,
                                }}
                            >
                                <Image src={whatsAppIcon} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            value={props.integrator.partnerPhone}
                            isInvalid={!!props.errors.partnerPhone}
                            onChange={props.handleChanges}
                            isValid={
                                props.integrator.partnerPhone &&
                                !props.errors.partnerPhone &&
                                props.signUp
                            }
                            name="partnerPhone"
                            as={MaskedInput}
                            mask={phoneMask}
                            type="tel"
                            placeholder="Whatsapp do Administrador da Empresa"
                        />
                        {props.errors.partnerPhone && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.partnerPhone}
                            </Form.Control.Feedback>
                        )}
                    </InputGroup>
                </Form.Group>

                <Row>
                    <Col onClick={() => setCalendarDate(true)} sm="5">
                        {!calendarDate ? (
                            <Form.Group controlId="formBasicBirthDate">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text
                                            style={{
                                                border: '1px solid #707070',
                                                width: 45,
                                                height: 48,
                                            }}
                                        >
                                            <Image src={calendarIcon} />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>

                                    <Form.Control
                                        max={
                                            new Date()
                                                .toISOString()
                                                .split('T')[0]
                                        }
                                        className={
                                            pathname === signUpRoute.path
                                                ? 'nexen-form-control'
                                                : ''
                                        }
                                        value={
                                            props.integrator.partnerBirthDate
                                        }
                                        isInvalid={
                                            !!props.errors.partnerBirthDate
                                        }
                                        isValid={
                                            props.integrator.partnerBirthDate &&
                                            !props.errors.partnerBirthDate &&
                                            props.signUp
                                        }
                                        name="partnerBirthDate"
                                        type="text"
                                        placeholder="Data de Nascimento"
                                    />
                                    {props.errors.partnerBirthDate && (
                                        <Form.Control.Feedback type="invalid">
                                            {props.errors.partnerBirthDate}
                                        </Form.Control.Feedback>
                                    )}
                                </InputGroup>
                            </Form.Group>
                        ) : (
                            <Form.Group controlId="formBasicBirthDate">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text
                                            style={{
                                                border: '1px solid #707070',
                                                width: 45,
                                                height: 48,
                                            }}
                                        >
                                            <Image src={calendarIcon} />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>

                                    <Form.Control
                                        max={
                                            new Date()
                                                .toISOString()
                                                .split('T')[0]
                                        }
                                        className={
                                            pathname === signUpRoute.path
                                                ? 'nexen-form-control'
                                                : ''
                                        }
                                        value={
                                            props.integrator.partnerBirthDate
                                        }
                                        isInvalid={
                                            !!props.errors.partnerBirthDate
                                        }
                                        onChange={props.handleChanges}
                                        isValid={
                                            props.integrator.partnerBirthDate &&
                                            !props.errors.partnerBirthDate &&
                                            props.signUp
                                        }
                                        name="partnerBirthDate"
                                        type="date"
                                        placeholder="Data de Nascimento"
                                    />
                                    {props.errors.partnerBirthDate && (
                                        <Form.Control.Feedback type="invalid">
                                            {props.errors.partnerBirthDate}
                                        </Form.Control.Feedback>
                                    )}
                                </InputGroup>
                            </Form.Group>
                        )}
                    </Col>
                    <Col sm="7">
                        <Form.Group controlId="formBasicCPF">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            border: '1px solid #707070',
                                            width: 45,
                                        }}
                                    >
                                        <Image src={idIcon} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    className={
                                        pathname === signUpRoute.path
                                            ? 'nexen-form-control'
                                            : ''
                                    }
                                    value={props.integrator.partnerDocument}
                                    isInvalid={!!props.errors.partnerDocument}
                                    as={MaskedInput}
                                    mask={cpfMask}
                                    onChange={props.handleChanges}
                                    isValid={
                                        props.integrator.partnerDocument &&
                                        !props.errors.partnerDocument &&
                                        props.signUp
                                    }
                                    name="partnerDocument"
                                    type="text"
                                    placeholder="CPF"
                                />
                                {props.errors.partnerDocument && (
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.partnerDocument}
                                    </Form.Control.Feedback>
                                )}
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <>
            <Form.Group controlId="formBasicPartnerName">
                <Form.Label>Nome do Administrador da Empresa</Form.Label>
                <Form.Control
                    className={
                        pathname === signUpRoute.path
                            ? 'nexen-form-control'
                            : ''
                    }
                    value={props.integrator.partnerName}
                    isInvalid={!!props.errors.partnerName}
                    onChange={props.handleChanges}
                    isValid={
                        props.integrator.partnerName &&
                        !props.errors.partnerName &&
                        props.signUp
                    }
                    name="partnerName"
                    type="text"
                    placeholder="Informe o nome do sócio"
                />
                {props.errors.partnerName && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.partnerName}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Row>
                <Col sm="7">
                    <Form.Group controlId="formBasicCPF">
                        <Form.Label>CPF</Form.Label>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            value={props.integrator.partnerDocument}
                            isInvalid={!!props.errors.partnerDocument}
                            as={MaskedInput}
                            mask={cpfMask}
                            onChange={props.handleChanges}
                            isValid={
                                props.integrator.partnerDocument &&
                                !props.errors.partnerDocument &&
                                props.signUp
                            }
                            name="partnerDocument"
                            type="text"
                            placeholder="Informe o CPF"
                        />
                        {props.errors.partnerDocument && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.partnerDocument}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                <Col sm="5">
                    <Form.Group controlId="formBasicBirthDate">
                        <Form.Label>Data de nascimento</Form.Label>
                        <Form.Control
                            max={new Date().toISOString().split('T')[0]}
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            value={props.integrator.partnerBirthDate}
                            isInvalid={!!props.errors.partnerBirthDate}
                            onChange={props.handleChanges}
                            isValid={
                                props.integrator.partnerBirthDate &&
                                !props.errors.partnerBirthDate &&
                                props.signUp
                            }
                            name="partnerBirthDate"
                            type="date"
                            placeholder="Informe a data de nascimento"
                        />
                        {props.errors.partnerBirthDate && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.partnerBirthDate}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>

            <Form.Group controlId="formBasicPartnerEmail">
                <Form.Label>E-mail do Administrador da Empresa</Form.Label>
                <Form.Control
                    className={
                        pathname === signUpRoute.path
                            ? 'nexen-form-control'
                            : ''
                    }
                    value={props.integrator.partnerEmail}
                    isInvalid={!!props.errors.partnerEmail}
                    onChange={props.handleChanges}
                    isValid={
                        props.integrator.partnerEmail &&
                        !props.errors.partnerEmail &&
                        props.signUp
                    }
                    name="partnerEmail"
                    type="text"
                    placeholder="Informe o e-mail do sócio"
                />
                {props.errors.partnerEmail && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.partnerEmail}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group controlId="formBasicPartnerPhone">
                <Form.Label>WhatsApp do Administrador da Empresa</Form.Label>
                <Form.Control
                    className={
                        pathname === signUpRoute.path
                            ? 'nexen-form-control'
                            : ''
                    }
                    value={props.integrator.partnerPhone}
                    isInvalid={!!props.errors.partnerPhone}
                    onChange={props.handleChanges}
                    isValid={
                        props.integrator.partnerPhone &&
                        !props.errors.partnerPhone &&
                        props.signUp
                    }
                    name="partnerPhone"
                    as={MaskedInput}
                    mask={phoneMask}
                    type="tel"
                    placeholder="Digite aqui..."
                />
                {props.errors.partnerPhone && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.partnerPhone}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        </>
    );
};

export default IntegratorPartner;
