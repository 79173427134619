import styled from 'styled-components';
import { Table } from 'react-bootstrap';

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;

export const StyledTable = styled(Table)`
    th:nth-child(1),
    td:nth-child(1) {
        width: 10%;
    }

    th:nth-child(2),
    td:nth-child(2) {
        width: 35%;
    }

    th:nth-child(3),
    td:nth-child(3) {
        width: 10%;
    }

    th:nth-child(4),
    td:nth-child(4) {
        width: 35%;
    }

    th:nth-child(5),
    td:nth-child(5) {
        width: 8%;
    }

    th:nth-child(6),
    td:nth-child(6) {
        width: 2%;
    }
`;
