import Request from './request';

export default class ReturnUsersHttpService {
    private static uri = 'returnUsers';

    public static index(params: {}) {
        return Request.get(this.uri, params);
    }

    public static store(data: {}) {
        return Request.post(this.uri, data);
    }

    public static delete(id: number) {
        return Request.del(`${this.uri}/${id}`);
    }
}
