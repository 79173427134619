import { Card } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
    padding: 30px;
    width: 500px;
    height: 100%;
`;

export const StyledH4 = styled.h4`
    font-family: Branding;
    font-weight: bold;
    font-size: 48px;
    color: #383838;
    margin-top: 2.5rem;
`;
