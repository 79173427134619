/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Dropdown,
    Form,
    InputGroup,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { LiaInfoCircleSolid } from 'react-icons/lia';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { toast } from 'react-toastify';
import { ReactComponent as ReactMoreIcon } from '../../../../assets/icons/more.svg';
import NotificationIcon from '../../../../constants/notificationIcon';
import { useConfiguration } from '../../../../contexts/configurationContext';
import kitsHttpService from '../../../../services/http/kit-http';
import Branch from '../../../../shared/interfaces/branch.interface';
import { StyledPageTitle } from '../../../../styles/pageTitle';
import ConfirmationDialog from '../../../../utils/ConfirmationDialog';
import RoofTypeEditor from './RoofTypeEditor/RoofTypeEditor';
import { StyledTh } from './RoofTypeEditor/styles';
import { StyledSpan } from './styles';

const RoofTypeTable = () => {
    const rowsPerPage = 20;
    const [term, setTerm] = useState('');
    const [page, setPage] = useState(1);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [loading, setLoading] = useState(false);

    const {
        roofTypes,
        setRoofTypes,
        showRoofTypeEditorModal,
        setShowRoofTypeEditorModal,
        setSelectedRoofType,
    } = useConfiguration();

    const [selectedRoofTypeId, setSelectedRoofTypeId] = useState<number | null>(
        null,
    );
    const [showRemoveRoofTypeModal, setShowRemoveRoofTypeModal] =
        useState<boolean>(false);

    const infiniteRef: any = useInfiniteScroll({
        loading,
        hasNextPage,
        // eslint-disable-next-line no-return-assign, no-param-reassign
        onLoadMore: () => setPage((p: any) => (p += 1)),
    });

    async function loadRoofTypes() {
        setLoading(true);

        try {
            const response = await kitsHttpService.getRoofTypes({
                term,
                skip: page - 1,
                take: rowsPerPage,
            });

            const totalItems = response.data.meta.total;

            setRoofTypes((prevRoofTypes: any) => [
                ...prevRoofTypes,
                ...response.data.roofTypes,
            ]);

            setHasNextPage(!(+roofTypes.length >= +totalItems));
            // eslint-disable-next-line no-empty
        } catch (error) {}

        setLoading(false);
    }

    async function setRoofTypeActivated(roofType: any) {
        setLoading(true);

        try {
            // eslint-disable-next-line no-param-reassign
            roofType.active = !roofType.active;

            await kitsHttpService.storeRoofType(roofType);

            if (roofType.active) {
                toast.success('Tipo de Telhado ativado.');
            } else {
                toast.success('Tipo de Telhado desativado.');
            }
        } catch (error) {
            toast.error('Erro ao salvar tipo de telhado.');
        }

        setLoading(false);
    }

    const deleteRoofType = async () => {
        setLoading(true);

        try {
            if (!selectedRoofTypeId) {
                throw new Error('Tipo de telhado não encontrado para exclusão');
            }
            await kitsHttpService.deleteRoofType(selectedRoofTypeId);

            const newRoofTypes = roofTypes.filter(
                (item: any) => item.id !== selectedRoofTypeId,
            );

            setRoofTypes(newRoofTypes);

            toast.success('Tipo de Telhado excluído.');
        } catch (error) {
            const err = error as any;

            toast.error(err.message);
        }

        setLoading(false);
    };

    useEffect(() => {
        setRoofTypes([]);
        setIsFirstRender(false);
    }, []);

    useEffect(() => {
        loadRoofTypes();
    }, [setSelectedRoofType]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (!isFirstRender) {
            const timer = setTimeout(() => {
                setRoofTypes([]);
                setPage(1);
                loadRoofTypes();
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [term]);

    useEffect(() => {
        if (!isFirstRender) {
            loadRoofTypes();
        }
    }, [page]);

    return (
        <>
            <RoofTypeEditor
                show={showRoofTypeEditorModal}
                onHide={() => {
                    setSelectedRoofType({});
                    setShowRoofTypeEditorModal(false);
                }}
            />

            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">
                        Tipos de Telhado
                    </StyledPageTitle>
                </Col>
                <Col>
                    <Button
                        className="float-right w-25 m-2"
                        onClick={() => {
                            setSelectedRoofType({});
                            setShowRoofTypeEditorModal(true);
                        }}
                    >
                        Novo Tipo
                    </Button>
                </Col>
            </Row>
            <Row>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <i className="fas fa-search" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        value={term}
                        onChange={(event) => setTerm(event.target.value)}
                        placeholder="Digite aqui o que procura..."
                    />
                </InputGroup>
            </Row>
            <Row>
                <Table
                    ref={infiniteRef}
                    bordered
                    hover
                    size="sm"
                    className="text-center"
                >
                    <thead>
                        <tr>
                            <StyledTh>ID</StyledTh>
                            <StyledTh>Nome</StyledTh>
                            <StyledTh>Cabo 1</StyledTh>
                            <StyledTh>Cabo 2</StyledTh>
                            <StyledTh>Qtd. Cabos</StyledTh>
                            <StyledTh>Módulos</StyledTh>
                            <StyledTh>Filiais</StyledTh>
                            <StyledTh>Ativo</StyledTh>
                            <StyledTh />
                        </tr>
                    </thead>
                    <tbody>
                        {roofTypes &&
                            roofTypes.map((item: any, index: any) => (
                                <tr key={index}>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.originalId}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.name}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.cable1}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.cable2}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item.cableQuantity?.toString()}
                                        />
                                    </td>
                                    <StyledSpan>
                                        <LiaInfoCircleSolid className="svg" />
                                        <span className="tooltip">
                                            {item.modules.length > 0
                                                ? item.modules.map((i: any) => (
                                                      <span key={i.id}>
                                                          {i.name}
                                                      </span>
                                                  ))
                                                : 'Nenhum módulo associado ao telhado'}
                                        </span>
                                    </StyledSpan>
                                    <td>
                                        {item.branches
                                            .map(
                                                (branch: Branch) => branch.name,
                                            )
                                            .join(', ')}
                                    </td>
                                    <td>
                                        <Form.Check
                                            id={`${index.toString()}rt`}
                                            checked={item.active}
                                            onChange={() => {
                                                setRoofTypeActivated(item);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Dropdown key="left">
                                            <Dropdown.Toggle
                                                bsPrefix="nexen"
                                                className="mt-n3 mb-n3"
                                                as={Button}
                                                variant="text"
                                            >
                                                <ReactMoreIcon
                                                    fill="#bdbdbd"
                                                    width="10"
                                                    height="20"
                                                />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setSelectedRoofType(
                                                            item,
                                                        );
                                                        setShowRoofTypeEditorModal(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    Editar
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setSelectedRoofTypeId(
                                                            item.id,
                                                        );
                                                        setShowRemoveRoofTypeModal(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    Remover
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Row>

            <Row className="mx-4">
                <br />
                {loading && hasNextPage && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}

                {!hasNextPage && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Row>

            {showRemoveRoofTypeModal && (
                <ConfirmationDialog
                    show={showRemoveRoofTypeModal}
                    onHide={() => {
                        setShowRemoveRoofTypeModal(false);
                        setSelectedRoofTypeId(null);
                    }}
                    onConfirm={deleteRoofType}
                    icon={NotificationIcon.Warning}
                    title="Excluir o tipo de telhado"
                    text="Deseja excluir o tipo de telhado selecionado?"
                />
            )}
        </>
    );
};

export default RoofTypeTable;
