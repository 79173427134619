import React, { useState, useEffect, ChangeEvent } from 'react';
import {
    Table,
    Dropdown,
    Row,
    Col,
    Form,
    Button,
    InputGroup,
    Spinner,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { ReactComponent as ReactMoreIcon } from '../../../assets/icons/more.svg';
import { StyledTh } from './styles';
import { StyledPageTitle } from '../../../styles/pageTitle';
import SalesCampaignHttpService from '../../../services/http/sales-campaign-http';
import ConfigurationHttpService from '../../../services/http/configuration-http';
import SalesCampaignModal, { Campaign } from './SalesCampaignModal';
import SalesCampaignProductsModal, {
    Product,
} from './SalesCampaignProductsModal';
import ConfigurationReference from '../../../constants/configurationReference';

export interface Params {
    term: string;
    skip: number;
    take: number;
}

const SalesCampaign = () => {
    const initialParams: Params = {
        term: '',
        skip: 0,
        take: 20,
    };

    const [params, setParams] = useState(initialParams);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [loading, setLoading] = useState(false);
    const [salesCampaigns, setSalesCampaigns] = useState<Campaign[]>([]);
    const [selectedItem, setSelectedItem] = useState<Campaign | null>(null);
    const [enabledCampaign, setEnabledCampaign] = useState(-1);
    const [showProductsModal, setShowProductsModal] = useState(false);
    const [showCampaignModal, setShowCampaignModal] = useState(false);
    const [disabledChecklist, setDisabledChecklist] = useState(false);

    const resetPage = () => {
        setSalesCampaigns([]);
        setParams(initialParams);
    };

    const infiniteRef: any = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: () => {
            const newParams = {
                term: params.term,
                skip: params.skip + 1,
                take: params.take,
            };

            setParams(newParams);
        },
    });

    useEffect(() => {
        async function getCampaigns() {
            setLoading(true);
            setDisabledChecklist(true);

            try {
                const response = await SalesCampaignHttpService.indexSeason(
                    params,
                );

                const newSalesCampaigns: Campaign[] = [];

                response.data.data.forEach((salesCampaign: any) => {
                    const products: Product[] = [];

                    salesCampaign.blackFridayItems.forEach((product: any) => {
                        if (!product.deletedAt) {
                            products.push({
                                id: product.id,
                                campaignId: salesCampaign.id,
                                erpCode: product.erpCode,
                                name: product.name,
                                quantity: product.quantity,
                            });
                        }
                    });

                    newSalesCampaigns.push({
                        id: salesCampaign.id,
                        name: salesCampaign.name,
                        priceTable: salesCampaign.priceTable,
                        maxDiscount: salesCampaign.maxDiscount || 0,
                        maxInvoices: salesCampaign.maxInvoices || 0,
                        products,
                    });
                });

                setSalesCampaigns((previousSalesCampaigns: any) => [
                    ...previousSalesCampaigns,
                    ...newSalesCampaigns,
                ]);

                setHasNextPage(response.data.meta.hasData);

                setLoading(false);
                setDisabledChecklist(false);
            } catch (error) {
                const err = error as any;

                toast.error(err.message);
                setLoading(false);
                setDisabledChecklist(false);
            }
        }

        async function getConfiguration() {
            try {
                const response = await ConfigurationHttpService.readOne(
                    ConfigurationReference.SalesCampaign,
                );

                setEnabledCampaign(+response.data.value);
            } catch (error) {
                console.log('error -> ', error);
            }
        }

        getCampaigns();
        getConfiguration();
    }, [params]);

    async function handleActivation(
        event: ChangeEvent<HTMLInputElement>,
        campaign: Campaign,
    ) {
        try {
            const checked = event.target?.checked;

            if (campaign.id) {
                setDisabledChecklist(true);

                await ConfigurationHttpService.update(
                    ConfigurationReference.SalesCampaign,
                    { value: checked ? campaign.id.toString() : null },
                );

                setEnabledCampaign(checked ? campaign.id : -1);

                resetPage();

                toast.success(
                    `Promoção ${
                        checked ? 'ativada' : 'desativada'
                    } com sucesso`,
                );

                setDisabledChecklist(false);
            }
        } catch (error) {
            const err = error as any;

            toast.error(err.message);

            setDisabledChecklist(false);
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">
                        Campanhas Promocionais
                    </StyledPageTitle>
                </Col>
                <Col>
                    <Button
                        className="float-right w-25 m-2"
                        onClick={() => {
                            setShowCampaignModal(true);
                        }}
                    >
                        Nova Campanha
                    </Button>
                </Col>
            </Row>
            <Row>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <i className="fas fa-search" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        value={params.term}
                        onChange={(event) => {
                            const newParams = {
                                term: event.target.value,
                                skip: params.skip,
                                take: params.take,
                            };

                            setParams(newParams);
                        }}
                        placeholder="Digite aqui o que procura..."
                    />
                </InputGroup>
            </Row>
            <Row>
                <Table
                    ref={infiniteRef}
                    bordered
                    hover
                    size="sm"
                    className="text-center"
                >
                    <thead>
                        <tr>
                            <StyledTh>Código</StyledTh>
                            <StyledTh>Nome</StyledTh>
                            <StyledTh>Tabela de preço</StyledTh>
                            <StyledTh>Desconto</StyledTh>
                            <StyledTh>Pedidos</StyledTh>
                            <StyledTh>Ativo</StyledTh>
                            <StyledTh />
                        </tr>
                    </thead>
                    <tbody>
                        {salesCampaigns.length > 0 &&
                            salesCampaigns.map(
                                (campaign: Campaign, index: number) => (
                                    <tr key={index}>
                                        <td style={{ padding: 10 }}>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[params.term]}
                                                textToHighlight={
                                                    campaign.id?.toString() ||
                                                    ''
                                                }
                                            />
                                        </td>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[params.term]}
                                                textToHighlight={campaign.name}
                                            />
                                        </td>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[params.term]}
                                                textToHighlight={
                                                    campaign.priceTable
                                                }
                                            />
                                        </td>
                                        <td>
                                            {`${Math.floor(
                                                campaign.maxDiscount,
                                            )},${(campaign.maxDiscount % 1)
                                                .toFixed(2)
                                                .substring(2)}%`}
                                        </td>
                                        <td>{campaign.maxInvoices}</td>
                                        <td>
                                            <Form.Check
                                                id={`${index.toString()}m`}
                                                checked={
                                                    enabledCampaign ===
                                                    campaign.id
                                                }
                                                onChange={(event) => {
                                                    handleActivation(
                                                        event,
                                                        campaign,
                                                    );
                                                }}
                                                disabled={disabledChecklist}
                                            />
                                        </td>
                                        <td>
                                            <Dropdown key="left">
                                                <Dropdown.Toggle
                                                    bsPrefix="nexen"
                                                    className="mt-n3 mb-n3"
                                                    as={Button}
                                                    variant="text"
                                                >
                                                    <ReactMoreIcon
                                                        fill="#bdbdbd"
                                                        width="10"
                                                        height="20"
                                                    />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setSelectedItem(
                                                                campaign,
                                                            );
                                                            setShowCampaignModal(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        Editar temporada
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setSelectedItem(
                                                                campaign,
                                                            );
                                                            setShowProductsModal(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        Editar produtos
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ),
                            )}
                    </tbody>
                </Table>
            </Row>

            <Row className="mx-4">
                <br />
                {loading && hasNextPage && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}

                {!hasNextPage && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Row>

            {showCampaignModal && (
                <SalesCampaignModal
                    show={showCampaignModal}
                    campaign={selectedItem}
                    reset={resetPage}
                    onHide={() => {
                        setSelectedItem(null);
                        setShowCampaignModal(false);
                    }}
                />
            )}

            {showProductsModal && selectedItem && selectedItem.id && (
                <SalesCampaignProductsModal
                    show={showProductsModal}
                    campaign={selectedItem}
                    reset={resetPage}
                    onHide={() => {
                        setSelectedItem(null);
                        setShowProductsModal(false);
                    }}
                />
            )}
        </>
    );
};

export { SalesCampaign };
