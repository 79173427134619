/* eslint-disable react/require-default-props */
import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import arrowBack from '../../assets/icons/arrow_back.svg';
import nexenLogo from '../../assets/nexen.svg';
import { StyledImage, StyledSpan } from './styles';

interface Props {
    goBackTo: any;
    setStep: any;
}

const HeaderAccess: React.FC<Props> = ({ goBackTo, setStep }) => {
    const history = useHistory();

    return (
        <Row className="mt-2">
            <Col>
                {goBackTo ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <StyledImage src={arrowBack} />
                        <StyledSpan
                            role="button"
                            onClick={() => {
                                if (setStep) {
                                    setStep();
                                } else {
                                    history.push(goBackTo);
                                }
                            }}
                            className="ml-2"
                        >
                            Voltar
                        </StyledSpan>
                    </div>
                ) : (
                    <Image className="mb-4" src={nexenLogo} />
                )}
            </Col>
        </Row>
    );
};

HeaderAccess.propTypes = {
    goBackTo: PropTypes.string,
    setStep: PropTypes.func,
};

export default HeaderAccess;
