import Request from './request';

export default class ContactHttpService {
    private static uri = 'contacts';

    public static create(contact: {}) {
        return Request.post(`${this.uri}`, contact);
    }

    public static readMany(params: {
        skip?: number;
        take?: number;
        term?: string;
    }) {
        return Request.get(`${this.uri}`, params);
    }

    public static update(id: number, contact: {}) {
        return Request.patch(`${this.uri}/${id}`, contact);
    }

    public static delete(id: number) {
        return Request.del(`${this.uri}/${id}`);
    }
}
