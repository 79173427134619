/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import {
    Row,
    Col,
    Table,
    Spinner,
    Button,
    Form,
    Dropdown,
    InputGroup,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import { useInfiniteQuery } from 'react-query';
import { StyledPageTitle } from '../../../styles/pageTitle';
import { StyledTh } from './styles';
import { useConfiguration } from '../../../contexts/configurationContext';
import { ReactComponent as ReactMoreIcon } from '../../../assets/icons/more.svg';
import BannerCreator from './BannerCreator/BannerCreator';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import getLocationName from '../../../utils/getLocationName';
import BannerEditor from './BannerEditor/BannerEditor';
import BannerHttpService from '../../../services/http/banner-http';

const BannerTable = () => {
    const [term, setTerm] = useState('');
    const [loading, setLoading] = useState(false);

    const {
        showBannerEditorModal,
        setShowBannerEditorModal,
        showBannerCreatorModal,
        setShowBannerCreatorModal,
        setSelectedBanner,
        hasChange,
        setHasChange,
    } = useConfiguration();

    const loadBanners = async ({ pageParam = 0 }: any) => {
        const response = await BannerHttpService.readMany({
            term,
            skip: pageParam,
            take: 25,
        });

        return {
            data: response.data.results,
            nextPage: pageParam,
        };
    };

    const { data, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
        'banner',
        loadBanners,
        {
            getNextPageParam: (lastPage) => lastPage.nextPage + 1,
        },
    );

    async function setBannerActivated(banner: any) {
        setLoading(true);

        try {
            // eslint-disable-next-line no-param-reassign
            banner.active = !banner.active;

            await BannerHttpService.update(banner.id, banner);

            if (banner.active) {
                toast.success('Banner ativado.');
            } else {
                toast.success('Banner desativado.');
            }
        } catch (error) {
            toast.error('Erro ao salvar banner.');
        }

        setLoading(false);
    }

    async function deleteBanner(banner: any) {
        setLoading(true);

        try {
            await BannerHttpService.delete(banner.id);

            refetch();

            toast.success('Banner excluído.');
        } catch (error) {
            const err = error as any;

            toast.error(err.message);
        }

        setLoading(false);
    }

    const loadMoreRef = useRef();

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage,
    });

    useEffect(() => {
        refetch();
        setHasChange(false);
    }, [term, hasChange]);

    return (
        <>
            <BannerCreator
                show={showBannerCreatorModal}
                onHide={() => {
                    setSelectedBanner({});
                    setShowBannerCreatorModal(false);
                }}
            />

            <BannerEditor
                show={showBannerEditorModal}
                onHide={() => {
                    setSelectedBanner({});
                    setShowBannerEditorModal(false);
                }}
            />

            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">Banners</StyledPageTitle>
                </Col>
                <Col>
                    <Button
                        className="float-right w-25 m-2"
                        onClick={() => {
                            setSelectedBanner({});
                            setShowBannerCreatorModal(true);
                        }}
                    >
                        Novo Banner
                    </Button>
                </Col>
            </Row>
            <Row>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <i className="fas fa-search" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        value={term}
                        onChange={(event) => setTerm(event.target.value)}
                        placeholder="Digite aqui o que procura..."
                    />
                </InputGroup>
            </Row>
            <Row>
                <Table bordered hover size="sm" className="text-center">
                    <thead>
                        <tr>
                            <StyledTh>Nome</StyledTh>
                            <StyledTh>Link</StyledTh>
                            <StyledTh>Local</StyledTh>
                            <StyledTh>Ativo</StyledTh>
                            <StyledTh />
                        </tr>
                    </thead>
                    <tbody>
                        {data?.pages &&
                            data?.pages.map((group: any) =>
                                group?.data.map((item: any, index: any) => (
                                    <tr key={index}>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={item.fileName}
                                            />
                                        </td>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={item.referLink}
                                            />
                                        </td>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={getLocationName(
                                                    item.location,
                                                )}
                                            />
                                        </td>
                                        <td>
                                            <Form.Check
                                                id={`${index.toString()}m`}
                                                checked={item.active}
                                                onChange={() => {
                                                    setBannerActivated(item);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Dropdown key="left">
                                                <Dropdown.Toggle
                                                    bsPrefix="nexen"
                                                    className="mt-n3 mb-n3"
                                                    as={Button}
                                                    variant="text"
                                                >
                                                    <ReactMoreIcon
                                                        fill="#bdbdbd"
                                                        width="10"
                                                        height="20"
                                                    />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setSelectedBanner(
                                                                item,
                                                            );
                                                            setShowBannerEditorModal(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        Editar
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            deleteBanner(item);
                                                            refetch();
                                                        }}
                                                    >
                                                        Remover
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )),
                            )}
                    </tbody>
                </Table>
            </Row>

            <Row className="mx-4">
                <br />
                {loading && hasNextPage && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}

                {!hasNextPage && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Row>
        </>
    );
};

export default BannerTable;
