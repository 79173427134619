import { Card } from 'react-bootstrap';
import { AiOutlineFileText } from 'react-icons/ai';
import StyledCardDiv from './styles';
import bytesToMB from '../../../../../../utils/convertBytesToMb';

interface FileCardProps {
    file: File;
}

export default function FileCard({ file }: FileCardProps) {
    return (
        <Card className="w-100 mr-3 ml-3">
            <Card.Body>
                <StyledCardDiv>
                    <AiOutlineFileText
                        size={48}
                        className="float-left"
                        style={{ marginRight: '10px' }}
                    />
                    <Card.Title className="float-left mb-0">
                        {file.name}
                        <Card.Text>
                            {`${bytesToMB(file.size).toFixed(2)} MB`}
                        </Card.Text>
                    </Card.Title>
                </StyledCardDiv>
            </Card.Body>
        </Card>
    );
}
