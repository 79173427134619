import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';
import OriginOptions from '../../../../../constants/productDeliveryFilters';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../../../styles/react-select-config';

export interface OptionProps {
    label: string;
    value: string | boolean;
}

export interface ProductDeliveryDateFilterProps {
    filterTerm: string;
    filterEndDate: string;
    filterStartDate: string;
    filterOrderProcess: string;
    filterBranch: OptionProps[];
    filterProduct: OptionProps[];
    filterOrigin: OptionProps | null;
    filterOrderBy: OptionProps | null;
    filterOrderType: OptionProps | null;
}

interface Props {
    filterToggle: boolean;
    control: Control<ProductDeliveryDateFilterProps>;
    products: OptionProps[];
    disableProduct: boolean;
    branches: OptionProps[];
    disableBranch: boolean;
}

const ProductDeliveryDateFilter = (props: Props) => {
    const {
        filterToggle,
        control,
        products: productFilterOptions,
        disableProduct,
        branches: branchFilterOptions,
        disableBranch,
    } = props;

    return (
        <>
            {filterToggle && (
                <Row>
                    <Col>
                        <Card className="mt-4 mx-2">
                            <Card.Body>
                                <Card.Title>
                                    <strong>Filtros</strong>
                                </Card.Title>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group controlId="formBasicProduct">
                                            <Form.Label>Produto</Form.Label>
                                            <Controller
                                                isClearable
                                                className="form-control-nexen"
                                                as={
                                                    <Select
                                                        isDisabled={
                                                            disableProduct
                                                        }
                                                    />
                                                }
                                                control={control}
                                                name="filterProduct"
                                                options={productFilterOptions}
                                                isMulti
                                                styles={{
                                                    control: tradeControl,
                                                    option: optionsTheme,
                                                }}
                                                theme={theme}
                                                placeholder={
                                                    disableProduct
                                                        ? 'Carregando produtos...'
                                                        : 'Selecione...'
                                                }
                                                defaultValue=""
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group controlId="formBasicOrderProcess">
                                            <Form.Label>
                                                Nº processo/pedido
                                            </Form.Label>
                                            <Controller
                                                as={
                                                    <Form.Control placeholder="Digite aqui o que procura..." />
                                                }
                                                type="text"
                                                name="filterOrderProcess"
                                                control={control}
                                                defaultValue=""
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group controlId="formBasicBranch">
                                            <Form.Label>Filial</Form.Label>
                                            <Controller
                                                isClearable
                                                className="form-control-nexen"
                                                as={
                                                    <Select
                                                        isDisabled={
                                                            disableBranch
                                                        }
                                                    />
                                                }
                                                control={control}
                                                name="filterBranch"
                                                options={branchFilterOptions}
                                                isMulti
                                                styles={{
                                                    control: tradeControl,
                                                    option: optionsTheme,
                                                }}
                                                theme={theme}
                                                placeholder={
                                                    disableBranch
                                                        ? 'Carregando filiais...'
                                                        : 'Selecione...'
                                                }
                                                defaultValue=""
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group controlId="formBasicOrigin">
                                            <Form.Label>Origem</Form.Label>
                                            <Controller
                                                isClearable
                                                className="form-control-nexen"
                                                as={Select}
                                                control={control}
                                                name="filterOrigin"
                                                options={OriginOptions}
                                                styles={{
                                                    control: tradeControl,
                                                    option: optionsTheme,
                                                }}
                                                theme={theme}
                                                placeholder="Selecione..."
                                                defaultValue=""
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="formDate">
                                            <Form.Label>
                                                Data de disponibilidade
                                            </Form.Label>
                                            <Row>
                                                <Col md={5}>
                                                    <Controller
                                                        render={({
                                                            onChange,
                                                            value,
                                                        }) => (
                                                            <Form.Control
                                                                type="date"
                                                                onChange={
                                                                    onChange
                                                                }
                                                                value={value}
                                                                min="2000-01-01"
                                                                max="2200-12-31"
                                                            />
                                                        )}
                                                        className="form-control-nexen"
                                                        control={control}
                                                        name="filterStartDate"
                                                        defaultValue=""
                                                    />
                                                </Col>
                                                <span className="d-flex align-self-center">
                                                    à
                                                </span>
                                                <Col md={5}>
                                                    <Controller
                                                        render={({
                                                            onChange,
                                                            value,
                                                        }) => (
                                                            <Form.Control
                                                                type="date"
                                                                onChange={
                                                                    onChange
                                                                }
                                                                value={value}
                                                                min="2000-01-01"
                                                                max="2200-12-31"
                                                            />
                                                        )}
                                                        className="form-control-nexen"
                                                        control={control}
                                                        name="filterEndDate"
                                                        defaultValue=""
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
            <Row className="pl-2 pr-2 mt-4">
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-search" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Controller
                            as={
                                <Form.Control placeholder="Digite aqui o que procura..." />
                            }
                            type="text"
                            name="filterTerm"
                            control={control}
                            defaultValue=""
                        />
                    </InputGroup>
                </Col>
            </Row>
        </>
    );
};

export { ProductDeliveryDateFilter };
