import { useState, createContext, useContext } from 'react';

export interface AuthValues {
    user: any;
    setUser: any;
}

const AuthContext = createContext<AuthValues>({} as AuthValues);
const useAuth = () => useContext(AuthContext);

function AuthProvider(props: any) {
    const [user, setUser] = useState();

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
}

export { AuthProvider, useAuth };
