import React, { useState } from 'react';
import { Row, Col, Form, Nav } from 'react-bootstrap';
import { StyledNav } from './styles';

import ModuleTable from './Modules/ModuleTable';
import RoofTypeTable from './RoofTypes/RoofTypeTable';
import KitTable from './Kits/KitTable';
import InvertersBrandsTable from './InvertersBrands';
import InvertersTable from './Inverters';
import AccessoriesTable from './Accessories';

const OnlineStoreData = () => {
    const [tab, setTab] = useState('modules');

    return (
        <>
            <Form.Group controlId="formConfiguration">
                <Row className="mb-3">
                    <Col md={8}>
                        <StyledNav
                            variant="tabs"
                            activeKey={tab}
                            onSelect={(selectedKey: string) =>
                                setTab(selectedKey)
                            }
                        >
                            <Nav.Item>
                                <Nav.Link eventKey="modules">Módulos</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="roofTypes">
                                    Tipos de Telhado
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="kits">Kits</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="invertersBrands">
                                    Marca Inversores
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="inverters">
                                    Inversores
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="accessories">
                                    Acessórios
                                </Nav.Link>
                            </Nav.Item>
                        </StyledNav>
                    </Col>
                </Row>

                {tab === 'modules' && <ModuleTable />}

                {tab === 'roofTypes' && <RoofTypeTable />}

                {tab === 'kits' && <KitTable />}

                {tab === 'invertersBrands' && <InvertersBrandsTable />}

                {tab === 'inverters' && <InvertersTable />}

                {tab === 'accessories' && <AccessoriesTable />}
            </Form.Group>
        </>
    );
};

export default OnlineStoreData;
