import { AxiosResponse } from 'axios';
import EmailConfirmationRequest from '../../shared/interfaces/email-confirmation-request.interface';
import EmailConfirmation from '../../shared/interfaces/email-confirmation.interface';
import { Users } from '../../shared/interfaces/users.interface';
import Request from './request';

export default class UserHttpService {
    private static uri = 'users';

    public static readMany(params: {}) {
        return Request.get(this.uri, params);
    }

    public static readOne(id: string) {
        return Request.get(`${this.uri}/${id}`);
    }

    public static create(data: {}) {
        return Request.post(this.uri, data);
    }

    public static update(id: string, data: {}) {
        return Request.patch(`${this.uri}/${id}`, data);
    }

    public static async delete(id: number) {
        return Request.del(`${this.uri}/${id}`);
    }

    public static batchUpdate(users: Users) {
        return Request.patch(`${this.uri}`, users);
    }

    public static requestEmailConfirmation(
        data: EmailConfirmationRequest,
    ): Promise<AxiosResponse<void>> {
        return Request.post(`${this.uri}/email-confirmation`, data);
    }

    public static confirmEmail(
        data: EmailConfirmation,
    ): Promise<AxiosResponse<boolean>> {
        return Request.patch(`${this.uri}/email-confirmation`, data);
    }
}
