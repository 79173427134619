import { Col, Form, Spinner } from 'react-bootstrap';
import useCommercialConsultants from '../../../hooks/useCommercialConsultants';
import Select, { SelectOption } from '../../Select';

interface IntegratorCommercialConsultantsProps {
    onCommercialConsultantsChange: (value: number[]) => void;
    selectedCommercialConsultants: number[];
}

export default function IntegratorCommercialConsultants({
    onCommercialConsultantsChange,
    selectedCommercialConsultants,
}: IntegratorCommercialConsultantsProps) {
    const { commercialConsultants, isLoading } = useCommercialConsultants();

    if (isLoading) {
        return (
            <Col md={12} className="text-center">
                <Spinner animation="border" />
            </Col>
        );
    }

    const selectValue = selectedCommercialConsultants.map((id) => {
        const consultant = commercialConsultants?.find(
            (currentConsultant) => currentConsultant.value === id,
        );

        return {
            value: id,
            label: consultant?.label || '',
        };
    });

    const handleChange = (value: SelectOption<number> | null) => {
        onCommercialConsultantsChange(value ? [value.value] : []);
    };

    return (
        <Form.Group controlId="formBasicCommercialConsultants">
            <Form.Label>Consultor comercial</Form.Label>
            <Select
                options={commercialConsultants}
                onChange={handleChange}
                value={selectValue}
                name="commercialConsultants"
            />
        </Form.Group>
    );
}
