/* eslint-disable func-names */
import * as Yup from 'yup';

const strongPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).*$/;

function validateLegalAge(dateOfBirth: string) {
    const legalAge = 16;
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age -= 1;
    }

    if (age < legalAge) {
        return false;
    }

    return true;
}

function validateCPF(cpfToValidate: string) {
    const cpf = cpfToValidate.replace(/\D/g, '');

    let sum;
    let rest;

    sum = 0;

    if (cpf === '00000000000') {
        return false;
    }

    for (let i = 1; i <= 9; i += 1) {
        sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
        rest = 0;
    }

    if (rest !== parseInt(cpf.substring(9, 10), 10)) {
        return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i += 1) {
        sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
        rest = 0;
    }

    if (rest !== parseInt(cpf.substring(10, 11), 10)) {
        return false;
    }

    return true;
}

export function validateCNPJ(cnpjToValidate: any) {
    const cnpj = cnpjToValidate ? cnpjToValidate.replace(/[^\d]+/g, '') : '';

    if (cnpj === '') {
        return false;
    }

    if (cnpj.length !== 14) {
        return false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (
        cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999'
    ) {
        return false;
    }

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);

    const digitos = cnpj.substring(tamanho);

    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i -= 1) {
        soma += numeros.charAt(tamanho - i) * pos;
        pos -= 1;

        if (pos < 2) {
            pos = 9;
        }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado.toString() !== digitos.charAt(0)) {
        return false;
    }

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i -= 1) {
        soma += numeros.charAt(tamanho - i) * pos;
        pos -= 1;

        if (pos < 2) {
            pos = 9;
        }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado.toString() !== digitos.charAt(1)) {
        return false;
    }

    return true;
}

export const userRules: any = {
    username: Yup.string().required('Campo obrigatório'),
    email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
    password: Yup.string().when('$password', {
        is: (value) => {
            return value !== '' && value !== undefined;
        },
        then: Yup.string()
            .required('Campo obrigatório')
            .min(8, 'A senha deve conter 8 ou mais caracteres')
            .matches(
                strongPasswordRegex,
                'A senha deve conter letras maiúsculas, minúsculas, números e caracteres especiais',
            ),
        otherwise: Yup.string().notRequired(),
    }),

    password_confirmation: Yup.string().when('$password', {
        is: '',
        then: Yup.string().notRequired(),
        otherwise: Yup.string()
            .required('Campo obrigatório')
            .oneOf([Yup.ref('$password')], 'Senha de confirmação incorreta'),
    }),
};

export const userRulesWithCNPJ: any = {
    username: Yup.string().required('Campo obrigatório'),
    email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
    password: Yup.string().when('$password', {
        is: (value) => {
            return value !== '' && value !== undefined;
        },
        then: Yup.string()
            .required('Campo obrigatório')
            .min(8, 'A senha deve conter 8 ou mais caracteres')
            .matches(
                strongPasswordRegex,
                'A senha deve conter letras maiúsculas, minúsculas, números e caracteres especiais',
            ),
        otherwise: Yup.string().notRequired(),
    }),

    password_confirmation: Yup.string().when('$password', {
        is: '',
        then: Yup.string().notRequired(),
        otherwise: Yup.string()
            .required('Campo obrigatório')
            .oneOf([Yup.ref('$password')], 'Senha de confirmação incorreta'),
    }),
    cnpj: Yup.string().test('invalid', 'CNPJ inválido', (value) =>
        validateCNPJ(value),
    ),
};

export const userRulesIntegrator: any = {
    integratorsNick: Yup.string().required('Campo obrigatório'),
    email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
    password: Yup.string().when('$password', {
        is: (value) => {
            return value !== '' && value !== undefined;
        },
        then: Yup.string()
            .required('Campo obrigatório')
            .min(8, 'A senha deve conter 8 ou mais caracteres')
            .matches(
                strongPasswordRegex,
                'A senha deve conter letras maiúsculas, minúsculas, números e caracteres especiais',
            ),
        otherwise: Yup.string().notRequired(),
    }),
    password_confirmation: Yup.string().when('$password', {
        is: '',
        then: Yup.string().notRequired(),
        otherwise: Yup.string()
            .required('Campo obrigatório')
            .oneOf([Yup.ref('$password')], 'Senha de confirmação incorreta'),
    }),
};

export const companyRules = {
    name: Yup.string().required('Campo obrigatório'),
    fancyName: Yup.string()
        .required('Campo obrigatório')
        .max(
            20,
            'Nome Fantasia do cliente não pode ter mais que 20 caracteres',
        ),
    cnpj: Yup.string()
        .required('Campo obrigatório')
        .test('invalid', 'CNPJ inválido', (value) => validateCNPJ(value)),
    phone: Yup.string().required('Campo obrigatório'),
    hasIe: Yup.string().required('Campo obrigatório'),
    ie: Yup.string().when('$hasIe', {
        is: '1',
        then: Yup.string().required('Campo obrigatório'),
    }),
};

export const partnerRules = {
    partnerName: Yup.string().required('Nome do sócio é obrigatório'),
    partnerDocument: Yup.string()
        .required('Campo obrigatório')
        .test('invalid', 'CPF inválido', (value) => validateCPF(value)),
    partnerBirthDate: Yup.string()
        .required('Data de nascimento é obrigatório')
        .test('invalid', 'Data de nascimento inválida', (value) =>
            validateLegalAge(value),
        ),
    partnerEmail: Yup.string()
        .required('Email é obrigatório')
        .email('E-mail inválido'),
    partnerPhone: Yup.string().required('Telefone é obrigatório'),
};

export const companyInfoRules = {
    question1: Yup.string().required('Campo é obrigatório'),
    question2: Yup.string().required('Campo é obrigatório'),
    question3: Yup.string().required('Campo é obrigatório'),
    question4: Yup.string().required('Campo é obrigatório'),
    question5: Yup.string().required('Campo é obrigatório'),
    question6: Yup.string().required('Campo é obrigatório'),
};

export const resetPasswordRules: any = {
    password: Yup.string()
        .required('Campo obrigatório')
        .min(8, 'A senha deve conter 8 ou mais caracteres')
        .matches(
            strongPasswordRegex,
            'A senha deve conter letras maiúsculas, minúsculas, números e caracteres especiais',
        ),
    password_confirmation: Yup.string()
        .required('Campo obrigatório')
        .oneOf([Yup.ref('$password')], 'Senha de confirmação incorreta'),
};

export const userEmailExistsRules = {
    email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
};

export const addressRules = {
    addressCep: Yup.string()
        .required('Campo obrigatório')
        .min(8, 'CEP inválido'),
    addressDescription: Yup.string().required('Campo obrigatório'),
    addressNeighborhood: Yup.string().required('Campo obrigatório'),
    erpState: Yup.string().required('Campo obrigatório'),
    erpCity: Yup.string().required('Campo obrigatório'),
    addressNumber: Yup.string().required('Campo obrigatório'),
};

export const integratorRules = {
    ...userRules,
    ...companyRules,
    ...addressRules,
    ...partnerRules,
};

export default integratorRules;
