import React, { useState, createContext, useContext } from 'react';

const ConfigurationContext = createContext<any>({});
const useConfiguration = () => useContext(ConfigurationContext);

function ConfigurationProvider(props: any) {
    const [showBannerCreatorModal, setShowBannerCreatorModal] = useState(false);
    const [showBannerEditorModal, setShowBannerEditorModal] = useState(false);
    const [selectedBanner, setSelectedBanner] = useState({});
    const [hasChange, setHasChange] = useState({});
    const [showModuleEditorModal, setShowModuleEditorModal] = useState(false);
    const [showRoofTypeEditorModal, setShowRoofTypeEditorModal] =
        useState(false);
    const [
        showSelectedProductDeliveryDateEditorModal,
        setShowSelectedProductDeliveryDateEditorModal,
    ] = useState(false);
    const [showKitsImportModal, setShowKitsImportModal] = useState(false);
    const [selectedModule, setSelectedModule] = useState({});
    const [selectedRoofType, setSelectedRoofType] = useState({});

    const [roofTypes, setRoofTypes] = useState([]);
    const [modules, setModules] = useState([]);
    const [kits, setKits] = useState([]);

    return (
        <ConfigurationContext.Provider
            value={{
                showBannerEditorModal,
                setShowBannerEditorModal,
                hasChange,
                setHasChange,
                showBannerCreatorModal,
                setShowBannerCreatorModal,
                selectedBanner,
                setSelectedBanner,
                showModuleEditorModal,
                setShowModuleEditorModal,
                showKitsImportModal,
                setShowKitsImportModal,
                showRoofTypeEditorModal,
                setShowRoofTypeEditorModal,
                kits,
                setKits,
                roofTypes,
                setRoofTypes,
                modules,
                setModules,
                selectedModule,
                setSelectedModule,
                selectedRoofType,
                setSelectedRoofType,
                showSelectedProductDeliveryDateEditorModal,
                setShowSelectedProductDeliveryDateEditorModal,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
}

export { ConfigurationProvider, useConfiguration };
