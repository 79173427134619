import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Spinner } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { StyledRow, StyledTitle } from '../styles';
import {
    theme,
    tradeControl,
    optionsTheme,
} from '../../../styles/react-select-config';
import LibraryHttpService from '../../../services/http/library-http';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';

function CategoriesModalInputs({
    register,
    errors,
    control,
    id,
    setValue,
}: any) {
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);

    async function loadData() {
        const { data } = await LibraryHttpService.getBrandCategory(id);

        const keys = Object.keys(data);

        // eslint-disable-next-line no-restricted-syntax
        for (const key of keys) {
            if (key === 'libraryBrandId') {
                const option = options.find(
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    (option: any) => option.value === data.libraryBrandId,
                );
                setValue(key, option);
                // eslint-disable-next-line no-continue
                continue;
            }

            setValue(key, data[key]);
        }
    }

    // eslint-disable-next-line consistent-return
    async function loadBrands() {
        try {
            const { data } = await LibraryHttpService.getBrands({});

            setOptions(
                data.data.map((brand: any) => {
                    return {
                        label: brand.name,
                        value: brand.id,
                    };
                }),
            );
        } catch (error) {
            const err = error as any;

            if (isFriendlyHttpError(err)) return toast.error(err.message);

            toast.error('Erro ao carregar opções');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (id) loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    useEffect(() => {
        loadBrands();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <StyledRow className="ml-0 mt-2 mr-1">
                <Col>
                    <StyledTitle>Nome</StyledTitle>
                </Col>
            </StyledRow>
            <Row className="ml-0 mr-1">
                <Col>
                    <Form.Group>
                        <Form.Control
                            ref={register}
                            name="name"
                            type="string"
                            className="form-control-nexen"
                            placeholder="Digite aqui..."
                            isInvalid={!!errors.name}
                        />
                        {errors.name && (
                            <Form.Control.Feedback type="invalid">
                                {errors.name}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            {!loading ? (
                <>
                    <StyledRow className="ml-0 mt-2 mr-1">
                        <Col>
                            <StyledTitle>Marca</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mr-1">
                        <Col>
                            <Controller
                                isClearable={false}
                                className="form-control-nexen"
                                as={Select}
                                control={control}
                                name="libraryBrandId"
                                options={options}
                                isMulti={false}
                                styles={{
                                    control: tradeControl,
                                    option: optionsTheme,
                                }}
                                theme={theme}
                                placeholder="Selecione uma marca..."
                                defaultValue={options[0]}
                                handleMouseDownOnMenu={false}
                                menuPosition="fixed"
                                menuShouldBlockScroll={false}
                                menuShouldScrollIntoView
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <Col>
                    <Spinner
                        className="mr-2 mt-2"
                        size="sm"
                        animation="border"
                    />
                    Carregando...
                </Col>
            )}
        </>
    );
}

export default CategoriesModalInputs;
