/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Col, Modal, Row, Form, Button, Spinner, Image } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { StyledRow, StyledTitle } from './styles';
import { useConfiguration } from '../../../../contexts/configurationContext';
import fileUploadImg from '../../../../assets/icons/fileUpload.png';
import {
    tradeControl,
    theme,
    optionsTheme,
} from '../../../../styles/react-select-config';
import { BannerLocation } from '../../../../constants/configuration';
import BannerHttpService from '../../../../services/http/banner-http';

interface FormData {
    fileName: string;
    location: { label: string; value: string };
    awsLink: string;
    referLink: string;
    active: boolean;
}

const BannerCreator = (props: any) => {
    const [loading, setLoading] = useState(false);
    const { register, getValues, setValue, reset, control } =
        useForm<FormData>();
    const {
        setShowBannerCreatorModal,
        selectedBanner,
        setSelectedBanner,
        setHasChange,
    } = useConfiguration();

    const [data, setData]: any = useState();

    const locationOptions = [
        { label: 'Dashboard', value: BannerLocation.dashboard },
        {
            label: 'Pontos - Informações',
            value: BannerLocation.pointsInformations,
        },
    ];

    async function saveBanner() {
        setLoading(true);

        try {
            const useFormData = getValues();

            const formData = new FormData();

            formData.append('file', data.get('file'));
            formData.append('fileName', useFormData.fileName);
            formData.append('referLink', useFormData.referLink);
            formData.append('location', useFormData.location.value);

            await BannerHttpService.create(formData);

            setHasChange(true);
            setShowBannerCreatorModal(false);
            setSelectedBanner({});

            toast.success('Banner salvo.');
        } catch (error) {
            console.log(error);

            toast.error('Erro ao criar banner.');
        }

        setLoading(false);
    }

    useEffect(() => {
        setData({});

        if (selectedBanner?.fileName) {
            setValue('fileName', selectedBanner.fileName);
            setValue('location', selectedBanner.location);
            setValue('awsLink', selectedBanner.awsLink);
            setValue('referLink', selectedBanner.referLink);
            setValue('active', selectedBanner.active);
        } else {
            reset({});
        }
    }, [selectedBanner]);

    return (
        <>
            <Modal
                className="modal-dialog-scrollable"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                size="md"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className="ml-3 mr-3"
                        id="contained-modal-title-vcenter"
                    >
                        Banner
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="w-100">
                    <Row className="mb-3 ml-1 mr-1">
                        <Dropzone
                            accept={['image/png', 'image/jpeg']}
                            onDropRejected={() => {
                                toast.error(
                                    'Arquivo muito grande ou formato incorreto',
                                );
                            }}
                            maxSize={5242880}
                            onDrop={(file: any) => {
                                // eslint-disable-next-line @typescript-eslint/no-shadow
                                const data = new FormData();

                                if (file.length === 0) {
                                    return;
                                }

                                data.append('file', file[0], file[0].name);

                                setData(data);
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section className="custom-file-upload">
                                    {
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        <div {...getRootProps()}>
                                            {
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                <input {...getInputProps()} />
                                            }
                                            <Row className="dropzone-title">
                                                <Col
                                                    className="dropzone-title"
                                                    sm={8}
                                                >
                                                    <h5
                                                        style={{
                                                            color: '#F2994A',
                                                            fontSize: '1rem',
                                                        }}
                                                        className="dropzone-title p-4"
                                                    >
                                                        Clique aqui para
                                                        selecionar um arquivo ou
                                                        arraste-o
                                                    </h5>
                                                </Col>
                                                <Col className="text-right">
                                                    {loading ? (
                                                        <Spinner
                                                            className="mr-4 mt-4"
                                                            animation="border"
                                                        />
                                                    ) : (
                                                        <Image
                                                            className="mr-4 mt-3"
                                                            src={fileUploadImg}
                                                        />
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="mt-n4 ml-4">
                                                <h6
                                                    style={{
                                                        fontSize: '0.813rem',
                                                    }}
                                                >
                                                    arquivos jpg e pngs até 5 MB
                                                </h6>
                                            </Row>
                                        </div>
                                    }
                                </section>
                            )}
                        </Dropzone>
                    </Row>
                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Nome</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="fileName"
                                type="string"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Link Destino</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="referLink"
                                type="string"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>
                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Local</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Controller
                                isClearable={false}
                                className="form-control-nexen"
                                as={Select}
                                control={control}
                                name="location"
                                options={locationOptions}
                                isMulti={false}
                                styles={{
                                    control: tradeControl,
                                    option: optionsTheme,
                                }}
                                theme={theme}
                                placeholder="Selecione..."
                                defaultValue=""
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-5 mr-1">
                        <Col sm={6} />
                        <Col>
                            <Button
                                disabled={loading}
                                className="w-100 float-right"
                                variant="outline-primary"
                                onClick={() => {
                                    setShowBannerCreatorModal(false);
                                    setSelectedBanner({});
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                disabled={loading}
                                className="w-100 float-right"
                                onClick={() => {
                                    saveBanner();
                                }}
                            >
                                Salvar{'   '}
                                {loading && (
                                    <Spinner animation="border" size="sm" />
                                )}
                            </Button>
                        </Col>
                    </StyledRow>
                </Modal.Body>
                <Modal.Footer className="ml-3 mr-3 mb-2" />
            </Modal>
        </>
    );
};

export default BannerCreator;
