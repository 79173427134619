import styled from 'styled-components';

export const StyledH4 = styled.h4`
    font-size: 20px !important;
    color: #707070;
`;

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;
