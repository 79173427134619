import React, { useRef, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    Image,
    OverlayTrigger,
    Row,
    Spinner,
    Tooltip,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { useForm } from 'react-hook-form';
import { useInfiniteQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ReactComponent as ReactCheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as EraserIcon } from '../../assets/icons/eraser-solid.svg';
import filterIcon from '../../assets/icons/filter.png';
import { ReactComponent as ReactMoreIcon } from '../../assets/icons/more.svg';
import { ReactComponent as ReactTruckIcon } from '../../assets/icons/truck.svg';
import BaseLayout from '../../components/BaseLayout';
import PreReturnModal from '../../components/Configuration/Returns/PreReturnModal';
import {
    ReturnsFilterProps,
    ReturnsFilter,
} from '../../components/Configuration/Returns/ReturnsFilter';
import { rowsPerPage } from '../../constants/pagination';
import ReturnStatus from '../../constants/returnStatus';
import Role from '../../constants/roles';
import { useAuth } from '../../contexts/authContext';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import ReturnHttpService from '../../services/http/return-http';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import formatCurrency from '../../utils/formatCurrency';
import getReturnStatusText from '../../utils/getReturnStatusText';
import getTotalInvoice from '../../utils/getTotalInvoice';
import userHasRoles from '../../utils/userHasRoles';
import { StyledBadge, StyledSpan, StyledTh, StyledTable } from './styles';

interface ReturnsParams {
    term: string;
    skip: number;
    take: number;
    statusFilter: string | boolean;
    logisticsFilter: string | boolean;
    financialFilter: string | boolean;
}

interface InvoiceData {
    id: number;
    customerName: string;
    nf: string;
}

interface UserData {
    id: number;
    name: string;
}

interface ReturnLogsData {
    id: number;
    type: string;
    description: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    user: UserData;
}

interface ReturnsData {
    id: number;
    status: string;
    observation: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    logistics: boolean;
    financial: boolean;
    invoiceId: number;
    returnNf: string;
    invoiceStatus: string;
    invoice: InvoiceData;
    returnLogs: ReturnLogsData[];
}

interface PagesData {
    data: ReturnsData[];
    currentPage: number;
    pages: number;
}

const List: React.FC = () => {
    const { user } = useAuth();
    const [filterToggle, setFilterToggle] = useState(false);
    const [showPreReturnModal, setShowPreReturnModal] = useState({
        show: false,
        invoiceId: '',
        logistics: false,
        financial: false,
    });

    const defaultFilterValues: ReturnsFilterProps = {
        filterTerm: '',
        filterStatus: null,
        filterLogistics: null,
        filterFinancial: null,
    };

    const {
        control,
        watch,
        reset,
        formState: { isDirty },
    } = useForm<ReturnsFilterProps>({
        shouldUnregister: false,
        defaultValues: defaultFilterValues,
    });

    const filterData = watch();

    async function loadReturnsInvoices({ pageParam = 0 }): Promise<{
        data: ReturnsData[];
        currentPage: number;
        pages: number;
    }> {
        const params: ReturnsParams = {
            term: filterData.filterTerm || '',
            skip: pageParam,
            take: rowsPerPage,
            statusFilter: filterData.filterStatus
                ? filterData?.filterStatus?.value
                : '',
            logisticsFilter: filterData.filterLogistics
                ? filterData?.filterLogistics?.value
                : '',
            financialFilter: filterData.filterFinancial
                ? filterData?.filterFinancial?.value
                : '',
        };

        const response = await ReturnHttpService.index(params);

        return {
            data: response.data.data,
            currentPage: pageParam,
            pages: Math.ceil(response.data.meta.total / rowsPerPage),
        };
    }

    const returnsInvoices = useInfiniteQuery(
        [
            'returnsInvoices',
            filterData.filterTerm,
            filterData.filterStatus,
            filterData.filterLogistics,
            filterData.filterFinancial,
        ],
        loadReturnsInvoices,
        {
            getNextPageParam: (response) => {
                if (response.currentPage + 1 < response.pages) {
                    return response.currentPage + 1;
                }
                return undefined;
            },
            staleTime: 300000,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    );

    const loadMoreRef = useRef(null);

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: returnsInvoices.fetchNextPage,
        enabled: returnsInvoices.hasNextPage,
    });

    const showLoading =
        returnsInvoices.isLoading ||
        returnsInvoices.isFetchingNextPage ||
        !returnsInvoices.isFetched;

    const noData = !(
        returnsInvoices?.data?.pages &&
        returnsInvoices.data.pages.length &&
        returnsInvoices.data.pages[0].data.length
    );

    const gerarDevolucao = async (id: any) => {
        try {
            await ReturnHttpService.gerarRetorno({
                id,
                status: ReturnStatus.AwaitDisassembly,
            });

            returnsInvoices.refetch();
            toast.success('Pedido devolvido com sucesso!');
        } catch (error) {
            console.log('error gerarDevolucao -> ', error);
            toast.error('Houve um erro ao gerar devolução');
        }
    };

    const desmontarKits = async (id: any) => {
        try {
            await ReturnHttpService.desmontarKits({
                id,
                status: ReturnStatus.Returned,
            });

            returnsInvoices.refetch();
            toast.success('Kit desmontado com sucesso!');
        } catch (error) {
            console.log('error gerarDevolucao -> ', error);
            toast.error('Houve um erro ao desmontar o kit');
        }
    };

    const removeReturn = async (order: any) => {
        try {
            await ReturnHttpService.removeOrder(order.id);

            returnsInvoices.refetch();
            toast.success('Pedido removido com sucesso!');
        } catch (error) {
            toast.error('Houve um erro ao remover o pedido');
        }
    };

    return (
        <>
            <BaseLayout>
                <Row className="header align-items-center pr-2 pl-2">
                    <Col>
                        <StyledPageTitle className="mt-2">
                            Devoluções
                        </StyledPageTitle>
                        <StyledPageSubTitle>
                            Todas as informações das devoluções em um só lugar.
                        </StyledPageSubTitle>
                    </Col>
                    <Col className="text-right">
                        <ButtonGroup className="float-right">
                            {isDirty && (
                                <Button
                                    style={{
                                        color: '#2F80ED',
                                    }}
                                    className="mr-2"
                                    variant="text"
                                    onClick={() => reset()}
                                >
                                    <EraserIcon
                                        fill="#2F80ED"
                                        className="mr-2"
                                        width={18}
                                    />{' '}
                                    Limpar filtros
                                </Button>
                            )}
                            <Button
                                className="mr-1"
                                variant="light"
                                style={{
                                    backgroundColor: '#EEEEEE',
                                }}
                                onClick={() => setFilterToggle(!filterToggle)}
                            >
                                <Image src={filterIcon} />
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>

                <ReturnsFilter control={control} filterToggle={filterToggle} />

                <Row className="pl-2 pr-2">
                    <Col>
                        <StyledTable
                            bordered
                            hover
                            size="sm"
                            className="text-center"
                        >
                            <thead>
                                <tr>
                                    <StyledTh>PEDIDO</StyledTh>
                                    <StyledTh>CLIENTE</StyledTh>
                                    <StyledTh>VALOR</StyledTh>
                                    <StyledTh>OBS.</StyledTh>
                                    <StyledTh>NOTA</StyledTh>
                                    <StyledTh>NOTA DEVOLUÇÃO</StyledTh>
                                    <StyledTh>STATUS</StyledTh>
                                    <StyledTh>LIBERAÇÃO LOGISTICA</StyledTh>
                                    <StyledTh>LIBERAÇÃO FINANCEIRO</StyledTh>
                                    <StyledTh />
                                </tr>
                            </thead>
                            <tbody>
                                {returnsInvoices.data?.pages &&
                                    returnsInvoices.data?.pages?.map(
                                        (page: PagesData) =>
                                            page?.data.map(
                                                (item: ReturnsData) => {
                                                    const logsLogistica =
                                                        item.returnLogs.filter(
                                                            (a: any) =>
                                                                a.type === 'L',
                                                        )[0];
                                                    const logsFinanceiro =
                                                        item.returnLogs.filter(
                                                            (a: any) =>
                                                                a.type === 'F',
                                                        )[0];

                                                    return (
                                                        <tr key={item.id}>
                                                            <td>
                                                                <Highlighter
                                                                    autoEscape
                                                                    highlightClassName="highlight-term"
                                                                    searchWords={[
                                                                        filterData.filterTerm,
                                                                    ]}
                                                                    textToHighlight={item.invoice.id.toString()}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Highlighter
                                                                    autoEscape
                                                                    highlightClassName="highlight-term"
                                                                    searchWords={[
                                                                        filterData.filterTerm,
                                                                    ]}
                                                                    textToHighlight={
                                                                        item
                                                                            .invoice
                                                                            .customerName
                                                                    }
                                                                />
                                                            </td>
                                                            <td>
                                                                {formatCurrency(
                                                                    getTotalInvoice(
                                                                        item.invoice,
                                                                    ),
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Highlighter
                                                                    autoEscape
                                                                    highlightClassName="highlight-term"
                                                                    searchWords={[
                                                                        filterData.filterTerm,
                                                                    ]}
                                                                    textToHighlight={
                                                                        item.observation
                                                                    }
                                                                />
                                                            </td>
                                                            <td>
                                                                <Highlighter
                                                                    autoEscape
                                                                    highlightClassName="highlight-term"
                                                                    searchWords={[
                                                                        filterData.filterTerm,
                                                                    ]}
                                                                    textToHighlight={
                                                                        item
                                                                            .invoice
                                                                            .nf
                                                                    }
                                                                />
                                                            </td>
                                                            <td>
                                                                <Highlighter
                                                                    autoEscape
                                                                    highlightClassName="highlight-term"
                                                                    searchWords={[
                                                                        filterData.filterTerm,
                                                                    ]}
                                                                    textToHighlight={
                                                                        item?.returnNf ||
                                                                        ''
                                                                    }
                                                                />
                                                            </td>
                                                            <td>
                                                                <h5>
                                                                    <StyledBadge
                                                                        status={
                                                                            item.status
                                                                        }
                                                                    >
                                                                        {getReturnStatusText(
                                                                            item.status,
                                                                        )}
                                                                    </StyledBadge>
                                                                </h5>
                                                            </td>

                                                            <td>
                                                                {!item.logistics ? (
                                                                    userHasRoles(
                                                                        user,
                                                                        [
                                                                            Role.Administrator,
                                                                            Role.Logistics,
                                                                        ],
                                                                    ) && (
                                                                        <ReactTruckIcon
                                                                            fill="#f2994a"
                                                                            width="25"
                                                                            height="25"
                                                                            onClick={() => {
                                                                                setShowPreReturnModal(
                                                                                    {
                                                                                        show: true,
                                                                                        invoiceId:
                                                                                            item?.id?.toString(),
                                                                                        logistics:
                                                                                            true,
                                                                                        financial:
                                                                                            false,
                                                                                    },
                                                                                );
                                                                            }}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <OverlayTrigger
                                                                        key="top"
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id="tooltip-top">
                                                                                {`${
                                                                                    logsLogistica
                                                                                        ?.user
                                                                                        ?.name
                                                                                }: ${
                                                                                    logsLogistica?.description
                                                                                        ? logsLogistica?.description
                                                                                        : 'Sem Observações'
                                                                                }`}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <ReactCheckIcon
                                                                            fill="#00AA00"
                                                                            className="ml-2"
                                                                            width="30"
                                                                            height="25"
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        />
                                                                    </OverlayTrigger>
                                                                )}
                                                            </td>

                                                            <td>
                                                                {!item.financial ? (
                                                                    userHasRoles(
                                                                        user,
                                                                        [
                                                                            Role.Administrator,
                                                                            Role.Financial,
                                                                        ],
                                                                    ) && (
                                                                        <ReactTruckIcon
                                                                            fill="#f2994a"
                                                                            width="25"
                                                                            height="25"
                                                                            onClick={() => {
                                                                                setShowPreReturnModal(
                                                                                    {
                                                                                        show: true,
                                                                                        invoiceId:
                                                                                            item?.id?.toString(),
                                                                                        logistics:
                                                                                            false,
                                                                                        financial:
                                                                                            true,
                                                                                    },
                                                                                );
                                                                            }}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <OverlayTrigger
                                                                        key="top"
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id="tooltip-top">
                                                                                {`${
                                                                                    logsFinanceiro
                                                                                        ?.user
                                                                                        ?.name
                                                                                }: ${
                                                                                    logsFinanceiro?.description
                                                                                        ? logsFinanceiro?.description
                                                                                        : 'Sem Observações'
                                                                                }`}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <ReactCheckIcon
                                                                            fill="#00AA00"
                                                                            className="ml-2"
                                                                            width="30"
                                                                            height="25"
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        />
                                                                    </OverlayTrigger>
                                                                )}
                                                            </td>

                                                            <td>
                                                                <Dropdown key="left">
                                                                    <Dropdown.Toggle
                                                                        bsPrefix="nexen"
                                                                        as={
                                                                            Button
                                                                        }
                                                                        variant="text"
                                                                    >
                                                                        <ReactMoreIcon
                                                                            fill="#bdbdbd"
                                                                            width="10"
                                                                            height="20"
                                                                        />
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        {userHasRoles(
                                                                            user,
                                                                            [
                                                                                Role.Administrator,
                                                                                Role.Production,
                                                                            ],
                                                                        ) &&
                                                                            item.status ===
                                                                                ReturnStatus.FreeToReturn && (
                                                                                <Dropdown.Item
                                                                                    onClick={async () => {
                                                                                        gerarDevolucao(
                                                                                            item.id,
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Gerar
                                                                                    devolução
                                                                                </Dropdown.Item>
                                                                            )}

                                                                        {userHasRoles(
                                                                            user,
                                                                            [
                                                                                Role.Administrator,
                                                                                Role.Production,
                                                                            ],
                                                                        ) &&
                                                                            item.status ===
                                                                                ReturnStatus.AwaitDisassembly && (
                                                                                <Dropdown.Item
                                                                                    onClick={async () => {
                                                                                        desmontarKits(
                                                                                            item.id,
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Desmontar
                                                                                    kits
                                                                                </Dropdown.Item>
                                                                            )}

                                                                        {(item.status ===
                                                                            ReturnStatus.WaitingApproval ||
                                                                            item.status ===
                                                                                ReturnStatus.FreeToReturn) && (
                                                                            <Dropdown.Item
                                                                                onClick={async () =>
                                                                                    removeReturn(
                                                                                        item,
                                                                                    )
                                                                                }
                                                                            >
                                                                                Remover
                                                                                do
                                                                                processo
                                                                                de
                                                                                devolução
                                                                            </Dropdown.Item>
                                                                        )}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            ),
                                    )}
                            </tbody>
                        </StyledTable>
                    </Col>

                    <PreReturnModal
                        data={showPreReturnModal}
                        onHide={() => {
                            loadReturnsInvoices({ pageParam: 0 });

                            setShowPreReturnModal({
                                show: false,
                                invoiceId: '',
                                logistics: false,
                                financial: false,
                            });
                            returnsInvoices.refetch();
                        }}
                    />

                    <Col md={12} className="text-center" ref={loadMoreRef}>
                        {showLoading && (
                            <Col md={12} className="text-center">
                                <Spinner animation="border" />
                            </Col>
                        )}
                        {!showLoading && noData && (
                            <Col md={12} className="text-center">
                                <StyledSpan>Sem itens para carregar</StyledSpan>
                            </Col>
                        )}
                    </Col>
                </Row>
            </BaseLayout>
        </>
    );
};

export default List;
