import Request from './request';

export default class ProductHttpService {
    private static uri = 'erp/products';

    public static index(params: {}) {
        return Request.get(this.uri, params);
    }

    public static show(params: any) {
        return Request.get(
            `${this.uri}/${params.sellerId}/${params.productId}/${params.type}`,
        );
    }

    public static get(data: any) {
        return Request.get(this.uri, data);
    }

    public static findAll(params: {}) {
        return Request.get(`${this.uri}/all`, params);
    }
}
