import { Form } from 'react-bootstrap';
import { ChangeEvent } from 'react';
import { Discount } from '../../../../shared/interfaces/discount.interface';

interface PaymentMethodListItemProps {
    method: string;
    installment: number;
    reference: string;
    value?: number;
    onChange: (paymentKey: string, discount: Discount) => void;
}

export default function DiscountsListAccordionItem({
    method,
    installment,
    reference,
    value,
    onChange,
}: PaymentMethodListItemProps) {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newPercentage = Math.max(
            0,
            Math.min(100, parseFloat(e.target.value)),
        );

        const discountDto: Discount = {
            value: newPercentage,
            method: reference,
            installment,
        };

        const paymentKey = `${reference}-${installment}`;
        onChange(paymentKey, discountDto);
    };

    return (
        <Form.Group controlId={`formPaymentMethod-${reference}-${installment}`}>
            <Form.Label>{`${method} (${installment}x)`}</Form.Label>
            <Form.Control
                type="number"
                value={value || ''}
                onChange={handleChange}
                placeholder="Informe a % de desconto máximo..."
            />
        </Form.Group>
    );
}
