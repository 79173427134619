import React from 'react';

const ShippingPolicies: React.FC = () => (
    <div>
        <b style={{ color: '#707070' }}>Políticas de Frete e Entrega</b>
        <p>
            <span>
                <span>
                    <span>
                        <span>
                            A nexen.com.br utiliza diversos serviços para a
                            entrega de seus produtos, a fim de oferecer as
                            melhores condições de entrega aos nossos clientes,
                            visando sempre o menor preço de frete e melhor
                            serviço. Trabalhamos com frota própria e empresas de
                            transporte terceirizadas, as quais foram
                            criteriosamente selecionadas e treinadas para
                            atender nossos clientes com eficiência, respeito e
                            muito carinho, pois carregam muito mais que
                            produtos, transportam e entregam sonhos.
                        </span>
                        <br />
                        <span>
                            Nosso site opera com a seguinte modalidade de frete:
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        <br />
                        <span>• ENTREGA NO ENDEREÇO:</span>
                        <br />
                        <span>
                            Serviço de transporte pago pelo cliente, com entrega
                            do produto no endereço de desejo, informado no ato
                            da compra.
                        </span>
                        <br />
                        <br />
                        <span>
                            <span>
                                <br />
                                <b>PRAZO DE ENTREGA:</b>
                            </span>
                        </span>
                        <br />
                        <span>
                            Informamos que o prazo indicado nos pedidos trata-se
                            de mera previsão, sendo que o referido prazo pode
                            sofrer alterações em razão de circunstâncias
                            adversas e alheias à vontade da Nexen, como: roubo
                            ou furto de cargas, extravio de mercadorias pela
                            transportadora, avarias causadas acidentalmente,
                            problemas na fabricação de produtos pelo fornecedor,
                            problemas na expedição do produto, condições
                            climáticas adversas, etc. Em virtude dessa condição,
                            nossa empresa não se responsabiliza por
                        </span>
                        <br />
                        <span>
                            obrigações assumidas pelo cliente e prejuízos
                            decorrentes, como: impossibilidade de inauguração,
                            inviabilidade de recebimento devido a reforma ou
                            viagem, datas comemorativas, despesas com mão de
                            obra e demais obrigações assumidas com terceiros,
                            etc.
                        </span>
                        <br />
                        <br />
                        <span>
                            O prazo de entrega é contado a partir da confirmação
                            do pagamento em dias úteis, ou seja, não inclui
                            sábados, domingos e feriados.
                        </span>
                        <br />
                        <span>
                            O tempo de entrega pode variar de acordo com a
                            região do destinatário e o sistema logístico
                            compreendido para o produto selecionado.
                        </span>
                        <br />
                        <span>
                            Esse prazo é informado desde a simulação em campo
                            específico, na página do produto. Ele também é
                            devidamente informado durante todo o processo de
                            compra, antes mesmo da confirmação do pedido e em
                            todos os e-mails referente à compra.
                        </span>
                        <br />
                        <br />
                        <span>*VALOR DO FRETE:</span>
                        <br />
                        <span>
                            A nexen.com.br não visa lucro sobre o valor do
                            frete.
                        </span>
                        <br />
                        <span>
                            O sistema buscará automaticamente todos as
                            modalidades de frete para o endereço informado,
                            assim como informará o valor de cada, ficando a
                            critério do cliente a escolha entre os mesmos.
                        </span>
                        <br />
                        <br />
                        <br />
                        <b>
                            <span>
                                <span>INFORMAÇÕES IMPORTANTES:</span>
                            </span>
                        </b>
                        <br />
                        <br />
                        <span>• </span>
                        <b>ENTREGA NO ENDEREÇO:</b>
                        <br />
                        <br />
                        <span>
                            • Todas as encomendas a serem entregues estarão em
                            caixas lacradas. Caso constatar que a embalagem foi
                            violada, deverá analisar a mercadoria antes de
                            liberar o entregador, evitando assim posteriores
                            transtornos.
                        </span>
                        <br />
                        <br />
                        <span>
                            • A nexen.com.br apenas entrega os produtos até o
                            endereço indicado pelo cliente, conforme descrito no
                            pedido e nota fiscal. Os entregadores não estão
                            autorizados a instalar, montar, desmontar, mover,
                            ligar, testar ou realizar qualquer tarefa que
                            implique em manuseio dos produtos entregues, sob
                            qualquer hipótese.
                        </span>
                        <br />
                        <span>
                            • As dimensões do produto devem ser conferidas antes
                            da conclusão da compra, garantindo que estão
                            adequadas aos elevadores, portas e corredores do
                            local da entrega, pois não realizamos montagem,
                            desmontagem do produto e/ou portas e janelas,
                            transporte por escadas ou içamento pelo lado de fora
                            do prédio ou casa.
                        </span>
                        <br />
                        <span>
                            • As entregas são realizadas somente em dias úteis,
                            durante o horário comercial, das 8h às 18h.
                        </span>
                        <br />
                        <span>
                            • Entregas serão realizadas exclusivamente no
                            endereço da nota fiscal.
                        </span>
                        <br />
                        <br />
                        <span>
                            • Caso a entrega não seja concluída por ausência de
                            responsável no local, haverá 02 (duas) tentativas
                            posteriores. É indispensável que, no endereço
                            solicitado, esteja presente o titular da compra ou
                            tenha uma pessoa autorizada, maior de 18 anos, e
                            portando documento de identificação para receber a
                            mercadoria. Contudo, se mesmo assim todas as
                            tentativas de entrega resultarem em insucesso, o
                            produto retornará ao Centro de Distribuição da
                            nexen.com.br e a compra será automaticamente
                        </span>
                        <br />
                        <span>cancelada.</span>
                        <br />
                        <br />
                        <b>ATENÇÃO:</b>
                        <br />
                        <br />
                        <span>
                            • Após a finalização da compra não é possível
                            alterar a forma de entrega, solicitar adiantamento
                            ou prioridade.
                        </span>
                        <br />
                        <span>
                            • Caso houver a necessidade de troca de endereço de
                            entrega, a alteração poderá ser realizada somente se
                            a nota fiscal não tiver sido emitida. Para alterar,
                            basta entrar em contato com a Central de Atenção ao
                            Cliente e formalizar sua solicitação. Informamos que
                            será realizada análise prévia do custo do frete para
                            o novo endereço, podendo sofrer alteração para mais
                            ou para menos, com reembolso ou pagamento orientado
                            pelos nossos atendentes.
                        </span>
                        <br />
                        <br />
                        <span>
                            • Se identificar erro no preenchimento do endereço
                            após conclusão da compra, informar imediatamente a
                            correção para nossa Central. É fundamental que o
                            endereço esteja completo e correto.
                        </span>
                        <br />
                        <span>
                            • Por medida de segurança, em nenhuma hipótese,
                            nossos atendentes estão autorizados a alterar o
                            endereço de entrega ou os dados cadastrais dos
                            clientes, sem solicitação formalizada pelo titular
                            da compra.
                        </span>
                        <br />
                        <br />
                        <span>
                            • A cada atualização no status do pedido nosso
                            sistema envia automaticamente e-mails de alerta. Por
                            isso, é MUITO IMPORTANTE manter seu e-mail
                            atualizado no cadastro.
                        </span>
                        <br />
                        <br />
                        <span>
                            • Fatores naturais e adversos, como chuvas fortes,
                            acidentes, greves ou eventos oficiais na região onde
                            o produto deverá ser entregue podem prejudicar ou
                            impossibilitar a chegada do transportador até o
                            endereço. Nesse caso, após a normalização da
                            situação, o produto será entregue.
                        </span>
                        <br />
                        <br />
                        <br />
                        <span>
                            • O prazo de entrega poderá sofrer alteração, por
                            situação adversa, a qual será previamente comunicada
                            através de e-mail. O cliente poderá optar por
                            aguardar o novo prazo ou cancelar a compra, caso não
                            corresponder a necessidade.
                        </span>
                        <br />
                        <br />
                        <br />
                        <span>
                            • Caso desejar o cancelamento da compra durante o
                            período de entrega, é importante comunicar a nossa
                            Central no menor tempo possível. Efetuaremos a
                            interceptação da mercadoria, a qual não garante que
                            a transportadora não tente efetuar a entrega. Dessa
                            forma, é fundamental recusar a mesma, informando ao
                            entregador e registrando uma observação de
                            cancelamento no verso da nota fiscal.
                        </span>
                        <br />
                        <br />
                        <br />
                        <span>
                            • A nexen.com.br não realiza agendamento de
                            entregas. Caso houver a necessidade, o agendamento
                            deverá ocorrer diretamente com a transportadora
                            responsável.
                        </span>
                        <br />
                        <br />
                        <br />
                        <span>
                            • Orientamos que as conferências dos produtos
                            entregues sejam realizadas no ato, sendo que ao
                            identificar qualquer irregularidade, poderá recusar
                            a entrega. A recusa quando há inconformidades, é de
                            fundamental importância, pois a agiliza a solução do
                            problema ocorrido. A recusa deve ocorrer, nos
                            seguintes casos:
                        </span>
                        <br />
                        <br />
                        <span>- Embalagem violada;</span>
                        <br />
                        <br />
                        <span>- Embalagem e produto avariado;</span>
                        <br />
                        <br />
                        <span>- Produto em desacordo com a compra;</span>
                        <br />
                        <br />
                        <span>- Arrependimento ou desistência.</span>
                        <br />
                        <br />
                        <span>• É vedado à transportadora:</span>
                        <br />
                        <br />
                        <span>- Entrar no domicílio;</span>
                        <br />
                        <br />
                        <span>
                            - Entregar por meios alternativos (escada e
                            içamento);
                        </span>
                        <br />
                        <br />
                        <span>
                            - Realizar instalação ou manutenção de produtos;
                        </span>
                        <br />
                        <br />
                        <span>- Abrir a embalagem do produto;</span>
                        <br />
                        <br />
                        <span>
                            - Coletar produtos de devolução sem autorização da
                            Nexen;
                        </span>
                        <br />
                        <br />
                        <span>
                            - Realizar entrega em endereço diferente ao que
                            consta na nota fiscal;
                        </span>
                        <br />
                        <br />
                        <span>
                            - Realizar entrega a menores de idade ou sem
                            documento de identificação.
                        </span>
                    </span>
                </span>
            </span>
        </p>
    </div>
);

export default ShippingPolicies;
