import { Col, Form, InputGroup, Row, Card } from 'react-bootstrap';
import Select from 'react-select';
import { Control, Controller } from 'react-hook-form';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../../styles/react-select-config';
import {
    clearanceOptions,
    statusFilterOptions,
} from '../../../../constants/returnsFilter';

interface OptionProps {
    label: string;
    value: string | boolean;
}

export interface ReturnsFilterProps {
    filterTerm: string;
    filterStatus: OptionProps | null;
    filterLogistics: OptionProps | null;
    filterFinancial: OptionProps | null;
}

interface Props {
    control: Control<ReturnsFilterProps>;
    filterToggle: boolean;
}

const ReturnsFilter = (props: Props) => {
    const { control, filterToggle } = props;

    return (
        <>
            <Row>
                <Col>
                    {filterToggle && (
                        <Card className="mt-4 mx-2">
                            <Card.Body>
                                <Card.Title>
                                    <strong>Filtros</strong>
                                </Card.Title>

                                <Row>
                                    <Col md={4}>
                                        <Form.Group controlId="formBasicReturn">
                                            <Form.Label>Status</Form.Label>
                                            <Controller
                                                isClearable
                                                as={<Select />}
                                                control={control}
                                                name="filterStatus"
                                                classname="form-control-nexen"
                                                options={statusFilterOptions}
                                                theme={theme}
                                                styles={{
                                                    control: tradeControl,
                                                    option: optionsTheme,
                                                }}
                                                placeholder="Selecione..."
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="formBasicReturn">
                                            <Form.Label>
                                                Liberação Logística
                                            </Form.Label>
                                            <Controller
                                                isClearable
                                                as={<Select />}
                                                control={control}
                                                classname="form-control-nexen"
                                                name="filterLogistics"
                                                options={clearanceOptions}
                                                theme={theme}
                                                styles={{
                                                    control: tradeControl,
                                                    option: optionsTheme,
                                                }}
                                                placeholder="Selecione..."
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="formBasicReturn">
                                            <Form.Label>
                                                Liberação Financeiro
                                            </Form.Label>
                                            <Controller
                                                isClearable
                                                as={<Select />}
                                                control={control}
                                                name="filterFinancial"
                                                classname="form-control-nexen"
                                                options={clearanceOptions}
                                                theme={theme}
                                                styles={{
                                                    control: tradeControl,
                                                    option: optionsTheme,
                                                }}
                                                placeholder="Selecione..."
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    )}
                </Col>
            </Row>

            <Row className="pl-2 pr-2 mt-4">
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-search" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Controller
                            as={
                                <Form.Control placeholder="Digite aqui o que procura..." />
                            }
                            type="text"
                            name="filterTerm"
                            control={control}
                        />
                    </InputGroup>
                </Col>
            </Row>
        </>
    );
};

export { ReturnsFilter };
