import Request from './request';

export default class RegionHttpService {
    private static uri = 'regions';

    public static readMany(params: { term?: string }) {
        return Request.get(this.uri, params);
    }

    public static readOne(id: string) {
        return Request.get(`${this.uri}/${id}`);
    }

    public static create(data: { name: string; states: string }) {
        return Request.post(this.uri, data);
    }

    public static update(id: string, data: { name: string; states: string }) {
        return Request.patch(`${this.uri}/${id}`, data);
    }

    public static async delete(id: number) {
        return Request.del(`${this.uri}/${id}`);
    }
}
