import { Nav, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledNavLink = styled(Nav.Link)`
    color: #f2994a;
    :active {
        color: #f2994a !important;
    }
    :focus {
        color: #f2994a !important;
    }
    :hover {
        color: #f2994a !important;
    }
`;

export const StyledRow = styled(Row)`
    height: 50vh;
    overflow-y: scroll;
`;
