import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import BaseLayout from '../../components/BaseLayout';
import RegionHttpService from '../../services/http/region-http';
import { regionListRoute } from '../../routes/config';
import history from '../../services/history';
import { theme, control } from '../../styles/react-select-config';
import { regionRules } from '../../validations/region';
import getValidationsErrors from '../../utils/getValidationsErrors';
import { StyledH4, StyledLink } from './styles';
import StateService from '../../services/state';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import Select, { SelectOption } from '../../components/Select';
import Region from '../../shared/interfaces/region.interface';

interface Error {
    name?: string;
    states?: string;
}

interface RegionFields {
    id?: string;
    name: string;
    states: string[];
}

interface Payload {
    name: string;
    states: string;
}

interface GetRegionResponse {
    data: Region;
}

// eslint-disable-next-line import/prefer-default-export
export function New() {
    const { id } = useParams<{ id: string }>();
    const formRef: any = useRef<FormHandles>(null);
    const [errors, setErrors] = useState<Error>({
        name: '',
        states: '',
    });
    const [stateOptions, setStateOptions] = useState<
        Array<SelectOption<string>>
    >([]);
    const [region, setRegion] = useState<RegionFields>({
        name: '',
        states: [],
    });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const loadStateOptions = async () => {
            const results = await StateService.get();
            const mappedStates: Array<SelectOption<string>> = results.map(
                (item: any) => ({
                    value: item.code,
                    label: item.name,
                }),
            );

            setStateOptions(mappedStates);
        };

        loadStateOptions();
    }, []);

    useEffect(() => {
        async function loadRegion(): Promise<void> {
            if (!id) {
                return;
            }
            const response = await RegionHttpService.readOne(id);
            const { data }: GetRegionResponse = response;

            setRegion({
                id: data.id.toString(),
                name: data.name,
                states: data.states.split(','),
            });
        }
        loadRegion();
    }, [id]);

    const handleNameChange = (event: any) => {
        setRegion({
            ...region,
            name: event.target.value,
        });
        setErrors({
            ...errors,
            name: undefined,
        });
    };

    const handleStateChanges = (
        newStates: Array<SelectOption<string>> | null,
    ) => {
        setRegion({
            ...region,
            states: newStates?.map((state) => state.value) || [],
        });
    };

    const handleSubmit = async (event: any): Promise<void> => {
        event?.preventDefault();

        try {
            const schema = Yup.object().shape(regionRules);

            await schema.validate(region, { abortEarly: false });

            setLoading(true);

            try {
                const data: Payload = {
                    name: region.name,
                    states: region.states.join(','),
                };

                let response = null;

                if (region.id) {
                    response = await RegionHttpService.update(region.id, data);
                } else {
                    response = await RegionHttpService.create(data);
                }

                if (response) {
                    history.push(regionListRoute.path);
                    toast.success('Região salva com sucesso!');
                }
            } catch (error: any) {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);
                }

                toast.error('Erro ao salvar dados da região!');
            } finally {
                setLoading(false);
            }
        } catch (error) {
            console.log(getValidationsErrors(error));
            setErrors(getValidationsErrors(error));
        }
    };

    const buttonText = id
        ? 'Alterar informações da região'
        : 'Cadastrar região';

    const statesSelectValue = region.states.map((state) => ({
        value: state,
        label:
            stateOptions.find((option) => option.value === state)?.label || '',
    }));

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">Regiões</StyledPageTitle>
                    <StyledPageSubTitle>
                        Informações sobre as Regiões
                    </StyledPageSubTitle>
                </Col>
                <Col className="text-right">
                    <Button
                        className="float-right"
                        disabled={loading}
                        onClick={handleSubmit}
                    >
                        {loading && <Spinner animation="border" size="sm" />}
                        {buttonText}
                    </Button>
                </Col>
            </Row>
            <Row className="pl-2 pr-2 mt-4">
                <Col>
                    <StyledH4>
                        <StyledLink to={regionListRoute.path}>
                            <i className="fas fa-chevron-left mr-2" />
                            {region.name || 'Cadastro'}
                        </StyledLink>
                    </StyledH4>
                    <p>Informações sobre a região</p>
                </Col>
            </Row>
            <Row className="pl-2 pr-2">
                <Col>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                isInvalid={Boolean(errors.name)}
                                onChange={handleNameChange}
                                value={region.name}
                                name="name"
                                type="text"
                                placeholder="Informe o nome"
                                maxLength={255}
                            />
                            {errors.name && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group controlId="formBasicStates">
                            <Form.Label>Estados</Form.Label>
                            <Select
                                isInvalid={Boolean(errors.states)}
                                onChange={handleStateChanges}
                                value={statesSelectValue}
                                styles={{ control }}
                                options={stateOptions}
                                isMulti
                                name="states"
                                theme={theme}
                            />
                            {errors.states && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.states}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </BaseLayout>
    );
}
