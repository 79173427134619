import React from 'react';
import { Switch } from 'react-router-dom';
import { List } from '../../pages/Dashboard';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';

export const dashboardDefaultRoute = new Route('/');
export const dashboardListRoute = new Route('/dashboard');

export const DashboardRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={dashboardDefaultRoute.path}
            component={List}
            exact
            isPrivate
            redirectTo={dashboardListRoute.path}
        />
        <RouteWrapper
            exact
            path={dashboardListRoute.path}
            component={List}
            isPrivate
            redirectIfNotExact
        />
    </Switch>
);
