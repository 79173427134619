import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledCancelButton = styled(Button)`
    width: 200px;
    background-color: #ffffff;
    border: 1px solid solid #6fcf97;
    color: #6fcf97 !important;
    :hover {
        background-color: ${({ disabled }) => !disabled && '#6fcf97'};
        color: ${({ disabled }) => !disabled && '#ffffff !important'};
    }
`;

export const StyledFooterDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 9px;
    margin-bottom: 10px;
`;

export const StyledButtonDiv = styled.div`
    display: flex;
    justify-content: flex-end;

    * {
        margin-left: 15px;
    }
`;
