/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Col, Form, Nav, Row, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import BaseLayout from '../../components/BaseLayout';
import GeneralData from '../../components/Profile/GeneralData';
import SecurityData from '../../components/Profile/SecurityData';
import CompanyData from '../../components/Profile/CompanyData';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import { AuthValues, useAuth } from '../../contexts/authContext';
import AddressData from '../../components/Profile/AddressData';
import userHasRoles from '../../utils/userHasRoles';
import Role from '../../constants/roles';
import { StyledNav } from '../../styles/nav';
import { StyledPageTitle, StyledPageSubTitle } from '../../styles/pageTitle';
import AuthHttpService from '../../services/http/auth-http';

const Profile: React.FC = () => {
    const pathname = window.location.pathname.split('/')[2];

    const [tab, setTab] = useState(pathname);
    const [loading, setLoading] = useState(false);

    const { user, setUser }: AuthValues = useAuth();

    const isIntegrator = userHasRoles(user, [Role.Integrator]);

    user.password = '';

    const history = useHistory();
    const methods = useForm({
        defaultValues: user,
    });

    const handleTab = (eventKey: any) => {
        setTab(eventKey);

        history.push(eventKey);
    };

    const onSubmit = async (data: any) => {
        // eslint-disable-next-line no-param-reassign
        data.id = parseInt(data.id, 10);
        // eslint-disable-next-line no-param-reassign
        data.notificationMethods = data.notificationMethods?.map(
            (item: any) => item.value,
        );

        try {
            setLoading(true);

            const response = await AuthHttpService.updateUser(data);

            if (response) {
                toast.success('Usuário salvo com sucesso!');

                const newUser = {
                    name: response.data.name,
                    email: response.data.email,
                    password: '',
                    passqword_confirmation: '',
                    notificationMethods: data.notificationMethods,
                };

                setUser({ ...user, ...newUser });
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);

            const error = err as any;

            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Erro ao salvar dados do usuário!');
        }
    };

    useEffect(() => {
        setTab(pathname);
    }, [pathname]);

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">
                        Configurações de usuário
                    </StyledPageTitle>
                    <StyledPageSubTitle>
                        Matenha seus dados sempre atualizados.
                    </StyledPageSubTitle>
                </Col>
                {tab === 'usuario' && (
                    <Col className="text-right">
                        <Button
                            style={{ width: '150px' }}
                            onClick={methods.handleSubmit(onSubmit)}
                        >
                            {loading ? (
                                <Spinner animation="border" />
                            ) : (
                                'Salvar alterações'
                            )}
                        </Button>
                    </Col>
                )}
            </Row>
            <Row className="mt-4 pl-2 pr-2">
                <Col>
                    <StyledNav
                        variant="tabs"
                        activeKey={tab}
                        onSelect={(selectedKey: any) => handleTab(selectedKey)}
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="usuario">Meu perfil</Nav.Link>
                        </Nav.Item>
                        {isIntegrator && (
                            <Nav.Item>
                                <Nav.Link eventKey="empresa">
                                    Minha empresa
                                </Nav.Link>
                            </Nav.Item>
                        )}
                    </StyledNav>
                </Col>
            </Row>

            {
                // eslint-disable-next-line react/jsx-props-no-spreading
                <FormProvider {...methods}>
                    <Form className="mx-2">
                        {tab === 'usuario' && (
                            <>
                                <Row className="mt-4">
                                    <Col>
                                        <GeneralData />
                                    </Col>
                                </Row>
                                <Row className="mt-4 ">
                                    <Col>
                                        <SecurityData />
                                    </Col>
                                </Row>
                            </>
                        )}

                        {isIntegrator && tab === 'empresa' && (
                            <>
                                <Row className="mt-4">
                                    <Col>
                                        <CompanyData user={user || {}} />
                                    </Col>
                                </Row>

                                <Row className="mt-4">
                                    <Col>
                                        <AddressData user={user || {}} />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form>
                </FormProvider>
            }
        </BaseLayout>
    );
};

// eslint-disable-next-line import/prefer-default-export
export { Profile };
