/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { Col, Modal, Row, Spinner, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import { Branch } from '../../../../constants/configuration';
import StateService from '../../../../services/state';
import {
    StyledConfirmButton,
    StyledOutlineButton,
} from '../../../../styles/button';
import {
    optionsTheme,
    theme,
    multiControl as tradeControl,
} from '../../../../styles/react-select-config';
import { validateCNPJ } from '../../../../validations/integrator';
import { cnpjMask } from '../../../../utils/masks';
import BranchHttpService from '../../../../services/http/branch-http';
import formatMask from '../../../../utils/fotmatMask';

interface Props {
    data?: Branch;
    show?: boolean | undefined;
    edit?: boolean;
    onSave(data: any): void;
    onHide(): void;
}

interface ErpStates {
    value: string;
    label: string;
}

export interface RequestParams {
    id?: number;
    cnpj: string;
    name: string;
    companyCode: string;
    branchCOde: string;
    states: string;
}

function BranchModal(props: Props) {
    const [loading, setLoading] = useState(false);
    const [states, setStates] = useState<ErpStates[]>([]);

    const loadErpStates = async () => {
        const results = await StateService.get();

        setStates(
            results
                .filter((r) => !props.data?.states?.split(',').includes(r.code))
                .map((item: any) => ({
                    value: item.code,
                    label: item.name,
                })),
        );
    };

    const { control, handleSubmit, setValue, errors } = useForm({
        defaultValues: {
            name: '',
            cnpj: '',
            companyCode: '',
            branchCode: '',
            states: '',
            ...props.data,
        },
    });

    // eslint-disable-next-line consistent-return
    const checkUniqueCnpj: any = async (cnpj: string) => {
        if (!cnpj) {
            return true;
        }

        try {
            const response = await BranchHttpService.readMany({
                cnpj: cnpj.replace(/\D/g, ''),
            });

            if (!response.data.results?.length) {
                return true;
            }
        } catch (error) {
            console.error(error);

            return false;
        }
    };

    async function onSubmit(params: any) {
        if (loading) {
            return;
        }

        setLoading(true);

        // eslint-disable-next-line no-param-reassign
        params = {
            ...params,
            id: props.data?.id || null,
            states: Array.isArray(params.states)
                ? params.states.map((item: any) => item.value).join(',')
                : null,
            cnpj: params.cnpj && params.cnpj.replace(/\D/g, ''),
        };

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleSubmitRequest(params);
    }

    async function handleSubmitRequest(params: RequestParams): Promise<void> {
        try {
            if (props.data?.id) {
                const { data } = await BranchHttpService.update(
                    props.data.id,
                    params,
                );

                toast.success('Alterações feita com sucesso');
                props.onSave(data);
            } else {
                const { data } = await BranchHttpService.create(params);

                toast.success('Filial cadastrada com sucesso');
                props.onSave(data);
            }
        } catch (err: any) {
            toast.error(err.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadErpStates();
    }, []);

    async function loadDefaultData() {
        const results = await StateService.get();

        setValue('name', props.data?.name || '');
        setValue(
            'cnpj',
            props.data?.cnpj
                ? formatMask(props.data?.cnpj, '##.###.###/####-##')
                : '',
        );
        setValue('companyCode', props.data?.companyCode || '');
        setValue('branchCode', props.data?.branchCode || '');
        setValue(
            'states',
            props.data?.states?.split(',').map((state) => {
                const stateFound = results.find((r) => r.code === state);

                return {
                    label: stateFound?.name,
                    value: state,
                };
            }),
        );
    }

    useEffect(() => {
        loadDefaultData();
    }, [props.data]);

    return (
        <Modal
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton onHide={props.onHide}>
                    <Modal.Title>
                        {props.data?.id ? 'Editar' : 'Nova'} filial
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="mb-2">
                    <Row>
                        <Col md="6">
                            <Controller
                                rules={{
                                    required: 'Nome da filial necessária',
                                }}
                                control={control}
                                name="name"
                                render={({ onChange, value }) => (
                                    <>
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={value}
                                            onChange={onChange}
                                        />
                                    </>
                                )}
                            />
                            {errors.name?.message && (
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                >
                                    {errors.name?.message}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                        <Col>
                            {!props.edit ? (
                                <>
                                    <Controller
                                        rules={{
                                            required:
                                                'Cnpj da filial necessária',
                                            validate: {
                                                invalid: (value) =>
                                                    validateCNPJ(value) ||
                                                    'CNPJ Inválido!',
                                                unique: async (value) =>
                                                    (await checkUniqueCnpj(
                                                        value,
                                                    )) || 'CNPJ Já cadastrado!',
                                            },
                                        }}
                                        control={control}
                                        name="cnpj"
                                        render={({ onChange, value }) => (
                                            <>
                                                <Form.Label>CNPJ</Form.Label>
                                                <MaskedInput
                                                    className="form-control"
                                                    mask={cnpjMask}
                                                    placeholder="Digite aqui..."
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                    {errors.cnpj?.message && (
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className="d-block"
                                        >
                                            {errors.cnpj?.message}
                                        </Form.Control.Feedback>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Controller
                                        control={control}
                                        name="cnpj"
                                        render={({ value }) => (
                                            <>
                                                <Form.Label>CNPJ</Form.Label>
                                                <MaskedInput
                                                    className="form-control"
                                                    mask={cnpjMask}
                                                    value={value}
                                                    readOnly
                                                />
                                            </>
                                        )}
                                    />
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 15 }}>
                        <Col md="6">
                            <Controller
                                rules={{
                                    required: 'Campo obrigatório',
                                    minLength: 2,
                                }}
                                control={control}
                                name="companyCode"
                                render={({ onChange, value }) => (
                                    <>
                                        <Form.Label>
                                            Código da empresa
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            minLength={2}
                                            maxLength={2}
                                            placeholder="Digite aqui..."
                                            value={value.replace(/\D+/g, '')}
                                            onChange={onChange}
                                        />
                                    </>
                                )}
                            />
                            {errors.companyCode?.message && (
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                >
                                    {errors.companyCode?.message}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                        <Col>
                            <Controller
                                control={control}
                                name="branchCode"
                                rules={{
                                    required: 'Campo obrigatório',
                                    minLength: 3,
                                }}
                                render={({ onChange, value }) => (
                                    <>
                                        <Form.Label>
                                            Código da filial
                                        </Form.Label>
                                        <Form.Control
                                            minLength={3}
                                            maxLength={3}
                                            placeholder="Digite aqui..."
                                            value={value.replace(/\D+/g, '')}
                                            onChange={onChange}
                                        />
                                    </>
                                )}
                            />
                            {errors.branchCode?.message && (
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                >
                                    {errors.branchCode?.message}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 15 }}>
                        <Col>
                            <Controller
                                control={control}
                                name="states"
                                render={({ onChange, value }) => (
                                    <>
                                        <Form.Label>
                                            Estados associados
                                        </Form.Label>
                                        <Select
                                            onChange={onChange}
                                            styles={{
                                                control: tradeControl,
                                                option: optionsTheme,
                                            }}
                                            value={value}
                                            options={states}
                                            isMulti
                                            theme={theme}
                                            placeholder="Selecione os estados..."
                                            noOptionsMessage={() =>
                                                'Opção não encontrada...'
                                            }
                                        />
                                    </>
                                )}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <StyledOutlineButton
                        onClick={() => {
                            props.onHide();
                        }}
                    >
                        Cancelar
                    </StyledOutlineButton>
                    <StyledConfirmButton
                        variant="primary"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? (
                            <Col md={12} className="text-center">
                                <Spinner animation="border" />
                            </Col>
                        ) : (
                            'Salvar'
                        )}
                    </StyledConfirmButton>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default BranchModal;
