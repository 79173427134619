import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as ReactFeedbackIcon } from '../../../assets/icons/feedback.svg';
import useSellers from '../../../hooks/useSellers';
import Seller from '../../../shared/interfaces/seller.interface';

interface SellerInternalNoteProps {
    seller: Seller;
}

export default function SellerInternalNote({
    seller,
}: SellerInternalNoteProps) {
    const { setShowInternalNotesModal, setSelectedSeller } = useSellers();

    return (
        <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="left"
            overlay={
                <Tooltip id="sellerInternalNote">Observações internas</Tooltip>
            }
        >
            <ReactFeedbackIcon
                width={24}
                height={24}
                fill="#bdbdbd"
                style={{
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setSelectedSeller(seller);
                    setShowInternalNotesModal(true);
                }}
            />
        </OverlayTrigger>
    );
}
