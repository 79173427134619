import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { Col, Row } from 'react-bootstrap';
import UserHttpService from '../../services/http/user-http';
import { StyledH4 } from './styles';
import AccessLayout from '../../components/AccessLayout';
import { signInRoute } from '../../routes/config/signin.route';
import HeaderAccess from '../../components/HeaderAccess';
import EmailConfirmationRequestLayout from '../../components/EmailConfirmation/EmailConfirmationRequestLayout';
import EmailConfirmationSucessLayout from '../../components/EmailConfirmation/EmailConfirmationSucessLayout';
import handleResponseError from '../../utils/handleResponseError';
import EmailConfirmationSendCodeLayout from '../../components/EmailConfirmation/EmailConfirmationSendCodeLayout';

export interface EmailConfirmationFields {
    code: string;
    email: string;
}

type Step = 'request' | 'send-code' | 'success';

export default function EmailConfirmation() {
    const { control, watch, errors, handleSubmit } =
        useForm<EmailConfirmationFields>({
            shouldUnregister: false,
            defaultValues: {
                code: '',
                email: localStorage.getItem('g_email') ?? '',
            },
        });

    const [currentStep, setCurrentStep] = useState<Step>('request');

    const formData = watch();
    const history = useHistory();

    if (!formData.email) {
        history.push(signInRoute.path);
    }

    const requestEmailMutation = useMutation({
        mutationFn: async () => {
            UserHttpService.requestEmailConfirmation({
                email: formData.email,
            });

            setCurrentStep('send-code');
        },
        onError: (error: any) => {
            handleResponseError(error, 'Erro ao enviar e-mail de confirmação');
        },
        onSuccess: () => {
            toast.success('E-mail enviado com sucesso');
        },
    });

    const confirmEmailMutation = useMutation({
        mutationFn: async () =>
            UserHttpService.confirmEmail({
                email: formData.email,
                code: formData.code,
            }),
        onError: (error: any) => {
            handleResponseError(error, 'Erro ao tentar confirmar e-mail');
        },
        onSuccess: () => {
            setCurrentStep('success');
            toast.success('E-mail confirmado com sucesso');
        },
    });

    const onSubmit = handleSubmit(async () =>
        confirmEmailMutation.mutateAsync(),
    );

    return (
        <AccessLayout>
            <Row>
                <Col md={1} />
                <Col md={10}>
                    <HeaderAccess setStep={undefined} goBackTo={undefined} />
                    <StyledH4>Confirmar seu e-mail</StyledH4>

                    {currentStep === 'request' && (
                        <EmailConfirmationRequestLayout
                            email={formData.email}
                            handleRequestEmail={async () =>
                                requestEmailMutation.mutateAsync()
                            }
                            isLoading={requestEmailMutation.isLoading}
                        />
                    )}

                    {currentStep === 'send-code' && (
                        <EmailConfirmationSendCodeLayout
                            control={control}
                            email={formData.email}
                            handleSubmit={onSubmit}
                            handleRequestEmail={async () =>
                                requestEmailMutation.mutateAsync()
                            }
                            errorMessage={errors.code?.message}
                            isLoading={confirmEmailMutation.isLoading}
                        />
                    )}

                    {currentStep === 'success' && (
                        <EmailConfirmationSucessLayout />
                    )}
                </Col>
            </Row>
        </AccessLayout>
    );
}
