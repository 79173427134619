import React, { useEffect, useState } from 'react';
import { Col, Image, Form, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { StyledLinkAvatar } from './styles';
import ChangeAvatarModal from '../ChangeAvatarModal';
import { ReactComponent as ReactProfileIcon } from '../../../assets/icons/profile.svg';
import { AuthValues, useAuth } from '../../../contexts/authContext';
import NotificationMethods from '../../../constants/notificationMethods';
import { theme } from '../../../styles/react-select-config';
import { StyledPageSubTitle, StyledPageTitle } from '../../../styles/pageTitle';

const styles = {
    multiValue: (base: any, state: any) => {
        return state.data.isFixed
            ? {
                  ...base,
                  backgroundColor: 'gray',
                  border: state.isFocused
                      ? '1px solid rgba(47, 204, 139, 0.233)'
                      : null,
                  borderRadius: 3,
                  boxShadow: state.isFocused
                      ? '0px 0px 6px rgba(0, 0, 0, 0.30)'
                      : null,
                  fontSize: 10,
              }
            : base;
    },
    multiValueLabel: (base: any, state: any) => {
        return state.data.isFixed
            ? {
                  ...base,
                  color: 'white',
                  border: state.isFocused
                      ? '1px solid rgba(47, 204, 139, 0.233)'
                      : null,
                  borderRadius: 3,
                  boxShadow: state.isFocused
                      ? '0px 0px 6px rgba(0, 0, 0, 0.30)'
                      : null,
                  fontSize: 10,
              }
            : base;
    },
    multiValueRemove: (base: any, state: any) => {
        return state.data.isFixed
            ? {
                  ...base,
                  display: 'none',
                  border: state.isFocused
                      ? '1px solid rgba(47, 204, 139, 0.233)'
                      : null,
                  borderRadius: 3,
                  boxShadow: state.isFocused
                      ? '0px 0px 6px rgba(0, 0, 0, 0.30)'
                      : null,
                  fontSize: 10,
              }
            : base;
    },
};

const GeneralData = () => {
    const { register, errors, control, setValue } = useFormContext();
    const { user }: AuthValues = useAuth();
    const [showChangeAvatarModal, setShowChangeAvatarModal] = useState(false);
    const [notificationMethodsList, setNotificationMethodsList] = useState(
        [] as any,
    );
    const [notificationMethods] = useState([
        {
            label: 'Aplicação',
            value: NotificationMethods.Aplicacao,
            isFixed: true,
        },
    ] as any);

    const avatar = user.avatar
        ? Buffer.from(user.avatar, 'base64').toString('ascii')
        : '';

    useEffect(() => {
        setNotificationMethodsList([
            ...notificationMethods,
            ...[
                {
                    label: 'E-mail',
                    value: NotificationMethods.Email,
                    isFixed: false,
                },
            ],
        ]);

        const newNoti = user.notificationMethods
            .toString()
            ?.split(',')
            .map((item: any) => {
                let label = '';
                let value = '';
                let isFixed = false;

                if (item.toString()?.trim() === NotificationMethods.Aplicacao) {
                    label = 'Aplicação';
                    value = NotificationMethods.Aplicacao;
                    isFixed = true;
                }

                if (item.toString()?.trim() === NotificationMethods.Email) {
                    label = 'E-mail';
                    value = NotificationMethods.Email;
                    isFixed = false;
                }

                return {
                    value,
                    label,
                    isFixed,
                };
            });

        setValue('notificationMethods', newNoti);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <StyledPageTitle>Dados gerais</StyledPageTitle>
            <StyledPageSubTitle>
                Alguns dados não podem ser editados
            </StyledPageSubTitle>
            <hr />

            {avatar ? (
                <Image width="60" src={avatar} className="mr-2" roundedCircle />
            ) : (
                <ReactProfileIcon
                    width={60}
                    height={60}
                    fill="#cccccc"
                    className="mr-2"
                />
            )}

            <StyledLinkAvatar onClick={() => setShowChangeAvatarModal(true)}>
                Alterar imagem
            </StyledLinkAvatar>

            <Row className="mt-4">
                <Col sm={12} md={6}>
                    <Form.Control ref={register} name="id" type="hidden" />
                    <Form.Group controlId="generalDataForm.name">
                        <Form.Label>Nome completo</Form.Label>
                        <Form.Control
                            ref={register({ required: 'Campo obrigatório' })}
                            isInvalid={!!errors.name}
                            name="name"
                            type="text"
                        />
                        {errors.name && (
                            <Form.Control.Feedback type="invalid">
                                {errors?.name?.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>

                <Col sm={12} md={6}>
                    <Form.Group controlId="generalDataForm.email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            ref={register({
                                required: 'Campo obrigatório',
                                pattern: {
                                    // eslint-disable-next-line no-useless-escape
                                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                    message: 'E-mail inválido',
                                },
                            })}
                            isInvalid={!!errors.email}
                            name="email"
                            type="email"
                        />
                        {errors.email && (
                            <Form.Control.Feedback type="invalid">
                                {errors?.email?.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                    <Form.Group controlId="formBasicNotify">
                        <Form.Label>Meios de notificação</Form.Label>
                        <Controller
                            isClearable={false}
                            className="form-control-nexen"
                            as={Select}
                            control={control}
                            name="notificationMethods"
                            options={notificationMethodsList}
                            isMulti
                            styles={styles}
                            theme={theme}
                            placeholder="Selecione..."
                            defaultValue={notificationMethods}
                            selected={notificationMethods}
                            value={notificationMethods}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <ChangeAvatarModal
                show={showChangeAvatarModal}
                onHide={() => {
                    setShowChangeAvatarModal(false);
                }}
            />
        </>
    );
};

export default GeneralData;
