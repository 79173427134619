import sellerStatus from '../../constants/sellerStatus';
import Request from './request';

export default class SellerHttpService {
    private static uri = 'sellers';

    public static readMany(params: {}) {
        return Request.get(this.uri, params);
    }

    public static readOne(id: string) {
        return Request.get(`${this.uri}/${id}`);
    }

    public static create(data: {}) {
        return Request.post(`${this.uri}`, data);
    }

    public static update(id: string, data: {}) {
        return Request.patch(`${this.uri}/${id}`, data);
    }

    public static updateAuth(data: {}) {
        return Request.patch(this.uri, data);
    }

    public static createClicksignDocument(id: string) {
        return Request.post(`${this.uri}/${id}/clicksign-document`);
    }

    public static createClicksignDocumentNotification(id: string) {
        return Request.post(
            `${this.uri}/${id}/clicksign-document/notification`,
        );
    }

    public static updateClicksignDocument(id: string) {
        return Request.patch(`${this.uri}/${id}/clicksign-document`);
    }

    public static updateStatus(
        id: number,
        status: sellerStatus,
        observation?: string,
    ) {
        return Request.put(`${this.uri}/${id}/status`, {
            origin: 'MANAGEMENT',
            status,
            observation,
        });
    }

    public static deleteClicksignDocument(id: string) {
        return Request.del(`${this.uri}/${id}/clicksign-document`);
    }
}
