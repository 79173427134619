import { Table } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;

export const StyledTable = styled(Table)`
    th:nth-child(1),
    td:nth-child(1) {
        width: 5%;
    }

    th:nth-child(2),
    td:nth-child(2) {
        width: 30%;
    }

    th:nth-child(3),
    td:nth-child(3) {
        width: 5%;
    }

    th:nth-child(4),
    td:nth-child(4) {
        width: 40%;
    }

    th:nth-child(5),
    td:nth-child(5) {
        width: 2%;
    }
`;
