export enum LibraryRerefence {
    TechnicalSupport = 'TECHNICAL_SUPPORT',
    Commercial = 'COMMERCIAL',
    Gallery = 'GALLERY',
    Platform = 'PLATFORM',
}

export enum LibraryItemReference {
    FAQ = 'FAQ',
    Video = 'VIDEO',
    Documentation = 'DOCUMENTATION',
}
