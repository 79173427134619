import { createContext, ReactNode } from 'react';
import { UseQueryResult, useQuery } from 'react-query';
import Notification from '../shared/interfaces/notification.interface';
import NotificationHttpService from '../services/http/notification-http';
import { useAuth } from './authContext';
import { NOTIFICATION_UPDATE_INTERVAL } from '../config/query';

interface NotificationsContextValue {
    notificationsQuery: UseQueryResult<Notification[], unknown>;
}

export const NotificationsContext = createContext<NotificationsContextValue>(
    {} as NotificationsContextValue,
);

interface NotificationsProviderProps {
    children: ReactNode;
}

export function NotificationProvider({ children }: NotificationsProviderProps) {
    const { user } = useAuth();

    const notificationsQuery = useQuery({
        queryKey: ['notifications'],
        queryFn: async () => {
            const response = await NotificationHttpService.readMany();

            return response.data.results;
        },
        refetchInterval: NOTIFICATION_UPDATE_INTERVAL,
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !!user,
    });

    const value: NotificationsContextValue = {
        notificationsQuery,
    };

    return (
        <NotificationsContext.Provider value={value}>
            {children}
        </NotificationsContext.Provider>
    );
}
