import React, { useEffect, useState } from 'react';
import {
    Col,
    Image,
    Nav,
    Navbar,
    NavDropdown,
    OverlayTrigger,
    Popover,
    Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PiList } from 'react-icons/pi';
import { dashboardListRoute } from '../../routes/config';
import {
    Icon,
    OptionText,
    StyledButton,
    StyledNavbar,
    IconContainer,
    StyledAlignDiv,
    OptionContainer,
    StyledNavbarBrand,
    StyledNavDropdown,
    StyledNavbarItemDivider,
} from './styles';
import { AuthValues, useAuth } from '../../contexts/authContext';
import MenuService, { Option, Suboption } from '../../services/menu';
import apps from '../../assets/icons/apps.svg';
import ChangeAvatarModal from '../Profile/ChangeAvatarModal';
import UserDropdownMenu from './UserDropdownMenu';

import Role from '../../constants/roles';
import userHasRoles from '../../utils/userHasRoles';
import sellerStatus from '../../constants/sellerStatus';
import { documentsRoute } from '../../routes/config/documents.route';
import pages from '../../constants/pages';
import NotificationsDropdown from './NotificationsDropdownMenu';

interface Props {
    small: boolean;
    toggle: () => void;
}

const NavbarHeader = (props: Props) => {
    const { small: isSmallScreen, toggle: handleToggle } = props;
    const { user }: AuthValues = useAuth();

    const [showChangeAvatarModal, setShowChangeAvatarModal] = useState(false);
    const [navigation, setNavigation] = useState([]);

    const { pathname } = window.location;
    const activeMenuStyles: any = {
        background: 'rgba(255, 255, 255, 0.18)',
    };

    const checkActiveMenu = (suboptions: Suboption[]) => {
        const exists = suboptions.find((suboption: Suboption) =>
            pathname.startsWith(suboption.path),
        );

        return exists;
    };

    const userAllowedPages = pages.filter((page) =>
        page.roles.length > 0 ? userHasRoles(user, page.roles) : true,
    );

    useEffect(() => {
        const nav: any = MenuService.get(user);

        setNavigation(nav);
    }, [user]);

    if (isSmallScreen) {
        return (
            <>
                <StyledNavbar
                    bg="dark"
                    variant="dark"
                    className="ma-0 pa-0 d-flex justify-content-between"
                >
                    <StyledAlignDiv>
                        <StyledButton onClick={handleToggle}>
                            <PiList size={30} />
                        </StyledButton>
                    </StyledAlignDiv>

                    <StyledNavbarBrand
                        as={Link}
                        to={
                            userHasRoles(user, [Role.Integrator]) &&
                            user?.seller?.status !== sellerStatus.Approved
                                ? documentsRoute.path
                                : dashboardListRoute.path
                        }
                        className="ml-2"
                    >
                        nexen
                    </StyledNavbarBrand>

                    <StyledAlignDiv />
                </StyledNavbar>
            </>
        );
    }

    return (
        <>
            <StyledNavbar
                expand="lg"
                bg="dark"
                variant="dark"
                className="ma-0 pa-0"
            >
                <Navbar.Brand
                    as={Link}
                    to={
                        userHasRoles(user, [Role.Integrator]) &&
                        user?.seller?.status !== sellerStatus.Approved
                            ? documentsRoute.path
                            : dashboardListRoute.path
                    }
                    style={{
                        fontSize: '28px',
                        fontWeight: 700,
                        width: '200px',
                        fontFamily: 'Branding',
                    }}
                    className="ml-2 d-block"
                >
                    nexen
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        {navigation.map((option: Option, index: number) =>
                            option.suboptions ? (
                                <StyledNavDropdown
                                    key={index}
                                    drop="down"
                                    id="2"
                                    style={{
                                        ...(checkActiveMenu(option.suboptions)
                                            ? activeMenuStyles
                                            : {}),
                                        ...{ width: '90px' },
                                    }}
                                    className="pl-0 pr-0"
                                    title={
                                        <div className="text-center">
                                            <Image src={option.icon} />
                                            <span className="d-block mt-2">
                                                {option.text}
                                            </span>
                                        </div>
                                    }
                                >
                                    {option.suboptions.map(
                                        (
                                            suboption: Suboption,
                                            suboptionIndex: number,
                                        ) => (
                                            <NavDropdown.Item
                                                key={suboptionIndex}
                                                to={suboption.path}
                                                as={Link}
                                            >
                                                {suboption.text}
                                            </NavDropdown.Item>
                                        ),
                                    )}
                                </StyledNavDropdown>
                            ) : (
                                <Nav.Link
                                    key={index}
                                    as={Link}
                                    to={option.path}
                                    style={{
                                        ...(pathname.startsWith(
                                            `/${option.path.split('/')[1]}`,
                                        )
                                            ? activeMenuStyles
                                            : null),
                                        ...{
                                            width: '90px',
                                            color: '#ffffff',
                                        },
                                    }}
                                    className="text-center pl-0 pr-0"
                                >
                                    <Image
                                        style={{ height: '24px' }}
                                        src={option.icon}
                                    />
                                    <span className="d-block mt-1">
                                        {option.text}
                                    </span>
                                </Nav.Link>
                            ),
                        )}
                    </Nav>
                    <Nav>
                        {((userHasRoles(user, [Role.Integrator]) &&
                            user?.seller?.status === sellerStatus.Approved) ||
                            !userHasRoles(user, [Role.Integrator])) && (
                            <NotificationsDropdown />
                        )}

                        <StyledNavbarItemDivider className="ml-3 mr-3" />

                        {((userHasRoles(user, [Role.Integrator]) &&
                            user?.seller?.status === sellerStatus.Approved) ||
                            !userHasRoles(user, [Role.Integrator])) && (
                            <OverlayTrigger
                                trigger="click"
                                placement="bottom"
                                rootClose
                                overlay={
                                    <Popover
                                        id="popover-contained"
                                        className="p-3 w-300"
                                        style={{ maxWidth: '420px' }}
                                    >
                                        <Col>
                                            <Row>
                                                {userAllowedPages.map(
                                                    (page: any, index) => (
                                                        <OptionContainer
                                                            key={index}
                                                            href={page.url}
                                                        >
                                                            <IconContainer>
                                                                <Icon
                                                                    className={
                                                                        page.icon
                                                                    }
                                                                />
                                                            </IconContainer>
                                                            <OptionText>
                                                                {page.name}
                                                            </OptionText>
                                                        </OptionContainer>
                                                    ),
                                                )}
                                            </Row>
                                        </Col>
                                    </Popover>
                                }
                            >
                                <Image
                                    style={{ cursor: 'pointer' }}
                                    src={apps}
                                    width={30}
                                    className="mr-2 ml-2"
                                />
                            </OverlayTrigger>
                        )}

                        <UserDropdownMenu />
                    </Nav>
                </Navbar.Collapse>
            </StyledNavbar>

            <ChangeAvatarModal
                show={showChangeAvatarModal}
                onHide={() => {
                    setShowChangeAvatarModal(false);
                }}
            />
        </>
    );
};

export default NavbarHeader;
