import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';
import { List } from '../../pages/Customers';

export const customerListRoute = new Route('/clientes');

export const customerShowRoute = new Route('/clientes/:id/ver');

export const customerEditRoute = new Route('/clientes/:id/editar');

export const customerCreateRoute = new Route('/clientes/novo');

export const CustomersRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={customerListRoute.path}
            component={List}
            isPrivate
            exact
        />
    </Switch>
);
