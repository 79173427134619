import { StatusCode } from 'status-code-enum';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { SelectOption } from '../components/Select';
import City from '../shared/interfaces/city.interface';
import CityHttpService from '../services/http/city-http';

type GetCitiesResponseData = AxiosResponse<City[]>;

interface useCitiesResponse {
    cities?: City[];
    citiesOptions?: Array<SelectOption<string>>;
    isLoading: boolean;
}

export default function useCities(states: string[]): useCitiesResponse {
    const getCitiesFromState = async (state: string): Promise<City[]> => {
        const { status, data: citiesFromState } = (await CityHttpService.get({
            state,
        })) as GetCitiesResponseData;

        if (!citiesFromState || status !== StatusCode.SuccessOK) {
            throw new Error(
                'Não foi possível buscar as cidades do estado selecionado',
            );
        }

        return citiesFromState;
    };

    const getCities = async (): Promise<City[]> => {
        const citiesPromises = states.map((state) => getCitiesFromState(state));
        const citiesResponses = await Promise.all(citiesPromises);
        return citiesResponses.flat();
    };

    const { data: cities, isLoading } = useQuery({
        queryKey: ['cities', states],
        queryFn: getCities,
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
    });

    const citiesOptions: Array<SelectOption<string>> | undefined = cities?.map(
        ({ code, name }: City) => {
            return {
                value: code,
                label: name,
            };
        },
    );

    return { cities, citiesOptions, isLoading };
}
