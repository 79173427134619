import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';
import Documents from '../../pages/Integrators/Documents';

export const documentsRoute = new Route('/documents');

export const documentsRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={documentsRoute.path}
            exact
            component={Documents}
            isPrivate
        />
    </Switch>
);
