import styled from 'styled-components';

export const StyledDropdownTitle = styled.span`
    font-weight: bolder;
    font-size: 14px;
    overflow-wrap: break-word;
    word-wrap: break-word;
`;

export const StyledLink = styled.span`
    font-size: 0.75rem;
    cursor: pointer;
    margin-left: 3px;
    color: #f6b44f;

    :hover {
        color: #e0814e;
        text-decoration: underline;
    }
`;
