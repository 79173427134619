/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import {
    IntegratorsRoutesComponent,
    integratorListRoute,
    dashboardDefaultRoute,
    dashboardListRoute,
    DashboardRoutesComponent,
    userCreateRoute,
    userListRoute,
    UsersRoutesComponent,
    customerCreateRoute,
    CustomersRoutesComponent,
    customerListRoute,
    RegionsRoutesComponent,
    regionCreateRoute,
    regionListRoute,
    userProfileRoute,
    ProfileRoutesComponent,
    companyProfileRoute,
    configPaymentRoute,
    configPointsRoute,
    configBannersRoute,
    configOnlineStoreRoute,
    ConfigurationsRoutesComponent,
    configProductsRoute,
    configBranchesRoute,
    configContactsRoute,
    configReturnUsersRoute,
    configSalesCampaignRoute,
    contributorListRoute,
    contributorsRoutesComponent,
    returnListRoute,
    returnRoutesComponent,
    configInsuranceRoute,
} from './config';
import { SignInRouteComponent, signInRoute } from './config/signin.route';
import {
    selectModuleRegistrationRoute,
    SelectModuleRegistrationComponent,
} from './config/select-module-registration.route';
import { signUpRoute, SignUpRouteComponent } from './config/signup.route';
import {
    forgotPasswordRoute,
    ForgotPasswordRouteComponent,
} from './config/forgot-password.route';
import {
    resetPasswordRoute,
    ResetPasswordRouteComponent,
} from './config/reset-password.route';

import {
    librariesRoute,
    libraryModulesRoute,
    libraryBrandsRoute,
    libraryBrandsCategoriesRoute,
    libraryItemRoute,
    LibrariesRoutesComponent,
} from './config/libraries.route';
import { useAuth } from '../contexts/authContext';
import { useContact } from '../contexts/contactContext';
import {
    documentsRoute,
    documentsRoutesComponent,
} from './config/documents.route';
import FloatConsultantContactButton from '../components/FloatButton';
import {
    discountRoute,
    discountRouteComponent,
} from './config/discounts.route';
import {
    emailConfirmationRouteComponent,
    emailConfirmationRoute,
} from './config/email-confirmation.route';

const AppRoutes: React.FC = () => {
    const auth = useAuth();
    const { consultants } = useContact();
    const [showFloat, setShowFloat] = useState(true);

    const handleToggleFloat = () => {
        setShowFloat((showFloat) => !showFloat);
    };

    const user = {
        ...auth.user,
    };

    const userState = user && user.seller && user.seller.erpState;
    let consultantInformation = null;

    if (userState && consultants.length > 0) {
        consultantInformation = consultants.find(
            (consultant: any) => consultant.state === userState,
        );
    }

    return (
        <>
            <Switch>
                <Route
                    path={signInRoute.path}
                    component={SignInRouteComponent}
                    exact
                />
                <Route
                    path={emailConfirmationRoute.path}
                    component={emailConfirmationRouteComponent}
                />
                <Route
                    path={selectModuleRegistrationRoute.path}
                    component={SelectModuleRegistrationComponent}
                    exact
                />
                <Route
                    path={signUpRoute.path}
                    component={SignUpRouteComponent}
                    exact
                />
                <Route
                    path={forgotPasswordRoute.path}
                    component={ForgotPasswordRouteComponent}
                    exact
                />
                <Route
                    path={resetPasswordRoute.path}
                    component={ResetPasswordRouteComponent}
                    exact
                />
                <Route
                    path={dashboardDefaultRoute.path}
                    component={DashboardRoutesComponent}
                    exact
                />
                <Route
                    path={dashboardListRoute.path}
                    component={DashboardRoutesComponent}
                />
                <Route
                    path={configPointsRoute.path}
                    component={ConfigurationsRoutesComponent}
                />
                <Route
                    path={configPaymentRoute.path}
                    component={ConfigurationsRoutesComponent}
                />
                <Route
                    path={configOnlineStoreRoute.path}
                    component={ConfigurationsRoutesComponent}
                />
                <Route
                    path={configBannersRoute.path}
                    component={ConfigurationsRoutesComponent}
                />
                <Route
                    path={configContactsRoute.path}
                    component={ConfigurationsRoutesComponent}
                />
                <Route
                    path={configProductsRoute.path}
                    component={ConfigurationsRoutesComponent}
                />
                <Route
                    path={configInsuranceRoute.path}
                    component={ConfigurationsRoutesComponent}
                />
                <Route
                    path={configBranchesRoute.path}
                    component={ConfigurationsRoutesComponent}
                />
                <Route
                    path={configReturnUsersRoute.path}
                    component={ConfigurationsRoutesComponent}
                />
                <Route
                    path={configSalesCampaignRoute.path}
                    component={ConfigurationsRoutesComponent}
                />
                <Route
                    path={integratorListRoute.path}
                    component={IntegratorsRoutesComponent}
                />
                <Route
                    path={customerCreateRoute.path}
                    component={CustomersRoutesComponent}
                />
                <Route
                    path={customerListRoute.path}
                    component={CustomersRoutesComponent}
                />
                <Route
                    path={userCreateRoute.path}
                    component={UsersRoutesComponent}
                />
                <Route
                    path={userListRoute.path}
                    component={UsersRoutesComponent}
                />
                <Route
                    path={regionCreateRoute.path}
                    component={RegionsRoutesComponent}
                />
                <Route
                    path={regionListRoute.path}
                    component={RegionsRoutesComponent}
                />

                <Route
                    path={userProfileRoute.path}
                    component={ProfileRoutesComponent}
                />
                <Route
                    path={companyProfileRoute.path}
                    component={ProfileRoutesComponent}
                />
                <Route
                    path={librariesRoute.path}
                    component={LibrariesRoutesComponent}
                />
                <Route
                    path={libraryModulesRoute.path}
                    component={LibrariesRoutesComponent}
                />
                <Route
                    path={libraryBrandsRoute.path}
                    component={LibrariesRoutesComponent}
                />
                <Route
                    path={libraryBrandsCategoriesRoute.path}
                    component={LibrariesRoutesComponent}
                />
                <Route
                    path={libraryItemRoute.path}
                    component={LibrariesRoutesComponent}
                />
                <Route
                    path={contributorListRoute.path}
                    component={contributorsRoutesComponent}
                />
                <Route
                    path={returnListRoute.path}
                    component={returnRoutesComponent}
                />
                <Route
                    path={documentsRoute.path}
                    component={documentsRoutesComponent}
                />
                <Route
                    path={discountRoute.path}
                    component={discountRouteComponent}
                />
            </Switch>
            {consultantInformation && (
                <>
                    <FloatConsultantContactButton
                        consultantNumber={
                            consultantInformation.consultantWhatsapp
                        }
                        showFloat={showFloat}
                        toggle={handleToggleFloat}
                    />
                </>
            )}
        </>
    );
};

export default AppRoutes;
