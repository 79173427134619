import SellerStatus from '../../../constants/sellerStatus';
import StyledBadge from './styles';

interface SellerStatusBadgeProps {
    status: SellerStatus;
}

export default function SellerStatusBadge({ status }: SellerStatusBadgeProps) {
    const { variant, label } = {
        [SellerStatus.Disabled]: { label: 'Bloqueado', variant: 'info' },
        [SellerStatus.Reproved]: { label: 'Reprovado', variant: 'danger' },
        [SellerStatus.Approved]: { label: 'Ativo', variant: 'success' },
        [SellerStatus.Pending]: { label: 'Pendente', variant: 'warning' },
        [SellerStatus.Processing]: { label: 'Processando', variant: 'warning' },
    }[status];

    return <StyledBadge variant={variant}>{label}</StyledBadge>;
}
