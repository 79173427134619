import React from 'react';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';
import SelectionModuleregistrationRoute from '../../pages/SelectModuleRegistration';

export const selectModuleRegistrationRoute = new Route(
    '/select-module-registration',
);

export const SelectModuleRegistrationComponent: React.FC = () => (
    <RouteWrapper
        path={selectModuleRegistrationRoute.path}
        exact
        component={SelectionModuleregistrationRoute}
    />
);
