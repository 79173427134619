import React from 'react';

const OffersAndPromotions: React.FC = () => (
    <div>
        <p>
            <span>
                <span>
                    <span>1. TERMOS E CONDIÇÕES GERAIS</span>
                </span>
            </span>
        </p>
        <p>
            {' '}
            1.1 A nexen.com.br disponibiliza aos consumidores e clientes uma
            variedade de ofertas e promoções. Para sua divulgação, fazemos uso
            de diferentes tipos de tecnologias e meios de comunicação, tais como
            e-mail, SMS, MMS, aplicativos, mala-direta, banners, redes sociais,
            sites externos, afiliados, entre outros digitais e impressos, a
            exclusivo critério da schumann.com.br.
        </p>

        <p>
            {' '}
            1.2 As campanhas promocionais divulgadas em todos os canais de
            comunicação possuem prazo determinado, facultado exclusivamente pela
            Nexen, sendo que, o cumprimento da oferta só poderá ser exigido pelo
            consumidor durante a vigência da promoção. Assim, qualquer exigência
            relativa ao abatimento de preços em compras realizadas fora do
            período de vigência não será válida, tampouco acatada com o
            cumprimento das vantagens e benefícios.
        </p>

        <p>
            {' '}
            1.3 Não é possível efetuar reserva de produtos. Ocorrendo ruptura
            (indisponibilidade) de estoque ou identificação de produto fora de
            produção pela indústria, a nexen.com.br fará contato com o
            consumidor a fim de buscar a melhor solução, ofertando, quando
            possível, uma opção de produto similar ou ainda ressarcindo do valor
            pago, com o devido cancelamento da compra.
        </p>

        <p>
            1.4 Os preços e condições de cada campanha são exclusivos para
            compras via internet
        </p>

        <p>
            {' '}
            1.5 Caso haja divergência entre o preço do produto na página do
            anúncio, banner ou promoção e o valor do produto na seção “Carrinho”
            (última etapa antes de finalizar a compra), o preço que prevalecerá
            é o do “Carrinho”.
        </p>

        <p>
            1.5.1 Todos os preços e condições podem ser alterados sem aviso
            prévio, por isso é importante conferir o preço no “Carrinho”. Se o
            cliente finalizar a compra com o preço constante do “Carrinho”, não
            será realizado qualquer abatimento de preço.
        </p>

        <p>
            1.5.2 Quando o anúncio for veiculado com evidente erro de digitação,
            que resulte em divulgação de valor ínfimo, desproporcional a média
            de preços praticada no mercado para o produto, ainda que a compra
            seja finalizada pelo cliente, a Nexen desde já, reserva-se o direito
            de cancelar o pedido unilateralmente, com a devida restituição dos
            valores pagos, a qual é regida pela Política de Reembolsos.
        </p>

        <p>
            1.6 Como regra geral, as promoções não são cumulativas, ou seja, ao
            optar por uma promoção disponível, outras serão
            invalidadas.&nbsp;Se, excepcionalmente, haver cumulatividade em
            determinada campanha promocional, essa informação estará constando
            nas peças de comunicação da promoção em questão.
        </p>

        <p>
            1.6.1 Cada promoção possui um regulamento próprio, o qual poderá ser
            solicitado pelo cliente a qualquer momento, através da Central de
            Atendimento ao Cliente.
        </p>

        <p>
            1.7 As campanhas promovidas pela nexen.com.br não se aplicam aos
            produtos de marketplaces (que são vendidos e entregues por outros
            parceiros). No caso destes produtos, que possuem tal informação
            visível no anúncio, as condições de venda e entrega são de
            responsabilidade da empresa ofertante.
        </p>

        <p>
            1.8 Promoções e ofertas somente serão enviadas diretamente ao
            consumidor mediante aprovação. Caso desejar, poderá descadastrar-se
            de nosso banco de dados a qualquer momento, basta clicar no local
            indicado no próprio conteúdo, ou então através da Central de
            Atendimento ao Cliente.
        </p>
    </div>
);

export default OffersAndPromotions;
