import { ListGroup } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledListGroup = styled(ListGroup)`
    overflow: auto;
    height: auto;
    max-height: 200px;
`;

export const StyledDropdownSubtitle = styled.span`
    font-size: 12px;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
`;
