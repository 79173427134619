import React, { useEffect, useState } from 'react';
import {
    Badge,
    Button,
    Col,
    Dropdown,
    Form,
    InputGroup,
    Row,
    Table,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as ReactMoreIcon } from '../../assets/icons/more.svg';
import BaseLayout from '../../components/BaseLayout';
import { regionCreateRoute, regionEditRoute } from '../../routes/config';
import RegionHttpService from '../../services/http/region-http';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import { StyledTh } from './styles';
import ConfirmationDialog from '../../utils/ConfirmationDialog';
import NotificationIcon from '../../constants/notificationIcon';

const List: React.FC = () => {
    const [regions, setRegions] = useState([]);
    const [term, setTerm] = useState('');
    const [page, setPage] = useState(1);

    const [selectedRegionId, setSelectedRegionId] = useState<number | null>(
        null,
    );
    const [showRemoveRegionModal, setShowRemoveRegionModal] =
        useState<boolean>(false);

    async function loadRegions(): Promise<void> {
        const params = {
            term,
        };

        const response = await RegionHttpService.readMany(params);

        setRegions(response.data);
    }

    const deleteRegion = async () => {
        try {
            if (!selectedRegionId) {
                throw new Error('Região não encontrada para exclusão');
            }
            await RegionHttpService.delete(selectedRegionId);

            toast.success('Região excluida com sucesso!');

            setPage(1);
            loadRegions();
        } catch (err) {
            const error = err as any;

            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Erro ao excluir região');
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            loadRegions();
        }, 500);

        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    useEffect(() => {
        loadRegions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">Regiões</StyledPageTitle>
                    <StyledPageSubTitle>
                        Informações sobre as Regiões
                    </StyledPageSubTitle>
                </Col>
                <Col className="text-right">
                    <Link to={regionCreateRoute.path}>
                        <Button className="float-right">
                            <i className="fas fa-plus" /> Nova Região
                        </Button>
                    </Link>
                </Col>
            </Row>

            <Row className="pl-2 pr-2 mt-4">
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-search" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type="text"
                            value={term}
                            onChange={(event) => setTerm(event.target.value)}
                            placeholder="Digite aqui o que procura..."
                        />
                    </InputGroup>
                </Col>
            </Row>

            <Row className="pl-2 pr-2">
                <Col>
                    <Table bordered hover size="sm" className="text-center">
                        <thead>
                            <tr>
                                <StyledTh style={{ minWidth: '150px' }}>
                                    NOME
                                </StyledTh>
                                <StyledTh>ESTADOS</StyledTh>
                                <StyledTh />
                            </tr>
                        </thead>
                        <tbody>
                            {regions &&
                                regions.map((item: any, index: any) => (
                                    <tr key={index}>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={item.name}
                                            />
                                        </td>

                                        <td>
                                            <h5 className="m-2">
                                                {item.states
                                                    .split(',')
                                                    .map((state: any) => (
                                                        <Badge
                                                            style={{
                                                                margin: '1%',
                                                                backgroundColor:
                                                                    'rgba(47, 204, 139, 0.1)',
                                                                color: ' #2fcc8b',
                                                            }}
                                                            variant="light"
                                                            key={state}
                                                        >
                                                            {state}
                                                        </Badge>
                                                    ))}
                                            </h5>
                                        </td>

                                        <td>
                                            <Dropdown key="left">
                                                <Dropdown.Toggle
                                                    bsPrefix="nexen"
                                                    as={Button}
                                                    variant="text"
                                                >
                                                    <ReactMoreIcon
                                                        fill="#bdbdbd"
                                                        width="10"
                                                        height="20"
                                                    />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        as={Link}
                                                        to={regionEditRoute.build(
                                                            item,
                                                        )}
                                                    >
                                                        Editar
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setSelectedRegionId(
                                                                item.id,
                                                            );
                                                            setShowRemoveRegionModal(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        Remover
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            {showRemoveRegionModal && (
                <ConfirmationDialog
                    show={showRemoveRegionModal}
                    onHide={() => {
                        setShowRemoveRegionModal(false);
                        setSelectedRegionId(null);
                    }}
                    onConfirm={deleteRegion}
                    icon={NotificationIcon.Warning}
                    title="Excluir região"
                    text="Deseja excluir a região selecionada?"
                />
            )}
        </BaseLayout>
    );
};

// eslint-disable-next-line import/prefer-default-export
export { List };
