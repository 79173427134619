export default function formatDateWithHours(dateToFormat: string): string {
    if (dateToFormat) {
        const date = new Date(dateToFormat).toLocaleDateString();
        const time = new Date(dateToFormat).toLocaleTimeString().split(':');

        return `${time[0]}h${time[1]}    ${date}`;
    }

    return '-';
}
