/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../styles/react-select-config';
import { StyledDiscountButton } from './styles';

export interface Discount {
    id?: number;
    method?: string;
    installment: string;
    value: number;
}

interface Props {
    paymentMethods: never[];
    userMaxDiscounts: Discount[];
    setUserMaxDiscounts: React.Dispatch<React.SetStateAction<Discount[]>>;
    handleInstallmentChanges: (e: any, method: any, index: number) => void;
    handleDiscountChanges: (e: any, method: any, index: number) => void;
}

const UserMaxDiscounts = (props: Props) => {
    const newDiscount: Discount = {
        installment: 'Informe o Nº de parcelas...',
        value: 0,
    };

    const addDiscount = (method: string) => {
        newDiscount.method = method;

        props.setUserMaxDiscounts((previousDiscount: Discount[]) => [
            ...previousDiscount,
            newDiscount,
        ]);
    };

    function options(maxInstallments: number, method: string) {
        const installments = [];
        const exists: any = [];

        props.userMaxDiscounts
            .filter((i: any) => i.method === method)
            .forEach((i: any) => exists.push(i.installment));

        for (let i = 1; i <= maxInstallments; i += 1) {
            if (!exists.find((it: any) => it === i)) {
                installments.push({
                    value: i,
                    label: i.toString(),
                });
            }
        }

        return installments;
    }

    function orderUserMaxDiscounts(a: Discount, b: Discount) {
        if (a.id && b.id) {
            return Number(a.installment) - Number(b.installment);
        }

        if (a.id) {
            return -1;
        }

        if (b.id) {
            return 1;
        }

        return (
            props.userMaxDiscounts.indexOf(a) -
            props.userMaxDiscounts.indexOf(b)
        );
    }

    function orderPaymentMethods(a: any, b: any) {
        return Number(a.id) - Number(b.id);
    }

    return (
        <>
            <Row className="pt-2 pl-2 pr-2">
                <Col>
                    <Accordion>
                        {props.paymentMethods
                            .sort(orderPaymentMethods)
                            .map((item: any, index: number) => (
                                <Card key={index}>
                                    <>
                                        <Card.Header>
                                            <Accordion.Toggle
                                                as={Button}
                                                variant="link"
                                                eventKey={item.name}
                                            >
                                                {item.name}
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse
                                            eventKey={item.name}
                                        >
                                            <Card.Body>
                                                <Row className="pt-2 pl-2 pr-2">
                                                    <Col>
                                                        {props.userMaxDiscounts.filter(
                                                            (i: any) =>
                                                                i.method ===
                                                                item.reference,
                                                        ).length ===
                                                        item.maxInstallments ? (
                                                            <StyledDiscountButton
                                                                size="sm"
                                                                type="button"
                                                                disabled
                                                            >
                                                                + Adicionar
                                                            </StyledDiscountButton>
                                                        ) : (
                                                            <StyledDiscountButton
                                                                size="sm"
                                                                type="button"
                                                                onClick={() => {
                                                                    addDiscount(
                                                                        item.reference,
                                                                    );
                                                                }}
                                                            >
                                                                + Adicionar
                                                            </StyledDiscountButton>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pl-2 pr-2">
                                                    <Col sm={2}>
                                                        <Form.Label>
                                                            Parcelamento
                                                        </Form.Label>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <Form.Label>
                                                            Percentual máximo de
                                                            desconto permitido
                                                        </Form.Label>
                                                    </Col>
                                                </Row>
                                                {props.userMaxDiscounts
                                                    .sort(orderUserMaxDiscounts)
                                                    ?.filter(
                                                        (discount: Discount) =>
                                                            discount.method ===
                                                            item.reference,
                                                    )
                                                    ?.map(
                                                        (
                                                            discount: Discount,
                                                            discountIndex: number,
                                                        ) => (
                                                            <Row
                                                                className="pt-3 pl-2 pr-2"
                                                                key={
                                                                    discountIndex
                                                                }
                                                            >
                                                                {!discount.id ? (
                                                                    <Col sm={2}>
                                                                        <Select
                                                                            menuPortalTarget={
                                                                                document.body
                                                                            }
                                                                            styles={{
                                                                                control:
                                                                                    tradeControl,
                                                                                menuPortal:
                                                                                    (
                                                                                        base,
                                                                                    ) => ({
                                                                                        ...base,
                                                                                        zIndex: 9999,
                                                                                    }),
                                                                            }}
                                                                            value={{
                                                                                label: discount.installment,
                                                                                value: Number(
                                                                                    discount.installment,
                                                                                ),
                                                                            }}
                                                                            options={options(
                                                                                item.maxInstallments,
                                                                                item.reference,
                                                                            )}
                                                                            theme={
                                                                                theme
                                                                            }
                                                                            onChange={(
                                                                                e,
                                                                            ) =>
                                                                                props.handleInstallmentChanges(
                                                                                    e,
                                                                                    discount.method,
                                                                                    discountIndex,
                                                                                )
                                                                            }
                                                                            placeholder="Informe o Nº de parcelas..."
                                                                        />
                                                                    </Col>
                                                                ) : (
                                                                    <Col sm={2}>
                                                                        <Select
                                                                            styles={{
                                                                                control:
                                                                                    tradeControl,
                                                                                option: optionsTheme,
                                                                            }}
                                                                            value={{
                                                                                label: discount.installment,
                                                                                value: discount.installment,
                                                                            }}
                                                                            theme={
                                                                                theme
                                                                            }
                                                                            isDisabled
                                                                        />
                                                                    </Col>
                                                                )}
                                                                <Col sm={3}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        max={
                                                                            100
                                                                        }
                                                                        min={0}
                                                                        step={
                                                                            0.1
                                                                        }
                                                                        name="discount"
                                                                        value={
                                                                            discount.value
                                                                        }
                                                                        onChange={(
                                                                            e,
                                                                        ) =>
                                                                            props.handleDiscountChanges(
                                                                                e,
                                                                                discount.method,
                                                                                discountIndex,
                                                                            )
                                                                        }
                                                                        placeholder="Informe a % de desconto máximo..."
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        ),
                                                    )}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </>
                                </Card>
                            ))}
                    </Accordion>
                </Col>
            </Row>
        </>
    );
};

export default UserMaxDiscounts;
