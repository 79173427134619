/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledSpan = styled.td`
    .svg {
        width: 20px;
        height: 20px;
    }

    .tooltip {
        display: none;
    }

    .svg:hover + .tooltip,
    .tooltip:hover {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #f9f9f9;
        color: #707070;
        border: 1px groove #c3c1c1;
        border-radius: 5px;
        padding: 10px;
        opacity: 1;
        width: auto;
    }
`;
