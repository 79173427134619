import styled from 'styled-components';

const StyledCardDiv = styled.div`
    width: 100%;
    height: auto;
    background-color: white;
    font-size: 0.75rem;
    display: flex;
    flex-direction: row;
`;

export default StyledCardDiv;
