import { Badge, Button, Col, Image, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledH4 = styled.h4`
    font-size: 20px !important;
`;

export const StyledH5 = styled('h5')<{ weight: string }>`
    font-size: 17px;
    color: #707070;
    font-weight: ${(props) => props.weight};
`;

export const StyledH6: any = styled('h6')<{ color: string }>`
    font-size: 14px;
    color: ${(props) => props.color};
    margin-top: ${(props) => (props.color === '#707070' ? '0' : '10px')};
    margin-bottom: ${(props) => (props.color === '#707070' ? '18px' : '3px')};
`;

export const StyledNav = styled(Nav)`
    margin-right: -15px !important;
    margin-bottom: 5px;
    border: none;

    .nav-link {
        border: none;
        color: #bbb;
    }

    .nav-link:hover {
        border: none;
        color: #707070;
    }

    .nav-link.active {
        color: #6fcf97 !important;
    }
`;

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;

export const StyledLink = styled(Link)`
    color: inherit !important;
    font-weight: bolder;
`;

export const StyledDiv = styled.div`
    width: 95%;
    height: 656px;
`;

export const StyledCol = styled(Col)`
    padding: 0;
    margin: 0 10px 0 0;

    @media (max-width: 575px) {
        margin: 0 15px 15px 15px;
    }
`;

export const StyledImage = styled(Image)`
    width: 95%;
    height: 656px;
    object-fit: contain;
    object-position: 0% 0%;
`;

export const StyledBadge = styled(Badge)`
    background-color: #ddd;
    color: #707070;
`;

export const StyledReproveButton = styled(Button)`
    width: 100%;
    padding: 6px 6px;

    background-color: #ff6572;
    border-color: #ff6572;

    :hover {
        background-color: #ff4554;
        border-color: #ff3243;
    }

    :focus {
        background-color: #ff4554 !important;
        border-color: #ff3243 !important;
        box-shadow: 0 0 0 0.2rem rgb(255 50 67 / 50%) !important;
        outline: 0 none !important;
    }

    :active {
        background-color: #ff4554 !important;
        border-color: #ff3243 !important;
    }

    :disabled {
        border-color: #ffc9ce;
        background-color: #ffc9ce;
    }
`;

export const StyledApproveButton = styled(Button)`
    width: 100%;
    padding: 6px 6px;

    background-color: #6fcf97;
    border-color: #6fcf97;

    :hover {
        background-color: #52c582;
        border-color: #49c27b;
    }

    :focus {
        background-color: #52c582 !important;
        border-color: #49c27b !important;
        box-shadow: 0 0 0 0.2rem rgb(99 182 135 / 50%) !important;
        outline: 0 none !important;
    }
`;

export const StyledDisabledApproveButton = styled(Button)`
    width: 100%;
    padding: 6px 6px;

    border-color: #ade4c3;
    background-color: #ade4c3;

    :hover {
        border-color: #ade4c3;
        background-color: #ade4c3;
        cursor: default;
    }

    :focus {
        background-color: #ade4c3 !important;
        border-color: #ade4c3 !important;
        box-shadow: none !important;
        outline: 0 none !important;
    }
`;

export const StyledReturnButton = styled(Button)`
    width: 100%;
    padding: 6px 6px;

    background-color: #ffd968;
    border-color: #ffd453;
    color: #333 !important;

    :hover {
        background-color: #ffcb30;
        border-color: #febf00;
        color: #333 !important;
    }

    :focus {
        background-color: #ffcb30 !important;
        border-color: #febf00 !important;
        box-shadow: 0 0 0 0.2rem rgb(254 191 0 / 50%) !important;
        color: #333 !important;
        outline: 0 none !important;
    }

    :active {
        background-color: #ffcb30 !important;
        border-color: #febf00 !important;
        color: #333 !important;
    }

    :disabled {
        border-color: #ffe9a9;
        background-color: #ffe9a9;
        color: #333 !important;
    }
`;

export const StyledSaveButton = styled(Button)`
    width: 100%;
    padding: 6px 6px;
`;
