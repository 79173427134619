/* eslint-disable react/destructuring-assignment */
import { useState, useEffect, ChangeEvent } from 'react';
import { Col, Modal, Row, Form, Button, Spinner } from 'react-bootstrap';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { StyledRow, StyledTitle } from './styles';
import kitsHttpService from '../../../../../services/http/kit-http';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../../../styles/react-select-config';
import Branch from '../../../../../shared/interfaces/branch.interface';
import removeNonNumericChars from '../../../../../utils/removeNonNumericChars';
import BranchHttpService from '../../../../../services/http/branch-http';

interface FormData {
    inverterErpCode: string;
    inverterName: string;
    inverterBrand: string;
    inverterPower: string;
    connectorErpCode: string;
    connectorsQuantity: string;
    stickWifiCode: string;
    branches: BranchOptions[];
}

interface BranchOptions {
    label: string;
    value: number;
    id: number;
}

const InverterModal = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [inverterBrandsOptions, setInverterBrandsOptions] = useState([]);
    const [isDisabled, setDisabled] = useState(true);
    const {
        register,
        watch,
        setValue,
        reset,
        control,
        errors,
        setError,
        clearErrors,
    } = useForm<FormData>({
        defaultValues: {
            inverterErpCode: '',
            inverterName: '',
            inverterBrand: '',
            inverterPower: '',
            connectorErpCode: '',
            connectorsQuantity: '',
            stickWifiCode: '',
            branches: [],
        },
    });

    const formData = watch();

    const inverterRules = {
        branches: Yup.string().required('Campo obrigatório'),
    };

    async function saveInverter() {
        setLoading(true);
        clearErrors();

        try {
            const schemaInverter = Yup.object().shape(inverterRules);

            await schemaInverter.validate(formData, {
                abortEarly: false,
            });
        } catch (error) {
            setError('branches', {
                type: 'campo',
                message: 'Campo Obrigatório',
            });
            setLoading(false);

            return;
        }

        try {
            const inverter: any = {
                id: props?.selectedInverter?.id,
                inverterErpCode: formData?.inverterErpCode,
                inverterName: formData?.inverterName,
                inverterBrandId: Object.values(formData?.inverterBrand)[0],
                inverterPower: formData?.inverterPower,
                connectorErpCode: formData?.connectorErpCode,
                connectorsQuantity: formData?.connectorsQuantity,
                stickWifiCode: formData?.stickWifiCode,
                active: props?.selectedInverter?.active
                    ? props?.selectedInverter?.active
                    : false,
                branches: formData?.branches,
            };

            const response = await kitsHttpService.saveInverter(inverter);

            if (response.status === 201) {
                toast.success('Inversor salvo.');
                props.refetch();
            }
        } catch (error) {
            toast.error('Erro ao salvar inversor.');
        } finally {
            setLoading(false);
        }

        props.onHide();
    }

    const loadInverterBrandsOptions = async () => {
        try {
            const inverterBrands: any = [];
            const response = await kitsHttpService.getInverterBrands();

            if (response.status === 200) {
                response.data.data.forEach((item: any) => {
                    const option = {
                        value: item.id,
                        label: item.name,
                    };

                    inverterBrands.push(option);
                });

                setInverterBrandsOptions(inverterBrands);
            }
        } catch (error) {
            toast.error('Houve um erro ao carregar as marcas de inversores.');
        }
    };

    useEffect(() => {
        if (
            formData?.inverterErpCode?.length > 0 &&
            formData?.inverterName?.length > 0 &&
            formData?.inverterBrand &&
            Object.values(formData?.inverterBrand)[0] &&
            formData?.inverterPower?.length > 0 &&
            formData?.connectorErpCode?.length > 0 &&
            formData?.connectorsQuantity?.length > 0
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [formData]);

    useEffect(() => {
        loadInverterBrandsOptions();
    }, [props.show]);

    useEffect(() => {
        const selectedBranches = props?.selectedInverter?.branches?.map(
            (item: Branch) => ({
                label: item.name,
                value: item.id,
                id: item.id,
            }),
        );

        if (props?.selectedInverter) {
            const selectedBrand = {
                value: props?.selectedInverter?.inverterBrand?.id,
                label: props?.selectedInverter?.inverterBrand?.name,
            };

            setValue(
                'inverterErpCode',
                props?.selectedInverter?.inverterErpCode,
            );
            setValue('inverterName', props?.selectedInverter?.inverterName);
            setValue('inverterBrand', selectedBrand);
            setValue('inverterPower', props?.selectedInverter?.inverterPower);
            setValue(
                'connectorErpCode',
                props?.selectedInverter?.connectorErpCode,
            );
            setValue(
                'connectorsQuantity',
                props?.selectedInverter?.connectorsQuantity,
            );
            setValue('stickWifiCode', props?.selectedInverter?.stickWifiCode);
            setValue('branches', selectedBranches);
        } else {
            reset({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.selectedInverter]);

    async function loadCompanyBranches() {
        const response = await BranchHttpService.readMany({});

        const companyBranches: BranchOptions[] = [];

        if (response?.data?.results) {
            response.data.results.map((branch: Branch) =>
                companyBranches.push({
                    label: branch.name,
                    id: branch.id,
                    value: branch.id,
                }),
            );
        }

        return companyBranches;
    }

    const checkForBranch = (id: number) => {
        const currentBranches = formData?.branches?.map(
            (branch: BranchOptions) => branch.id,
        );

        return !currentBranches?.includes(id);
    };

    const { data: companyBranches, isLoading } = useQuery(
        'companyBranches',
        loadCompanyBranches,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
        },
    );

    const handleChangeErpCode = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.value = removeNonNumericChars(e.target.value);
    };

    return (
        <>
            <Modal
                className="modal-dialog-scrollable"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                size="md"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className="ml-3 mr-3"
                        id="contained-modal-title-vcenter"
                    >
                        Inversor
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="w-100">
                    <StyledRow className="ml-0 mt-2 mr-1">
                        <Col>
                            <StyledTitle>Filiais</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Group controlId="formBasicBranches">
                                <Controller
                                    className="form-control-nexen"
                                    as={Select}
                                    control={control}
                                    name="branches"
                                    options={
                                        !isLoading &&
                                        companyBranches?.filter(
                                            (branch: BranchOptions) =>
                                                checkForBranch(branch.id),
                                        )
                                    }
                                    isMulti
                                    styles={{
                                        control: tradeControl,
                                        option: optionsTheme,
                                    }}
                                    theme={theme}
                                    placeholder="Selecione..."
                                    defaultValue={[]}
                                    noOptionsMessage={() => 'Sem opções'}
                                />
                                {errors.branches && (
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className="d-block"
                                    >
                                        {
                                            (
                                                errors?.branches as unknown as FieldError
                                            )?.message
                                        }
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <StyledRow className="ml-0 mt-2 mr-1">
                        <Col>
                            <StyledTitle>Código ERP do inversor</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="inverterErpCode"
                                type="string"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                                onChange={handleChangeErpCode}
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Nome do inversor</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="inverterName"
                                type="string"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Marca do inversor</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Controller
                                className="form-control-nexen"
                                as={Select}
                                control={control}
                                name="inverterBrand"
                                options={inverterBrandsOptions}
                                isMulti={false}
                                styles={{
                                    control: tradeControl,
                                    option: optionsTheme,
                                }}
                                theme={theme}
                                placeholder="Selecione..."
                                noOptionsMessage={() => 'Sem opções'}
                                defaultValue={[]}
                                maxMenuHeight={100}
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Potência do inversor</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="inverterPower"
                                type="number"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Código ERP conector</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="connectorErpCode"
                                type="string"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                                onChange={handleChangeErpCode}
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>Quantidade de conectores</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="connectorsQuantity"
                                type="number"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledTitle>
                                Código Stick Wi-Fi (Não obrigatório)
                            </StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="stickWifiCode"
                                type="string"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-5 mr-1">
                        <Col sm={6} />
                        <Col>
                            <Button
                                className="w-100 float-right"
                                variant="outline-primary"
                                onClick={() => {
                                    props.onHide();
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100 float-right"
                                onClick={() => {
                                    saveInverter();
                                }}
                                disabled={isDisabled}
                            >
                                Salvar{'   '}
                                {loading && (
                                    <Spinner animation="border" size="sm" />
                                )}
                            </Button>
                        </Col>
                    </StyledRow>
                </Modal.Body>
                <Modal.Footer className="ml-3 mr-3 mb-2" />
            </Modal>
        </>
    );
};

export default InverterModal;
