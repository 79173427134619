import React from 'react';
import { Switch } from 'react-router-dom';
import List from '../../pages/Users/List';
import { New } from '../../pages/Users/New';
import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';

export const userListRoute = new Route('/users');

export const userShowRoute = new Route('/users/:id/show');

export const userEditRoute = new Route('/users/:id/edit');

export const userCreateRoute = new Route('/users/new');

export const UsersRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={userEditRoute.path}
            exact
            component={New}
            isPrivate
        />
        <RouteWrapper
            path={userCreateRoute.path}
            exact
            component={New}
            isPrivate
        />

        <RouteWrapper
            path={userListRoute.path}
            component={List}
            isPrivate
            exact
        />
    </Switch>
);
