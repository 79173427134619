import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Nav, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import BaseLayout from '../../components/BaseLayout';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import { StyledNav } from '../../styles/nav';
import Libraries from '../../components/LibraryLayout/Libraries';
import Modules from '../../components/LibraryLayout/Modules';
import Items from '../../components/LibraryLayout/Items';
import Brands from '../../components/LibraryLayout/Brands';
import Categories from '../../components/LibraryLayout/Categories';
import { New } from './New';

// eslint-disable-next-line import/prefer-default-export
export function Library() {
    const buttonName: any = {
        libraries: 'Nova biblioteca',
        items: 'Novo item',
        modules: 'Nova módulo',
        brands: 'Nova marca',
        categories: 'Nova categoria',
    };

    const pathname = window.location.pathname.split('/')[2];

    const [term, setTerm] = useState('');
    const [tab, setTab] = useState(pathname);
    const [errors, setErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [modalItemId, setModalItemId] = useState<any>(null);
    const [refreshData, setRefreshData] = useState(false);
    const [disabled, setDisabled] = useState<string[]>([]);

    const history = useHistory();

    const handleTab = (eventKey: any) => {
        setTab(eventKey);
        history.push(eventKey);
    };

    const handleSave = () => {
        setRefreshData(true);
    };

    const handleModalHiding = () => {
        setErrors({});
        setDisabled([]);
        setModalItemId(null);
        setShowModal(false);
        setRefreshData(false);
    };

    const handleOpenModal = (id?: number) => {
        setShowModal(true);
        if (id) {
            setModalItemId(id);
        }
    };

    useEffect(() => {
        setTab(pathname);
    }, [pathname]);

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">
                        Bibliotecas
                    </StyledPageTitle>
                    <StyledPageSubTitle>
                        Gerencie todas as bibliotecas
                    </StyledPageSubTitle>
                </Col>
                <Col className="text-right">
                    <Button
                        className="float-right"
                        onClick={() => handleOpenModal()}
                    >
                        <i className="fas fa-plus" /> {buttonName[tab]}
                    </Button>
                </Col>
            </Row>
            <Row className="mt-4 pl-2 pr-2">
                <Col md={8}>
                    <StyledNav
                        variant="tabs"
                        activeKey={tab}
                        onSelect={(selectedKey: string) =>
                            handleTab(selectedKey)
                        }
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="libraries">
                                Bibliotecas
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="modules">Módulos</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="brands">Marcas</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="categories">
                                Categorias
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="items">Itens</Nav.Link>
                        </Nav.Item>
                    </StyledNav>
                </Col>
            </Row>
            <Row className="pl-2 pr-2 mt-4">
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-search" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type="text"
                            value={term}
                            onChange={(event) => setTerm(event.target.value)}
                            placeholder="Digite aqui o que procura..."
                        />
                    </InputGroup>
                </Col>
            </Row>
            {tab === 'libraries' && (
                <Libraries
                    refresh={refreshData}
                    handleOpenModal={handleOpenModal}
                    term={term}
                />
            )}
            {tab === 'modules' && (
                <Modules
                    refresh={refreshData}
                    handleOpenModal={handleOpenModal}
                    term={term}
                />
            )}
            {tab === 'brands' && (
                <Brands
                    refresh={refreshData}
                    handleOpenModal={handleOpenModal}
                    term={term}
                />
            )}
            {tab === 'categories' && (
                <Categories
                    refresh={refreshData}
                    handleOpenModal={handleOpenModal}
                    term={term}
                />
            )}
            {tab === 'items' && (
                <Items
                    refresh={refreshData}
                    handleOpenModal={handleOpenModal}
                    term={term}
                />
            )}
            <New
                id={modalItemId}
                show={showModal}
                tab={tab}
                errors={errors}
                setErrors={setErrors}
                onHide={handleModalHiding}
                onSave={handleSave}
                disabled={disabled}
                setDisabled={setDisabled}
            />
        </BaseLayout>
    );
}
