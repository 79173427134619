enum IntegratorTabs {
    PersonalData = 'personal-data',
    Cnpj = 'cnpj',
    Address = 'address',
    CompanyInfo = 'company-info',
    CommecialConultants = 'commercial-consultants',
    Documents = 'documents',
}

export default IntegratorTabs;
