import { Card, Col, Form } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { ChangeEvent, useState } from 'react';
import { StyledCard } from './styles';
import User from '../../../shared/interfaces/user.interface';
import UserHttpService from '../../../services/http/user-http';
import RoleHttpService from '../../../services/http/role-http';
import RoleType from '../../../constants/roleType';
import RoleEnum from '../../../constants/roles';
import Role from '../../../shared/interfaces/role.interface';

interface DiscountsUsersListProps {
    setSelectedUsers: (ids: Set<number>) => void;
    selectedUsers: Set<number>;
}

export default function DiscountsUsersList({
    setSelectedUsers,
    selectedUsers,
}: DiscountsUsersListProps) {
    const [wasSelectedAll, setWasSelectedAll] = useState(false);

    const queryConfigs = {
        enabled: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
    };

    const { data: roles, isLoading } = useQuery<Role[]>({
        queryKey: ['roles', 'discounts'],
        queryFn: async () => {
            const params = {
                roleType: RoleType.RegularUserRoles,
            };

            const response = await RoleHttpService.readMany(params);

            return response.data;
        },
        ...queryConfigs,
        refetchOnMount: 'always',
    });

    const isCommercialOrAdmin = [
        RoleEnum.Administrator,
        RoleEnum.CommercialSupervisor,
        RoleEnum.Commercial,
        RoleEnum.CustomerSuccess,
    ];

    const { data: users } = useQuery<User[]>({
        queryKey: ['users'],
        queryFn: async () => {
            const roleReferencesToFilterBy = roles
                ?.filter((role: any) =>
                    isCommercialOrAdmin.includes(role.reference),
                )
                .map((role) => role.id);

            if (!roleReferencesToFilterBy) {
                return [];
            }

            const params = {
                roles: roleReferencesToFilterBy.join(','),
            };

            const response = await UserHttpService.readMany(params);

            return response.data.results;
        },
        ...queryConfigs,
        refetchOnMount: 'always',
        enabled: !isLoading,
    });

    const handleSelectAllUsers = (e: ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;

        let ids: number[] = [];

        if (isChecked) {
            ids = users?.map((user) => user.id) || [];
        }

        const usersId = new Set(ids);

        setSelectedUsers(usersId);
        setWasSelectedAll(isChecked);
    };

    const handleChange = (id: number) => {
        const hasId = selectedUsers.has(id);

        if (hasId) {
            selectedUsers.delete(id);
        } else {
            selectedUsers.add(id);
        }

        setSelectedUsers(selectedUsers);

        const areAllSelected = selectedUsers.size === users?.length;
        setWasSelectedAll(areAllSelected);
    };

    return (
        <Col>
            <StyledCard className="mt-4 w-100">
                <Card.Header as="h5">Usuários</Card.Header>
                <Card.Body className="light-scrollbar">
                    <Form.Check key="all-users" type="checkbox">
                        <Form.Check.Input
                            type="checkbox"
                            id="all-users"
                            checked={wasSelectedAll}
                            onChange={handleSelectAllUsers}
                        />
                        <Form.Check.Label htmlFor="all-users">
                            {wasSelectedAll
                                ? 'Desselecionar todos'
                                : 'Selecionar todos'}
                        </Form.Check.Label>
                    </Form.Check>
                    <hr />
                    {users?.map((user) => (
                        <Form.Check key={user.id} type="checkbox">
                            <Form.Check.Input
                                type="checkbox"
                                id={`user-${user.id}`}
                                onChange={() => {
                                    handleChange(user.id);
                                }}
                                checked={selectedUsers.has(user.id)}
                            />
                            <Form.Check.Label htmlFor={`user-${user.id}`}>
                                {user.name}
                            </Form.Check.Label>
                        </Form.Check>
                    ))}
                </Card.Body>
            </StyledCard>
        </Col>
    );
}
