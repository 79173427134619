/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import { useEffect, useState } from 'react';
import { Col, Form, Row, InputGroup, Image } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import CityHttpService from '../../../services/http/city-http';
import StateService from '../../../services/state';
import { cepMask } from '../../../utils/masks';
import normalizeString from '../../../utils/strings';
import ValidationHttpService from '../../../services/http/validation-http';
import Select from '../../Select';
import { theme, control } from '../../../styles/react-select-config';
import { signUpRoute } from '../../../routes/config';
import customerInputsLength from '../../../constants/customerInputsLength';
import pinIcon from '../../../assets/icons/pin.svg';
import profileIcon from '../../../assets/icons/alt_profile.svg';
import hashtagIcon from '../../../assets/icons/hashtag.svg';
import mapIcon from '../../../assets/icons/map.svg';
import cityIcon from '../../../assets/icons/city.svg';
import altCityIcon from '../../../assets/icons/alt_city.svg';
import pencilIcon from '../../../assets/icons/pencil.svg';
import CountryState from '../../../shared/interfaces/countryState.interface';

const IntegratorAddress = (props: any) => {
    const pathname = window.location.pathname;

    const [states, setStates] = useState([] as CountryState[]);
    const [cities, setCities] = useState([]);

    const getCitiesByState = async (state: string) => {
        const response = await CityHttpService.get({ state });

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const cities = response.data.map((obj: any) => {
            return {
                label: obj.name,
                value: obj.code,
            };
        });

        return cities;
    };

    const loadCities = async (state: string) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const cities = await getCitiesByState(state);

        setCities(cities);
    };

    const handleStateChanges = async (value: any) => {
        props.handleChanges([{ target: { name: 'erpState', value } }]);

        loadCities(value.code);
    };

    const handleCityChanges = async (value: any) => {
        props.handleChanges([
            { target: { name: 'erpCity', value } },
            { target: { name: 'erpCityName', value: value.label } },
        ]);
    };

    const handleCepChanges = async (event: any) => {
        const value: any = event.target.value;
        const changes = [{ target: { name: 'addressCep', value } }];

        if (value.replace(/\D/g, '').length === 8) {
            try {
                const data: any = await ValidationHttpService.validateCep(
                    value.replace(/\D/g, ''),
                );

                const street = data.data.street;
                const neighborhood = data.data.neighborhood;

                const state: any = states.find(
                    (item: any) => item.value === data.data.state,
                );

                // eslint-disable-next-line @typescript-eslint/no-shadow
                const cities = await getCitiesByState(state.value);

                const city: any = cities.find(
                    (item: any) =>
                        item.label ===
                        normalizeString(data?.data?.city || '')?.toUpperCase(),
                );

                changes.push({ target: { name: 'erpState', value: state } });
                changes.push({ target: { name: 'erpCity', value: city } });
                changes.push({
                    target: { name: 'erpCityName', value: city.label },
                });
                changes.push({
                    target: {
                        name: 'addressDescription',
                        value: street.substring(
                            0,
                            customerInputsLength.addressDescription,
                        ),
                    },
                });
                changes.push({
                    target: {
                        name: 'addressNeighborhood',
                        value: neighborhood.substring(
                            0,
                            customerInputsLength.addressNeighborhood,
                        ),
                    },
                });
            } catch (err) {
                changes.push({ target: { name: 'erpState', value: '' } });
                changes.push({ target: { name: 'erpCity', value: '' } });
                changes.push({ target: { name: 'erpCityName', value: '' } });
                changes.push({
                    target: { name: 'addressDescription', value: '' },
                });
                changes.push({
                    target: { name: 'addressNeighborhood', value: '' },
                });
            }
        }

        props.handleChanges(changes);
    };

    useEffect(() => {
        const loadErpStates = async () => {
            const results = await StateService.get();

            // eslint-disable-next-line array-callback-return
            results.map((obj: any) => {
                obj.label = obj.name;
                obj.value = obj.code;
            });

            setStates(results);

            if (props.integrator.erpState) {
                const uf: any = results.find(
                    (item: any) =>
                        item.code === props.integrator.erpState.value,
                );
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const cities = await getCitiesByState(
                    props.integrator.erpState.value,
                );
                const city: any = cities.find(
                    (item: any) =>
                        item.label ===
                        normalizeString(
                            props?.integrator?.erpCity?.label || '',
                        ).toUpperCase(),
                );

                props.handleChanges([
                    {
                        target: {
                            name: 'erpState',
                            value: {
                                name: uf?.label,
                                code: uf?.value,
                                label: uf?.label,
                                value: uf?.value,
                            },
                        },
                    },
                    {
                        target: {
                            name: 'erpCity',
                            value:
                                props.integrator.erpCity &&
                                props.integrator.erpCityName
                                    ? {
                                          label: props.integrator.erpCityName,
                                          value:
                                              typeof props.integrator
                                                  .erpCity === 'string'
                                                  ? props.integrator.erpCity
                                                  : props.integrator.erpCity
                                                        ?.value,
                                      }
                                    : {
                                          label: city?.label,
                                          value: city?.value,
                                      },
                        },
                    },
                    { target: { name: 'erpCityName', value: city?.label } },
                ]);
            }
        };

        loadErpStates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!props.integrator.erpState.value) {
            return;
        }

        loadCities(props.integrator.erpState.value);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.integrator.erpState]);

    useEffect(() => {
        if (typeof props.integrator.erpCity !== 'object') {
            const cityName =
                typeof props.integrator.erpCityName === 'string' &&
                normalizeString(
                    props?.integrator?.erpCityName || '',
                ).toUpperCase();

            const city: any = cities.find(
                (item: any) => item.label === cityName,
            );

            if (city) {
                handleCityChanges(city);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.integrator.erpCity, cities]);

    if (pathname === signUpRoute.path) {
        return (
            <>
                <Form.Group controlId="formBasicCep">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    border: '1px solid #707070',
                                    width: 45,
                                }}
                            >
                                <Image src={pinIcon} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            disabled={props.isDisabled}
                            value={props.integrator.addressCep}
                            isInvalid={!!props.errors.addressCep}
                            as={MaskedInput}
                            mask={cepMask}
                            onChange={handleCepChanges}
                            isValid={
                                props.integrator.addressCep &&
                                !props.errors.addressCep &&
                                props.signUp
                            }
                            name="addressCep"
                            type="text"
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            placeholder="CEP"
                        />
                        {props.errors.addressCep && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.addressCep}
                            </Form.Control.Feedback>
                        )}
                    </InputGroup>
                </Form.Group>

                <Row>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            border: '1px solid #707070',
                                            width: 45,
                                        }}
                                    >
                                        <Image src={profileIcon} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    className={
                                        pathname === signUpRoute.path
                                            ? 'nexen-form-control'
                                            : ''
                                    }
                                    disabled={props.isDisabled}
                                    value={props.integrator.addressDescription}
                                    isInvalid={
                                        !!props.errors.addressDescription
                                    }
                                    onChange={props.handleChanges}
                                    isValid={
                                        props.integrator.addressDescription &&
                                        !props.errors.addressDescription &&
                                        props.signUp
                                    }
                                    name="addressDescription"
                                    type="text"
                                    maxLength={
                                        customerInputsLength.addressDescription
                                    }
                                    placeholder="Endereço"
                                />
                                {props.errors.addressDescription && (
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.addressDescription}
                                    </Form.Control.Feedback>
                                )}
                            </InputGroup>
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group controlId="formBasicCep">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            border: '1px solid #707070',
                                            width: 45,
                                        }}
                                    >
                                        <Image src={hashtagIcon} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    className={
                                        pathname === signUpRoute.path
                                            ? 'nexen-form-control'
                                            : ''
                                    }
                                    disabled={props.isDisabled}
                                    value={props.integrator.addressNumber}
                                    isInvalid={!!props.errors.addressNumber}
                                    onChange={props.handleChanges}
                                    isValid={
                                        props.integrator.addressNumber &&
                                        !props.errors.addressNumber &&
                                        props.signUp
                                    }
                                    name="addressNumber"
                                    type="text"
                                    maxLength={
                                        customerInputsLength.addressNumber
                                    }
                                    placeholder="Número"
                                />
                                {props.errors.addressNumber && (
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.addressNumber}
                                    </Form.Control.Feedback>
                                )}
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>

                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <div style={{ display: 'flex', width: '48%' }}>
                        <div
                            style={{
                                width: 48,
                                height: 48,
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid #707070',
                                justifyContent: 'center',
                            }}
                        >
                            <Image src={mapIcon} />
                        </div>
                        <Form.Group
                            style={{ width: '100%' }}
                            controlId="formBasicState"
                        >
                            <Form.Control
                                style={{ display: 'none' }}
                                isInvalid={!!props.errors.erpState}
                            />

                            <Select
                                name="erpState"
                                onChange={handleStateChanges}
                                value={props.integrator.erpState}
                                isValid={
                                    props.integrator.erpState &&
                                    !props.errors.erpState &&
                                    props.signUp
                                }
                                isInvalid={!!props.errors.erpState}
                                options={states}
                                isMulti={false}
                                styles={{ control }}
                                theme={theme}
                                placeholder="Estado"
                                path={pathname}
                            />

                            {props.errors.erpState && (
                                <Form.Control.Feedback type="invalid">
                                    {props.errors.erpState}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </div>

                    <div style={{ display: 'flex', width: '48%' }}>
                        <div
                            style={{
                                width: 48,
                                height: 48,
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid #707070',
                                justifyContent: 'center',
                            }}
                        >
                            <Image src={cityIcon} />
                        </div>
                        <Form.Group
                            style={{ width: '100%' }}
                            controlId="formBasicCity"
                        >
                            <Form.Control
                                className={
                                    pathname === signUpRoute.path
                                        ? 'nexen-form-control'
                                        : ''
                                }
                                style={{ display: 'none' }}
                                isInvalid={!!props.errors.erpCity}
                            />
                            <Select
                                name="erpCity"
                                onChange={handleCityChanges}
                                value={props.integrator.erpCity}
                                isValid={
                                    props.integrator.erpCity &&
                                    !props.errors.erpCity &&
                                    props.signUp
                                }
                                isInvalid={!!props.errors.erpCity}
                                options={cities}
                                isMulti={false}
                                placeholder="Cidade"
                                path={pathname}
                            />
                            {props.errors.erpCity && (
                                <Form.Control.Feedback type="invalid">
                                    {props.errors.erpCity}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </div>
                </div>

                <Form.Group controlId="formBasicNeighborhood">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    border: '1px solid #707070',
                                    width: 55,
                                }}
                            >
                                <Image src={altCityIcon} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            disabled={props.isDisabled}
                            isInvalid={!!props.errors.addressNeighborhood}
                            onChange={props.handleChanges}
                            value={props.integrator.addressNeighborhood}
                            isValid={
                                props.integrator.addressNeighborhood &&
                                !props.errors.addressNeighborhood &&
                                props.signUp
                            }
                            name="addressNeighborhood"
                            type="text"
                            maxLength={customerInputsLength.addressNeighborhood}
                            placeholder="Bairro"
                        />
                        {props.errors.addressNeighborhood && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.addressNeighborhood}
                            </Form.Control.Feedback>
                        )}
                    </InputGroup>
                </Form.Group>

                <Form.Group controlId="formBasicComplement">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    border: '1px solid #707070',
                                    width: 45,
                                }}
                            >
                                <Image src={pencilIcon} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            isInvalid={!!props.errors.addressComplement}
                            onChange={props.handleChanges}
                            name="addressComplement"
                            value={props.integrator.addressComplement}
                            isValid={
                                props.integrator.addressComplement &&
                                !props.errors.addressComplement &&
                                props.signUp
                            }
                            type="text"
                            placeholder="Complemento"
                            maxLength={customerInputsLength.addressComplement}
                        />
                        {props.errors.addressComplement && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.addressComplement}
                            </Form.Control.Feedback>
                        )}
                    </InputGroup>
                </Form.Group>
            </>
        );
    }

    return (
        <>
            <Form.Group controlId="formBasicCep">
                <Form.Label>CEP</Form.Label>
                <Form.Control
                    disabled={props.isDisabled}
                    value={props.integrator.addressCep}
                    isInvalid={!!props.errors.addressCep}
                    as={MaskedInput}
                    mask={cepMask}
                    onChange={handleCepChanges}
                    isValid={
                        props.integrator.addressCep &&
                        !props.errors.addressCep &&
                        props.signUp
                    }
                    name="addressCep"
                    type="text"
                    className={
                        pathname === signUpRoute.path
                            ? 'nexen-form-control'
                            : ''
                    }
                    placeholder="Informe o cep"
                />
                {props.errors.addressCep && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.addressCep}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Endereço</Form.Label>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            disabled={props.isDisabled}
                            value={props.integrator.addressDescription}
                            isInvalid={!!props.errors.addressDescription}
                            onChange={props.handleChanges}
                            isValid={
                                props.integrator.addressDescription &&
                                !props.errors.addressDescription &&
                                props.signUp
                            }
                            name="addressDescription"
                            type="text"
                            maxLength={customerInputsLength.addressDescription}
                            placeholder="Informe o endereço"
                        />
                        {props.errors.addressDescription && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.addressDescription}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>

                <Col md={4}>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Número</Form.Label>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            disabled={props.isDisabled}
                            value={props.integrator.addressNumber}
                            isInvalid={!!props.errors.addressNumber}
                            onChange={props.handleChanges}
                            isValid={
                                props.integrator.addressNumber &&
                                !props.errors.addressNumber &&
                                props.signUp
                            }
                            name="addressNumber"
                            type="text"
                            maxLength={customerInputsLength.addressNumber}
                            placeholder="Informe o número"
                        />
                        {props.errors.addressNumber && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.addressNumber}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>

            <Form.Group controlId="formBasicState">
                <Form.Label>Estado</Form.Label>
                <Form.Control
                    style={{ display: 'none' }}
                    isInvalid={!!props.errors.erpState}
                />
                <Select
                    name="erpState"
                    onChange={handleStateChanges}
                    value={props.integrator.erpState}
                    isValid={
                        props.integrator.erpState &&
                        !props.errors.erpState &&
                        props.signUp
                    }
                    isInvalid={!!props.errors.erpState}
                    options={states}
                    isMulti={false}
                    styles={{ control }}
                    theme={theme}
                    path={pathname}
                />
                {props.errors.erpState && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.erpState}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group controlId="formBasicCity">
                <Form.Label>Cidade</Form.Label>
                <Form.Control
                    className={
                        pathname === signUpRoute.path
                            ? 'nexen-form-control'
                            : ''
                    }
                    style={{ display: 'none' }}
                    isInvalid={!!props.errors.erpCity}
                />
                <Select
                    name="erpCity"
                    onChange={handleCityChanges}
                    value={props.integrator.erpCity}
                    isValid={
                        props.integrator.erpCity &&
                        !props.errors.erpCity &&
                        props.signUp
                    }
                    isInvalid={!!props.errors.erpCity}
                    options={cities}
                    isMulti={false}
                    placeholder="Cidade"
                />
                {props.errors.erpCity && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.erpCity}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group controlId="formBasicNeighborhood">
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                    className={
                        pathname === signUpRoute.path
                            ? 'nexen-form-control'
                            : ''
                    }
                    disabled={props.isDisabled}
                    isInvalid={!!props.errors.addressNeighborhood}
                    onChange={props.handleChanges}
                    value={props.integrator.addressNeighborhood}
                    isValid={
                        props.integrator.addressNeighborhood &&
                        !props.errors.addressNeighborhood &&
                        props.signUp
                    }
                    name="addressNeighborhood"
                    type="text"
                    maxLength={customerInputsLength.addressNeighborhood}
                    placeholder="Informe o bairro"
                />
                {props.errors.addressNeighborhood && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.addressNeighborhood}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group controlId="formBasicComplement">
                <Form.Label>Complemento</Form.Label>
                <Form.Control
                    className={
                        pathname === signUpRoute.path
                            ? 'nexen-form-control'
                            : ''
                    }
                    isInvalid={!!props.errors.addressComplement}
                    onChange={props.handleChanges}
                    name="addressComplement"
                    value={props.integrator.addressComplement}
                    isValid={
                        props.integrator.addressComplement &&
                        !props.errors.addressComplement &&
                        props.signUp
                    }
                    type="text"
                    placeholder="Informe o complemento"
                    maxLength={customerInputsLength.addressComplement}
                />
                {props.errors.addressComplement && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.addressComplement}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        </>
    );
};

export default IntegratorAddress;
