import React, { useRef, useState } from 'react';
import { Row, Form, Col, InputGroup, Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import AccessLayout from '../../components/AccessLayout';
import HeaderAccess from '../../components/HeaderAccess';
import history from '../../services/history';
import { signInRoute } from '../../routes/config';
import { StyledButton, StyledButtonOutlined } from '../SignIn/styles';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import { userEmailExistsRules } from '../../validations/integrator';
import emailIcon from '../../assets/icons/email.svg';

import getValidationsErrors from '../../utils/getValidationsErrors';
import AuthHttpService from '../../services/http/auth-http';

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [emailMessage, setEmailMessage] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ email: '' });

    const formRef: any = useRef<FormHandles>(null);

    const reset = async () => {
        try {
            const schema = Yup.object().shape(userEmailExistsRules);

            await schema.validate({ email }, { abortEarly: false });
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);

            return;
        }

        try {
            const response = await AuthHttpService.forgotPassword(email);

            if (response) {
                setEmailMessage(email);
                setSuccessMessage(true);

                toast.success('E-mail enviado com sucesso!');
            }
        } catch (error) {
            const err = error as any;

            if (isFriendlyHttpError(err)) {
                toast.error(err.message);
                return;
            }

            setErrors(getValidationsErrors(error) as any);
            setSuccessMessage(false);
            setEmailMessage('');

            toast.error('Ocorreu um erro ao tentar recuperar a senha!');
            return;
        } finally {
            setErrors({ email: '' });
            setEmail('');
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        setLoading(true);

        await reset();

        setLoading(false);
    };

    const handleChanges = async (event: any) => {
        const changes: any = {};
        const newErrors: any = {};

        newErrors.email = undefined;

        setEmail(event.target.value);

        const schema = Yup.object().shape(userEmailExistsRules);

        changes[event.target.name] = event.target.value;

        try {
            await schema.validate(changes, { abortEarly: false });

            setErrors({ ...errors, ...newErrors });
        } catch (error) {
            const err = error as any;

            setErrors({ ...errors, ...(getValidationsErrors(err) as any) });
        }
    };

    return (
        <AccessLayout>
            <Row>
                <Col md={1} />
                <Col md={10}>
                    <HeaderAccess
                        setStep={undefined}
                        goBackTo={signInRoute.path}
                    />

                    <h4
                        style={{
                            fontFamily: 'Branding',
                            fontWeight: 'bold',
                            fontSize: 48,
                            color: '#383838',
                        }}
                        className="mt-5"
                    >
                        Recuperar senha por e-mail
                    </h4>

                    {successMessage ? (
                        <p>
                            Enviamos um e-mail para{' '}
                            <strong>{emailMessage} </strong>
                            com as instruções para redefinição da sua senha.
                        </p>
                    ) : (
                        <p>Enviaremos um código de recuperação.</p>
                    )}

                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            border: '1px solid #707070',
                                            width: 45,
                                        }}
                                    >
                                        <Image src={emailIcon} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    isInvalid={!!errors.email}
                                    className="nexen-form-control"
                                    name="email"
                                    value={email}
                                    onChange={handleChanges}
                                    isValid={email !== '' && !errors.email}
                                    placeholder="Digite seu e-mail"
                                />

                                {errors.email && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                )}
                            </InputGroup>
                        </Form.Group>

                        <StyledButton
                            className="btn-nexen"
                            disabled={isLoading || !email || errors.email}
                            block
                            type="submit"
                        >
                            {!isLoading
                                ? 'Enviar link de recuperação'
                                : 'Aguarde...'}
                        </StyledButton>

                        <StyledButtonOutlined
                            className="btn-nexen"
                            block
                            onClick={() => {
                                history.push(signInRoute.path);
                            }}
                        >
                            Cancelar
                        </StyledButtonOutlined>
                    </Form>
                </Col>
            </Row>
        </AccessLayout>
    );
};

export default ForgotPassword;
