const OriginOptions = [
    {
        label: 'NACIONAL',
        value: 'NACIONAL',
    },
    {
        label: 'IMPORTADO',
        value: 'IMPORTADO',
    },
];

export default OriginOptions;
