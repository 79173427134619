/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { theme, control } from '../../../styles/react-select-config';
import { cnpjMask, cpfMask, phoneMask } from '../../../utils/masks';
import userHasRoles from '../../../utils/userHasRoles';
import Role from '../../../constants/roles';
import { AuthValues, useAuth } from '../../../contexts/authContext';
import sellerStatus from '../../../constants/sellerStatus';
import IntegrationHttpService from '../../../services/http/integration-http';
import CityHttpService from '../../../services/http/city-http';
import normalizeString from '../../../utils/strings';
import { SINTEGRA_HOMO } from '../../../config/portal';
import customerInputsLength from '../../../constants/customerInputsLength';
import { StyledFormControl, StyledSelect } from './style';
import SellerHttpService from '../../../services/http/seller-http';

const CustomerUser = (props: any) => {
    const { user }: AuthValues = useAuth();
    const isIntegrator = userHasRoles(user, [
        Role.Integrator,
        Role.Manager,
        Role.Contributor,
    ]);
    const [loadingSintegraData, setLoadingSintegraData] = useState(false);

    async function loadIntegrators() {
        const response: any = await SellerHttpService.readMany({
            statusFilter: sellerStatus.Approved,
            isShortList: true,
        });

        return response.data.results;
    }

    const integrators: any = useQuery('integrators', loadIntegrators, {
        enabled: !isIntegrator,
    });

    if (integrators.isSuccess) {
        // eslint-disable-next-line array-callback-return
        integrators?.data?.map((obj: any) => {
            obj.label = obj.name;
            obj.value = obj.erpCode;
        });
    }

    const handleSellerChanges = async (value: any) => {
        props.handleChanges([{ target: { name: 'sellerCode', value } }]);
    };

    const getCitiesByState = async (state: string) => {
        const response = await CityHttpService.get({ state });

        // eslint-disable-next-line arrow-body-style
        const cities = response.data.map((obj: any) => {
            return {
                label: obj.name,
                value: obj.code,
            };
        });

        return cities;
    };

    // eslint-disable-next-line consistent-return
    const handleCnpjChanges = async (event: any) => {
        const value: any = event?.target?.value?.replace(/\D/g, '');

        props.handleChanges([{ target: { name: 'document', value } }]);

        const changes: any = [];

        if (value.length === 14 && !props.errors.document && !SINTEGRA_HOMO) {
            setLoadingSintegraData(true);

            try {
                const response: any =
                    await IntegrationHttpService.getSintegraData(value);

                if (!response.data.result) {
                    return toast.info(response.data.message);
                }

                const phoneNumber =
                    props.customer.phone || response?.data?.result?.telefone;

                const cities = await getCitiesByState(
                    response?.data?.result?.estado,
                );

                const city: any = cities.find(
                    (item: any) =>
                        item.label ===
                        normalizeString(
                            response?.data?.result?.municipio ||
                                response?.data?.result?.cidade,
                        ).toUpperCase(),
                );

                changes.push({ target: { name: 'document', value } });
                changes.push({
                    target: {
                        name: 'fancyName',
                        value: response?.data?.result?.nomeFantasia.substring(
                            0,
                            customerInputsLength.fancyName,
                        ),
                    },
                });
                changes.push({
                    target: {
                        name: 'name',
                        value: response?.data?.result?.nome.substring(
                            0,
                            customerInputsLength.name,
                        ),
                    },
                });
                changes.push({ target: { name: 'hasIe', value: false } });
                changes.push({ target: { name: 'ie', value: '' } });

                if (response?.data?.result?.inscricaoEstadual) {
                    changes.push({ target: { name: 'hasIe', value: true } });
                    changes.push({
                        target: {
                            name: 'ie',
                            value: response?.data?.result?.inscricaoEstadual,
                        },
                    });
                }

                changes.push({
                    target: {
                        name: 'erpState',
                        value: response?.data?.result?.estado,
                    },
                });
                changes.push({
                    target: {
                        name: 'erpCity',
                        value:
                            city.value ||
                            (response?.data?.result?.municipio ??
                                response?.data?.result?.cidade),
                    },
                });
                changes.push({
                    target: {
                        name: 'erpCityName',
                        value:
                            city.label ||
                            (response?.data?.result?.municipio ??
                                response?.data?.result?.cidade),
                    },
                });
                changes.push({
                    target: {
                        name: 'addressCep',
                        value: response?.data?.result?.cep.toString(),
                    },
                });
                changes.push({
                    target: {
                        name: 'addressDescription',
                        value: response?.data?.result?.logradouro.substring(
                            0,
                            customerInputsLength.addressDescription,
                        ),
                    },
                });
                changes.push({
                    target: {
                        name: 'addressNeighborhood',
                        value: response?.data?.result?.bairro.substring(
                            0,
                            customerInputsLength.addressNeighborhood,
                        ),
                    },
                });
                changes.push({
                    target: {
                        name: 'addressNumber',
                        value: response?.data?.result?.numero.substring(
                            0,
                            customerInputsLength.addressNumber,
                        ),
                    },
                });
                changes.push({
                    target: {
                        name: 'addressComplement',
                        value: response?.data?.result?.complemento.substring(
                            0,
                            customerInputsLength.addressComplement,
                        ),
                    },
                });
                changes.push({ target: { name: 'phone', value: phoneNumber } });
                changes.push({
                    target: {
                        name: 'email',
                        value:
                            props.customer.email ||
                            response?.data?.result?.email,
                    },
                });

                props.handleChanges(changes);
            } catch (err) {
                const error = err as any;
                toast.error(
                    error.message ||
                        'Não foi possível consultar o CNPJ. Prossiga com o cadastro manualmente',
                );
            } finally {
                setLoadingSintegraData(false);
            }

            setLoadingSintegraData(false);
        }
    };

    const getSellerData = async () => {
        if (props.customer.sellerCode) {
            const integrator = integrators?.data?.find(
                (item: any) => item.erpCode === props.customer.sellerCode,
            );
            if (integrator) {
                props.handleChanges([
                    {
                        target: {
                            name: 'sellerCode',
                            value: {
                                label: integrator.name,
                                value: integrator.erpCode,
                            },
                        },
                    },
                ]);
            }
        }
    };

    useEffect(() => {
        getSellerData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrators]);

    return (
        <>
            <Row>
                {!isIntegrator && (
                    <Col lg={12} md={12}>
                        <Form.Group controlId="formBasicSeller">
                            <Form.Label>Vendedor responsável</Form.Label>
                            <Form.Control
                                style={{ display: 'none' }}
                                isInvalid={!!props.errors.sellerCode}
                            />
                            <StyledSelect>
                                <Select
                                    isDisabled={
                                        props.customer.sellerCode &&
                                        props.customer.id
                                    }
                                    placeholder="Selecione..."
                                    noOptionsMessage={() => 'Sem opções'}
                                    name="sellerCode"
                                    onChange={handleSellerChanges}
                                    value={props.customer.sellerCode}
                                    options={integrators.data}
                                    isMulti={false}
                                    styles={{ control }}
                                    theme={theme}
                                    className="absolute border-maroon z4"
                                />
                            </StyledSelect>

                            {props.errors.sellerCode && (
                                <Form.Control.Feedback type="invalid">
                                    {props.errors.sellerCode}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Col>
                )}
                {props.documentType === 'J' ? (
                    <>
                        <Col lg={12} md={12}>
                            {loadingSintegraData && !props.errors.cnpj && (
                                <>
                                    <p>
                                        <Spinner
                                            animation="border"
                                            size="sm"
                                            className="mr-2"
                                        />
                                        Buscando informações da empresa...
                                    </p>
                                </>
                            )}
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Nome Fantasia</Form.Label>
                                <Form.Control
                                    disabled={props.customer.id}
                                    value={props.customer.fancyName}
                                    onChange={props.handleChanges}
                                    name="fancyName"
                                    maxLength={customerInputsLength.fancyName}
                                    type="text"
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={12}>
                            <Form.Group controlId="formBasicCnpj">
                                <Form.Label>CNPJ</Form.Label>
                                {loadingSintegraData}
                                <Form.Control
                                    disabled={props.customer.id}
                                    value={props.customer.document}
                                    isInvalid={!!props.errors.document}
                                    as={MaskedInput}
                                    mask={cnpjMask}
                                    onChange={handleCnpjChanges}
                                    name="document"
                                    type="text"
                                />
                                {props.errors.document && (
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.document}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </>
                ) : (
                    <Col lg={6} md={12}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>CPF</Form.Label>
                            <Form.Control
                                disabled={props.customer.id}
                                value={props.customer.document}
                                isInvalid={!!props.errors.document}
                                as={MaskedInput}
                                mask={cpfMask}
                                onChange={props.handleChanges}
                                name="document"
                                type="text"
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.errors.document}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                )}
                <Col lg={6} md={12}>
                    <Form.Group controlId="formBasicIe">
                        <Form.Label>Possui Inscrição Estadual?</Form.Label>
                        <Form.Check
                            id="switchIe"
                            name="hasIe"
                            type="switch"
                            checked={props.customer.hasIe}
                            className="float-left"
                            onChange={props.handleChanges}
                        />
                        <StyledFormControl
                            disabled={
                                props.isDisabled ||
                                props.customer.hasIe !== true
                            }
                            value={
                                props.customer.ie === 'ISENTO'
                                    ? ''
                                    : props.customer.ie
                            }
                            isInvalid={!!props.errors.ie}
                            onChange={props.handleChanges}
                            name="ie"
                            type="text"
                            placeholder={
                                props.customer.hasIe !== false
                                    ? 'Digite a Inscrição Estadual...'
                                    : 'Isento'
                            }
                        />
                        {props.errors.ie && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.ie}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                <Col lg={12} md={12}>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            disabled={props.customer.id}
                            isInvalid={!!props.errors.name}
                            onChange={props.handleChanges}
                            value={props.customer.name}
                            name="name"
                            type="text"
                            maxLength={customerInputsLength.name}
                        />

                        <Form.Control.Feedback type="invalid">
                            {props.errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col lg={6} md={12}>
                    <Form.Group controlId="formBasicPhone">
                        <Form.Label>Telefone</Form.Label>
                        <Form.Control
                            value={props.customer.phone}
                            isInvalid={!!props.errors.phone}
                            as={MaskedInput}
                            mask={phoneMask}
                            onChange={props.handleChanges}
                            name="phone"
                            type="tel"
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.errors.phone}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={6} md={12}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                            E-mail (utilize exclusivamente o e-mail do cliente)
                        </Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.email}
                            onChange={props.handleChanges}
                            value={props.customer.email}
                            name="email"
                            maxLength={100}
                            type="email"
                        />

                        <Form.Control.Feedback type="invalid">
                            {props.errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

export default CustomerUser;
