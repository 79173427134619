import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';
import { List, New } from '../../pages/Contributors';

export const contributorListRoute = new Route('/contributors');

export const contributorShowRoute = new Route('/contributors/:id/show');

export const contributorEditRoute = new Route('/contributors/:id/edit');

export const contributorCreateRoute = new Route('/contributors/new');

export const contributorsRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={contributorEditRoute.path}
            exact
            component={New}
            isPrivate
        />
        <RouteWrapper
            path={contributorCreateRoute.path}
            exact
            component={New}
            isPrivate
        />

        <RouteWrapper
            path={contributorListRoute.path}
            component={List}
            isPrivate
            exact
        />
    </Switch>
);
