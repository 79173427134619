import formatMask from './fotmatMask';
import removeNonNumericChars from './removeNonNumericChars';

export default function generatePartialCNPJs(term: string): string[] {
    const masks: Record<number, string[]> = {
        0: [''],
        1: ['#'],
        2: ['##', '#.#', '#/#', '#-#'],
        3: ['##.#', '#.##', '###', '#/##', '#-##', '##-#'],
        4: [
            '##.##',
            '#.###',
            '###.#',
            '###/#',
            '##/##',
            '#/###',
            '###-#',
            '##-##',
        ],
        5: [
            '##.###',
            '#.###.#',
            '###.##',
            '#.###/#',
            '###/##',
            '##/###',
            '##/####',
            '####-#',
            '###-##',
        ],
        6: [
            '##.###.#',
            '#.###.##',
            '###.###',
            '##.###/#',
            '#.###/##',
            '###/###',
            '##/####',
            '#/####-#',
            '####-##',
        ],
        7: [
            '##.###.##',
            '#.###.###',
            '###.###/#',
            '##.###/##',
            '#.###/###',
            '###/####',
            '##/####-#',
            '#/####-##',
        ],
        8: [
            '##.###.###',
            '#.###.###/#',
            '###.###/##',
            '##.###/###',
            '#.###/####',
            '###/####-#',
            '##/####-##',
        ],
        9: [
            '##.###.###/#',
            '#.###.###/##',
            '###.###/###',
            '##.###/####',
            '#.###/####-#',
            '###/####-##',
        ],
        10: [
            '##.###.###/##',
            '#.###.###/###',
            '###.###/####',
            '##.###/####-#',
            '#.###/####-##',
        ],
        11: [
            '##.###.###/###',
            '#.###.###/####',
            '###.###/####-#',
            '##.###/####-##',
        ],
        12: ['##.###.###/####', '#.###.###/####-#', '###.###/####-##'],
        13: ['##.###.###/####-#', '#.###.###/####-#'],
        14: ['##.###.###/####-##'],
    };

    const termClean = removeNonNumericChars(term);

    const maskForThisLength = masks[termClean.length] || [];

    return maskForThisLength.map(
        (mask) => formatMask(termClean, mask) as string,
    );
}
