import React from 'react';

const BuiyngPolicies: React.FC = () => (
    <div>
        <p>
            <span>1. TERMOS E CONDIÇÕES GERAIS:</span>
        </p>
        <p>
            <span>
                <span>
                    <span>
                        1.1. Ao realizar uma compra no site nexen.com.br o
                        consumidor demonstra que está ciente e que concorda com
                        todos os termos e condições previstos nas Políticas da
                        nexen.com.br. Por isso, é indispensável seu conhecimento
                        antes de concluir a compra. Assim, garantimos satisfação
                        durante toda a experiência com nossa empresa e
                        aproveitamento máximo de todas as vantagens e
                        facilidades que desenvolvemos para todos os consumidores
                        e clientes.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        1.2 Os usuários menores de 18 anos poderão realizar o
                        registro ou cadastro em nosso site, somente se estiverem
                        devidamente assistidos por um responsável;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        1.3 No caso das pessoas jurídicas, o endereço de entrega
                        considerado necessariamente será o endereço cadastrado
                        no Cadastro Nacional das Pessoas Jurídicas. A entrega em
                        endereço diverso somente será realizada mediante
                        solicitação formalizada e declaração assinada pelo
                        representante legal da pessoa jurídica, com
                        reconhecimento de firma e cópia do documento
                        constitutivo. Caso o endereço de entrega corresponder a
                        cidade diferente do endereço constante no Cadastro
                        Nacional, será realizado faturamento como venda à ordem.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>2. FUNCIONAMENTO:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1 O site nexen.com.br disponibiliza aos consumidores a
                        compra online durante 24 horas e em todos os dias da
                        semana. Para adquirir os produtos divulgados na referida
                        página, cujos direitos estão reservados, é necessário
                        seguir os seguintes passos:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>1. Escolher o produto de desejo;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>2. Incluir no carrinho de compras;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>3. Preencher o cadastro com dados pessoais;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>4. Preencher endereço de entrega;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>5. Escolher o tipo de frete;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>6. Escolher a forma e condição de pagamento;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>7. Finalizar a compra;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.2 Ao clicar no botão de finalização da compra, o
                        consumidor aceita integralmente todas as condições
                        estipuladas em nossas Políticas.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.3 Todas as compras estão sujeitas a análise e
                        confirmação de dados.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.4 Após a finalização da compra pelo consumidor, a
                        nexen.com.br enviará um e-mail de confirmação de
                        recebimento de pedido, descrevendo o número do pedido de
                        compra, a data da sua efetivação, a forma de pagamento
                        escolhida, a descrição dos produtos adquiridos, o
                        endereço e o prazo de entrega previsto.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.5 Posterior a análise dos dados correspondentes e
                        crédito, havendo a confirmação de pagamento, nos moldes
                        da modalidade escolhida, será enviado o e-mail de
                        confirmação de pagamento.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.6 A partir da confirmação do pagamento, o prazo de
                        entrega começará a ser contado, sendo que essa contagem
                        considerará apenas dias úteis (de segunda a sexta-feira,
                        com exceção de feriados).
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.7 Caso o pagamento não seja confirmado, o pedido será
                        cancelado automaticamente e a nexen.com.br enviará um
                        e-mail informando o ocorrido. Nessa hipótese, tal
                        pedido/compra não gerará qualquer direito ao consumidor,
                        tampouco gerará qualquer compromisso financeiro do
                        consumidor em relação à nexen.com.br.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.8 Poderão ser motivos de NÃO confirmação de pagamento:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        a) Não pagamento do boleto bancário ou insuficiência de
                        saldo ou crédito no cartão;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        b) Inconsistência nos dados fornecidos pelo cliente;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        c) Erro na formação do pedido em razão de defeitos em
                        sistema operacional;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        d) Motivos que tornem o cumprimento do pedido
                        impossível, pela quebra de confiança ou uso de má-fé.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.9 Em caso de suspeita de fraude de cartão de crédito
                        ou de dados cadastrais, mesmo após a aprovação do
                        pedido, a nexen.com.br poderá cancelar a compra sem
                        aviso prévio.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>3. MEIOS DE PAGAMENTO:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        Os meios de pagamento oferecidos pelo site nexen.com.br
                        são:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>3.1 Cartão de Crédito: </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        Possibilita que a compra seja realizada em até 10 (dez)
                        vezes sem juros, observado o valor da parcela mínima de
                        R$ 39,90 (trinta e nove reais e noventa centavos). A
                        aprovação da compra que contenha essa condição de
                        pagamento dependerá da operadora de cartão de crédito e
                        poderá ocorrer no máximo em 24hrs.{' '}
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>3.2 Boleto Bancário: </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        Trata-se de pagamento à vista, o qual pode oferecer ao
                        cliente promoções especiais de desconto. O vencimento
                        ocorre em até 03 (três) dias após a compra e possui um
                        prazo máximo de aprovação de até 72hrs, posterior ao
                        pagamento. Não é possível parcelar através dessa
                        condição de pagamento, nem prorrogar o vencimento, em
                        nenhuma hipótese.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>4. ESTÁGIOS DO PEDIDO:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        4.1 Assim que concluir o pedido, este passará pelos
                        estágios abaixo descritos, sendo possível ao cliente a
                        qualquer tempo a verificação de seu andamento no site,
                        e-mail e através de nossa Central de Atendimento:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>4.1.1 Estágios Obrigatórios:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Pedido realizado: Comprova o registro do pedido nos
                        sistemas Nexen;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Pagamento confirmado: A nexen.com.br confirma o
                        recebimento do seu pagamento;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Pedido faturado: Foi emitida a nota fiscal da sua
                        compra;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Pedido enviado: Seu pedido está com a transportadora;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Produto em trânsito: Seu pedido está a caminho do seu
                        endereço;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>- Saiu para entrega: Seu pedido será entregue;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>- Produto entregue: Seu pedido foi entregue.</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        3.1.2 Estágio Específico/Não obrigatório (não ocorre em
                        todos os pedidos):
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Pedido cancelado: Impossibilidade de processamento do
                        pedido por alguma razão específica.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>4. SERVIÇO DE MONTAGEM DO(S) PRODUTO(S):</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        4.1 O cliente deve entrar em contato através do canal{' '}
                        <a href="mailto:contato@nexen.com.br">
                            <span>
                                <span>contato@nexen.com.br</span>
                            </span>
                        </a>{' '}
                        para consultar a disponibilidade do serviço de
                        instalação em sua região.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>5. OBRIGAÇÕES DO CONSUMIDOR:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>5.1 Antes de concluir a compra:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        5.1.1 Ler todas as políticas e conhecer todas as regras
                        aplicáveis às compras em nexen.com.br;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        5.1.2 Conferir a ficha técnica do produto disponível na
                        página do mesmo ou então entrar em contato com nossa
                        Central para certificar-se que o produto escolhido
                        corresponde ao produto desejado;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        5.1.3 Observar se a voltagem do produto escolhido
                        corresponde ao local de instalação;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        5.1.4 Verificar a descrição da cor, estrutura e
                        acabamento do produto. É importante ressaltar que as
                        imagens publicadas em nosso site são meramente
                        ilustrativas e pequenas variações podem ocorrer;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        5.1.5 Analisar as dimensões do local onde o produto será
                        montado, assim como as dimensões por onde será
                        movimentado, como escadas, corredores, elevadores,
                        portas de acesso, etc;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        5.1.6 Estar ciente de que não está compreendido no
                        serviço de entrega o manuseio do produto ao interior da
                        casa, apartamento ou estabelecimento comercial, seja ele
                        por meio de elevadores, escadas ou içamento e que a
                        nexen.com.br não se responsabiliza por despesas
                        adicionais assumidas por iniciativa do cliente para esse
                        fim. As entregas serão realizadas apenas no pavimento
                        térreo;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        5.1.7 Certificar-se que o prazo de entrega atende a
                        necessidade. O mesmo é considerado em dias úteis e
                        inicia contagem após a confirmação de pagamento
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>5.2 Durante a compra:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        5.2.1 Preencher corretamente todos os dados
                        requisitados;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>5.2.2 Utilizar somente informações verdadeiras.</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>5.3 Depois de concluir a compra:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        5.3.1 Acompanhar a evolução dos status da compra,
                        através dos e-mails disparados automaticamente pelo
                        sistema ou então através do login em nosso site,
                        principalmente com relação a data de entrega;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        5.3.2 Estar presente no local da entrega, ou
                        disponibilizar responsável maior de idade e em posse de
                        documentos pessoais, estando apto a conferir e
                        autorizado para receber a mercadoria;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        5.3.3 ABRIR TODAS AS EMBALAGENS, conferir a mercadoria
                        ainda na presença do entregador e, constatando qualquer
                        irregularidade, recusar o recebimento, de acordo com as
                        situações descritas na Política de Frete e Entrega;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        5.3.4 Guardar as embalagens originais por um período
                        mínimo de 90 dias corridos, pois havendo a necessidade
                        de troca ou devolução, a mesma será requisitada.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>&nbsp;6. ACESSO AOS PEDIDOS:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        6.1 A nexen.com.br disponibiliza o acesso de todo o
                        histórico de compra para cada consumidor. O mesmo é
                        realizado através de login na área do cliente,
                        devidamente identificado no site.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        6.1.1 Cada cliente possui login e senha correspondente
                        aos dados cadastrados no momento da primeira compra.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>7. NEXEN MARKETPLACE:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        7.1 Aos produtos vendidos pelos parceiros comerciais da
                        nexen.com.br (marketplace), aplicam-se as políticas dos
                        mesmos.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>8. FORMAS DE CONTATO:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        8.1 O contato com a nexen.com.br poderá ser realizado
                        através dos canais abaixo, sempre considerando o horário
                        de atendimento correspondente:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>• SAC</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>Segunda à sexta-feira, das 8h às 18h. </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>Telefone: 0800 941 3301</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>e-mail: sac@nexen.com.br</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>• TELEVENDAS</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>Segunda à sexta-feira, das 8h às 18h. </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>Telefone: 0800 941 3301</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>e-mail: contato@nexen.com.br</span>
                </span>
            </span>
        </p>
    </div>
);

export default BuiyngPolicies;
