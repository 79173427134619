import { ChangeEvent, useState } from 'react';
import { Form, Modal, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { LiaInfoCircleSolid } from 'react-icons/lia';
import { PiQuestion, PiSignOut, PiWarning } from 'react-icons/pi';
import { StyledCancelButton, StyledConfirmButton, StyledModal } from './styles';
import NotificationIcon from '../../constants/notificationIcon';

interface ConfirmationDialogProps {
    show: boolean;
    title: string;
    text: string;
    icon: string;
    onHide: () => void;
    onConfirm: () => Promise<unknown>;
}

interface ConfirmationDialogWithFeedbackProps {
    show: boolean;
    title: string;
    text: string;
    icon: string;
    required: boolean;
    placeholder?: string;
    onHide: () => void;
    onConfirmWithFeedback: (feedback: string) => Promise<unknown>;
}

export default function ConfirmationDialog({
    show,
    title,
    text,
    icon,
    onHide,
    ...props
}: ConfirmationDialogProps | ConfirmationDialogWithFeedbackProps) {
    const required = 'required' in props ? props.required : false;
    const placeholder =
        'placeholder' in props
            ? props.placeholder
            : 'Insira aqui seu comentário...';

    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const handleChanges = (event: ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    };

    const bodyIcon = {
        [NotificationIcon.Info]: <LiaInfoCircleSolid />,
        [NotificationIcon.Warning]: <PiWarning />,
        [NotificationIcon.Logout]: <PiSignOut />,
    }[icon] || <PiQuestion />;

    const handleConfirmClick = async () => {
        setIsConfirming(true);

        try {
            if ('onConfirm' in props) {
                await props.onConfirm();
            } else {
                await props.onConfirmWithFeedback(message.trim());
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsConfirming(false);
            onHide();
        }
    };

    const formIsInvalid = required && !message.trim().length;

    return (
        <StyledModal show={show} onHide={onHide} centered icon={icon}>
            <Modal.Body className="text-center">
                {bodyIcon}
                <Modal.Title>{title}</Modal.Title>
                <pre className="pre">{text}</pre>
                {'onConfirmWithFeedback' in props && (
                    <Form.Control
                        className="textarea"
                        as="textarea"
                        name="feedback"
                        value={message}
                        onChange={handleChanges}
                        rows={5}
                        placeholder={placeholder}
                    />
                )}
                <StyledCancelButton onClick={onHide} disabled={isConfirming}>
                    Cancelar
                </StyledCancelButton>
                {formIsInvalid ? (
                    <OverlayTrigger
                        placement="right"
                        delay={{
                            show: 250,
                            hide: 400,
                        }}
                        overlay={
                            <Tooltip id="overlay-example">
                                O preenchimento do campo acima é obrigatório
                            </Tooltip>
                        }
                    >
                        <StyledConfirmButton disabled>
                            Confirmar
                        </StyledConfirmButton>
                    </OverlayTrigger>
                ) : (
                    <StyledConfirmButton
                        onClick={handleConfirmClick}
                        disabled={isConfirming}
                    >
                        {isConfirming ? (
                            <Spinner animation="border" />
                        ) : (
                            'Confirmar'
                        )}
                    </StyledConfirmButton>
                )}
            </Modal.Body>
        </StyledModal>
    );
}
