import Request from './request';

export default class AuthHttpService {
    private static uri = 'auth';

    public static getUser() {
        return Request.get(`${this.uri}/user`);
    }

    public static login(email: string, password: string) {
        return Request.post(`${this.uri}/login`, {
            email,
            password,
        });
    }

    public static forgotPassword(email: string) {
        return Request.post(`${this.uri}/password-reset`, { email });
    }

    public static resetPassword(data: Object) {
        return Request.patch(`${this.uri}/password`, data);
    }

    public static logout() {
        return Request.post(`${this.uri}/logout`);
    }

    public static updateUser(data: {}) {
        return Request.patch(`${this.uri}/user`, data);
    }
}
