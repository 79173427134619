import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledH4 = styled.h4`
    font-size: 20px;
    font-weight: bold;
    color: #707070;
`;

export const StyledH5 = styled('h5')<{ weight: string }>`
    font-size: 17px;
    color: #707070;
    font-weight: ${(props) => props.weight};
    margin: 10px 0 0 0;
`;

export const StyledH6: any = styled('h6')<{ color: string }>`
    margin-top: ${(props) => (props.color === '#707070' ? '0' : '10px')};
    margin-bottom: ${(props) => (props.color === '#707070' ? '18px' : '3px')};
    font-size: 14px;
    color: ${(props) => props.color};
`;

export const StyledCheckboxP: any = styled('p')<{ weight: string }>`
    width: 60%;
    font-size: 14px;
    color: #707070;
    font-weight: ${(props) => props.weight};
    margin: 0;
    padding-right: 30px;
    text-align: justify;
    vertical-align: middle;
`;

export const StyledDiv = styled.div`
    width: 100%;
    margin: 0 0 10px 0;
`;

export const StyledFlexDiv = styled.div`
    margin: 25px 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledForm = styled(Form)`
    width: 5%;
    padding: 0;
    text-align: center;
    vertical-align: middle;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    margin: 10px 0 0 0;
`;

export const StyledRestartButton = styled(Button)`
    width: 35%;

    background-color: #ff6572;
    border-color: #ff6572;

    :hover {
        background-color: #ff4554;
        border-color: #ff3243;
    }

    :focus {
        background-color: #ff4554 !important;
        border-color: #ff3243 !important;
        box-shadow: 0 0 0 0.2rem rgb(255 50 67 / 50%) !important;
        outline: 0 none !important;
    }

    :active {
        background-color: #ff4554 !important;
        border-color: #ff3243 !important;
    }

    :disabled {
        border-color: #ffc9ce;
        background-color: #ffc9ce;
    }
`;
