import React from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import ReactGA from 'react-ga';
import { QueryClient, QueryClientProvider } from 'react-query';
import Routes from './routes';
import history from './services/history';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { ConfigurationProvider } from './contexts/configurationContext';
import { RouterProvider } from './contexts/routerContext';
import { AuthProvider } from './contexts/authContext';
import GA_TRACKING_ID from './config/ga';

import { NotificationProvider } from './contexts/notificationContext';
import { ConsultantContactProvider } from './contexts/contactContext';
import { SellersProvider } from './contexts/sellersContext';

const queryClient = new QueryClient();

ReactGA.initialize(GA_TRACKING_ID as any);

const App: React.FC = () => {
    // Google Analytics
    history.listen(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    return (
        <>
            <RouterProvider>
                <AuthProvider>
                    <QueryClientProvider client={queryClient}>
                        <NotificationProvider>
                            <ConfigurationProvider>
                                <SellersProvider>
                                    <ConsultantContactProvider>
                                        <Router history={history}>
                                            <Routes />
                                        </Router>
                                    </ConsultantContactProvider>
                                </SellersProvider>
                            </ConfigurationProvider>
                        </NotificationProvider>
                    </QueryClientProvider>
                </AuthProvider>
            </RouterProvider>
            <ToastContainer />
        </>
    );
};

export default App;
