import styled from 'styled-components';

const StyledLabel = styled.label`
    width: 100%;
    padding: 2rem;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 2px dashed rgb(230, 212, 212);
    border-radius: 0.5rem;
    text-align: center;
    font-size: 0.75rem;
    cursor: pointer;
    transition: all ease-in-out 0.5s;
    margin: 1rem;

    &:hover,
    &.drag-over {
        filter: brightness(0.95);
    }

    &:active,
    &.drag-over {
        filter: brightness(0.85);
    }
`;

export default StyledLabel;
