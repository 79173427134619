import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import NavbarHeader from '../Navbar';
import Sidebar from '../Sidebar';

interface BaseLayoutProps {
    children?: ReactNode;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 1439px)' });

    const [showSidebar, setShowSidebar] = useState(false);

    const lockScroll = useCallback(() => {
        document.body.style.overflow = 'hidden';

        if (document.body.clientHeight > window.innerHeight) {
            document.body.style.paddingRight = '17px';
        }
    }, []);

    const unlockScroll = useCallback(() => {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
    }, []);

    const handleToggle = () => {
        if (showSidebar) {
            unlockScroll();
        } else {
            lockScroll();
        }

        setShowSidebar((prevShowSidebar) => !prevShowSidebar);
    };

    useEffect(() => {
        if (!isSmallScreen) {
            setShowSidebar(false);
            unlockScroll();
        }
    }, [isSmallScreen, unlockScroll]);

    return (
        <>
            <Container
                fluid
                style={{ overflowY: showSidebar ? 'hidden' : 'visible' }}
            >
                <NavbarHeader small={isSmallScreen} toggle={handleToggle} />
                {children}
                {showSidebar && <Sidebar onHide={handleToggle} />}
            </Container>

            <br />
        </>
    );
};

export default BaseLayout;
