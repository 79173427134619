import Request from './request';

export default class PointHttpService {
    private static uri = 'points';

    public static getPointsSeasons() {
        return Request.get(`${this.uri}/getPointsSeason`);
    }

    public static savePointsSeason(season: any) {
        return Request.post(`${this.uri}/createPointsSeason`, season);
    }

    public static findPointsSeasonById(id: number) {
        return Request.get(`${this.uri}/getPointSeason/${id}`);
    }

    public static editPointsSeason(id: number, season: any) {
        return Request.patch(`${this.uri}/updatePointsSeason/${id}`, season);
    }

    public static deletePointsSeason(id?: number) {
        return Request.del(`${this.uri}/deletePointsSeason/${id}`);
    }
}
