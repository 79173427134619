import { ValidationError } from 'yup';

interface GetValidationError {
    [key: string]: string;
}

function formatYupErrors(err: ValidationError): GetValidationError {
    const validationErrors: GetValidationError = {};

    err.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
    });

    return validationErrors;
}

export default function getValidationsErrors(err: any): GetValidationError {
    return formatYupErrors(err);
}
