/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import ConfigurationHttpService from '../../../services/http/configuration-http';
import { StyledTh } from './styles';
import { StyledPageTitle } from '../BranchesData/styles';
import ConfigurationReference from '../../../constants/configurationReference';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ModalRegisterProps {
    data?: any;
    show?: boolean;
}

function InsuranceData() {
    const [term, setTerm] = useState('');
    const [data, setData] = useState<any>([]);

    async function loadItems() {
        const response = await ConfigurationHttpService.readOne(
            ConfigurationReference.Insurance,
        );

        return setData([response.data]);
    }

    const refetch = async () => {
        loadItems();
    };

    async function onChangeStatus(event: ChangeEvent<HTMLInputElement>) {
        const checked = event.target?.checked;

        await ConfigurationHttpService.update(
            ConfigurationReference.Insurance,
            { value: checked ? '1' : '0' },
        );
        toast.success(
            `Seguro ${checked ? 'ativado' : 'desativado'} com sucesso`,
        );
        refetch();
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            refetch();
        }, 500);

        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    return (
        <>
            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">Seguro</StyledPageTitle>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <Row>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <i className="fas fa-search" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                value={term}
                                onChange={(event) =>
                                    setTerm(event.target.value)
                                }
                                placeholder="Digite aqui o que procura..."
                            />
                        </InputGroup>
                    </Row>
                </Col>
                <Table bordered hover size="sm" className="text-center">
                    <thead>
                        <tr>
                            <StyledTh>Criado</StyledTh>
                            <StyledTh>Alterado</StyledTh>
                            <StyledTh>Status</StyledTh>
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            data.map((item: any) => (
                                <tr key={item.id}>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[]}
                                            textToHighlight={new Date(
                                                item.createdAt,
                                            ).toLocaleDateString()}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[]}
                                            textToHighlight={new Date(
                                                item.updatedAt,
                                            ).toLocaleDateString()}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            type="checkbox"
                                            checked={item.value === '1'}
                                            // eslint-disable-next-line react/jsx-no-bind
                                            onChange={(event) =>
                                                onChangeStatus(event)
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Row>
        </>
    );
}

export default InsuranceData;
