import { useState } from 'react';
import { Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ReturnsHttpService from '../../../../services/http/return-http';
import { StyledCloseButton } from '../../../../pages/Returns/styles';

interface PreReturnModalProps {
    data: {
        show: boolean;
        invoiceId: string;
        logistics: boolean;
        financial: boolean;
    };
    onHide: () => void;
}

const PreReturnModal = ({ data, onHide }: PreReturnModalProps) => {
    const { show, invoiceId, logistics, financial } = data;
    const newPreReturnResolve = () => ({
        preReturnReason: '',
    });

    const [loading, setLoading] = useState(false);
    const [preReturnResolve, setPreReturnResolve] = useState(
        newPreReturnResolve(),
    );

    const handleChanges = (event: any) => {
        const changes: any = {};
        let events: any = event;

        if (!Array.isArray(event)) {
            events = [event];
        }

        events.forEach((item: any) => {
            changes[item.target.name] = item.target.value;
        });

        setPreReturnResolve({ ...preReturnResolve, ...changes });
    };

    const handleSubmit = async () => {
        try {
            const saveReturn = {
                id: invoiceId,
            };

            let response = null;

            if (logistics) {
                response = await ReturnsHttpService.logistics(saveReturn);
            } else if (financial) {
                response = await ReturnsHttpService.financial(saveReturn);
            }

            if (response?.data?.financial && response?.data?.logistics) {
                await ReturnsHttpService.updateStatus({ id: invoiceId });
            }

            const saveLog = {
                description: preReturnResolve?.preReturnReason,
                returnId: invoiceId,
                type: logistics ? 'L' : 'F',
            };

            await ReturnsHttpService.logs(saveLog);

            toast.success('Sucesso ao salvar a liberação!');
            onHide();
        } catch (error) {
            console.log('error -> ', error);
            toast.error('Ocorreu um erro ao salvar.');

            setLoading(false);
            onHide();
        }
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Observação {logistics ? 'logística' : 'financeiro'}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {loading ? (
                    <Row>
                        <Col md={12} className="text-center">
                            <Spinner animation="border" />
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col>
                                <Form.Group controlId="formBasicComment">
                                    <Form.Control
                                        as="textarea"
                                        name="preReturnReason"
                                        onChange={handleChanges}
                                        rows={5}
                                        placeholder="Insira aqui uma breve observação..."
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                )}
            </Modal.Body>

            <Modal.Footer>
                <StyledCloseButton
                    onClick={() => {
                        handleSubmit();
                        onHide();
                    }}
                >
                    Aprovar devolução
                </StyledCloseButton>
            </Modal.Footer>
        </Modal>
    );
};

export default PreReturnModal;
