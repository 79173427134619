export enum BannerLocation {
    dashboard = 'DASHBOARD',
    pointsInformations = 'POINTS_INFORMATIONS',
}

export enum BannerLocationNames {
    dashboard = 'Dashboard',
    pointsInformations = 'Pontos - Informações',
}

export interface Branch {
    id?: number;
    name: string;
    companyCode: string;
    branchCode: string;
    states: string | null;
    createdAt?: string;
    updateAt?: string;
    deletedAt?: string;
    cnpj?: string;
}
