import {
    Col,
    Form,
    Modal,
    OverlayTrigger,
    Row,
    Spinner,
    Tooltip,
} from 'react-bootstrap';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useState } from 'react';
import useSellers from '../../../hooks/useSellers';
import handleResponseError from '../../../utils/handleResponseError';
import SellerHttpService from '../../../services/http/seller-http';
import StyledSaveButton from './styles';
import sellerStatus from '../../../constants/sellerStatus';

export default function InternalNotesModal() {
    const {
        showInternalNotesModal,
        setShowInternalNotesModal,
        selectedSeller,
        setSelectedSeller,
    } = useSellers();

    const [notes, setNotes] = useState(selectedSeller?.internalNotes ?? '');

    const saveMutation = useMutation(
        async () => {
            if (!selectedSeller) {
                throw new Error('Integrador não encontrado para atualizar');
            }

            const trimmedNotes = notes.trim();

            await SellerHttpService.update(selectedSeller.id.toString(), {
                internalNotes: trimmedNotes,
            });

            selectedSeller.internalNotes = trimmedNotes;
        },
        {
            onError: (error: any) => {
                handleResponseError(error, 'Erro ao salvar observação');
            },
            onSuccess: async () => {
                toast.success('Observação salva com sucesso');
            },
        },
    );

    const nonEditableStatuses = [
        sellerStatus.Processing,
        sellerStatus.Disabled,
    ];
    const canEditNotes = selectedSeller
        ? !nonEditableStatuses.includes(selectedSeller.status)
        : false;

    return (
        <Modal
            aria-labelledby="invoice-validation-modal"
            size="lg"
            show={showInternalNotesModal}
            onHide={() => {
                setShowInternalNotesModal(false);
                setSelectedSeller(null);
            }}
            centered
        >
            <Modal.Header closeButton={!saveMutation.isLoading}>
                <Modal.Title
                    className="mb-0"
                    id="contained-modal-title-vcenter"
                >
                    Observações
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className="align-items-center">
                        <Col md={12}>
                            <Form.Control
                                as="textarea"
                                style={{ width: '100%', height: '8rem' }}
                                onChange={(e) => setNotes(e.target.value)}
                                placeholder="Digite aqui as observações..."
                                value={notes}
                                disabled={
                                    !canEditNotes || saveMutation.isLoading
                                }
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {canEditNotes ? (
                    <StyledSaveButton
                        onClick={() => saveMutation.mutateAsync()}
                        disabled={saveMutation.isLoading}
                    >
                        {saveMutation.isLoading ? (
                            <>
                                <Spinner animation="border" size="sm" />
                                <span>Salvando...</span>
                            </>
                        ) : (
                            'Salvar'
                        )}
                    </StyledSaveButton>
                ) : (
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={
                            <Tooltip id="internalNote">
                                O status do integrador não permite atualização
                            </Tooltip>
                        }
                    >
                        <StyledSaveButton disabled>Salvar</StyledSaveButton>
                    </OverlayTrigger>
                )}
            </Modal.Footer>
        </Modal>
    );
}
