/* eslint-disable no-constant-condition */
import React, { useState } from 'react';
import { Col, Form, Row, Spinner, InputGroup, Image } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import AccessLayout from '../../components/AccessLayout';
import HeaderAccess from '../../components/HeaderAccess';
import LoginService from '../../services/login';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import { selectModuleRegistrationRoute } from '../../routes/config/select-module-registration.route';
import { PORTAL_URL, ACCOUNT_URL } from '../../config/portal';
import { StyledButton } from './styles';
import { useAuth, AuthValues } from '../../contexts/authContext';
import loginRules from '../../validations/login';
import getValidationsErrors from '../../utils/getValidationsErrors';
import passwordIcon from '../../assets/icons/password.svg';
import emailIcon from '../../assets/icons/email.svg';
import whiteRightArrow from '../../assets/icons/small_white_right_arrow.svg';
import Role from '../../constants/roles';
import sellerStatus from '../../constants/sellerStatus';
import { emailConfirmationRoute } from '../../routes/config/email-confirmation.route';
import AuthError from '../../constants/AuthError';

const SignIn: React.FC = (Props: any) => {
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ email: '', password: '' });

    const history = useHistory();
    // eslint-disable-next-line react/destructuring-assignment
    const { redirectTo }: any = queryString.parse(Props.location.search);

    const { setUser }: AuthValues = useAuth();
    const [userData, setUserData] = useState({
        email: '',
        password: '',
    });

    const login = async (event: any) => {
        event.preventDefault();

        setErrors({ email: '', password: '' });

        try {
            const schema = Yup.object().shape(loginRules);

            await schema.validate(userData, { abortEarly: false });
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);

            return;
        }

        setLoading(true);

        try {
            const user = await LoginService.login(
                userData.email,
                userData.password,
            );

            setUser(user);

            let isPurchase = false;

            let isNotApproved = false;

            if (
                user?.seller?.status &&
                user?.seller?.status !== sellerStatus.Approved
            ) {
                isNotApproved = true;
            }

            user?.roles.forEach((eachRole: any) => {
                if (eachRole === Role.Purchase) {
                    isPurchase = true;
                }

                if (eachRole === Role.Administrator) {
                    isNotApproved = false;
                }
            });

            if (redirectTo) {
                window.location.replace(`${redirectTo}`);
            } else if (isPurchase) {
                window.location.replace(`${ACCOUNT_URL}`);
            } else if (isNotApproved) {
                window.location.replace(`${ACCOUNT_URL}/documents`);
            } else {
                window.location.replace(`${PORTAL_URL}`);
            }
        } catch (error) {
            const err = error as any;

            if (err?.errorCode === AuthError.userEmailNotConfirmed) {
                history.push(emailConfirmationRoute.path);
                return;
            }

            if (isFriendlyHttpError(err)) {
                toast.error(err.message);
                return;
            }

            toast.error('Ocorreu um erro ao efetuar o login!');
            return;
        } finally {
            setLoading(false);
        }
    };

    const handleChanges = (event: any) => {
        const { name } = event.target;
        const { value } = event.target;

        setUserData({ ...userData, [name]: value });
    };

    return (
        <AccessLayout>
            <Row>
                <Col md={1} />
                <Col md={10}>
                    <HeaderAccess setStep={undefined} goBackTo="" />
                    <h5
                        className="mt-5"
                        style={{
                            fontFamily: 'Branding',
                            fontWeight: 'bold',
                            fontSize: 48,
                            color: '#383838',
                        }}
                    >
                        Acesse agora e confira!
                    </h5>
                    <p>Seja bem-vindo(a). Insira seus dados.</p>

                    <Form className="mt-4" onSubmit={login}>
                        <Form.Group controlId="formBasicEmail">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            border: '1px solid #707070',
                                            width: 45,
                                        }}
                                    >
                                        <Image src={emailIcon} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    isInvalid={!!errors.email}
                                    className="nexen-form-control"
                                    name="email"
                                    onChange={handleChanges}
                                    placeholder="E-mail"
                                />
                            </InputGroup>

                            {errors.email && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            )}
                            <Form.Text className="text-muted" />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            border: '1px solid #707070',
                                            width: 45,
                                        }}
                                    >
                                        <Image src={passwordIcon} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    isInvalid={!!errors.password}
                                    className="nexen-form-control"
                                    type="password"
                                    name="password"
                                    onChange={handleChanges}
                                    placeholder="Senha"
                                />
                            </InputGroup>
                            {errors.password && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            )}
                            <Form.Text className="d-flex justify-content-end mt-3 text-muted">
                                Esqueceu sua senha?
                                <Link
                                    style={{
                                        color: '#1E1E1E',
                                        fontFamily: 'Branding',
                                        fontWeight: 'bold',
                                        textDecoration: 'underline',
                                        marginLeft: 5,
                                    }}
                                    to="./password/forgot"
                                >
                                    Clique aqui
                                </Link>
                            </Form.Text>
                        </Form.Group>

                        <StyledButton
                            className="mt-5 shadow-none"
                            disabled={isLoading}
                            block
                            type="submit"
                            style={{
                                background:
                                    'linear-gradient(90deg, #7800E1 0%, #F6700F 82.78%, #FFB855 100%)',
                            }}
                        >
                            {isLoading ? (
                                <Spinner animation="border" />
                            ) : (
                                <span
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontFamily: 'Branding',
                                        fontSize: 18,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Entrar no sistema
                                    <Image
                                        className="ml-2"
                                        src={whiteRightArrow}
                                    />
                                </span>
                            )}
                        </StyledButton>

                        <span
                            className="d-flex justify-content-center mt-3 text-muted"
                            style={{ fontSize: '80%' }}
                        >
                            Ainda não tem uma conta?
                            <Link
                                style={{
                                    color: '#1E1E1E',
                                    fontFamily: 'Branding',
                                    fontWeight: 'bold',
                                    textDecoration: 'underline',
                                    marginLeft: 5,
                                }}
                                to={selectModuleRegistrationRoute.path}
                            >
                                Clique aqui
                            </Link>
                        </span>
                    </Form>
                </Col>
            </Row>
        </AccessLayout>
    );
};

export default SignIn;
