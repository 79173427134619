/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import {
    Form,
    InputGroup,
    Modal,
    ModalFooter,
    Table,
    Button,
    Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { StyledTh } from '../styles';
import Pagination from '../../../Pagination';
import UserHttpService from '../../../../services/http/user-http';
import ReturnUsersHttpService from '../../../../services/http/return-users-http';
import RoleType from '../../../../constants/roleType';

interface Props {
    show: boolean;
    // eslint-disable-next-line react/no-unused-prop-types
    isOpen: Function;
    refetch(): void;
    onHide(): void;
}

const AddReturnUsersModal: React.FC<Props> = (props: Props) => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState<any>();
    const [term, setTerm] = useState('');
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [perPage] = useState(5);
    const [isLoading, setLoading] = useState(false);

    async function loadUsers(): Promise<void> {
        setLoading(true);

        const params = {
            term,
            skip: page - 1,
            take: perPage,
            roleType: RoleType.RegularUserRoles,
            removeReturnUsers: true,
        };

        try {
            const response = await UserHttpService.readMany(params);

            if (response.status === 200) {
                setPages(Math.ceil(response.data.metadata.total / perPage));
                setUsers(response.data.results);
            }
        } catch (error) {
            toast.error('Houve um erro ao carregar os usuários.');
        } finally {
            setLoading(false);
        }
    }

    const saveReturnUser = async (returnUser: any) => {
        setLoading(true);

        const saveUser = { user: returnUser.id };

        try {
            const response = await ReturnUsersHttpService.store(saveUser);

            if (response.status === 201) {
                props.refetch();
                props.onHide();
                toast.success('Usuário adicionado com sucesso!');
            }
        } catch (error) {
            toast.error('Houve um erro ao adicionar o usuário devolução');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (props.show) {
            setPage(1);
            setTerm('');

            loadUsers();
        }
    }, [props.show]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (props.show) {
            const timer = setTimeout(async () => {
                setPage(1);

                loadUsers();
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [term]);

    useEffect(() => {
        if (props.show) {
            loadUsers();
        }
    }, [page]);

    return (
        <>
            <Modal
                show={props.show}
                onHide={() => props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton onHide={props.onHide}>
                    <Modal.Title
                        className="ml-3 mr-3"
                        id="contained-modal-title-vcenter"
                    >
                        Selecionar usuário
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ml-3 mr-3">
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-search" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type="text"
                            value={term}
                            onChange={(event) => setTerm(event.target.value)}
                            placeholder="Pesquisar..."
                        />
                    </InputGroup>

                    {isLoading ? (
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                            }}
                        >
                            <Spinner animation="border" />
                        </div>
                    ) : (
                        <Table bordered hover size="sm" className="text-center">
                            <thead>
                                <tr>
                                    <StyledTh />
                                    <StyledTh>NOME</StyledTh>
                                    <StyledTh>E-MAIL</StyledTh>
                                </tr>
                            </thead>
                            <tbody>
                                {users &&
                                    users.map((user: any, index: any) => (
                                        <tr key={index}>
                                            <td>
                                                <Form.Check
                                                    checked={
                                                        selectedUser &&
                                                        selectedUser.id ===
                                                            user.id
                                                    }
                                                    type="radio"
                                                    name="selectedUser"
                                                    value={user.id}
                                                    onChange={() => {}}
                                                    onClick={() => {
                                                        setSelectedUser(user);
                                                    }}
                                                />
                                            </td>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    )}

                    <Pagination
                        pages={pages}
                        page={page}
                        // eslint-disable-next-line @typescript-eslint/no-shadow
                        onPageChange={(page: any) => setPage(page)}
                    />
                </Modal.Body>
                <ModalFooter>
                    <Button
                        variant="primary"
                        onClick={() => {
                            saveReturnUser(selectedUser);
                        }}
                        disabled={isLoading || !selectedUser}
                    >
                        {isLoading ? (
                            <Spinner animation="border" />
                        ) : (
                            <span>Incluir</span>
                        )}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default AddReturnUsersModal;
