import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useState,
} from 'react';
import { UseFormMethods, useForm } from 'react-hook-form';
import { SelectOption } from '../components/Select';
import Seller from '../shared/interfaces/seller.interface';

interface SellerFilterFields {
    filterStatus: Array<SelectOption<string>>;
    filterContract: Array<SelectOption<string | boolean>>;
    filterAttachment: SelectOption<boolean> | null;
    filterComercialConsultants: Array<SelectOption<number>>;
    filterStates: Array<SelectOption<string>>;
    filterCities: Array<SelectOption<string>>;
    filterStartDate: string;
    filterEndDate: string;
    filterTerm: string;
}

interface SellerContextValue {
    showBlockIntegratorModal: boolean;
    setShowBlockIntegratorModal: Dispatch<SetStateAction<boolean>>;
    showUnblockIntegratorModal: boolean;
    setShowUnblockIntegratorModal: Dispatch<SetStateAction<boolean>>;
    showDigitalContractModal: boolean;
    setShowDigitalContractModal: Dispatch<SetStateAction<boolean>>;
    showFilterSection: boolean;
    setShowFilterSection: Dispatch<SetStateAction<boolean>>;
    sellerOnDigitalContractUpdate: number | null;
    setSellerOnDigitalContractUpdate: Dispatch<SetStateAction<number | null>>;
    selectedSeller: Seller | null;
    setSelectedSeller: Dispatch<SetStateAction<Seller | null>>;
    filterForm: UseFormMethods<SellerFilterFields>;
    showInternalNotesModal: boolean;
    setShowInternalNotesModal: Dispatch<SetStateAction<boolean>>;
}

interface SellersProviderProps {
    children: ReactNode;
}

export const SellersContext = createContext<SellerContextValue>(
    {} as SellerContextValue,
);

export function SellersProvider({ children }: SellersProviderProps) {
    const [showBlockIntegratorModal, setShowBlockIntegratorModal] =
        useState<boolean>(false);

    const [showUnblockIntegratorModal, setShowUnblockIntegratorModal] =
        useState<boolean>(false);

    const [showDigitalContractModal, setShowDigitalContractModal] =
        useState<boolean>(false);

    const [sellerOnDigitalContractUpdate, setSellerOnDigitalContractUpdate] =
        useState<number | null>(null);

    const [selectedSeller, setSelectedSeller] = useState<Seller | null>(null);

    const [showFilterSection, setShowFilterSection] = useState<boolean>(false);

    const [showInternalNotesModal, setShowInternalNotesModal] =
        useState<boolean>(false);

    const filterForm = useForm<SellerFilterFields>({
        shouldUnregister: false,
        defaultValues: {
            filterTerm: '',
            filterStatus: [],
            filterContract: [],
            filterComercialConsultants: [],
            filterStates: [],
            filterCities: [],
            filterAttachment: null,
            filterStartDate: '',
            filterEndDate: '',
        },
    });

    const value: SellerContextValue = {
        showBlockIntegratorModal,
        setShowBlockIntegratorModal,
        showUnblockIntegratorModal,
        setShowUnblockIntegratorModal,
        showDigitalContractModal,
        setShowDigitalContractModal,
        showFilterSection,
        setShowFilterSection,
        sellerOnDigitalContractUpdate,
        setSellerOnDigitalContractUpdate,
        selectedSeller,
        setSelectedSeller,
        filterForm,
        showInternalNotesModal,
        setShowInternalNotesModal,
    };

    return (
        <SellersContext.Provider value={value}>
            {children}
        </SellersContext.Provider>
    );
}
