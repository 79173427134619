enum customerInputsLength {
    name = 40,
    fancyName = 20,
    addressNumber = 5,
    addressComplement = 20,
    addressDescription = 40,
    addressNeighborhood = 20,
}

export default customerInputsLength;
