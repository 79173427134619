/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth, AuthValues } from '../contexts/authContext';
import userHasRoles from '../utils/userHasRoles';
import Role from '../constants/roles';
import LoginService from '../services/login';
import sellerStatus from '../constants/sellerStatus';
import { documentsRoute } from './config/documents.route';
import { companyProfileRoute, userProfileRoute } from './config';
import AuthHttpService from '../services/http/auth-http';

interface RouteWrapperProps extends RouteProps {
    component?: any;
    isPrivate?: any;
    redirectIfNotExact?: any;
    path: any;
    redirectTo?: any;
}

const RouteWrapper: React.FC<RouteWrapperProps> = ({
    component: Component,
    isPrivate = false,
    // redirectIfNotExact = false,
    path,
    redirectTo,
    ...rest
}) => {
    const [canAccess, setCanAccess] = useState(undefined as any);
    const { user, setUser }: AuthValues = useAuth();

    const isShippingCompany = userHasRoles(user, [Role.ShippingCompany]);
    const isIntegrator = userHasRoles(user, [Role.Integrator]);

    const allowedPaths = [
        documentsRoute.path,
        userProfileRoute.path,
        companyProfileRoute.path,
    ];

    const logout = async () => {
        await LoginService.logout();
    };

    useEffect(() => {
        const check = async () => {
            if (isPrivate) {
                try {
                    let authUser = user;

                    if (!user) {
                        const response = await AuthHttpService.getUser();

                        authUser = response.data;

                        setUser(response.data);
                    }

                    if (
                        userHasRoles(authUser, [Role.Integrator]) &&
                        authUser.seller?.status !== sellerStatus.Approved &&
                        !allowedPaths.includes(path)
                    ) {
                        setCanAccess(false);
                        logout();
                    } else {
                        setCanAccess(true);
                    }
                } catch (err) {
                    setCanAccess(false);
                }

                return;
            }

            setCanAccess(true);
        };

        check();
    }, [isPrivate]);

    useEffect(() => {
        if (user) {
            if (isShippingCompany) {
                setCanAccess(false);
                logout();
            }
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            if (path.includes('/integrators')) {
                if (isIntegrator) {
                    setCanAccess(false);
                    logout();
                }
            }
        }
    }, [user]);

    let component = <Redirect to="/login" />;

    if (canAccess === undefined) {
        component = <></>;
    } else if (canAccess === true && !redirectTo) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        component = <Route {...rest} path={path} component={Component} />;
    } else if (canAccess === true && redirectTo) {
        component = <Redirect to={redirectTo} />;
    }

    return component;
};

RouteWrapper.propTypes = {
    component: PropTypes.any,
    isPrivate: PropTypes.any,
    redirectIfNotExact: PropTypes.any,
    path: PropTypes.string,
    redirectTo: PropTypes.any,
};

export default RouteWrapper;
