import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const StyledSaveButton = styled(Button)`
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
`;

export default StyledSaveButton;
