/* eslint-disable react/destructuring-assignment */
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { control, theme } from '../../styles/react-select-config';

const StyledSelect = styled.div`
    .valid-select > div {
        border-color: #8108d3;
    }
    .invalid-select > div {
        border-color: #dc3545;
    }
`;

export interface SelectOption<T = string | number> {
    value: T;
    label: string;
}

export default function Select(props: any) {
    let className = '';
    if (props.isValid) {
        className = 'valid-select';
    } else if (props.isInvalid) {
        className = 'invalid-select';
    }

    return (
        <StyledSelect>
            <ReactSelect
                placeholder={props.placeholder || 'Selecione...'}
                noOptionsMessage={() => 'Sem opções'}
                name={props.name}
                onChange={props.onChange}
                value={props.value}
                options={props.options}
                isMulti={props.isMulti}
                styles={{ control }}
                theme={theme}
                className={className}
                path={props.path}
            />
        </StyledSelect>
    );
}
