import { Accordion, Button } from 'react-bootstrap';
import PaymentMethod from '../../../../shared/interfaces/paymentMethod.interface';
import DiscountsListAccordionItem from '../DiscountsListAccordionItem';
import { Discount } from '../../../../shared/interfaces/discount.interface';

interface DiscountsListAccordionProps {
    paymentMethod: PaymentMethod;
    discounts: Record<string, Discount>;
    onChange: (paymentKey: string, discount: Discount) => void;
}

export default function DiscountsListAccordion({
    paymentMethod,
    discounts,
    onChange,
}: DiscountsListAccordionProps) {
    const installmentsIndex = Array.from(
        { length: paymentMethod.maxInstallments },
        (_, index) => index + 1,
    );

    return (
        <div key={paymentMethod.name} className="mb-2">
            <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={paymentMethod.name}
                style={{ display: 'contents', fontSize: '14px' }}
            >
                {paymentMethod.name}
            </Accordion.Toggle>

            <Accordion.Collapse eventKey={paymentMethod.name}>
                <div className="mb-4 mt-1">
                    {installmentsIndex.map((index) => (
                        <DiscountsListAccordionItem
                            key={`${paymentMethod.reference}-${index}`}
                            method={paymentMethod.name}
                            reference={paymentMethod.reference}
                            installment={index}
                            value={
                                discounts[`${paymentMethod.reference}-${index}`]
                                    ?.value
                            }
                            onChange={onChange}
                        />
                    ))}
                </div>
            </Accordion.Collapse>
        </div>
    );
}
