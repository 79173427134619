import { Col, Row, Spinner } from 'react-bootstrap';
import StyledButton from './styles';

interface EmailConfirmationRequestLayoutProps {
    email: string;
    handleRequestEmail: () => void;
    isLoading: boolean;
}

export default function EmailConfirmationRequestLayout({
    email,
    handleRequestEmail,
    isLoading,
}: EmailConfirmationRequestLayoutProps) {
    return (
        <Row className="mt-4">
            <Col xs="12" className="w-100">
                <p className="w-100">
                    Para sua segurança e proteção da sua conta, precisamos que
                    confirme o seu e-mail. Mas não se preocupe, esse processo é
                    rápido e fácil, basta utilizar o botão abaixo para que um
                    código de confirmação seja enviado para{' '}
                    <strong>{email}</strong>.
                </p>
            </Col>

            <Col>
                <StyledButton
                    className="btn-nexen mt-3"
                    disabled={isLoading}
                    block
                    onClick={handleRequestEmail}
                >
                    {isLoading ? (
                        <>
                            <Spinner animation="border" size="sm" />
                            <span>Enviando...</span>
                        </>
                    ) : (
                        'Enviar código de confirmação'
                    )}
                </StyledButton>
            </Col>
        </Row>
    );
}
