import {
    brandsRules,
    librariesRules,
    modulesRules,
    categoriesRules,
} from '../validations/library';

export default function libraryRule(tab: string): any {
    switch (tab) {
        case 'libraries':
            return librariesRules;

        case 'modules':
            return modulesRules;

        case 'brands':
            return brandsRules;

        case 'categories':
            return categoriesRules;

        default:
            return librariesRules;
    }
}
