/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ProgressBar = styled.progress`
    margin-top: 30px;
    margin-bottom: 20px;
    -webkit-appearance: none;
    appearance: none;

    width: 100%;
    height: 10px;

    &::-webkit-progress-bar {
        background-color: #eee;
        border-radius: 2px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
    }

    &::-webkit-progress-value {
        background: linear-gradient(
            90deg,
            #7800e1 0%,
            #f6700f 82.78%,
            #ffb855 100%
        );

        border-radius: 2px;
    }
`;
