import React from 'react';
import { Switch } from 'react-router-dom';
import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';
import DiscountManagement from '../../pages/DiscountsManagement';

export const discountRoute = new Route('/discounts');

export const discountRouteComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={discountRoute.path}
            component={DiscountManagement}
            isPrivate
            exact
        />
    </Switch>
);
