import React, { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Form, InputGroup, Image } from 'react-bootstrap';
import removeNonNumericChars from '../../../../utils/removeNonNumericChars';
import KeyIcon from '../../../../assets/icons/key.svg';

interface SendCodeLayoutInputProps {
    control: Control;
    errorMessage?: string;
}

export default function SendCodeLayoutInput({
    control,
    errorMessage,
}: SendCodeLayoutInputProps): ReactElement {
    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedData = event.clipboardData.getData('text');
        const numericData = removeNonNumericChars(pastedData);
        const newCode = numericData.slice(0, 6);
        control.setValue('code', newCode);
    };

    return (
        <Controller
            rules={{
                required: 'Campo obrigatório',
                minLength: {
                    value: 6,
                    message:
                        'O código de confirmação deve possuir seis dígitos',
                },
            }}
            control={control}
            name="code"
            render={({ onChange, value }) => (
                <InputGroup className="mt-4">
                    <InputGroup.Prepend>
                        <InputGroup.Text
                            style={{
                                border: '1px solid #707070',
                                width: 45,
                            }}
                        >
                            <Image src={KeyIcon} />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        isInvalid={!!errorMessage}
                        className="nexen-form-control"
                        name="email"
                        value={value}
                        type="text"
                        onChange={(e) => {
                            const numericValue = removeNonNumericChars(
                                e.target.value,
                            );
                            onChange(numericValue);
                        }}
                        onPaste={handlePaste}
                        isValid={value !== '' && !errorMessage}
                        placeholder="Digite o código de confirmação"
                        maxLength={6}
                    />
                    {errorMessage && (
                        <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                        >
                            {errorMessage}
                        </Form.Control.Feedback>
                    )}
                </InputGroup>
            )}
        />
    );
}
