import { Badge, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReturnStatus from '../../constants/returnStatus';

interface Props {
    status: string;
}

export const StyledH4 = styled.h4`
    font-size: 20px !important;
    cursor: pointer;
`;

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;

export const StyledLink = styled(Link)`
    color: inherit !important;
    font-weight: bolder;
`;

export const StyledBadge = styled(Badge)<Props>`
    background: rgba(235, 87, 87, 0.1);
    color: ${(props: any) => {
        if (props.status === ReturnStatus.WaitingApproval) {
            return '#2F80ED';
        }

        if (props.status === ReturnStatus.FreeToReturn) {
            return '#219653';
        }

        return '';
    }};
`;

export const StyledCloseButton = styled(Button)`
    width: 200px;
`;

export const StyledSpan = styled.span`
    font-weight: bolder;
    color: #adadad;
`;

export const StyledTable = styled(Table)`
    th:nth-child(1),
    td:nth-child(1) {
        width: 5%;
    }
    th:nth-child(2),
    td:nth-child(2) {
        width: 20%;
    }
    th:nth-child(3),
    td:nth-child(3) {
        width: 5%;
    }
    th:nth-child(4),
    td:nth-child(4) {
        width: 20%;
    }
    th:nth-child(5),
    td:nth-child(5) {
        width: 10%;
    }
    th:nth-child(5),
    td:nth-child(5) {
        width: 10%;
    }
    th:nth-child(5),
    td:nth-child(5) {
        width: 10%;
    }
    th:nth-child(5),
    td:nth-child(5) {
        width: 10%;
    }
    th:nth-child(5),
    td:nth-child(5) {
        width: 8%;
    }
    th:nth-child(5),
    td:nth-child(5) {
        width: 2%;
    }
`;
