import { toast } from 'react-toastify';
import ApiBasicErrorResponse from '../shared/interfaces/api-basic-error-response.interface';
import ApiErrorResponse from '../shared/interfaces/api-error-response.interface';

function isApiErrorResponse(data: any): data is ApiErrorResponse {
    return (
        typeof data === 'object' &&
        'timestamp' in data &&
        'statusCode' in data &&
        'error' in data &&
        'message' in data &&
        'path' in data
    );
}

function isApiBasicErrorResponse(data: any): data is ApiBasicErrorResponse {
    return (
        typeof data === 'object' && 'statusCode' in data && 'message' in data
    );
}

function getResponseErrorMessage(
    error: unknown,
    defaultMessage: string,
): string | string[] {
    if (isApiErrorResponse(error)) {
        if (typeof error.message === 'string') {
            return error.message;
        }

        if (Array.isArray(error.message)) {
            return error.message;
        }

        return defaultMessage;
    }

    if (isApiBasicErrorResponse(error)) {
        return error.message;
    }

    return defaultMessage;
}

export default function handleResponseError(
    error: unknown,
    defaultMessage: string,
) {
    const errorMessage = getResponseErrorMessage(error, defaultMessage);

    if (Array.isArray(errorMessage)) {
        const tenSeconds = 10000;
        errorMessage.forEach((message, messagesDisplayed) =>
            toast.error(message, {
                autoClose: (messagesDisplayed + 1) * tenSeconds,
            }),
        );
    } else {
        toast.error(errorMessage);
    }
}
