import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledCol = styled(Col)`
    max-height: 100vh !important;
    padding: 0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f2994a+1,ffffff+100&0.2+1,1+100 */
    background: -moz-linear-gradient(
        top,
        rgba(242, 153, 74, 0.2) 1%,
        rgba(255, 255, 255, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        top,
        rgba(242, 153, 74, 0.2) 1%,
        rgba(255, 255, 255, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to bottom,
        rgba(242, 153, 74, 0.2) 1%,
        rgba(255, 255, 255, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33f2994a', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

    @media (max-height: 624px) {
        max-height: 100% !important;
    }
`;

export const StyledCol2 = styled(Col)`
    display: flex !important;
    justify-content: center;
    align-items: center;
`;

export const LoginPageText = styled.h2`
    display: flex;
    position: absolute;
    top: 28%;
    left: 49%;
    font-size: 1.6rem;
    color: #fff;

    @media (max-width: 1170px) {
        font-size: 1.2rem;
    }

    @media (min-width: 1367px) {
        font-size: 2.3rem;
    }
`;

export const RegisterPageText = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    color: #fff;
    text-align: initial;
    top: 10%;
    left: 45%;
    font-size: 0.9rem;

    h2 {
        font-size: 2rem;

        @media (max-width: 1170px) {
            font-size: 1.4rem;
        }

        @media (min-width: 1367px) {
            font-size: 2.5rem;
        }
    }

    @media (max-width: 1170px) {
        font-size: 0.8rem;
        left: 42%;
    }

    @media (min-width: 1367px) {
        font-size: 1.1rem;
        top: 15%;
    }
`;
