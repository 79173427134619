import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';
import { List, New } from '../../pages/Regions';

export const regionListRoute = new Route('/regioes');

export const regionShowRoute = new Route('/regioes/:id/show');

export const regionEditRoute = new Route('/regioes/:id/edit');

export const regionCreateRoute = new Route('/regioes/new');

export const RegionsRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper path={regionEditRoute.path} component={New} isPrivate />

        <RouteWrapper
            path={regionCreateRoute.path}
            exact
            component={New}
            isPrivate
        />

        <RouteWrapper
            path={regionListRoute.path}
            exact
            component={List}
            isPrivate
        />
    </Switch>
);
