/* eslint-disable react/destructuring-assignment */
import { FormHandles } from '@unform/core';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Modal, Row, Spinner, Form, FormControl } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ProductDeliveryDateHttpService from '../../../../../services/http/product-delivery-date-http';
import {
    StyledConfirmButton,
    StyledOutlineButton,
} from '../../../../../styles/button';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../../../styles/react-select-config';
import isFriendlyHttpError from '../../../../../utils/isFriendlyHttpError';
import { OptionProps } from '../ProductDeliveryDateFilter';

interface Props {
    show: boolean;
    id: number | null;
    onHide: () => void;
    refetch: () => void;
    branches: OptionProps[];
    disableBranch: boolean;
    products: OptionProps[];
    disableProduct: boolean;
}

const ProductDeliveryDateEditor = (props: Props) => {
    const {
        show,
        id,
        onHide,
        refetch: refetchDates,
        branches,
        disableBranch,
        products,
        disableProduct,
    } = props;
    const [loading, setLoading] = useState(false);
    const [loadingButtonSave, setLoadingButtonSave] = useState(false);

    const formRef: any = useRef<FormHandles>(null);
    const { control, watch, register, setValue, errors, handleSubmit, reset } =
        useForm({
            defaultValues: {
                id: null,
                productDescription: '',
                productErpCode: '',
                boardingDate: '',
                tenantid: '',
                productQuantity: '',
                productOrigin: '',
                productOrderProcessNumber: '',
            },
        });

    const formData: any = watch();

    const loadProductDeliveryDate = useMutation(
        async (dateId: string) => ProductDeliveryDateHttpService.show(dateId),
        {
            onMutate: () => {
                setLoading(true);
            },
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao carregar o produto');
            },
            onSuccess: async (response) => {
                if (response.data) {
                    setTimeout(() => {
                        setValue('id', response?.data.id);
                        setValue(
                            'productErpCode',
                            response?.data.productErpCode,
                        );
                        setValue(
                            'productDescription',
                            response?.data.productDescription,
                        );
                        setValue(
                            'boardingDate',
                            new Date(response?.data.boardingDate),
                        );
                        setValue('tenantid', response?.data.tenantid);
                        setValue(
                            'productQuantity',
                            response?.data.productQuantity,
                        );
                        setValue('productOrigin', response?.data.productOrigin);
                        setValue(
                            'productOrderProcessNumber',
                            response?.data.productOrderProcessNumber || '',
                        );
                    }, 0);
                }
            },
            onSettled: async () => {
                setLoading(false);
            },
        },
    );

    const saveProductDeliveryDate = useMutation(
        async () =>
            formData.id
                ? // eslint-disable-next-line no-return-await
                  await ProductDeliveryDateHttpService.update(formData)
                : // eslint-disable-next-line no-return-await
                  await ProductDeliveryDateHttpService.store(formData),
        {
            onMutate: () => {
                setLoadingButtonSave(true);
            },
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao salvar o produto');
            },
            onSuccess: async () => {
                toast.success('Produto salvo com sucesso');

                onHide();
                refetchDates();
            },
            onSettled: async () => {
                setLoadingButtonSave(false);
            },
        },
    );

    const onSubmit = async () => {
        await saveProductDeliveryDate.mutateAsync();
    };

    function removeErpCode(description: string) {
        if (!description) {
            return '';
        }

        return description.split(' - ').slice(1).join(' - ');
    }

    const handleChangeProduct = (e: any) => {
        setValue('productErpCode', e?.value || '');
        setValue('productDescription', removeErpCode(e?.label));
    };

    const handleChangeBranch = (e: any) => {
        setValue('tenantid', e?.value || '');
    };

    useEffect(() => {
        if (!show) {
            return;
        }

        if (id) {
            loadProductDeliveryDate.mutateAsync(id.toString());

            return;
        }

        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{id ? 'Editar' : 'Novo'} produto</Modal.Title>
                </Modal.Header>

                <Modal.Body className="mb-2">
                    {loading ? (
                        <Row>
                            <Col md={12} className="text-center">
                                <Spinner animation="border" />
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col
                                xs="12"
                                md="12"
                                style={{ paddingBottom: '15px' }}
                            >
                                <Form.Control
                                    ref={register}
                                    name="id"
                                    type="hidden"
                                    disabled
                                />

                                <Form.Control
                                    ref={register}
                                    name="productDescription"
                                    type="hidden"
                                    disabled
                                />
                                <Form.Label>Produto</Form.Label>
                                <Controller
                                    rules={{ required: 'Campo obrigatório' }}
                                    control={control}
                                    name="productErpCode"
                                    render={() => (
                                        <Select
                                            placeholder={
                                                disableProduct
                                                    ? 'Carregando produtos...'
                                                    : 'Selecione...'
                                            }
                                            className="form-control-nexen"
                                            isClearable
                                            onChange={(e) =>
                                                handleChangeProduct(e)
                                            }
                                            value={products.find(
                                                (product: OptionProps) =>
                                                    product.value ===
                                                    formData.productErpCode,
                                            )}
                                            options={products}
                                            isDisabled={disableProduct}
                                            styles={{
                                                control: tradeControl,
                                                option: optionsTheme,
                                            }}
                                            theme={theme}
                                        />
                                    )}
                                />
                                {errors.productErpCode?.message && (
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className="d-block"
                                    >
                                        {errors.productErpCode?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col
                                xs="12"
                                md="4"
                                style={{ paddingBottom: '15px' }}
                            >
                                <Form.Label>Filial</Form.Label>
                                <Controller
                                    rules={{ required: 'Campo obrigatório' }}
                                    control={control}
                                    name="tenantid"
                                    render={() => (
                                        <Select
                                            placeholder={
                                                disableBranch
                                                    ? 'Carregando filiais...'
                                                    : 'Selecione...'
                                            }
                                            className="form-control-nexen"
                                            isClearable
                                            onChange={(e) =>
                                                handleChangeBranch(e)
                                            }
                                            value={branches.find(
                                                (branch: OptionProps) =>
                                                    branch.value ===
                                                    formData.tenantid,
                                            )}
                                            options={branches}
                                            isDisabled={disableBranch}
                                            styles={{
                                                control: tradeControl,
                                                option: optionsTheme,
                                            }}
                                            theme={theme}
                                        />
                                    )}
                                />
                                {errors.tenantid?.message && (
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className="d-block"
                                    >
                                        {errors.tenantid.message}
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col
                                xs="12"
                                md="4"
                                style={{ paddingBottom: '15px' }}
                            >
                                <Form.Label>Disponibilidade</Form.Label>
                                <Controller
                                    rules={{ required: 'Campo obrigatório' }}
                                    render={({ onChange, value }) => (
                                        <ReactDatePicker
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/AAAA"
                                            selected={value}
                                            onChange={onChange}
                                        />
                                    )}
                                    control={control}
                                    name="boardingDate"
                                    defaultValue=""
                                />
                                {errors.boardingDate?.message && (
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className="d-block"
                                    >
                                        {errors.boardingDate?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col xs="12" md="4">
                                <Form.Label>Origem</Form.Label>
                                <Controller
                                    rules={{ required: 'Campo obrigatório' }}
                                    render={({ onChange }) => (
                                        <>
                                            <Form.Check
                                                style={{ display: 'flex' }}
                                                checked={
                                                    formData.productOrigin ===
                                                    'IMPORTADO'
                                                }
                                                label="Importado"
                                                type="radio"
                                                value="IMPORTADO"
                                                name="productOrigin"
                                                onChange={onChange}
                                            />
                                            <Form.Check
                                                style={{ display: 'flex' }}
                                                checked={
                                                    formData.productOrigin ===
                                                    'NACIONAL'
                                                }
                                                label="Nacional"
                                                type="radio"
                                                value="NACIONAL"
                                                name="productOrigin"
                                                onChange={onChange}
                                            />
                                        </>
                                    )}
                                    control={control}
                                    name="productOrigin"
                                    defaultValue=""
                                />
                                {errors.productOrigin?.message && (
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className="d-block"
                                    >
                                        {errors.productOrigin?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col xs="12" md="4">
                                <Form.Label>Quantidade</Form.Label>
                                <Controller
                                    rules={{ required: 'Campo obrigatório' }}
                                    render={({ onChange, value }) => (
                                        <FormControl
                                            type="number"
                                            placeholder="Digite aqui..."
                                            value={value}
                                            onChange={onChange}
                                            min={1}
                                        />
                                    )}
                                    control={control}
                                    name="productQuantity"
                                />
                                {errors.productQuantity?.message && (
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className="d-block"
                                    >
                                        {errors.productQuantity?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col xs="12" md="4">
                                <Form.Label>
                                    Número do processo/pedido
                                </Form.Label>
                                <Controller
                                    render={({ onChange, value }) => (
                                        <FormControl
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                    control={control}
                                    name="productOrderProcessNumber"
                                    defaultValue=""
                                />
                            </Col>
                        </Row>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <StyledOutlineButton onClick={() => onHide()}>
                        Cancelar
                    </StyledOutlineButton>

                    <StyledConfirmButton variant="primary" type="submit">
                        {loadingButtonSave ? (
                            <Spinner animation="border" size="sm" />
                        ) : (
                            'Salvar'
                        )}
                    </StyledConfirmButton>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ProductDeliveryDateEditor;
