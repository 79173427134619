import React from 'react';

const ExchangesRefundsAndReturnsPolicy: React.FC = () => (
    <div>
        <p>1. TERMOS E CONDIÇÕES GERAIS</p>
        <p>
            <span>
                <span>
                    <span>
                        1.1 A nexen.com.br se dedica para disponibilizar aos
                        seus clientes o acesso a uma grande variedade de
                        produtos, através de parcerias com fornecedores
                        conceituados no mercado que nos permitam oferecer o
                        melhor preço e qualidade, mas, acima de tudo,
                        preocupa-se em lhes proporcionar uma experiência de
                        compra agradável e satisfatória, em quaisquer
                        circunstâncias.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        1.1.1 Nesse sentido, disponibilizamos nossa Política de
                        Trocas e Devoluções, para que todas as informações e
                        regras possam ser facilmente acessadas pelos
                        consumidores e assim estes possam conhecer e compreender
                        detalhadamente os nossos processos antes mesmo da
                        realização da compra.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        1.1.2 Todos os processos contidos nessa Política,
                        observam, preservam e garantem os direitos e garantias
                        previstos no Código de Defesa do Consumidor e demais
                        normas e legislações aplicáveis às relações de consumo.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        1.2 Todas as solicitações de trocas e devoluções de
                        produtos são criteriosamente analisadas por nossa
                        Central de Atenção ao Cliente, que ficará responsável
                        por comunicar o cliente em até 24hrs (ou 48h no caso de
                        final de semana) com as devidas orientações quanto a
                        melhor solução, sempre privilegiando aquela que se dê no
                        menor prazo.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        1.2.1 A colaboração do cliente, no sentido de atender a
                        todas as orientações da Central de Atendimento, é
                        imprescindível para o sucesso do processo de troca ou
                        devolução.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2. HIPÓTESES EM QUE É CABÍVEL A TROCA OU DEVOLUÇÃO DE
                        PRODUTOS À NEXEN.COM.BR:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        As solicitações de troca ou devolução de produto à{' '}
                        <span>
                            <span>
                                <span>
                                    <span>nexen.com.br </span>
                                </span>
                            </span>
                        </span>
                        somente serão cabíveis e, consequentemente, acatadas
                        pela{' '}
                        <span>
                            <span>
                                <span>
                                    <span>nexen.com.br </span>
                                </span>
                            </span>
                        </span>
                        &nbsp;nas situações em que ficar caracterizado:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>- Produto em desacordo com a compra;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Produto avariado no transporte ou com defeito de
                        fabricação (inclusive falta de peças);
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>2.1 Produto em desacordo com a compra:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.1 Se constatar, durante a entrega, que o produto
                        possui qualquer caraterística divergente do anúncio, que
                        ele é incompatível com a compra ou que possui
                        discrepância de quantidade em relação à descrição da
                        Nota Fiscal, o cliente DEVE:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Recusar o recebimento, descrevendo o motivo no verso
                        da Nota Fiscal e solicitar ao entregador a devolução do
                        produto para a{' '}
                        <span>
                            <span>
                                <span>
                                    <span>nexen.com.br</span>
                                </span>
                            </span>
                        </span>
                        ;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Entrar em contato com nossa Central de Atendimento ao
                        Cliente para informar a recusa do recebimento e relatar
                        o motivo.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.2 Caso não tenha verificado o desacordo durante a
                        entrega, o prazo para reclamar a troca ou cancelamento
                        da compra junto a Central de Atendimento é de até 07
                        (sete) dias corridos após o recebimento. Em nenhuma
                        hipótese será concedido o direito a troca ou devolução,
                        se a solicitação ocorrer fora dos prazos estipulados.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.3 Para formalizar sua reclamação o cliente DEVE:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Entrar em contato com nossa Central de Atendimento ao
                        Cliente através dos canais de atendimento detalhando a
                        divergência e enviar fotos do produto recebido e de
                        todas as etiquetas coladas em sua embalagem.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.4 Nossa equipe realizará análise da ocorrência e,
                        sendo cabível, providenciará o envio do produto correto
                        ou o cancelamento do pedido e estorno do seu pagamento,
                        mediante devolução do produto divergente.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.5&nbsp; Via de regra, o cliente poderá escolher se
                        deseja a Troca ou o Cancelamento do pedido, contudo, se
                        o produto solicitado para troca não for mais produzido
                        pelo fabricante e os estoques estiverem esgotados,
                        caberá apenas a restituição de valores. A nexen.com.br
                        não trabalha com Vale-Trocas.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.4 O envio do produto correto e o estorno do
                        pagamento estão condicionados à devolução do produto em
                        desacordo para a nexen.com.br e serão realizados somente
                        após a chegada deste ao nosso Centro de Distribuição.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.5 Após o recebimento, nenhum produto poderá ser
                        devolvido à nexen.com.br sem comunicação prévia à
                        Central de Atendimento.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.6 Para as compras realizadas com as modalidades de
                        entrega “Entrega econômica” e “Entrega expressa”, a
                        coleta do produto será de responsabilidade da
                        nexen.com.br e não gerará nenhum custo para o cliente. O
                        recolhimento ocorrerá exclusivamente no mesmo local em
                        que se deu a entrega, não sendo possível sua alteração,
                        em nenhuma hipótese.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.9 O produto deve ser devolvido em perfeito estado,
                        na embalagem original e acompanhado de todas as peças,
                        acessórios e manuais que o acompanham.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.10 A nexen.com.br isenta-se da obrigação de cancelar
                        o pedido e estornar pagamento, trocar ou consertar
                        qualquer produto que apresente indícios de uso,
                        instalação e montagem.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.11 O prazo para conclusão do processo de troca ou
                        cancelamento e estorno de valores está associado ao tipo
                        de produto, e pode ser influenciado por fatores como
                        indisponibilidade de estoque, logística de retorno,
                        logística de distribuição, sistema de armazenamento,
                        entre outros.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.12 Em todos os casos, o cliente será mantido
                        informado sobre o andamento do processo, bem como
                        poderá, a qualquer tempo, solicitar atualização das
                        informações junto a Central de Atendimento ao Cliente.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>&nbsp;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.2&nbsp; Produto avariado no transporte ou com defeito
                        de fabricação (inclusive falta de peças):
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.2.1 Se, no momento da entrega, constatar danos na
                        embalagem e/ou produto ou defeito de fabricação o
                        cliente DEVE:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Recusar o recebimento, descrevendo o motivo no verso
                        da Nota Fiscal e solicitar ao entregador a devolução do
                        produto para a nexen.com.br;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Entrar em contato com nossa Central de Atendimento ao
                        Cliente para informar a recusa do recebimento e relatar
                        o motivo.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.2 Caso não tenha verificado a divergência durante a
                        entrega, o prazo para reclamar a troca ou cancelamento
                        da compra junto a Central de Atendimento é de até 07
                        (sete) dias corridos após o recebimento. Em nenhuma
                        hipótese será concedido o direito a troca ou devolução,
                        se a solicitação ocorrer fora dos prazos estipulados.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.3 Para formalizar sua reclamação o cliente DEVE:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Entrar em contato com nossa Central de Atendimento ao
                        Cliente através dos canais de atendimento detalhando as
                        avarias ou defeitos, enviar fotos do produto recebido e
                        da sua embalagem.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.4 Nossa equipe realizará a análise da ocorrência e,
                        sendo cabível, providenciará envio de um novo item em
                        perfeito estado ou o cancelamento do pedido e estorno do
                        seu pagamento, mediante devolução do produto
                        avariado/defeituoso.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.5&nbsp; Via de regra, o cliente poderá escolher se
                        deseja a Troca ou o Cancelamento do pedido, contudo, se
                        o produto solicitado para troca não for mais produzido
                        pelo fabricante e os estoques estiverem esgotados,
                        caberá apenas a restituição de valores. A nexen.com.br
                        não trabalha com Vale-Trocas.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.6 O envio do novo item e o estorno do pagamento
                        estão condicionados à devolução do produto
                        avariado/defeituoso para a nexen.com.br e serão
                        realizados somente após a chegada deste ao nosso Centro
                        de Distribuição.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.7 Após o recebimento, nenhum produto poderá ser
                        devolvido à nexen.com.br sem comunicação prévia à
                        Central de Atendimento.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.8 Para as compras realizadas com as modalidades de
                        entrega “Entrega econômica” e “Entrega expressa”, a
                        coleta do produto será de responsabilidade da
                        nexen.com.br e não gerará nenhum custo para o cliente. O
                        recolhimento ocorrerá exclusivamente no mesmo local em
                        que se deu a entrega, não sendo possível sua alteração,
                        em nenhuma hipótese.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.11 O produto deve ser devolvido em perfeito estado,
                        na embalagem original e acompanhado de todas as peças,
                        acessórios e manuais que o acompanham.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.12 A nexen.com.br isenta-se da obrigação de cancelar
                        o pedido e estornar pagamento, trocar ou consertar
                        qualquer produto que apresente indícios de uso,
                        instalação e montagem.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.13 O prazo para conclusão do processo de troca ou
                        cancelamento e estorno de valores está associado ao tipo
                        de produto, e pode ser influenciado por fatores como
                        indisponibilidade de estoque, logística de retorno,
                        logística de distribuição, sistema de armazenamento,
                        entre outros.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.14 Em todos os casos, o cliente será mantido
                        informado sobre o andamento do processo, bem como
                        poderá, a qualquer tempo, solicitar atualização das
                        informações junto a Central de Atendimento ao Cliente.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.15 Se o cliente não tiver reclamado junto a Central
                        de Atendimento no prazo de troca ou cancelamento (7 dias
                        corridos após o recebimento), mas estiver no prazo de
                        garantia oferecido pelo fabricante para o produto
                        adquirido, ou, sendo desde logo a sua preferência,
                        poderá ser atendido por meio de Assistência Técnica, por
                        meio da qual a nexen.com.br realizará o envio de novas
                        peças para substituição das que apresentaram problema ou
                        faltantes em um prazo máximo de 30 dias corridos.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.16 O referido prazo de 30 dias para conclusão do
                        processo de assistência técnica será contado a partir da
                        correta indicação da(s) peça(s) pelo cliente, o qual tem
                        o dever de informar o número e descrição da(s) peça(s),
                        conforme estiver descrito no manual de montagem do
                        fabricante.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.17&nbsp; No formato da assistência técnica não será
                        necessário a devolução total do produto, nem das peças
                        avariadas/defeituosas, as quais poderão ser descartadas
                        pelo cliente.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.18 O prazo de garantia de todos os produtos vendidos
                        no site Nexen.com.br é de, no mínimo, 90 dias. Porém,
                        cada produto pode possuir um prazo diferenciado, o qual
                        sempre está informado na Ficha Técnica, na página do
                        produto e poderá ser visualizado pelo cliente antes
                        mesmo de realizar a compra.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1.19 NÃO serão assegurados pela Nexen ou pela
                        Indústria fabricante:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Defeitos e danos informados/reclamados pelo cliente
                        fora do prazo de garantia;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Defeitos e danos causados por culpa exclusiva de
                        terceiro ou do cliente, (ex.: transporte após retirada
                        na loja);
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Defeitos e danos causados por erro em serviços de
                        instalação ou montagem;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Defeitos e danos causados por armazenagem de produtos
                        em desacordo com as instruções do fabricante;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Defeitos e danos causados por mau uso ou desgaste
                        natural do produto;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Defeitos e danos causados por condição adversa do
                        local de instalação e armazenamento (ex. umidade,
                        infiltração, poeira, desnível, exposição direta a luz do
                        sol, etc.);
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Defeitos e danos causados por caso fortuito (ex.: ação
                        da natureza, quedas, manuseio incorreto, etc.).{' '}
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>2.3 Arrependimento ou desistência:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        O cliente poderá se arrepender/desistir da compra em até
                        7 dias corridos após o recebimento do produto, de acordo
                        com o previsto no Código de Defesa do Consumidor. Para
                        isso, DEVERÁ:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.3.1.1 Antes da emissão da NF ou expedição do produto:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Entrar em contato com a Central de Atendimento ao
                        Cliente, através dos canais de atendimento, para
                        formalizar seu pedido de cancelamento por
                        arrependimento/desistência.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.3.1.2 Após a expedição do produto (durante o trânsito
                        do produto para o endereço de entrega):
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Recusar o recebimento do produto, descrevendo o motivo
                        no verso da Nota Fiscal e solicitar ao entregador a
                        devolução do mesmo para a nexen.com.br;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Contatar a Central de Atendimento ao Cliente, através
                        dos canais de atendimento, para informar sobre a recusa
                        e formalizar seu pedido de cancelamento por
                        arrependimento/desistência.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>2.3.1.2 Após o recebimento do produto:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Entrar em contato com a Central de Atendimento ao
                        Cliente, através dos canais de atendimento, para
                        formalizar seu pedido de cancelamento por
                        arrependimento/desistência;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        - Viabilizar a devolução do produto à schumann.com.br,
                        de acordo a modalidade de entrega escolhida na compra,
                        quais sejam:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        a) Para as compras realizadas com as modalidades de
                        entrega Entrega Econômica e Entrega expressa:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        A coleta do produto será de responsabilidade da
                        nexen.com.br e não gerará nenhum custo para o cliente. O
                        recolhimento ocorrerá exclusivamente no mesmo local em
                        que se deu a entrega, não sendo possível sua alteração,
                        em nenhuma hipótese.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.3.2 Em todas as solicitações de cancelamento por
                        arrependimento/desistência, nossa equipe realizará a
                        análise e retornará o contato em até 24 horas (ou 48
                        horas no caso de final de semana) com informações
                        relativas a devolução do produto, cancelamento do pedido
                        e estorno dos valores pagos, conforme nossa Política de
                        Reembolso.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.3.3 Via de regra, o estorno do valor da compra será
                        realizado somente após o retorno do produto ao nosso
                        Centro de Distribuição e análise do mesmo pela equipe
                        técnica responsável.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.3.4 O estorno imediato somente ocorrerá se o
                        arrependimento/desistência for reclamado antes da
                        emissão da Nota Fiscal e expedição do produto, ou seja,
                        se o produto ainda não tiver saído do Centro de
                        Distribuição da nexen.com.br.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>&nbsp;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>3 POLÍTICA DE REEMBOLSO:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        3.1 A nexen.com.br preserva o direito do consumidor,
                        facultando a ele o direito de cancelamento da compra e a
                        devida restituição de valores, nas situações em que o
                        mesmo é cabível, observados os prazos e situações
                        previstas na Política de Trocas e Devoluções.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        3.2 Os estornos serão realizados conforme os prazos e
                        condições estabelecidas e de acordo com a circunstância
                        da solicitação, conforme segue:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        a) Devoluções de produtos por desacordo, avaria, defeito
                        ou arrependimento/desistência após o recebimento ou
                        durante o seu trânsito para o endereço de entrega:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        O estorno de valores está condicionado à devolução e
                        chegada do produto ao Centro de Distribuição da
                        nexen.com.br. A liberação do estorno será concedida em
                        até 3 dias úteis após a análise do produto devolvido
                        pela equipe técnica responsável, observados os critérios
                        estabelecidos na Política de Trocas e Devoluções.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>&nbsp;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        b) Arrependimento/desistência da compra antes da emissão
                        da Nota Fiscal e expedição do produto:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        O cancelamento do pedido e estorno dos valores será
                        providenciado em até 72 horas da solicitação formal
                        junto a Central de Atendimento ao Cliente através dos
                        canais de atendimento.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>&nbsp;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        3.2.1 O estorno imediato somente ocorrerá se o produto
                        não tiver sido expedido, ou seja, se produto não tiver
                        saído do Centro de Distribuição da nexen.com.br.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        3.3 Nos casos de cancelamento da compra, a nexen.com.br
                        providenciará a restituição dos valores ao cliente sob a
                        mesma forma de pagamento utilizada no momento da compra.
                        Em nenhuma hipótese serão realizados estornos em
                        modalidade diversa.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>&nbsp;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        3.3.1 Se o pagamento tiver sido realizado com Cartão de
                        Crédito:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        A Nexen realizará apenas a autorização de estorno da
                        transação para a operadora do cartão e enviará o
                        referido comprovante ao cliente. O efetivo repasse dos
                        valores (lançamento em fatura) é responsabilidade da
                        operadora, que o fará em até 02 (duas) faturas
                        subsequentes no formato que considerar adequado, podendo
                        ser em 01 (uma) única vez ou então na mesma quantidade
                        de parcelas que foi realizada a compra.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        3.3.2 Se o pagamento tiver sido realizado com Boleto
                        Bancário:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        A nexen.com.br realizará o reembolso dos valores pagos
                        por meio de depósito bancário, em conta corrente em nome
                        do titular da compra, a ser informada pelo cliente à
                        Central de Atendimento, quando solicitado. O respetivo
                        comprovante de depósito será enviado para o cliente para
                        que possa identificar a concretização do reembolso.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        3.3.2.1 Caso o cliente se recuse a fornecer os dados
                        bancários para reembolso, a restituição será realizada
                        mediante ordem de pagamento bancária, na Caixa Econômica
                        Federal, ou mediante ação judicial de consignação e
                        pagamento, a critério exclusivo da nexen.com.br, sendo
                        que, na segunda hipótese, o cliente deverá arcar com os
                        custos processuais.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        3.4 O valor do frete será devolvido ao cliente somente
                        na hipótese de devolução integral da compra (todos os
                        produtos). Nas situações de devolução parcial da compra,
                        o valor de frete não será restituído ao cliente.
                    </span>
                </span>
            </span>
        </p>
    </div>
);

export default ExchangesRefundsAndReturnsPolicy;
