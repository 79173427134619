import book from '../assets/icons/book.svg';
import house from '../assets/icons/house.svg';
import people from '../assets/icons/people.svg';
import region from '../assets/icons/region.svg';
import settings from '../assets/icons/settings.svg';
import truck from '../assets/icons/truck.svg';
import discount from '../assets/icons/discount.svg';
import Role from '../constants/roles';
import sellerStatus from '../constants/sellerStatus';
import userHasRoles from '../utils/userHasRoles';

export interface Suboption {
    sidebarIcon: string;
    text: string;
    path: string;
    allowedRoles: Role[];
}

export interface Option {
    icon: string;
    sidebarIcon: string;
    text: string;
    path: string;
    suboptions?: Suboption[];
    allowedRoles: Role[];
}

const home: Option = {
    icon: house,
    sidebarIcon: 'PiHouse',
    text: 'Início',
    path: '/dashboard',
    allowedRoles: [
        Role.Administrator,
        Role.Registration,
        Role.Integrator,
        Role.Manager,
        Role.Contributor,
        Role.CommercialSupervisor,
        Role.Commercial,
        Role.Financial,
        Role.CustomerSuccess,
        Role.TechnicalSupport,
        Role.PointsAdministrator,
        Role.Logistics,
        Role.Production,
        Role.Purchase,
    ],
};

const homeForNotApprovedSeller: Option = {
    icon: house,
    sidebarIcon: 'PiHouse',
    text: 'Início',
    path: '/documents',
    allowedRoles: [Role.Integrator],
};

const peopleOpt: Option = {
    icon: people,
    sidebarIcon: 'PiUser',
    text: 'Pessoas',
    path: '',
    allowedRoles: [
        Role.Administrator,
        Role.Registration,
        Role.Integrator,
        Role.Manager,
        Role.Contributor,
        Role.CommercialSupervisor,
        Role.Commercial,
        Role.CustomerSuccess,
        Role.TechnicalSupport,
        Role.PointsAdministrator,
    ],
};

const sellers: Suboption = {
    sidebarIcon: 'PiHandshake',
    text: 'Integradores',
    path: '/integrators',
    allowedRoles: [
        Role.Administrator,
        Role.Registration,
        Role.CommercialSupervisor,
        Role.Commercial,
        Role.CustomerSuccess,
        Role.TechnicalSupport,
        Role.PointsAdministrator,
    ],
};

const users: Suboption = {
    sidebarIcon: 'PiUsersThree',
    text: 'Usuários',
    path: '/users',
    allowedRoles: [Role.Administrator],
};

const customers: Suboption = {
    sidebarIcon: 'PiUser',
    text: 'Clientes',
    path: '/clientes',
    allowedRoles: [
        Role.Administrator,
        Role.Integrator,
        Role.Manager,
        Role.Contributor,
        Role.CommercialSupervisor,
        Role.Commercial,
        Role.CustomerSuccess,
        Role.TechnicalSupport,
        Role.PointsAdministrator,
    ],
};

const contributors: Suboption = {
    sidebarIcon: 'LiaUserTieSolid',
    text: 'Colaboradores',
    path: '/contributors',
    allowedRoles: [Role.Administrator, Role.Integrator, Role.Manager],
};

const regions: Option = {
    icon: region,
    sidebarIcon: 'PiMapPin',
    text: 'Regiões',
    path: '/regioes',
    allowedRoles: [Role.Administrator, Role.CommercialSupervisor],
};

const configurationsProducts: Option = {
    icon: settings,
    sidebarIcon: 'PiGear',
    text: 'Configurações',
    path: '/configurations/products',
    allowedRoles: [Role.Administrator, Role.Purchase],
};

const configurationsOnlineStore: Option = {
    icon: settings,
    sidebarIcon: 'PiGear',
    text: 'Configurações',
    path: '/configurations/onlineStore',
    allowedRoles: [Role.TechnicalSupport],
};

const configurationsContacts: Option = {
    icon: settings,
    sidebarIcon: 'PiGear',
    text: 'Configurações',
    path: '/configurations/contacts',
    allowedRoles: [Role.CommercialSupervisor, Role.Commercial],
};

const configurationsBanners: Option = {
    icon: settings,
    sidebarIcon: 'PiGear',
    text: 'Configurações',
    path: '/configurations/banners',
    allowedRoles: [Role.Administrator, Role.Marketing],
};

const libraries: Option = {
    icon: book,
    sidebarIcon: 'PiBooks',
    text: 'Bibliotecas',
    path: '/library/libraries',
    allowedRoles: [Role.Administrator, Role.TechnicalSupport],
};

const returns: Option = {
    icon: truck,
    sidebarIcon: 'PiTruck',
    text: 'Devoluções',
    path: '/returns',
    allowedRoles: [
        Role.Administrator,
        Role.Logistics,
        Role.Production,
        Role.Financial,
        Role.CommercialSupervisor,
    ],
};

const discounts: Option = {
    icon: discount,
    sidebarIcon: 'PiPercent',
    text: 'Descontos',
    path: '/discounts',
    allowedRoles: [Role.Administrator],
};

export default class MenuService {
    public static get(user: any) {
        const menu: Option[] = [];

        if (!user) {
            return menu;
        }

        if (
            userHasRoles(user, [Role.Integrator]) &&
            user.seller?.status !== sellerStatus.Approved
        ) {
            menu.push(homeForNotApprovedSeller);

            return menu;
        }

        if (userHasRoles(user, home.allowedRoles)) {
            menu.push(home);
        }

        if (userHasRoles(user, peopleOpt.allowedRoles)) {
            const suboptions = [];

            if (userHasRoles(user, sellers.allowedRoles)) {
                suboptions.push(sellers);
            }

            if (userHasRoles(user, users.allowedRoles)) {
                suboptions.push(users);
            }

            if (userHasRoles(user, customers.allowedRoles)) {
                suboptions.push(customers);
            }

            if (userHasRoles(user, contributors.allowedRoles)) {
                suboptions.push(contributors);
            }

            const peopleFullOpt = { ...peopleOpt, suboptions };

            menu.push(peopleFullOpt);
        }

        if (userHasRoles(user, regions.allowedRoles)) {
            menu.push(regions);
        }

        if (userHasRoles(user, configurationsProducts.allowedRoles)) {
            menu.push(configurationsProducts);
        } else if (userHasRoles(user, configurationsOnlineStore.allowedRoles)) {
            menu.push(configurationsOnlineStore);
        } else if (userHasRoles(user, configurationsContacts.allowedRoles)) {
            menu.push(configurationsContacts);
        } else if (userHasRoles(user, configurationsBanners.allowedRoles)) {
            menu.push(configurationsBanners);
        }

        if (userHasRoles(user, libraries.allowedRoles)) {
            menu.push(libraries);
        }

        if (userHasRoles(user, returns.allowedRoles)) {
            menu.push(returns);
        }

        if (userHasRoles(user, discounts.allowedRoles)) {
            menu.push(discounts);
        }

        return menu;
    }
}
