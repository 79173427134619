/* eslint-disable react/destructuring-assignment */
/* eslint-disable indent */
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import { toast } from 'react-toastify';
import { AuthValues, useAuth } from '../../../contexts/authContext';
import { StyledCancelButton, StyledConfirmButton, StyledRange } from './styles';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import getCroppedImg from '../../../utils/react-easy-crop/cropImage';
import { ReactComponent as ReactProfileIcon } from '../../../assets/icons/profile.svg';
import AuthHttpService from '../../../services/http/auth-http';

const ChangeAvatarModal = (props: any) => {
    const { user, setUser }: AuthValues = useAuth();
    const [loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState(
        user.avatar
            ? Buffer.from(user.avatar, 'base64').toString('ascii')
            : ('' as any),
    );
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [aspect] = useState(3 / 3);
    const [croppedImage, setCroppedImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });

    function readFile(file: any) {
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.addEventListener(
                'load',
                () => resolve(reader.result),
                false,
            );
            reader.readAsDataURL(file);
        });
    }

    const onFileChange = async (e: any) => {
        try {
            if (e.target.files && e.target.files.length > 0) {
                const ext = e.target.files[0].name
                    .split('.')
                    .pop()
                    .toLowerCase();

                if (!['jpeg', 'jpg', 'png'].includes(ext)) {
                    toast.info('Tipo de arquivo não permitido (JPG, PNG)');

                    return;
                }

                if (e.target.files[0].size > 102400) {
                    toast.info('Tamanho do arquivo excedido (100kb)');

                    return;
                }

                const file = e.target.files[0];

                const imageDataUrl: any = await readFile(file);

                setAvatar(imageDataUrl);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onCropChange = (newCrop: any) => {
        setCrop(newCrop);
    };

    const onZoomChange = (newZoom: any) => {
        setZoom(newZoom);
    };

    const onCropComplete = async (croppedArea: any, croppedAreaPixels: any) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const croppedImage: any = await getCroppedImg(
                avatar,
                croppedAreaPixels,
                rotation,
            );

            setCroppedImage(croppedImage);
        } catch (e) {
            console.error(e);
        }
    };

    // eslint-disable-next-line consistent-return
    const save = async () => {
        // if (!avatar || !croppedImage) {
        //     return toast.info('Escolha uma imagem');
        // }

        const data = {
            avatar: !avatar ? '' : croppedImage,
        };

        try {
            setLoading(true);

            const response = await AuthHttpService.updateUser(data);

            if (response) {
                toast.success('Imagem de perfil alterada com sucesso!');

                // eslint-disable-next-line @typescript-eslint/no-shadow
                const data = response.data?.avatar;
                const buff = data ? Buffer.from(data) : '';

                const newUser = {
                    avatar: buff,
                };

                setUser({ ...user, ...newUser });

                setLoading(false);

                props.onHide();
            }
        } catch (err) {
            setLoading(false);

            const error = err as any;

            if (isFriendlyHttpError(error)) {
                return toast.error(error.message);
            }

            return toast.error('Erro ao salvar imagem de perfil!');
        }
    };

    const cancel = () => {
        setAvatar(
            user.avatar
                ? Buffer.from(user.avatar, 'base64').toString('ascii')
                : '',
        );

        props.onHide();
    };

    useEffect(() => {
        setAvatar(
            user.avatar
                ? Buffer.from(user.avatar, 'base64').toString('ascii')
                : '',
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.avatar]);

    return (
        <Modal
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onExit={() => {}}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Alterar imagem de perfil
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col>
                        <Form>
                            <Form.File
                                id="custom-file"
                                className="custom-range"
                                label="Clique para carregar uma nova imagem"
                                data-browse="Selecionar"
                                onChange={onFileChange}
                                accept="image/*"
                                custom
                            />
                        </Form>
                    </Col>
                    {avatar && (
                        <Col md={4} className="text-right">
                            <Button
                                variant="outline-primary"
                                style={{ height: '37px' }}
                                size="lg"
                                onClick={() => setAvatar('')}
                            >
                                Remover imagem
                            </Button>
                        </Col>
                    )}
                </Row>

                <Row>
                    <Col
                        md={12}
                        className="mt-4 pa-4 text-center"
                        style={{ height: '280px' }}
                    >
                        {avatar ? (
                            <Cropper
                                image={avatar}
                                crop={crop}
                                zoom={zoom}
                                rotation={rotation}
                                cropShape="round"
                                aspect={aspect}
                                onCropChange={onCropChange}
                                onCropComplete={onCropComplete}
                                onZoomChange={onZoomChange}
                            />
                        ) : (
                            <ReactProfileIcon
                                className="mt-5"
                                width={200}
                                height={200}
                                fill="#cccccc"
                            />
                        )}
                    </Col>
                </Row>
                {avatar && (
                    <Row>
                        <Col>
                            <Form.Group controlId="formBasicRangeCustom">
                                <Form.Label>Zoom</Form.Label>
                                <StyledRange
                                    variant="primary"
                                    type="range"
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    value={zoom}
                                    onChange={(newZoom: any) =>
                                        setZoom(newZoom.target.value)
                                    }
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicRangeCustom">
                                <Form.Label>Rotação</Form.Label>
                                <StyledRange
                                    variant="primary"
                                    type="range"
                                    min={0}
                                    max={360}
                                    step={1}
                                    value={rotation}
                                    onChange={(event: any) =>
                                        setRotation(event.target.value)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                )}
            </Modal.Body>

            <Modal.Footer className="mt-4">
                <StyledCancelButton onClick={cancel}>
                    Cancelar
                </StyledCancelButton>
                <StyledConfirmButton onClick={save}>
                    {loading ? <Spinner animation="border" /> : 'Confirmar'}
                </StyledConfirmButton>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangeAvatarModal;
