import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Modal, Spinner } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { StyledConfirmButton, StyledCancelButton } from './styles';

import getValidationsErrors from '../../utils/getValidationsErrors';
import LibrariesModalInputs from '../../components/LibraryLayout/Libraries/Inputs';
import BrandsModalInputs from '../../components/LibraryLayout/Brands/Inputs';
import libraryRule from '../../utils/getInputRulesByTab';
import ModulesModalInputs from '../../components/LibraryLayout/Modules/Inputs';
import CategoriesModalInputs from '../../components/LibraryLayout/Categories/Inputs';
import { LibraryItemReference } from '../../constants/library';
import ItemsModalInputs from '../../components/LibraryLayout/Items/Inputs';
import {
    saveBrand,
    saveCategory,
    saveItem,
    saveLibrary,
    saveModule,
} from '../../services/libraries';

interface Props {
    id: number;
    tab: string;
    show: boolean;
    errors: any;
    disabled: string[];
    // eslint-disable-next-line no-unused-vars
    setErrors: (message: string) => void;
    // eslint-disable-next-line no-unused-vars
    setDisabled: (disabled: string[]) => void;
    onHide: () => void;
    onSave: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export function New({
    id,
    show,
    tab,
    errors,
    setErrors,
    onHide,
    onSave,
    disabled,
    setDisabled,
}: Props) {
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState<any>(new FormData());
    const { register, getValues, setValue, control } = useForm();

    const libraryOptions: any[] = [
        { label: 'Documentação', value: LibraryItemReference.Documentation },
        { label: 'Vídeo', value: LibraryItemReference.Video },
        { label: 'FAQ', value: LibraryItemReference.FAQ },
    ];

    const titles: any = {
        libraries: 'Nova biblioteca',
        items: 'Novo item',
        modules: 'Novo módulo',
        brands: 'Nova marca',
        categories: 'Nova categoria',
    };

    async function save(): Promise<void> {
        switch (tab) {
            case 'libraries':
                saveLibrary({
                    setLoading,
                    getValues,
                    onHide,
                    onSave,
                    id,
                });
                break;

            case 'items':
                saveItem({
                    setLoading,
                    getValues,
                    onHide,
                    onSave,
                    form,
                    id,
                });
                break;

            case 'modules':
                saveModule({
                    setLoading,
                    getValues,
                    onHide,
                    onSave,
                    id,
                });
                break;

            case 'brands':
                saveBrand({
                    setLoading,
                    getValues,
                    onHide,
                    onSave,
                    id,
                });
                break;

            case 'categories':
                saveCategory({
                    setLoading,
                    getValues,
                    onHide,
                    onSave,
                    id,
                });
                break;
            default:
        }
    }

    async function handleSubmit() {
        try {
            const fieldsData = getValues();

            if (!['items'].includes(tab)) {
                const schema = Yup.object().shape(libraryRule(tab));
                await schema.validate(fieldsData, {
                    abortEarly: false,
                    context: fieldsData,
                });
            }

            await save();
        } catch (error) {
            const err = error as any;
            setErrors(getValidationsErrors(err) as any);
        }
    }

    useEffect(() => {
        if (!show) setForm(new FormData());
    }, [show]);

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header>
                <Modal.Title
                    className="ml-3 mr-3"
                    id="contained-modal-title-vcenter"
                >
                    {id ? 'Editando item' : titles[tab]}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="w-100">
                {tab === 'libraries' && (
                    <LibrariesModalInputs
                        id={id}
                        register={register}
                        errors={errors}
                        control={control}
                        options={libraryOptions}
                        setValue={setValue}
                    />
                )}
                {tab === 'items' && (
                    <ItemsModalInputs
                        id={id}
                        register={register}
                        errors={errors}
                        control={control}
                        options={libraryOptions}
                        setForm={setForm}
                        setValue={setValue}
                        disabled={disabled}
                        setDisabled={setDisabled}
                    />
                )}
                {tab === 'brands' && (
                    <BrandsModalInputs
                        id={id}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                    />
                )}
                {tab === 'modules' && (
                    <ModulesModalInputs
                        id={id}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                    />
                )}
                {tab === 'categories' && (
                    <CategoriesModalInputs
                        id={id}
                        register={register}
                        errors={errors}
                        control={control}
                        setValue={setValue}
                    />
                )}
            </Modal.Body>
            <Modal.Footer className="mt-4">
                <StyledCancelButton onClick={onHide}>
                    Cancelar
                </StyledCancelButton>
                <StyledConfirmButton
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {loading ? <Spinner animation="border" /> : 'Salvar'}
                </StyledConfirmButton>
            </Modal.Footer>
        </Modal>
    );
}
