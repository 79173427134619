import Request from './request';

export default class LibraryHttpService {
    private static uri = 'libraries';

    public static getLibraries(data: any) {
        return Request.get(`${this.uri}`, data);
    }

    public static getLibrary(id: number, data?: any) {
        return Request.get(`${this.uri}/${id}`, data);
    }

    public static storeLibrary(data: {}) {
        return Request.post(`${this.uri}`, data);
    }

    public static patchLibrary(id: number, data: {}) {
        return Request.patch(`${this.uri}/${id}`, data);
    }

    public static deleteLibrary(id: number) {
        return Request.del(`${this.uri}/${id}`);
    }

    public static getModules(data: any) {
        return Request.get(`${this.uri}/modules`, data);
    }

    public static getModule(id: number, data?: any) {
        return Request.get(`${this.uri}/modules/${id}`, data);
    }

    public static storeModule(data: {}) {
        return Request.post(`${this.uri}/modules`, data);
    }

    public static patchModule(id: number, data: {}) {
        return Request.patch(`${this.uri}/modules/${id}`, data);
    }

    public static deleteModule(id: number) {
        return Request.del(`${this.uri}/modules/${id}`);
    }

    public static getBrands(data: any) {
        return Request.get(`${this.uri}/brands`, data);
    }

    public static getBrand(id: number, data?: any) {
        return Request.get(`${this.uri}/brands/${id}`, data);
    }

    public static storeBrand(data: {}) {
        return Request.post(`${this.uri}/brands`, data);
    }

    public static patchBrand(id: number, data: {}) {
        return Request.patch(`${this.uri}/brands/${id}`, data);
    }

    public static deleteBrand(id: number) {
        return Request.del(`${this.uri}/brands/${id}`);
    }

    public static getBrandsCategories(data: any) {
        return Request.get(`${this.uri}/brands/categories`, data);
    }

    public static getBrandCategory(id: number, data?: any) {
        return Request.get(`${this.uri}/brands/categories/${id}`, data);
    }

    public static storeBrandCategory(data: {}) {
        return Request.post(`${this.uri}/brands/categories`, data);
    }

    public static patchBrandCategory(id: number, data: {}) {
        return Request.patch(`${this.uri}/brands/categories/${id}`, data);
    }

    public static deleteBrandCategory(id: number) {
        return Request.del(`${this.uri}/brands/categories/${id}`);
    }

    public static getItems(data: any) {
        return Request.get(`${this.uri}/items`, data);
    }

    public static getItem(id: number, data?: any) {
        return Request.get(`${this.uri}/items/${id}`, data);
    }

    public static storeItem(data: {}) {
        return Request.post(`${this.uri}/items`, data);
    }

    public static patchItem(id: number, data: {}) {
        return Request.patch(`${this.uri}/items/${id}`, data);
    }

    public static deleteItem(id: number) {
        return Request.del(`${this.uri}/items/${id}`);
    }

    public static storeFiles(file: {}) {
        return Request.post(`${this.uri}/files`, file);
    }
}
