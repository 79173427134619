import React from 'react';

const PrivacyAndSecurity: React.FC = () => (
    <div>
        <p>
            <span>
                <span>
                    <span>1. TERMOS E CONDIÇÕES GERAIS</span>
                </span>
            </span>
        </p>
        <p>
            <span>
                <span>
                    <span>
                        1.1 Através do presente documento a nexen.com.br
                        pretende apresentar todo o mecanismo dos processos de
                        segurança e privacidade aplicados às compras realizadas
                        nesse site, ao qual o usuário estará sujeito. Pedimos,
                        portanto, que leia atentamente a todas as cláusulas
                        estabelecidas nessa política, antes de iniciar a
                        navegação, vez que, a utilização do site, qualquer que
                        seja o objetivo, sugere que o usuário reconhece ter
                        lido, entendido e concordado com os termos aqui
                        estabelecidos.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        1.2 As cláusulas dessa política poderão ser alteradas a
                        qualquer tempo, sem notificação prévia. Desta forma,
                        aconselha-se a sua leitura a cada novo acesso.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        1.3 Não havendo concordância quanto aos termos abaixo,
                        orientamos que a navegação seja suspendida
                        imediatamente.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        1.4 O site nexen.com.br é propriedade da Nexen Energias
                        Renováveis, inscrita sob o CNPJ nº 31.841.964/0001-96
                        com sede na Estrada Rosalino Nardi, KM 20, na cidade de
                        Arvoredo, estado de Santa Catarina – 89778-000.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>2. PROTEÇÃO E PRIVACIDADE DE DADOS</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.1 A nexen.com.br se preocupa com a privacidade dos
                        dados pessoais de seus usuários armazenados em sua base,
                        e compromete-se a utilizar todas as tecnologias que
                        sejam suficientemente adequadas para a proteção de tais
                        dados. Contudo, considerando que nenhum sistema de
                        segurança é absolutamente seguro, a Nexen se exime de
                        quaisquer responsabilidades por eventuais danos e/ou
                        prejuízos decorrentes de vírus, invasões no site e na
                        base de dados e demais falhas relacionadas, salvo se
                        configurado dolo ou culpa da empresa.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.2 O site nexen.com.br se reveste das medidas mais
                        modernas de segurança para proteger suas informações
                        pessoais. Faz-se uso de um sistema de segurança de
                        codificação para transações na internet, o que o torna
                        um ponto seguro na rede mundial de computadores. Todos
                        os dados cadastrais são guardados de forma criptografada
                        e não são comercializados, tampouco repassados a
                        terceiros, restringindo o seu acesso apenas às pessoas
                        devidamente autorizadas.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.3 O site nexen.com.br também trabalha com um firewall
                        que impede a entrada de usuários não autorizados. O
                        usuário estará em uma área segura sempre que um cadeado
                        for mostrado na margem inferior da sua tela.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.4 Qualquer ato que ameace a integridade da empresa e
                        clientes, assim como a segurança e privacidade,
                        incluindo propósitos ilegais, informações ou corrupção
                        de dados, violação de direitos intelectuais ou de
                        autoria, envio de mensagens com conteúdo racista,
                        pornográfico e ofensivo, assim como tentativa invasiva
                        de interferência ou interrupção dos serviços prestados
                        pelo site, gerará a revogação imediata de qualquer
                        relação entre a nexen.com.br e o usuário.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        2.5 A nexen.com.br realiza contatos por e-mail
                        (promoções, informativos e newsletter) e telefone,
                        apenas com a prévia autorização do consumidor, sendo
                        possível o cancelamento do recebimento a qualquer
                        momento.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>3. OBJETIVO DOS DADOS PESSOAIS E NAVEGACIONAIS:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        3.1 Uma das principais diretrizes da nexen.com.br é o
                        respeito pelo indivíduo. Assim, temos o compromisso de
                        manter suas informações pessoais sob o mais completo
                        sigilo, vinculando-as unicamente ao seu registro e
                        utilizando-as para:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>a) Identificar o usuário;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        b) Realizar a entrega e a cobrança dos produtos e
                        serviços adquiridos em nosso site;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        c) Facilitar as compras futuras do usuário
                        correspondente, sendo possível, contudo, alterar e
                        excluir as informações a qualquer momento;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        d) Obter estatísticas genéricas para identificação do
                        perfil e hábitos de consumo.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>&nbsp;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        3.2 As informações solicitadas aos usuários são as
                        mínimas necessárias para o que o site nexen.com.br possa
                        atingir a sua finalidade principal, que consiste em
                        disponibilizar ao consumidor produtos e serviços através
                        do comércio eletrônico.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        3.3 O usuário garante a veracidade e exatidão das
                        informações e dados que fornece ao site nexen.com.br,
                        assumindo a correspondente responsabilidade caso as
                        mesmas não sejam exatas, bem como se compromete em
                        mantê-las atualizadas. O site nexen.com.br não assume
                        qualquer responsabilidade em caso de danos causados pela
                        inexatidão dos dados informados.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        3.4 Ao se cadastrar no site nexen.com.br utilizando
                        dados de terceiros poderá incorrer nos crimes de falsa
                        identidade, estelionato ou outros, e, portanto,
                        responderá judicialmente por todo e qualquer dado
                        decorrente do ato criminoso.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        3.5 O consumidor poderá, a qualquer momento, solicitar
                        alteração e/ou retificação de seus dados, bastando para
                        tanto, acessar seu cadastro diretamente mediante
                        autenticação no site ou então em contato com a Central
                        de Atenção ao Cliente.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        3.6 A nexen.com.br, diante da natureza de seu negócio,
                        exige de seus usuários idade mínima de 18 anos. Caso
                        possuir menos de 18 anos, o registro em nosso site
                        deverá ser obrigatoriamente assistido por um
                        responsável.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>&nbsp;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>4. OBTENÇÃO DE DADOS:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>4.1.1 Pessoais:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        4.1.1.1&nbsp; São coletadas informações pessoais,
                        capazes de identificar os usuários, quando estes:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>a) Se cadastram no site nexen.com.br;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        b) Interagem com as diversas ferramentas existentes no
                        site nexen.com.br, fornecendo as informações
                        voluntariamente;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        c) Quando efetuam o pagamento de seu pedido através do
                        site nexen.com.br;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        d) Ao entrarem em contato com a Central de Atendimento
                        ao Cliente.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>&nbsp;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        4.1.1.2&nbsp; Todos os dados pessoais coletados serão
                        incorporados à base de dados deste site, cabendo a Nexen
                        o armazenamento destes dados.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        4.1.1.3 A Nexen poderá confirmar os dados pessoais
                        informados pelo usuário consultando entidades públicas,
                        companhias especializadas, banco de dados lícitos, para
                        o que fica expressamente autorizado. Tal atitude,
                        contudo, não cessa nem diminui a responsabilidade do
                        usuário quanto à exatidão e veracidade dos dados que
                        fornece, motivo pelo qual a Schumann poderá, a seu
                        exclusivo critério, suspender e/ou cancelar o cadastro
                        do usuário a qualquer momento, caso detecte qualquer
                        divergência.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        4.1.1.4 A Nexen poderá permanecer com o registro de
                        dados e informações dos internautas pelo período máximo
                        de 5 (cinco) anos para fins de auditoria e preservação
                        de direitos, com a faculdade de excluí-los
                        definitivamente segundo sua conveniência em período
                        menor.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>&nbsp;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>4.1.2 Navegacionais:</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        4.1.2.1 A Nexen também recebe e armazena
                        automaticamente, informações em seus servidores sobre as
                        atividades advindas do navegador, incluindo o endereço
                        IP e a página acessada, através dos cookies. Os cookies
                        permitem o reconhecimento dos computadores que acessam
                        os sites e acompanham a navegação do usuário dentro da
                        nossa página, de maneira a personalizá-la de acordo com
                        o perfil de cada cliente. Os cookies não armazenam
                        nenhuma informação pessoal, apenas contêm os dados de
                        login do usuário. De todo modo, você pode descartar o
                        cookie de seu computador quando desejar ou, ainda,
                        configurar seu browser de internet para rejeitá-los.
                        Ressaltamos, no entanto, que essa última hipótese pode
                        limitar algumas funcionalidades do site.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        4.2.1.2 Outras tecnologias poderão ser utilizadas para
                        obtenção de dados de navegação dos internautas. No
                        entanto, respeitarão sempre os termos dessa política e
                        as opções da coleta e armazenamento.
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        4.1.3 Utilização dos dados Pessoais e Navegacionais:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        4.1.3.1 Sem prejuízo das demais disposições descritas
                        nesta política, o usuário declara ter ciência e concorda
                        que a nexen.com.br poderá utilizar as informações
                        coletadas para os seguintes propósitos gerais:
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        a) Viabilizar a interatividade entre cliente e empresa;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>b) Informar a respeito de novos eventos;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        c) Aperfeiçoar a usabilidade e a experiência interativa
                        durante a navegação do usuário no site;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        d) Elaborar estatísticas gerais, sem que haja
                        identificação dos usuários;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>e) Garantir a segurança dos visitantes;</span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        f) Oferecer suporte eficiente a eventuais dúvidas ou
                        solicitações dos usuários;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        g) Constituir, defender ou exercer regularmente direitos
                        em âmbito judicial ou administrativo;
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span />
                </span>
            </span>
        </p>

        <p>
            <span>
                <span>
                    <span>
                        h) Realizar pesquisas e campanhas de comunicação e
                        marketing de relacionamento, bem como divulgar ofertas e
                        promoções.
                    </span>
                </span>
            </span>
        </p>
    </div>
);

export default PrivacyAndSecurity;
