import { signUpRoute } from '../routes/config';

export const control = (base: any, state: any) => ({
    ...base,
    height: 48,
    // eslint-disable-next-line no-nested-ternary
    border: state.isFocused
        ? '1px solid #8108D3'
        : state.selectProps.path === signUpRoute.path
        ? '1px solid #707070'
        : '1px solid #ced4da',
    borderRadius:
        state.selectProps.path === signUpRoute.path
            ? '0px 10px 10px 0px'
            : '0px',
    boxShadow: state.isFocused ? '0px 0px 6px rgba(0, 0, 0, 0.30)' : null,
    fontSize: 12,
});
export const multiControl = (base: any, state: any) => ({
    ...base,
    height: '100%',
    border: state.isFocused ? '1px solid rgba(47, 204, 139, 0.233)' : null,
    borderRadius: 3,
    boxShadow: state.isFocused ? '0px 0px 6px rgba(0, 0, 0, 0.30)' : null,
    fontSize: 12,
});

export const tradeControl = (base: any, state: any) => ({
    ...base,
    border: state.isFocused ? '1px solid rgba(47, 204, 139, 0.233)' : null,
    borderRadius: 3,
    boxShadow: state.isFocused ? '0px 0px 6px rgba(0, 0, 0, 0.30)' : null,
    minHeight: 48,
    backgroundColor: state.isDisabled ? '#e9ecef' : '#ffffff',
    fontSize: 12,
});

export const optionsTheme = (base: any) => ({
    ...base,
    color: '#000000',
});

export const theme = (base: any) => ({
    ...base,

    colors: {
        ...base.colors,
        primary: 'rgba(129,8,211, 0.5)',
        primary25: 'rgba(129,8,211, 0.1)',
        boxShadow: 'none',
    },
});
