import { Col, Image } from 'react-bootstrap';
import styled from 'styled-components';

export const HeaderTitle = styled.h1`
    font-weight: bold;
    font-size: 2rem;

    @media (max-width: 1170px) {
        font-size: 1.6rem;
        left: 42%;
    }

    @media (min-width: 1367px) {
        font-size: 2.4rem;
    }
`;

export const HeaderTitleSuccess = styled.h1`
    font-weight: bold;
    font-size: 2rem;

    background: -webkit-linear-gradient(
        left,
        #7800e1 0%,
        #f6700f 82.78%,
        #ffb855 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;

    @media (max-width: 1170px) {
        font-size: 1.6rem;
        left: 42%;
    }

    @media (min-width: 1367px) {
        font-size: 2.4rem;
    }
`;

export const StyledCol = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const StyledImage = styled(Image)`
    @media (max-width: 1170px) {
        margin-top: 30px;
    }
`;

export const StyledSpan = styled.span`
    font-weight: bold;

    @media (max-width: 1170px) {
        margin-top: 30px;
    }
`;
