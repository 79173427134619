import { Badge } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;

export const StyledPageTitle = styled.h3`
    font-size: 22px !important;
    font-weight: bolder !important;
`;

export const StyledPageSubTitle = styled.p`
    font-size: 14px !important;
    margin-bottom: 10px !important;
`;

export const StyledBadge = styled(Badge)`
    margin: 1%;
    background-color: rgba(47, 204, 139, 0.1);
    color: #2fcc8b;
    font-size: 0.9rem;
`;

export const StyledText = styled.span`
    padding: 9px;
    display: block;
`;
