/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-continue */
import React, { useEffect, useState } from 'react';
import {
    Col,
    Row,
    Form,
    Spinner,
    Image,
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip,
    Button,
} from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import LibraryHttpService from '../../../services/http/library-http';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../styles/react-select-config';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import { StyledRow, StyledTitle } from '../styles';
import fileUploadImg from '../../../assets/icons/fileUpload.png';
import { LibraryItemReference } from '../../../constants/library';
import fileExtensionsAllowed from '../../../constants/fileExtensionsAllowed';

function ItemsModalInputs({
    register,
    control,
    options,
    setForm,
    id,
    setValue,
    disabled,
    setDisabled,
}: any) {
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<any>(null);
    const [optionsLibraries, setOptionsLibraries] = useState([]);
    const [optionsBrands, setOptionsBrands] = useState([]);
    const [optionsModules, setOptionsModules] = useState([]);
    const [optionsCategories, setOptionsCategories] = useState([]);
    const [ignoredTabs, setIgnoredTabs] = useState<string[]>([]);
    const [reference, setReference] = useState(null);

    // eslint-disable-next-line consistent-return
    async function loadDatas(tab: string) {
        try {
            setLoading(true);

            if (tab === 'libraries') {
                const { data } = await LibraryHttpService.getLibraries({});

                const optionList = data.data.map((library: any) => {
                    return {
                        label: library.title,
                        value: library.id,
                    };
                });

                setOptionsLibraries(optionList);

                return optionList;
            }

            if (tab === 'modules') {
                const { data } = await LibraryHttpService.getModules({});

                const defaultOption: any = [
                    {
                        label: 'Nenhum',
                        value: null,
                    },
                ];

                const optionsList = data.data.map((module: any) => {
                    return {
                        label: module.name,
                        value: module.id,
                    };
                });

                setOptionsModules(defaultOption.concat(optionsList));

                return optionsList;
            }

            if (tab === 'brands') {
                const { data } = await LibraryHttpService.getBrands({});

                const defaultOption: any = [
                    {
                        label: 'Nenhuma',
                        value: null,
                    },
                ];

                const optionsList = data.data.map((brand: any) => {
                    return {
                        label: brand.name,
                        value: brand.id,
                    };
                });

                setOptionsBrands(defaultOption.concat(optionsList));

                return optionsList;
            }

            if (tab === 'categories') {
                const { data } = await LibraryHttpService.getBrandsCategories(
                    {},
                );

                const defaultOption: any = [
                    {
                        label: 'Nenhuma',
                        value: null,
                    },
                ];

                const optionsList = data.data.map((category: any) => {
                    return {
                        label: `(${category.brand.name}) ${category.name}`,
                        value: category.id,
                    };
                });

                setOptionsCategories(defaultOption.concat(optionsList));

                return optionsList;
            }
        } catch (error) {
            const err = error as any;

            if (isFriendlyHttpError(err)) return toast.error(err.message);

            toast.error('Erro ao carregar opções');
        } finally {
            setLoading(false);
        }
    }

    async function prepareData() {
        const types = ['libraries', 'modules', 'brands', 'categories'];
        const optionsList: any = {};

        // eslint-disable-next-line no-restricted-syntax
        for (const type of types) {
            // eslint-disable-next-line no-await-in-loop
            optionsList[type] = await loadDatas(type);
        }

        if (!id) return;

        const { data } = await LibraryHttpService.getItem(id);

        const keys = Object.keys(data);

        // eslint-disable-next-line no-restricted-syntax
        for (const key of keys) {
            if (key === 'fileId') {
                if (!data.file) continue;

                setFile({
                    name: data.file.fileName,
                    awsFileUrl: data.file.awsFileUrl,
                });

                continue;
            }

            if (key === 'reference') {
                const option = options.find(
                    (option: any) => option.value === data.reference,
                );

                if (option.value === LibraryItemReference.FAQ) {
                    setIgnoredTabs([
                        'files',
                        'modules',
                        'brands',
                        'categories',
                    ]);
                } else if (option.value === LibraryItemReference.Video) {
                    setIgnoredTabs(['modules', 'brands', 'categories']);
                } else {
                    setIgnoredTabs([]);
                }

                setReference(option.value);
                setValue(key, option);
                continue;
            }

            if (key === 'libraryId') {
                setValue(
                    key,
                    optionsList.libraries.find(
                        (option: any) => option.value === data.libraryId,
                    ),
                );
                continue;
            }

            if (key === 'libraryModuleId') {
                setValue(
                    key,
                    optionsList.modules.find(
                        (option: any) => option.value === data.libraryModuleId,
                    ),
                );
                continue;
            }

            if (key === 'libraryBrandId') {
                const option = optionsList.brands.find(
                    (option: any) => option.value === data.libraryBrandId,
                );

                setValue(key, option);
                setDisabled((disabled: string[]) =>
                    option && option.value
                        ? disabled.concat('libraryBrandCategoryId')
                        : disabled.filter(
                              (d) => d !== 'libraryBrandCategoryId',
                          ),
                );

                continue;
            }

            if (key === 'libraryBrandCategoryId') {
                const option = optionsList.categories.find(
                    (option: any) =>
                        option.value === data.libraryBrandCategoryId,
                );

                setValue(key, option);
                setDisabled((disabled: string[]) =>
                    option && option.value
                        ? disabled.concat('libraryBrandId')
                        : disabled.filter((d) => d !== 'libraryBrandId'),
                );

                continue;
            }

            setValue(key, data[key]);
        }
    }

    useEffect(() => {
        prepareData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <StyledRow className="ml-0 mt-2 mr-1">
                <Col>
                    <StyledTitle>
                        {reference === LibraryItemReference.FAQ
                            ? 'Pergunta'
                            : 'Título'}
                    </StyledTitle>
                </Col>
            </StyledRow>
            <Row className="ml-0 mr-1">
                <Col>
                    <Form.Group>
                        <Form.Control
                            ref={register}
                            name="title"
                            type="string"
                            className="form-control-nexen"
                            placeholder="Digite aqui..."
                        />
                    </Form.Group>
                </Col>
            </Row>
            <StyledRow className="ml-0 mt-2 mr-1">
                <Col>
                    <StyledTitle>
                        {reference === LibraryItemReference.FAQ
                            ? 'Resposta'
                            : 'Descrição'}
                    </StyledTitle>
                </Col>
            </StyledRow>
            <Row className="ml-0 mr-1">
                <Col>
                    <Form.Group>
                        <Form.Control
                            ref={register}
                            name="description"
                            type="string"
                            as="textarea"
                            className="form-control-nexen"
                            placeholder="Digite aqui..."
                        />
                    </Form.Group>
                </Col>
            </Row>
            <StyledRow className="ml-0 mt-2 mr-1">
                <Col>
                    <StyledTitle>Biblioteca</StyledTitle>
                </Col>
            </StyledRow>
            <Row className="ml-0 mr-1">
                {optionsLibraries.length > 0 && (
                    <>
                        <Col>
                            <Controller
                                isDisabled={!!id}
                                isClearable={false}
                                className="form-control-nexen"
                                as={Select}
                                control={control}
                                name="libraryId"
                                options={optionsLibraries}
                                isMulti={false}
                                styles={{
                                    control: tradeControl,
                                    option: optionsTheme,
                                }}
                                theme={theme}
                                placeholder="Selecione uma biblioteca..."
                                defaultValue={optionsLibraries[0]}
                                handleMouseDownOnMenu={false}
                                menuPosition="fixed"
                                menuShouldBlockScroll={false}
                                menuShouldScrollIntoView
                            />
                        </Col>
                    </>
                )}
                {optionsLibraries.length === 0 && (
                    <Col>
                        <Spinner
                            className="mr-2 mt-2"
                            size="sm"
                            animation="border"
                        />
                        Carregando...
                    </Col>
                )}
            </Row>
            {!(
                ignoredTabs.includes('files') &&
                ignoredTabs.includes('items') &&
                ignoredTabs.includes('modules') &&
                ignoredTabs.includes('brands') &&
                ignoredTabs.includes('categories')
            ) && (
                <Row className="ml-0 mt-3 mr-1">
                    <Col>
                        <Tabs
                            fill
                            defaultActiveKey="files"
                            className="mb-3"
                            style={{
                                fontSize: 13,
                            }}
                        >
                            {!ignoredTabs.includes('files') && (
                                <Tab
                                    eventKey="files"
                                    title="Arquivo"
                                    disabled={disabled.includes('files')}
                                    tabClassName={
                                        disabled.includes('files')
                                            ? 'tab-disabled-nexen'
                                            : ''
                                    }
                                >
                                    <Dropzone
                                        accept={fileExtensionsAllowed}
                                        onDropRejected={() => {
                                            toast.error(
                                                'Formato do aquivo não permitido.',
                                            );
                                        }}
                                        onDrop={(file: any) => {
                                            const data = new FormData();

                                            if (file.length === 0) return;

                                            data.append(
                                                'file',
                                                file[0],
                                                file[0].name,
                                            );

                                            setForm(data);
                                            setFile(file[0]);
                                        }}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <Row className="mt-4 dropzone-title">
                                                    <Col
                                                        className="dropzone-title"
                                                        sm={8}
                                                    >
                                                        <h5
                                                            style={{
                                                                color: '#F2994A',
                                                                fontSize:
                                                                    '1rem',
                                                            }}
                                                            className="dropzone-title p-4"
                                                        >
                                                            Clique aqui para
                                                            selecionar um
                                                            arquivo ou arraste-o
                                                        </h5>
                                                    </Col>
                                                    <Col className="text-right">
                                                        {loading ? (
                                                            <Spinner
                                                                className="mr-4 mt-4"
                                                                animation="border"
                                                            />
                                                        ) : (
                                                            <Image
                                                                className="mr-4 mt-3"
                                                                src={
                                                                    fileUploadImg
                                                                }
                                                            />
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row className="mt-n4 ml-4">
                                                    <h6
                                                        style={{
                                                            fontSize:
                                                                '0.813rem',
                                                        }}
                                                    >
                                                        {file && file.name}
                                                    </h6>
                                                </Row>
                                            </div>
                                        )}
                                    </Dropzone>
                                </Tab>
                            )}
                            {!ignoredTabs.includes('modules') && (
                                <Tab
                                    eventKey="modules"
                                    title="Módulo"
                                    disabled={disabled.includes(
                                        'libraryModuleId',
                                    )}
                                    tabClassName={
                                        disabled.includes('libraryModuleId')
                                            ? 'tab-disabled-nexen'
                                            : ''
                                    }
                                >
                                    <Row className="ml-0 mt-4 mr-1">
                                        <Col>
                                            <Controller
                                                isClearable={false}
                                                className="form-control-nexen"
                                                as={Select}
                                                control={control}
                                                name="libraryModuleId"
                                                options={optionsModules}
                                                isMulti={false}
                                                styles={{
                                                    control: tradeControl,
                                                    option: optionsTheme,
                                                }}
                                                theme={theme}
                                                placeholder="Selecione um módulo ..."
                                                defaultValue={optionsModules[0]}
                                                handleMouseDownOnMenu={false}
                                                menuPosition="fixed"
                                                menuShouldBlockScroll={false}
                                                menuShouldScrollIntoView
                                            />
                                        </Col>
                                    </Row>
                                </Tab>
                            )}
                            {!ignoredTabs.includes('brands') && (
                                <Tab
                                    eventKey="brands"
                                    title="Marca"
                                    disabled={disabled.includes(
                                        'libraryBrandId',
                                    )}
                                    tabClassName={
                                        disabled.includes('libraryBrandId')
                                            ? 'tab-disabled-nexen'
                                            : ''
                                    }
                                >
                                    <Row className="ml-0 mt-4 mr-1 align-items-center">
                                        <OverlayTrigger
                                            trigger="hover"
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="brands">
                                                    Quando uma marca é
                                                    selecionada, não é possível
                                                    selecionar uma categoria!
                                                </Tooltip>
                                            }
                                        >
                                            <Button
                                                variant="light"
                                                className="align-items-center"
                                                style={{
                                                    width: '3rem',
                                                    height: '3rem',
                                                    borderRadius: '100%',
                                                }}
                                            >
                                                <i
                                                    style={{ color: '#666' }}
                                                    className="fas fa-question-circle"
                                                />
                                            </Button>
                                        </OverlayTrigger>
                                        <Col>
                                            <Controller
                                                control={control}
                                                name="libraryBrandId"
                                                defaultValue={optionsBrands[0]}
                                                render={({
                                                    onChange,
                                                    value,
                                                }) => (
                                                    <Select
                                                        isClearable={false}
                                                        className="form-control-nexen"
                                                        options={optionsBrands}
                                                        isMulti={false}
                                                        styles={{
                                                            control:
                                                                tradeControl,
                                                            option: optionsTheme,
                                                        }}
                                                        theme={theme}
                                                        placeholder="Selecione uma marca..."
                                                        handleMouseDownOnMenu={
                                                            false
                                                        }
                                                        menuPosition="fixed"
                                                        menuShouldBlockScroll={
                                                            false
                                                        }
                                                        menuShouldScrollIntoView
                                                        onChange={(e: any) => {
                                                            setDisabled(
                                                                (
                                                                    disabled: string[],
                                                                ) =>
                                                                    e.value
                                                                        ? disabled.concat(
                                                                              'libraryBrandCategoryId',
                                                                          )
                                                                        : disabled.filter(
                                                                              (
                                                                                  d,
                                                                              ) =>
                                                                                  d !==
                                                                                  'libraryBrandCategoryId',
                                                                          ),
                                                            );
                                                            onChange(e);
                                                        }}
                                                        value={value}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </Tab>
                            )}
                            {!ignoredTabs.includes('categories') && (
                                <Tab
                                    eventKey="categories"
                                    title="Categoria"
                                    disabled={disabled.includes(
                                        'libraryBrandCategoryId',
                                    )}
                                    tabClassName={
                                        disabled.includes(
                                            'libraryBrandCategoryId',
                                        )
                                            ? 'tab-disabled-nexen'
                                            : ''
                                    }
                                >
                                    <Row className="ml-0 mt-4 mr-1">
                                        <OverlayTrigger
                                            trigger="hover"
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="categories">
                                                    Quando uma categoria é
                                                    selecionada, não é possível
                                                    selecionar uma marca!
                                                </Tooltip>
                                            }
                                        >
                                            <Button
                                                variant="light"
                                                className="align-items-center"
                                                style={{
                                                    width: '3rem',
                                                    height: '3rem',
                                                    borderRadius: '100%',
                                                }}
                                            >
                                                <i
                                                    style={{ color: '#666' }}
                                                    className="fas fa-question-circle"
                                                />
                                            </Button>
                                        </OverlayTrigger>
                                        <Col>
                                            <Controller
                                                control={control}
                                                name="libraryBrandCategoryId"
                                                defaultValue={
                                                    optionsCategories[0]
                                                }
                                                render={({
                                                    onChange,
                                                    value,
                                                }) => (
                                                    <Select
                                                        isClearable={false}
                                                        className="form-control-nexen"
                                                        options={
                                                            optionsCategories
                                                        }
                                                        isMulti={false}
                                                        styles={{
                                                            control:
                                                                tradeControl,
                                                            option: optionsTheme,
                                                        }}
                                                        theme={theme}
                                                        placeholder="Selecione uma categoria..."
                                                        handleMouseDownOnMenu={
                                                            false
                                                        }
                                                        menuPosition="fixed"
                                                        menuShouldBlockScroll={
                                                            false
                                                        }
                                                        menuShouldScrollIntoView
                                                        onChange={(e: any) => {
                                                            setDisabled(
                                                                (
                                                                    disabled: string[],
                                                                ) =>
                                                                    e.value
                                                                        ? disabled.concat(
                                                                              'libraryBrandId',
                                                                          )
                                                                        : disabled.filter(
                                                                              (
                                                                                  d,
                                                                              ) =>
                                                                                  d !==
                                                                                  'libraryBrandId',
                                                                          ),
                                                            );
                                                            onChange(e);
                                                        }}
                                                        value={value}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </Tab>
                            )}
                        </Tabs>
                    </Col>
                </Row>
            )}
        </>
    );
}

export default ItemsModalInputs;
