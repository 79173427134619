import React from 'react';
import { Switch } from 'react-router-dom';
import { Profile } from '../../pages/Profile';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';

export const userProfileRoute = new Route('/perfil/usuario');
export const companyProfileRoute = new Route('/perfil/empresa');

export const ProfileRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={userProfileRoute.path}
            component={Profile}
            isPrivate
            exact
        />

        <RouteWrapper
            path={companyProfileRoute.path}
            component={Profile}
            isPrivate
            exact
        />
    </Switch>
);
