import { Dropdown, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import sellerStatus from '../../../constants/sellerStatus';
import Seller from '../../../shared/interfaces/seller.interface';
import Role from '../../../constants/roles';
import { useAuth } from '../../../contexts/authContext';
import useSellers from '../../../hooks/useSellers';
import userHasRoles from '../../../utils/userHasRoles';
import { ReactComponent as ReactMoreIcon } from '../../../assets/icons/more.svg';

interface SellerTableDropdownProps {
    seller: Seller;
    onUpdateDigitalContract: (id: number) => void;
}

export default function SellerTableDropdown({
    seller,
    onUpdateDigitalContract,
}: SellerTableDropdownProps) {
    const history = useHistory();

    const { user } = useAuth();

    const isRegistration = userHasRoles(user, [Role.Registration]);
    const isAdmin = userHasRoles(user, [Role.Administrator]);

    const {
        setSelectedSeller,
        setShowDigitalContractModal,
        setShowBlockIntegratorModal,
        setShowUnblockIntegratorModal,
    } = useSellers();

    return (
        <Dropdown key="left">
            <Dropdown.Toggle bsPrefix="nexen" as={Button} variant="text">
                <ReactMoreIcon fill="#bdbdbd" width="10" height="20" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item
                    onClick={async () => {
                        history.push(`integrators/${seller.id}/view`);
                    }}
                >
                    Visualizar/Resolver
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={async () => {
                        history.push(`integrators/${seller.id}/edit`);
                    }}
                    disabled={seller.status === sellerStatus.Disabled}
                >
                    Editar
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => {
                        setSelectedSeller(seller);
                        setShowDigitalContractModal(true);
                    }}
                    disabled={
                        !(isAdmin || isRegistration) ||
                        (seller.status !== sellerStatus.Pending &&
                            seller.status !== sellerStatus.Approved)
                    }
                >
                    Gerar Contrato Digital
                </Dropdown.Item>
                {isAdmin && (
                    <Dropdown.Item
                        onClick={() => onUpdateDigitalContract(seller.id)}
                        disabled={
                            ![
                                sellerStatus.Pending,
                                sellerStatus.Approved,
                            ].includes(seller.status)
                        }
                    >
                        Buscar Contrato Assinado
                    </Dropdown.Item>
                )}
                {seller.status === sellerStatus.Approved && (
                    <Dropdown.Item
                        onClick={() => {
                            setSelectedSeller(seller);
                            setShowBlockIntegratorModal(true);
                        }}
                    >
                        Bloquear
                    </Dropdown.Item>
                )}
                {seller.status === sellerStatus.Disabled && (
                    <Dropdown.Item
                        onClick={() => {
                            setSelectedSeller(seller);
                            setShowUnblockIntegratorModal(true);
                        }}
                    >
                        Desbloquear
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}
