import React from 'react';
import { Col, Row, Card, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AccessLayout from '../../components/AccessLayout';

import WhiteRightArrow from '../../assets/icons/white_right_arrow.svg';
import Briefcase from '../../assets/icons/briefcase.svg';
import HeaderAccess from '../../components/HeaderAccess';
import { StyledDiv } from './styles';
import { signInRoute } from '../../routes/config';

const SelectModuleRegistration: React.FC = () => (
    <AccessLayout>
        <Row>
            <Col md={1} />
            <Col md={10} className="mt-n5 mb-5">
                <HeaderAccess setStep={undefined} goBackTo={signInRoute.path} />

                <h4 className="mt-5">
                    <strong style={{ fontFamily: 'Branding', fontSize: 30 }}>
                        Crie uma nova conta
                    </strong>
                </h4>
                <p style={{ color: '#383838' }}>
                    Os seus dados serão preservados. Nossa plataforma é 100%
                    segura.
                </p>
                <hr />

                <Card
                    style={{
                        background:
                            'linear-gradient(90deg, #7800E1 0%, #F6700F 82.78%, #FFB855 100%)',
                        borderRadius: 10,
                        textDecoration: 'none',
                    }}
                    as={Link}
                    to="./signup"
                    className="mt-5"
                >
                    <Card.Body>
                        <Row>
                            <Col>
                                <Image
                                    style={{
                                        backgroundColor: '#fff',
                                        padding: 5,
                                        borderRadius: 10,
                                    }}
                                    src={Briefcase}
                                />
                            </Col>
                            <Col>
                                <StyledDiv className="mt-3">
                                    <Card.Subtitle className="mb-2 text-muted">
                                        <strong
                                            style={{
                                                color: '#FFF',
                                                fontSize: 20,
                                            }}
                                        >
                                            Integrador
                                        </strong>
                                    </Card.Subtitle>
                                    <Card.Text style={{ color: '#FFF' }}>
                                        Faça parte desse movimento, seja um
                                        integrador nexen.
                                    </Card.Text>
                                </StyledDiv>
                            </Col>
                            <Col xl={1}>
                                <Image
                                    className="float-right mt-4"
                                    src={WhiteRightArrow}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </AccessLayout>
);

export default SelectModuleRegistration;
