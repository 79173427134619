/* eslint-disable react/destructuring-assignment */
import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = (props: any) => (
    <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        breakLabel="..."
        containerClassName="float-right pagination"
        pageLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        breakLinkClassName="page-link"
        breakClassName="page-item"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
        disabledClassName="disabled"
        activeClassName="active"
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        pageCount={props.pages}
        forcePage={props.page - 1}
        onPageChange={(page: any) => props.onPageChange(page.selected + 1)}
    />
);

export default Pagination;
