const fileExtensionsAllowed = [
    '.pdf',
    '.doc',
    '.docx',
    '.zip',
    '.ond',
    '.mp4',
    '.webm',
    '.ogg',
    '.pan',
    '.jpeg',
    '.png',
    '.jpg',
    '.xls',
    '.ppt',
    '.pptx',
    '.xlsx',
    '.rar',
];

export default fileExtensionsAllowed;
